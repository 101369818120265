import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, Link, useParams, Route } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import { useQuery } from 'react-query';
import MetaTags from 'react-meta-tags';

import './pages.css';
import './FindPlans.css';

import Headbar from '../components/Headbar';
import Sidebar from '../components/Sidebar';
import SetEnvironment from '../components/SetEnvironment';
import LoadingOverlay from '../components/LoadingOverlay';

function NotFoundErrorPage( props ) {
    const history = createBrowserHistory({forceRefresh:true});

    return(
         <div style={{fontFamily: "DM Sans"}}>
             <MetaTags>
                <title>
                    Page Not Found - MedicareBits
                </title>
                <meta name="description" content=""/>
                <meta property="og:title" content="Page Not Found - MedicareBits"/>
                <meta name="robots" content="noindex, follow" />
                <link rel="canonical" href={`https://plans.medicarebits.com/404`}></link>
            </MetaTags>
            <div style={{marginLeft: '15%'}}>
                <div style={{width: "130%", height:"115px", marginLeft: "-30%", padding: "30px", paddingLeft: "5%", backgroundColor: "white"}}>
                    <div style={{marginLeft: "24.4%", fontFamily: "DM Serif Display", fontSize: "2em"}}>MedicareBits</div>
                    <div style={{color: 'black', width: '100%', height: '30px', paddingTop: '10px', fontSize: '16px', fontWeight: '700', marginLeft: "24.4%"}}>
                        <a href="https://www.medicarebits.com" style={{marginRight: "20px", color: 'black'}}>HOME</a>
                        <a href="https://medicarebits.com/?p=171" style={{marginRight: "20px", color: 'black'}}>MEDICARE ADVANTAGE</a>
                        <a href="https://medicarebits.com/?p=179" style={{marginRight: "20px", color: 'black'}}>MEDICARE SUPPLEMENT</a>
                        <a href="https://medicarebits.com/?p=184" style={{marginRight: "20px", color: 'black'}}>DRUG PLANS</a>
                        <a href="https://medicarebits.com/?p=279" style={{marginRight: "20px", color: 'black'}}>SPECIAL NEEDS</a>
                        <a href="https://www.plans.medicarebits.com/findplans" style={{marginRight: "20px", color: 'black'}}>FIND PLANS</a>
                    </div>
                </div>             
                <div className="horCont" style={{marginLeft: '5%', marginTop: '20px', marginBottom: '10px', justifyContent: "normal"}}>
                    <><a className="breadcrumb" href={`${window.location.origin}/findplans`}>Find Plans</a></><><div className="breadcrumb">{">"}</div><a className="breadcrumb">Page Not Found</a></>
                </div>
                <div style={{marginLeft: "5%", width: "70%"}}>
                    <div style={{fontSize: "3em", fontFamily: "DM Serif Display"}}>Page Not Found</div><br/><br/>
                    <div style={{marginBottom: '20px', width: '60%'}}>
                        The page you are looking for is not available. It might have moved or been deleted.
                    </div>
                    <div style={{backgroundColor: 'white', maxWidth: '800px', minWidth: '300px', padding: '60px', borderRadius: "35px"}}>
                        <a class="whiteButton" style={{padding: "15px", width: '50%', minWidth: '300px'}} href="/"><h3>Find plans in your area</h3></a>
                    </div>
                </div>
            </div>
            <div className = "footer" style={{backgroundColor: "#FFF7EB"}}>
                <div className = "footer-content" style={{marginLeft:"20%", marginTop: "50px", paddingTop: "30px", width: "60%"}}>
                    <img src='https://planprovide-resources.s3.us-west-1.amazonaws.com/medicare-bits-logo.png' height='35px' style={{}}></img><br/>
                    
                    <div className="horCont" style={{fontFamily: 'DM Serif Display', justifyContent: "flex-start"}}>
                        <div style={{marginRight: "20px"}}>About us</div>
                        <div style={{marginRight: "20px"}}>Terms of Use</div>
                        <div style={{marginRight: "20px"}}>Privacy Policy</div>
                        <div style={{marginRight: "20px"}}>Disclaimers</div>
                    </div>

                    <div className="footerDetails" style={{paddingBottom: "50px", fontSize: "12px"}}>
                        MedicareBits.com is not affiliated with or endorsed by any government website entity or publication.

                        The Medicare Advantage and Medicare Part D prescription drug plan data on our site comes directly from Medicare and is subject to change.
                        Medicare has neither reviewed nor endorsed the information on our site.

                        We provide our site for educational purposes and strive to present unbiased and accurate information. However, MedicareBits is not intended as 
                        a substitute for your lawyer, doctor, healthcare provider, financial advisor, or pharmacist. For more information on your Medicare coverage, 
                        please be sure to seek legal, medical, pharmaceutical, or financial advice from a licensed professional or telephone Medicare at 1-800-633-4227.

                        We are an independent education, research, and technology company. We are not affiliated with any Medicare plan, plan carrier, healthcare provider, 
                        or insurance company. We are not compensated for Medicare plan enrollments.

                        Limitations, copayments, and restrictions may apply.
                        We make every effort to show all available Medicare Part D or Medicare Advantage plans in your service area. However, since our data is provided 
                        by Medicare, it is possible that this may not be a complete listing of plans available in your service area. For a complete listing please contact 
                        1-800-MEDICARE (TTY users should call 1-877-486-2048), 24 hours a day/7 days a week or consult www.medicare.gov.
                    </div>
                </div>
            </div>
         </div>
    );
}



export default withRouter(NotFoundErrorPage);
