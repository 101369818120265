import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Router, Route, Switch, useLocation, useHistory, Redirect } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';

// import Home from './pages/Home';
import AddClient from './pages/AddClient';
import Agency from './pages/Agency';
import Applications from './pages/Applications';
import BrokerSign from './pages/Broker-Sign';
import BrokerSignPage from './pages/Broker-Sign';
import CheckAuth0Account from './components/CheckAuth0Account';
import ClientDetails from './pages/Client-DetailsPage';
import Clients from './pages/ClientsPage';
import CountyStatePage from './pages/CountyStateMAPage';
import Dashboard from './pages/Dashboard';
import ErrorPage from './pages/ErrorPage';
import FindPlans from './pages/FindPlans';
import Help from './pages/HelpPage';
import LiveSalesSessionBrokerView from './pages/LiveSalesSessionBrokerView';
import LiveSalesSessionBrokerStart from './pages/LiveSalesSessionBrokerStart';
import LiveSalesSessionClientView from './pages/LiveSalesSessionClientView';
import Login from './pages/Login';
import NotFoundErrorPage from './pages/NotFoundErrorPage';
import PDFTest from './pages/PDFTest';
import Profile from './pages/Profile';
import Register from './pages/Register';
import StandaloneSalesSessionBrokerView from './pages/StandaloneSalesSessionBrokerView';
import StandaloneSalesSessionBrokerStart from './pages/StandaloneSalesSessionBrokerStart';
import StandaloneSalesSessionBrokerLogin from './pages/StandaloneSalesSessionBrokerLogin';
import WebRTCTest from './pages/WebRTCTest';
import WritingNumbersPage from './pages/WritingNumbersPage';

import AncillaryCoverage        from './pages/subpages/Applications/AncillaryCoverage';
import AncillaryPlansList       from './pages/subpages/Applications/AncillaryPlansList';
import ApplicationsListPage     from './pages/subpages/Applications/ApplicationsListPage';
import ApplicationWritingNumber from './pages/subpages/Applications/ApplicationWritingNumber';
import PickClient        from './pages/subpages/Applications/PickClient';
import PickPlan          from './pages/subpages/Applications/PickPlan';
import PlansList         from  './pages/subpages/Applications/PlansList';
import CoverageQuestions from './pages/subpages/Applications/CoverageQuestions';
import ClientFormPage    from './pages/subpages/Applications/ClientFormPage';
import ReadyToSign       from './pages/subpages/Applications/ReadyToSign';
import ReadyToSubmit     from './pages/subpages/Applications/ReadyToSubmit';
import Submitted         from './pages/subpages/Applications/Submitted';

import { ENVProvider, ENVContext } from './components/ENVContext';
import { NavigationProvider } from './components/NavigationContext';
import { BrokerProvider, BrokerContext } from './components/BrokerContext';
import { PlanClientApplicationProvider } from './components/PCAContext';

import './index.css'

import registerServiceWorker from './registerServiceWorker';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const controller = {
  controller: new AbortController(),
  abort: function() {
    this.controller.abort();
    this.controller = new AbortController();
    // if (window.confirm("Are you sure you want to navigate away from this page? Your progress may not be saved.")) {
    //   this.controller.abort();
    //   this.controller = new AbortController();
    // }
  },
}

function auth0Logout() {
  window.open(window.location.origin + '/auth0/logout', '_self');
}

function standaloneAuth0Logout() {
  window.open(window.location.origin + '/auth0/standalone_logout', '_self');
}

const history = createHistory();

function App() {

   return (
     <>
       <ENVProvider>
        <NavigationProvider>
          <BrokerProvider>
            <PlanClientApplicationProvider>
              <Router history={history}>
                <CheckAuth0Account>
                  <ScrollToTop></ScrollToTop>
                  <AppBody />
                </CheckAuth0Account>
              </Router>
            </PlanClientApplicationProvider>
          </BrokerProvider>
        </NavigationProvider>
       </ENVProvider>
     </>
   )
}

function AppBody() {
  const [broker, setBroker] = useContext(BrokerContext);

  return (
    (String(window.location.href) === 'https://plans.medicarebits.com/' || String(window.location.href) === 'https://www.plans.medicarebits.com/') ?
      <>
        <Switch>
          <Route path="" exact render={(props) => (<FindPlans controller={controller}/>)} />
          <Route path="/404" exact render={(props) => (<NotFoundErrorPage controller={controller}/>)} />
        </Switch>
      </>
      :
      (String(window.location.href).includes('/standalone-meeting')) ?
        <>
          {
            <>
              <img src="https://planprovide-resources.s3.us-west-1.amazonaws.com/PlanProvide-logo-black.png" width="200px"
                style={{ position: "absolute", top: "9px", left: "27px", zIndex: 3 }} />
              <div style={{ position: "absolute", top: "25px", left: "222px", zIndex: 3, fontSize: "20px" }}>Meetings</div>
              <div className="horCont" style={{
                width: "100%", height: "20px", position: "absolute",
                display: "flex", flexDirection: "row-reverse", paddingRight: "20px",
              }}>
                <div style={{ color: "#c6c6c6", fontSize: "12px", marginTop: "50px" }}>
                  <u><a onClick={standaloneAuth0Logout}>{"↵ Logout"}</a></u>
                </div>
              </div> </>
          }
          <Switch>
            <Route path="/standalone-meeting" exact render={() => (<StandaloneSalesSessionBrokerLogin controller={controller} />)} />
            <Route path="/standalone-meeting/invite/:id?" exact render={() => (<StandaloneSalesSessionBrokerStart controller={controller} />)} />
            <Route path="/standalone-meeting/live-client-session/:id?/:room?" exact render={() => (<StandaloneSalesSessionBrokerView controller={controller} />)} />
          </Switch>
        </>
      :
      <div id="override-bootstrap">
        {/* <Navigation></Navigation> */}
        {broker.email ?
          <>
          <img src="https://planprovide-resources.s3.us-west-1.amazonaws.com/PlanProvide-logo-black.png" width="200px"
            style={{ position: "absolute", top: "17px", left: "27px", zIndex: 3 }} />
          <div className="horCont" style = {{width: "100%", height: "20px", position:"absolute",
                                            display: "flex", flexDirection: "row-reverse", paddingRight: "20px",}}>
            <div style={{ color: "#c6c6c6", fontSize: "12px", marginTop: "50px"}}>
              You are currently logged in as {broker.email} .<u><a onClick={auth0Logout}>{"↵ Logout"}</a></u>
            </div>
          </div> </>: <></>
        }
        <Switch>
          <Route path="/"                                      exact component={Login}/>
          <Route path="/404"                                   exact render={(props) => (<NotFoundErrorPage controller={controller}/>)} />
          <Route path="/login"                                       component={Login}/>
          <Route path="/pdftest"                                     component={PDFTest}/>
          <Route path="/profile"                                     render={(props) => (<Profile controller={controller}/>)} />
          <Route path="/writing-numbers"                             render={(props) => (<WritingNumbersPage controller={controller}/>)} />
          <Route path="/applications/:subpage?/:application_id?"     render={(props) => (<Applications controller={controller}/>)} />
          {/*
          <Route path={`/applications/applications-list/`}                  component={ApplicationsListPage}/>
          <Route path={`/applications/pick-client`}                         component={PickClient}/>
          <Route path={`/applications/pick-plan`}                           component={PickPlan}/>
          <Route path={`/applications/plans-list`}                          component={PlansList}/>
          <Route path={`/applications/writing-number`}                      component={ApplicationWritingNumber}/>

          <Route path={`/applications/client-form-page/:application_id?`}   component={ClientFormPage}/>
          <Route path={`/applications/coverage-questions/:application_id?`} component={CoverageQuestions}/>
          <Route path={`/applications/ancillary-coverage/:application_id?`} component={AncillaryCoverage}/>
          <Route path={`/applications/ready-sign/:application_id?`}         component={ReadyToSign}/>
          <Route path={`/applications/ready-submit/:application_id?`}       component={ReadyToSubmit}/>
          <Route path={`/applications/submitted/:application_id?`}          component={Submitted}/>
          */}

          <Route path="/dashboard"                                   render={(props) => (<Dashboard controller={controller}/>)} />
          <Route path="/error/:errorMessage/:hideSidebar?"           render={(props) => (<ErrorPage controller={controller}/>)} />
          <Route path="/register/:subpage?"                          render={(props) => (<Register controller={controller}/>)} />
          <Route path="/clients/:showBanner?"                  exact render={(props) => (<Clients controller={controller}/>)} />
          <Route path="/clientdetails/:id"                           render={(props) => (<ClientDetails controller={controller}/>)} />
          <Route path="/help"                                        render={(props) => (<Help controller={controller}/>)} />
          <Route path="/agency"                                      render={(props) => (<Agency controller={controller}/>)} />
          <Route path="/brokersign"                                  render={(props) => (<BrokerSignPage controller={controller}/>)} />
          <Route path="/addclient"                                   render={(props) => (<AddClient controller={controller}/>)} />
          <Route path="/findplans/:state?/:county?/:type?/:carrier?" render={(props) => (<FindPlans controller={controller}/>)} />
          <Route path="/webrtc"                                      component={WebRTCTest} />
          <Route path="/live-client-session/:room?"                  component={LiveSalesSessionClientView} />  
          <Route path="/meetings"                              exact render={() => (<LiveSalesSessionBrokerStart controller={controller}/>)}/>
          <Route path="/meetings/live-client-session/:room?"   exact render={() => (<LiveSalesSessionBrokerView controller={controller}/>)}/>
        </Switch>
      </div>
  )
}

ReactDOM.render(<App />, document.body);
registerServiceWorker();
