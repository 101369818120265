import React, { useContext, useEffect, useState } from 'react';
import { withRouter, Link, useParams, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';

import { NavigationContext } from '../../../components/NavigationContext';
import { PlanContext, ClientContext, ApplicationContext } from '../../../components/PCAContext';
import AncillaryPlanBox from '../../../components/AncillaryPlanBox';
import LoadingOverlay from '../../../components/LoadingOverlay';

import './SubmitPage.css'

import SetEnvironment from '../../../components/SetEnvironment';

const fetchApplicationsInApplicationEnvelope = async (key, application_envelope_id) => {
    if (application_envelope_id == -1) {
      return null;
    }
    let obj = {
        "application_envelope_id": application_envelope_id,
    }

    let json = JSON.stringify(obj);

    const res = await fetch('/api/fetch_applications_ready_to_submit', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: json
    })
    return res.json();
}

function removeGMT(s) {
  if (s === null) {
    return "";
  }
  return s.replace("GMT", "");
}

function Submitted(props) {
    const [plan, setPlan] = useContext(PlanContext);
    const [client, setClient] = useContext(ClientContext);
    const [application, setApplication] = useContext(ApplicationContext);

    const [dentalPlan, setDentalPlan] = useState({});
    const [visionPlan, setVisionPlan] = useState({});

    const { data: multiplePlansQuery, status: multiplePlansStatus } = useQuery(['multiplePlans', application.id], fetchApplicationsInApplicationEnvelope);

    let history = useHistory();

    return(
      <>
        {
          (multiplePlansStatus === "loading") ?
            <LoadingOverlay/> : <></>
        }
        <div className = "mainArea">
              <div className = "mainTitle">
                  <div className = "titleInfo">
                      <h1 className = "titleName">{application.date_submitted ? "Application Submitted" : "Document Completed"}</h1>
                      <p className = "titleDetails">
                      {
                        application.date_submitted ?
                        `Congratulations! Your application for ${client.first_name} ${client.last_name} has been submitted. Download a copy of the submitted application below.`
                        : `Congratulations! Your document for ${client.first_name} ${client.last_name} has been completed. Download a copy of the completed document below.`
                      }
                      </p>
                  </div>
              </div>
              <div style={{marginLeft:"5%", marginTop: "40px"}} className = "vertCont">
                  <label>Name</label>
                  <Link to = {'/clientdetails/'+client.id} className = "linkStyle">{`${client.first_name} ${client.last_name}`}</Link>
                  <label>Plans</label>
              </div>

              {
              multiplePlansStatus === 'success' && multiplePlansQuery !== null && multiplePlansQuery !== undefined && Array.isArray(multiplePlansQuery) ?
              multiplePlansQuery.sort((a, b) => parseFloat(a.premium) < parseFloat(b.premium) ? 1 : -1)
              .map((option, index) => (
               <form className = "submitAppForm"
                     name = {`form_${option.type}`}
                     id={`form_${option.type}`}
                     style={{height: "300px"}}>
                   <div style = {{display: "flex", marginLeft: "-17%", width: "128%", paddingTop: "20px", paddingLeft: "80px"}}
                        className = "vertCont submitInfo formArea">
                       <div className = "horCont">
                           <div style = {{left: "0%"}} className = "vertCont">
                               <div className = "vertCont">
                                   {option.type === 'SOA' ? <></> :
                                     <div style = {{left: "0px", marginLeft: "0px", width: "100%"}} className = "horCont boxLabels">
                                         <div style = {{width: "90%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                             <label style = {{width: "35%", marginLeft: "60px"}}>Carrier</label>
                                             <label style = {{width: "20%"}}>Plan</label>
                                             <label style = {{width: "20%"}}>Type</label>
                                             <label style = {{width: "25%"}}></label>
                                         </div>
                                     </div>
                                   }
                                   <AncillaryPlanBox
                                      type={option.type === '1' ? "Medicare Supplement" : option.type === 'dental' ? "Dental" : option.type === 'vision' ? "Vision" : option.type}
                                      companyName = {option.carrier_name}
                                      planName = {option.name}
                                      value = {option.id}
                                      notes = {option.notes}
                                      premium = {option.premium ? option.premium : "Not available"}
                                      style = {{marginLeft: "0%", width:"90%", flex: "1", border: "solid 1px #BDBDBD", boxShadow: "none"}}
                                      carrierWidth = "35%"
                                      planWidth = "20%"
                                      typeWidth = "20%"
                                      buttonWidth = "25%"
                                      hideButton
                                      hideDetailsButton
                                      hidePremium
                                   ></AncillaryPlanBox>
                               </div>
                           </div>
                       </div>
                       <input type="button" className = "secondaryButtonStyle"
                              value="Download Application"
                              name={`download_button_${index}`} id={`download_button_${index}`}
                              style = {{width: "210px", marginTop: "10px"}}
                              onClick = {() => {window.open(option.download_link)}}></input>
                       <div style={{marginTop: "30px", marginBottom: "10px", width: "700px"}}
                           name={`submission_text_${index}`} id={`submission_text_${index}`}>
                           {option.date_submitted ? "Emailed on " + removeGMT(option.date_submitted) + " to " + option.submit_email + "." : null}
                       </div>
                   </div>
               </form>
              )) : <></>
            }
        </div>
      </>
    );
}

export default Submitted;
