import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { ClientContext } from '../../../components/PCAContext';
import { BrokerContext } from '../../../components/BrokerContext';

import mixpanel from 'mixpanel-browser';

import './../../pages.css';
import './../../ClientFormPage.css';

import WritingNumbers from '../../../components/WritingNumbers';
import InfiniteQueryList from '../../../components/InfiniteQueryList';
import AgencyNavigation from '../../../components/AgencyNavigation';
import Banner from '../../../components/Banner';

// Fetch all applications by broker id
const fetchClientApplications = async (key, broker_id, client_id, page_num=1) => {
  if (client_id != -1) {
    let json = JSON.stringify({"broker_id": broker_id, "client_id": client_id, "page_num": page_num});

    const res = await fetch('/api/fetch_paginated_client_applications', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: json
    });
    return res.json();
  } else {
    return [];
  }
}

function AgencySettings(props) {
  let history = useHistory();

  const [client, setClient] = useContext(ClientContext);
  const [broker, setBroker] = useContext(BrokerContext);

  const [showBanner, setShowBanner] = useState(false);
  let { id, brokerId, brokerName } = useParams();

  let bannerSuccessMessage = "Client Updated!";
  let bannerFailureMessage = "An unknown error occurred."

  return (
    <>
      {showBanner ?
          <Banner message={"Agency contracting updated!"}></Banner>
          : <></>
      }
      <AgencyNavigation current="settings"/>
      <div>
        <WritingNumbers
          broker={broker}
          buttonLabel="Update"
          type="agency"
          carrierLabelWidth="52%"
          setShowBanner = {setShowBanner}
          style={{padding: "40px"}}
          controller={props.controller}
        />
      </div>
    </>
  );
}

export default withRouter(AgencySettings);
