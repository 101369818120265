import Agency from '../data/agency';
import ApplicationEnvelope from '../data/applicationEnvelope';
import Broker from '../data/broker';
import Client from '../data/client';

export const create = async (data, signal) => {
    const res = await fetch('/api/carrier/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(data),
        signal: signal,
    })

    return res.json();
}

export const get = async (id, signal) => {
    let filters = "";
    if (id != null) {
        filters = `?id=${id}`;
    }

    const res = await fetch(`/api/carrier${filters}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        signal: signal,
    })

    return res.json();
}

export const update = async (data) => {
    const res = await fetch('/api/carrier/update', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(data),
    })
    
    return res.json();
}

