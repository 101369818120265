import React, { useContext, useState, useEffect } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { BrokerContext } from './BrokerContext';
import { PlanContext, ClientContext, ApplicationContext } from './PCAContext';
import { ENVContext } from './ENVContext';

import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';

import mixpanel from 'mixpanel-browser';

import InputField from '../components/InputField';
import StateForm from '../components/StateForm';
import Banner from '../components/Banner';
import SetEnvironment from '../components/SetEnvironment';
import LoadingOverlay from '../components/LoadingOverlay';
import FormikInputField from '../components/FormikInputField';

import { printRFC3339Date } from '../utils.js';

const ClientDetailsForm = (props) => {
    let history = useHistory();

    const [ENV, setENV] = useContext(ENVContext);
    const [client, setClient] = useContext(ClientContext);
    const [application, setApplication] = useContext(ApplicationContext);
    const [broker, setBroker] = useContext(BrokerContext);
    const [plan, setPlan] = useContext(PlanContext);

    const [showBanner, setShowBanner] = useState(false);
    const [bannerStatus, setBannerStatus] = useState(false);
    const [initialFormSubmit, setInitialFormSubmit] = useState(false);

    const [sameAddress, setSameAddress] = useState(true);
    const [showBillPref, setShowBillPref] = useState(false);

    const [loading, setLoading] = useState(false);

    if (client.dob && client.dob != '') {
      var dob = new Date(client.dob.replace(" GMT", ""));
      console.log("DOB")
      console.log(dob)
    }
    else {
      var dob = '';
    }
    if (client.part_a_start_date && client.part_a_start_date != '') {
      var part_a = new Date(client.part_a_start_date.replace(" GMT", ""));
    } else {
      var part_a ='';
    }
    if (client.part_b_start_date && client.part_b_start_date != '') {
      var part_b = new Date(client.part_b_start_date.replace(" GMT", ""));
    } else {
      var part_b = '';
    }

    useEffect(() => {
      if (props.client_id != -1) {
        let client_json = JSON.stringify(props.client_id);
        fetch('/api/get_client_by_id', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: client_json
        }).then(res => res.json())
        .then(client_data => {
          if (client_data["status"] === "error") {
            let errorMessage = client_data["errorMessage"];
            history.push(`/error/${errorMessage}`)
          } else {
            console.log("CLIENT DATA")
            console.log(client_data);
            setClient({...client, ...client_data});
          }
        });
      }
    }, []);

    useEffect(() => {
      console.log("CLIENT CHANGED")
      console.log(client);
      console.log(client.billing_preference);
      setShowBillPref(client.billing_preference === "direct_debit");
    }, [client]);


    function handleSameAddrCheckbox() {
      setSameAddress(!sameAddress);
    }

    function handleFormSubmit(object) {
      console.log("Client submit started");

      console.log(object);

      // Creates Date objects for dob, part a, and part b
      // object["dob"] = new Date(`${object.DOBMonth}/${object.DOBDay}/${object.DOBYear}`);
      // object["part_a_start_date"] = new Date(`${object.AMonth}/01/${object.AYear}`);
      // object["part_b_start_date"] = new Date(`${object.BMonth}/01/${object.BYear}`);

      object["broker_id"] = parseInt(broker["id"]);
      object["plan_id"] = parseInt(plan["id"]);
      object["client_id"] = parseInt(client["id"]);

      object["same_address"] = sameAddress;

      if (sameAddress) {
        delete object["mailing_state"];
      }

      object["agency_id"] = broker.agency_id;
      object["agency_name"] = broker.agency_name;
      object["agency_npn"] = broker.agency_npn;
      object["broker_name"] = broker.first_name + " " + broker.last_name;
      object["broker_npn"] = broker.broker_npn;
      object["broker_email"] = broker.email;
      object["broker_phone"] = broker.phone;
      object["premium"] = plan.premium || "";

      let json = JSON.stringify(object);
      // Call the parent's handleSubmit function
      props.handleSubmit(json).then((status) => {
        // Set the banner with the appropriate status based on parent's result
        setBannerStatus(status);
        setShowBanner(true);
        // bannerTriggerOn();
        setLoading(false);
      });

      if (props.mixpanelEvent) {
        mixpanel.init(ENV.MIXPANEL_TOKEN);
        mixpanel.track(props.mixpanelEvent, {client_id: client.id});
      }

      window.scrollTo(0,0);

      return Promise.resolve("Dummy response to keep the console quiet");
    }

    function todayString() {
      var today = new Date();
      var dd = '01';//String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 2).padStart(2, '0');//String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = String(today.getFullYear());
      console.log(yyyy + '-' + mm + '-' + dd + '-')

      return yyyy + '-' + mm + '-' + dd;
    }

    return(
      <>
        {
          loading
          ? <LoadingOverlay></LoadingOverlay>
          : <div style = {{display: "none"}}></div>
        }
        <div style={props.style}>
          <div className = "mainArea" style={{width:"90%"}}>
            <div className = "mainTitle">
                {showBanner ?
                  bannerStatus === "success"
                  ? (<Banner style={{"marginTop": "-49px"}}
                            message ={props.bannerSuccessMessage}
                            isSuccess={true}></Banner>)
                  : (<Banner style={{"marginTop": "-49px"}}
                            message ={props.bannerFailureMessage}
                            isSuccess={false}></Banner>)
                : <></>}
                <div className = "titleInfo">
                  <h1 className = "titleName">{props.title}</h1>
                  {props.ownerBroker ? <p>Broker: <a className="linkStyle" onClick={() => {history.push(`/agency/brokerdetails/${props.ownerBrokerId}`)}}>{props.ownerBroker}</a></p> : <></>}
                  <p className = "titleDetails">{props.subTitle}</p>
                </div>
            </div>
            <>
              <Formik
                initialValues={{
                  title: client.title || 'Mr.',
                  gender: client.gender || 'male',
                  first_name: client.first_name || '',
                  middle_initial: client.middle_initial || '',
                  last_name: client.last_name || '',
                  email: client.email || '',
                  phone: client.phone || '',
                  address: client.address || '',
                  city: client.city || '',
                  state: client.state || '',
                  zip: client.zip || '',

                  dob: printRFC3339Date(client.dob) || null,
                  effective_date: printRFC3339Date(application.effective_date) || null,
                  medicare_claim_number: client.medicare_claim_number || '',
                  aarp_membership_number: client.aarp_membership_number || '',
                  part_a_start_date: printRFC3339Date(client.part_a_start_date) || null,
                  part_b_start_date: printRFC3339Date(client.part_b_start_date) || null,

                  billing_preference: client.billing_preference,
                  bank_name: client.bank_name || '',
                  bank_routing: client.bank_routing || '',
                  bank_account: client.bank_account || '',
                }}
                validationSchema={Yup.object({
                  title: Yup.string()
                    .required('This field is required'),
                  gender: Yup.string()
                    .required('This field is required'),
                  first_name: Yup.string()
                    .required('This field is required'),
                  middle_initial: Yup.string()
                    .max(1, 'Invalid format.')
                    .matches(/^[A-Za-z]+$/, 'Invalid format.')
                    .required('This field is required'),
                  last_name: Yup.string()
                    .required('This field is required'),
                  email: Yup.string()
                    .email('Invalid email address')
                    .required('This field is required'),
                  phone: Yup.string()
                    .matches(/\d{10}/, 'Invalid phone number')
                    .required('This field is required'),
                  address: Yup.string()
                    .required('This field is required'),
                  city: Yup.string()
                    .required('This field is required'),
                  zip: Yup.string()
                    .matches(/\d{5}/, 'Invalid zip code.')
                    .required('This field is required'),

                  dob: plan.type === 'SOA' ? null : Yup.date()
                    .required('This field is required')
                    .typeError('This field is required')
                    .min('1900-01-01', 'Birthdate must be later than 01-01-1900')
                    .max('2100-01-01', 'Birthdate must be earlier than 01-01-2100'),
                  effective_date: plan.type !== 'SOA' && application.effective_date ? Yup.date()
                    .required('This field is required')
                    .min(todayString(), 'Effective Date must be later than ' + todayString())
                    .max('2100-01-01', 'Effective Date must be earlier than 01-01-2100') : undefined,
                  medicare_claim_number: plan.type === '1' ? Yup.string()
                    .matches(/\d{1}[a-zA-Z]{1}\w{1}\d{1}[a-zA-Z]{1}\w{1}\d{1}[a-zA-Z]{2}\d{2}/,
                      'Please enter a valid medicare claim number.')
                    .required('This field is required') : null,

                  part_a_start_date: plan.type === '1' ? Yup.date()
                    .required('This field is required')
                    .min('1900-01-01', 'Medicare Part A Start Date must be later than 01-01-1900')
                    .max('2100-01-01', 'Medicare Part A Start Date must be earlier than 01-01-2100') : null,
                  part_b_start_date: plan.type === '1' ? Yup.date()
                    .required('This field is required')
                    .min('1900-01-01', 'Medicare Part B Start Date must be later than 01-01-1900')
                    .max('2100-01-01', 'Medicare Part B Start Date must be earlier than 01-01-2100') : null,


                })}
                onSubmit={(values, actions) => {
                  setLoading(true);
                  console.log("SUBMIT!");
                  console.log(values);
                  handleFormSubmit(values);
                  actions.setSubmitting(false);
                }}
                enableReinitialize
              >
                {({ isSubmitting, isValid, dirty, handleSubmit, handleChange, values }) => (
                  <Form className = "clientForm" style={props.formStyle}>
                    <div className = "vertCont">
                      <div className = "personal info">
                        <div className = "horCont">
                          <div className = "horCont half">
                            <div>
                              <label for = "title">*Title</label>
                              <br></br>
                              <Field name = "title"
                                     as="select">
                                <option disabled value>Select</option>
                                <option value = "Mr.">Mr.</option>
                                <option value = "Mrs.">Mrs.</option>
                                <option value = "Ms.">Ms.</option>
                                <option value = "Dr.">Dr.</option>
                              </Field>
                            </div>
                          </div>
                          <div className = "horCont half">
                            <div style = {{width: "100px"}}>
                                <div>
                                    <label htmlFor="gender">*Gender</label>
                                    <br></br>
                                </div>
                                <Field name="gender"
                                       as="select">
                                   <option disabled value="">Select</option>
                                   <option value="male">M</option>
                                   <option value="female">F</option>
                                   <option value="non-binary">Non-binary</option>
                                </Field>
                                <div className="errorLabel">
                                  <ErrorMessage name="gender" component="div" />
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className = "horCont">
                          <div className = "horCont half">
                            <div className = "fname">
                              <FormikInputField
                                  name = "first_name"
                                  labelInfo = "*First Name"
                                  errorLabel = "name"
                                  type="text"
                                  maxLength = "50"
                                  ></FormikInputField>
                            </div>
                            <div className = "mi">
                              <FormikInputField
                                  name = "middle_initial"
                                  labelInfo = "*Middle Initial"
                                  errorLabel = "initial"
                                  type="text"
                                  placeholder = "I"
                                  maxLength = "1"
                                  ></FormikInputField>
                            </div>
                          </div>
                          <div className = "half">
                            <FormikInputField
                                name = "last_name"
                                labelInfo = "*Last Name"
                                errorLabel = "last name"
                                type="text"
                                maxLength = "50"
                                ></FormikInputField>
                          </div>
                        </div>
                        <div className = "horCont">
                          <div className = "half">
                            <br></br>
                            <FormikInputField
                                type="text"
                                name = "email"
                                labelInfo = "*Email address"
                                errorLabel = "email. For example: example@example.com"
                                placeholder = "example@example.com"
                                maxLength = "50"
                                readonly = {
                                  props.canEditEmail ? undefined : true
                                }
                                ></FormikInputField>
                          </div>
                          <div className = "half">
                            <FormikInputField
                                type="text"
                                name = "phone"
                                labelInfo = "*Phone number"
                                errorLabel = "10 digit phone number"
                                placeholder = "1234567890"
                                maxLength = "10"
                                ></FormikInputField>
                          </div>
                        </div>
                      </div>
                      <br></br>
                      <div className = "address info">
                        <div className= "horCont">
                          <div className = "half">
                            <FormikInputField
                                type="text"
                                name = "address"
                                labelInfo = "*Street address"
                                errorLabel = "address"
                                maxLength = "50"
                                ></FormikInputField>
                          </div>
                          <div className = "half">
                            <FormikInputField
                                type="text"
                                name = "city"
                                labelInfo = "*City"
                                errorLabel = "city"
                                maxLength = "50"
                                ></FormikInputField>
                          </div>
                        </div>
                        <div className = "horCont">
                          <div className = "half">
                            <StateForm name = "state"
                                       labelInfo = "*State"
                                       ></StateForm>
                          </div>
                          <div className = "half">
                            <FormikInputField
                                type="text"
                                name = "zip"
                                labelInfo = "*Zip code"
                                errorLabel = "5 digit zip code"
                                placeholder = "00000"
                                maxLength = "5"
                                ></FormikInputField>
                          </div>
                        </div>
                        <div style = {{display: "flex", marginTop: "20px"}}>
                          <input type = "checkbox"
                                 style = {{marginRight: "10px"}}
                                 onChange = {handleSameAddrCheckbox}
                                 defaultChecked = {
                                   sameAddress ? true : undefined
                                 }></input>
                          <label>Mailing address same as address</label>
                        </div>
                        <div className = {sameAddress ? "hide" : ""}>
                          <div className = "horCont">
                            <div className = "half">
                              <FormikInputField
                                  type="text"
                                  name = "mailing_address"
                                  labelInfo = "Mailing Street Address"
                                  errorLabel = "address"
                                  maxLength = "50">
                                  </FormikInputField>
                            </div>
                            <div className = "half">
                              <FormikInputField
                                  type="text"
                                  name = "mailing_city"
                                  labelInfo = "Mailing City"
                                  errorLabel = "city"
                                  maxLength = "50">
                                  </FormikInputField>
                            </div>
                          </div>
                          <div className = "horCont">
                            <div className = "half">
                              <StateForm name = "mailing_state"
                                         labelInfo = "Mailing State"
                                         defaultValue = {client.mailing_state}></StateForm>
                            </div>
                            <div className = "half">
                              <FormikInputField
                                  type="text"
                                  name = "mailing_zip"
                                  labelInfo = "Mailing Zip code"
                                  errorLabel = "5 digit zip code"
                                  placeholder = "00000"
                                  maxLength = "5">
                                  </FormikInputField>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br></br>
                      {plan.type === "SOA" ? <></> :
                        <div className = "DOB info">
                            <div className = "horCont">
                              <div className = "bday half">
                                <FormikInputField
                                    type="date"
                                    name = "dob"
                                    labelInfo = "*Birthdate"
                                    errorLabel = "Birthdate"
                                    ></FormikInputField>
                              </div>
                              {
                                application.effective_date ?
                                <div className = "effective_date half">
                                  <FormikInputField
                                      type="date"
                                      name = "effective_date"
                                      labelInfo = "*Effective Date"
                                      style = {{minWidth: "150px", maxWidth: "150px"}}
                                  ></FormikInputField>
                                </div>
                                : <></>
                              }
                            </div>
                        </div>
                      }
                      <br></br>
                      {plan.type === '1' ?
                        <div className = "medicare info">
                          <div style = {{alignItems: "start"}} className = "horCont">
                            <div className = "half vert">
                              <div className = "top">
                                <FormikInputField
                                    type="text"
                                    name = "medicare_claim_number"
                                    labelInfo = "*Medicare Claim Number"
                                    errorLabel = "medicare number. For example: 1AB2CD3EF45"
                                    placeholder = "1AB2CD3EF45"
                                    maxLength = "11"
                                    ></FormikInputField>
                              </div>
                              {
                                plan.id == 1 ?
                                <div className = "bottom">
                                  <FormikInputField
                                      type="text"
                                      name = "aarp_membership_number"
                                      labelInfo = "*AARP Membership Number"
                                      errorLabel = "AARP membership number"
                                      placeholder = "1234567890"
                                      maxLength = "10"
                                      ></FormikInputField>
                                </div>
                                :
                                <></>
                              }
                            </div>
                            <div className = "half vert">
                              <div className = "top">
                                <FormikInputField
                                    type="date"
                                    name = "part_a_start_date"
                                    errorLabel = "Medicare Part A Start Date"
                                    labelInfo = "*Medicare Part A Start Date"
                                    ></FormikInputField>
                              </div>
                              <div className = "bottom">
                              <FormikInputField
                                  type="date"
                                  name = "part_b_start_date"
                                  errorLabel = "AARP membership number"
                                  labelInfo = "Medicare Part B Start Date"
                                  ></FormikInputField>
                              </div>
                            </div>
                          </div>
                        </div>
                      : <></>}
                      <br></br>
                      {plan.type === "SOA" ? <></> :
                        <div className = "bank info">
                          <div className = "horCont">
                            <div className = "half">
                              <label for = "billing_preference">*Billing Preference</label>
                              <br></br>
                              <Field name="billing_preference"
                                     id="billing_preference"
                                     as="select"
                                     style={{"width": "250px"}}
                                     onChange={(evt) => {
                                       handleChange(evt);
                                       if (evt.target.value === "direct_debit") {
                                         setShowBillPref(true);
                                       }
                                       else {
                                         setShowBillPref(false);
                                       }
                                     }}
                                     >
                                <option disabled value>Select</option>
                                <option value = "paper_bill">Receive Bill Directly By Carrier</option>
                                <option value = "direct_debit">Monthly Direct Debit</option>
                                {plan.type === "HMO" || plan.type === "Local PPO" || plan.type === "Medicare-Medicaid Plan HMO" || plan.type === "HMOPOS" || plan.type === "National PACE" || plan.type === 'SNP' ?
                                  <option value = "deduct_premium_from_ss">Automatically deduct premium from Social Security</option>
                                  : <></>
                                }
                              </Field>
                              <div className="errorLabel">
                                <ErrorMessage name="billing_preference" component="div" />
                              </div>
                            </div>
                          </div>
                          {
                            showBillPref ?
                            <>
                              <div className = "horCont">
                                <div className = "half">
                                  <FormikInputField
                                      type="text"
                                      name = "bank_name"
                                      labelInfo = "*Bank Name"
                                      errorLabel = "10 digit number"
                                      ></FormikInputField>
                                </div>
                                <div className = "half">
                                  <FormikInputField
                                      type="text"
                                      name = "bank_routing"
                                      labelInfo = "*Bank Routing Number"
                                      errorLabel = "9 digit routing number"
                                      maxLength = "9"
                                      ></FormikInputField>
                                </div>
                              </div>
                              <div className = "horCont">
                                <div className = "half end">
                                  <FormikInputField
                                      type="text"
                                      name = "bank_account"
                                      labelInfo = "*Bank Account Number"
                                      errorLabel = "10-12 digit account number"
                                      maxLength = "12"
                                      ></FormikInputField>
                                </div>
                              </div>
                            </>
                            : <></>
                          }
                        </div>
                      }
                      <div className = "continue">
                        {!initialFormSubmit || isValid ?
                          <button type="submit" onClick={(e) => {console.log("THESE ARE THE VALUES"); console.log(values); setInitialFormSubmit(true); handleSubmit(e);}} className="buttonStyle">
                            {props.buttonLabel}
                          </button>
                        :
                          <>
                            <button className="disabledButtonStyle" disabled>
                              {props.buttonLabel}
                            </button>
                          </>
                        }
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          </div>
        </div>
      </>
    );
}

export default ClientDetailsForm;
