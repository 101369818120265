import React, { useContext, useState, useEffect } from 'react';
import { withRouter, useHistory, useParams, Link } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';

import './BrokerProfileForm.css';

import Banner from '../components/Banner';
import FormikInputField from '../components/FormikInputField';

import Table from 'react-bootstrap/Table';

import * as AgencyServices from '../domain/agency';
import * as BrokerServices from '../domain/broker';
import * as CarrierServices from '../domain/carrier';

const WritingNumbers = (props) => {
    let history = useHistory();

    let broker = props.broker;
    const [writingNumbersList, setWritingNumbersList] = useState([]);
    const [bannerMessage, setBannerMessage] = useState("");
    const [showBanner, setShowBanner] = useState(false);

    const { data: carriers, status } = useQuery(
      props.type === "agency" ? ['carriers'] : ["agencySupportedCarriers", props.agency_id ? props.agency_id : broker.agency_id],
      props.type === "agency" ?
        async (key, id) => {
          return CarrierServices.get(null, props.controller.controller.signal);
        }
      : async (key, id) => {
          return AgencyServices.getSupportedCarriers(id, props.controller.controller.signal);
        },
      {
        refetchOnWindowFocus: false, refetchOnMount: false,
        refetchOnReconnect: false,
      }
    );

    const { data: writingNumbersQuery, status: writingNumbersStatus } = useQuery(
      props.type === "agency" ? ['agencyWritingNumbers', props.agency_id ? props.agency_id : broker.agency_id] : ["writingNumbers", broker.id],
      props.type === "agency" ? async (key, id) => {
        return AgencyServices.getWritingNumbers(id, props.controller.controller.signal);
      }
      : async (key, id) => {
        return BrokerServices.getWritingNumbers(id, props.controller.controller.signal);
      },
      {
        refetchOnWindowFocus: false, refetchOnMount: false,
        refetchOnReconnect: false,
      }
    );
    // const [mutateAgencyNums, { status: mutationAgencyStatus, data: mutationAgencyData, error: mutationAgencyError }] = useMutation((id, writingNums, signal) =>
    //   AgencyServices.updateWritingNumbers(id, writingNums, signal)
    // );
    // const [mutateBrokerNums, { status: mutationBrokerStatus, data: mutationBrokerData, error: mutationBrokerError }] = useMutation(
    //   BrokerServices.updateWritingNumbers
    // );

    useEffect(() => {
      if (props.setWritingNumbers) {
        let wnCopy = [...writingNumbersList];
        props.setWritingNumbers(wnCopy);
      }
    }, [writingNumbersList]);

    useEffect(() => {
      console.log("wriNumQuer")
      console.log(writingNumbersQuery);
      if (writingNumbersQuery) {
        let i=0;
        for (let w of writingNumbersQuery) {
          w.index = i;
          i+=1;
        }
        setWritingNumbersList(writingNumbersQuery);
      }
    }, [writingNumbersQuery])

    useEffect(() => {
      if (props.type !== "agency" && carriers) {
        let i=0;
        let nums = [];
        if (carriers && carriers.length > 0) {
          for (let c of carriers) {
            nums.push({"index": i, "carrier_id": c.id, "agent_number": searchWritingNumsQuery(c.id), "agency_number": ""})
            i+=1;
          }
          setWritingNumbersList( nums );
        }
      }
    }, [carriers, writingNumbersQuery])


    const setSelectedCarrier = (e, index) => {
      for (let w of writingNumbersList) {
        if (parseInt(w.index) === index) {
          w.carrier_id = parseInt(e.currentTarget.value);
        }
      }
    }
    const setSelectedAgentNum = (e, index) => {
      console.log(e);
      console.log(writingNumbersList);
      for (let w of writingNumbersList) {
        if (parseInt(w.index) === index) {
          w.agent_number = e.target.value;
        }
      }
    }
    const setSelectedAgencyNum = (e, index) => {
      console.log(e);
      for (let w of writingNumbersList) {
        if (parseInt(w.index) === index) {
          w.agency_number = e.target.value;
        }
      }
    }

    const deleteWritingNumber = (e, index) => {
      let ls = writingNumbersList.map((x) => x);
      for (let i = 0; i < ls.length; i++) {
        console.log(ls[i].index)
        console.log(index)
        if (ls[i].index === index) {
          ls.splice(i, 1);
          for (let j = i; j < ls.length; j++) {
            ls[j].index -= 1;
          }
          break;
        }
      }
      console.log(ls)
      setWritingNumbersList(ls);
    }

    function isCarrierChosen(carrier_id, this_index) {
      for (let w of writingNumbersList) {
        if (parseInt(w.carrier_id) === carrier_id) {
          return true;
        }
      }
      return false;
    }

    function searchWritingNums(carrier_id){
        if (writingNumbersList === undefined) {
          return "";
        }
        for (var i=0; i < writingNumbersList.length; i++) {
            if (writingNumbersList[i].carrier_id === carrier_id) {
                return writingNumbersList[i].agent_number;
            }
        }
    }
    function searchWritingNumsQuery(carrier_id){
        if (writingNumbersList === undefined || writingNumbersQuery === undefined) {
          return "";
        }
        for (var i=0; i < writingNumbersQuery.length; i++) {
            if (writingNumbersQuery[i].carrier_id === carrier_id) {
                return writingNumbersQuery[i].agent_number;
            }
        }
    }

    return(
      <>
        <div className = "mainArea">
          {showBanner ? <Banner message={bannerMessage}/> : <></>}
          <Formik
            initialValues={{
              carrier: "",
              agent_number: "",
              agency_number: "",
            }}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
            }}
          >
            {({ isSubmitting, isValid, dirty, errors, handleSubmit, handleChange, handleBlur }) => (
              <form className = "profileForm" onSubmit = {handleSubmit}>
                <div style = {{marginTop: props.marginTop ? props.marginTop : null, ...props.style}}>
                  <div style={{fontFamily: "Raleway", width:"100%"}}>
                    {props.agencyName ? "Agency: "+props.agencyName : ""}
                  </div>
                  <div>
                    {props.isBrokerProfile && props.hideTitle !== true ? <h3>Writing Numbers</h3> : <></>}
                  </div>
                  <Table borderless>
                    {writingNumbersList && writingNumbersList.length > 0 ?
                      <>
                        <thead>
                          <tr>
                            <th>Carrier</th>
                            <th>{props.type === "agency" && !props.showBothColumns ? "Agency writing number" : "Agent writing number (principal)"}</th>
                            {props.showBothColumns ?
                              <th>Agency writing number</th>
                              : <></>
                            }
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            props.type === "agency" ?
                            writingNumbersList.filter(item => {return (item.carrier_id !== -1);})
                            .map((w, index) => (
                                <tr style={{borderTopWidth: "1px", borderColor: "#dee2e6"}}>
                                  <td style={{verticalAlign: "middle"}}>
                                    <Field  as = "select"
                                          name = {"carrier"+index}
                                          style={{"width": "100%"}}
                                          defaultValue=""
                                          value={w.carrier_id}
                                          onChange={(e) => {
                                            setSelectedCarrier(e, index);
                                            handleChange(e);
                                          }}
                                          onBlur={handleBlur}
                                          required>
                                        <option disabled value = "">Select</option>
                                    {   status === "success" && carriers !== undefined ?
                                        carriers.sort((a, b) => { // sort alphabetically by carrier name
                                          if (a.name < b.name) {
                                            return -1;
                                          }
                                          if (a.name > b.name) {
                                            return 1;
                                          }
                                          return 0;
                                        })
                                        .filter(item => {return item.is_supported;})
                                        .map((c, carrierIndex) => (
                                            isCarrierChosen(c.id, index) && c.id !== w.carrier_id ? <></> :
                                            <option value = {c.id}>{c.name}</option>
                                        ))
                                        : <></>
                                    }
                                    </Field>
                                  </td>
                                  {
                                    props.showBothColumns ?
                                    <td style={{verticalAlign: "middle"}}>
                                      <FormikInputField
                                        name={"agent_number"+index}
                                        style={{width:"100%"}}
                                        parentStyle={{width:"100%"}}
                                        no_label
                                        type="text"
                                        maxLength="20"
                                        pattern = "[a-zA-Z0-9]{1,20}"
                                        value={w.agent_number}
                                        onChange={(e) => {
                                          setSelectedAgentNum(e, index);
                                          handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        required
                                      ></FormikInputField>
                                    </td>
                                    : <></>
                                  }
                                  <td style={{verticalAlign: "middle"}}>
                                    <FormikInputField
                                      name={"agency_number"+index}
                                      parentStyle={{width:"100%"}}
                                      style={{width:"100%"}}
                                      no_label
                                      type="text"
                                      maxLength="20"
                                      pattern = "[a-zA-Z0-9]{1,20}"
                                      value={w.agency_number}
                                      onChange={(e) => {
                                        setSelectedAgencyNum(e, index);
                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                      required
                                    ></FormikInputField>
                                  </td>
                                  <td style={{verticalAlign: "middle"}}>
                                    <input type="button"
                                            style={{"marginLeft": "5px", "marginTop": "3px", "fontColor": "red"}}
                                            className="plusMinusButton"
                                            value=" x "
                                            onClick={(e) => { deleteWritingNumber(e, index); }}></input>
                                  </td>
                                </tr>
                            ))
                            :
                            carriers === undefined ?
                              <tr><td style={{verticalAlign: "middle"}} colspan={props.showBothColumns ? "4" : "3"}>Loading...</td></tr>
                            : carriers.length === 0 ?
                              props.isBrokerProfile ?
                                <tr><td style={{verticalAlign: "middle"}} colspan={props.showBothColumns ? "4" : "3"}>{broker.agency_name} has not added any carriers that they are contracted with. Please contact them to add carriers into the system or email us at hi@planprovide.com for further assistance.</td></tr>
                                : <tr><td style={{verticalAlign: "middle"}} colspan={props.showBothColumns ? "4" : "3"}>{broker.agency_name} has not added carriers. Please continue with your registration by clicking on Next below.</td></tr>
                            :
                              carriers.filter(item => {return (item.id !== -1 && item.is_supported);})
                              .map((c, index) => (
                              <tr style={{borderTopWidth: "1px", borderColor: "#dee2e6"}}>
                                <td style={{verticalAlign: "middle"}}>
                                  {c.name}
                                </td>
                                <td style={{verticalAlign: "middle"}}>
                                  <FormikInputField
                                    name={"agent_number"+index}
                                    style={{width:"100%"}}
                                    parentStyle={{width:"100%"}}
                                    no_label
                                    type="text"
                                    maxLength="20"
                                    pattern = "[a-zA-Z0-9]{1,20}"
                                    value={searchWritingNums(c.id)}
                                    onChange={(e) => {
                                      setSelectedAgentNum(e, index);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    required
                                  ></FormikInputField>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </>
                      : <></>
                    }
                  </Table>
                  <div>
                    {props.type === "agency" ?
                      <a className="linkStyle" style={{"marginTop":"10px"}}
                          onClick={e => {
                            setWritingNumbersList(
                              [...writingNumbersList, {"index": writingNumbersList.length, "carrier_id": "", "agent_number": "", "agency_number": ""}]
                          )
                      }}>+ Add Writing Number</a>
                      :<></>
                    }
                  </div>
                </div>
                {props.isBrokerProfile ? <></> :
                  <button
                    onClick={() => {
                        if (props.setWritingNumbers) {
                          props.setWritingNumbers(writingNumbersList);
                        }
                        else {
                          if (props.showBothColumns) {
                            
                            AgencyServices.updateWritingNumbers(props.agency_id || broker.agency_id, writingNumbersList, props.controller.controller.signal);
                            BrokerServices.updateWritingNumbers(broker.id, writingNumbersList, true, props.controller.controller.signal);
                          }
                          else if (props.type === "agency") {
                            console.log("WRTING NUMS: ", writingNumbersList);
                            AgencyServices.updateWritingNumbers(props.agency_id || broker.agency_id, writingNumbersList, props.controller.controller.signal);
                          }
                          else {
                            BrokerServices.updateWritingNumbers(broker.id, writingNumbersList, true, props.controller.controller.signal);
                          }
                        }

                        if (props.isRegistration) {
                          history.push("/register/profile-details")
                        }
                        else {
                          setBannerMessage("Writing numbers updated!")
                          if (props.setShowBanner) {
                            props.setShowBanner(true);
                          }
                          else {
                            setShowBanner(true);
                          }
                        }
                    }}
                    className="buttonStyle"
                    style={{marginLeft: props.isRegistration ? "0px" : "40px", marginTop: "20px", marginBottom: "40px"}}>
                      {props.buttonLabel}
                  </button>
                }
              </form>
            )}
          </Formik>
        </div>
      </>
    );
}

export default WritingNumbers;
