import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { BrokerContext } from '../components/BrokerContext';

import Banner from '../components/Banner';

import * as SWRTC from '@andyet/simplewebrtc';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';

function WebRTCTest (props) {
  // ====================================================================
  // IMPORTANT SETUP
  // ====================================================================
  // Replace `YOUR_PUBLISHABLE_API_KEY` here with the Publishable API Key
  // you received when signing up for SimpleWebRTC
  // --------------------------------------------------------------------
  const API_KEY = 'da3d25e6ff485b53e7fb2bc9';
  // ====================================================================

  const ROOM_NAME = 'YOUR_ROOM_NAME';
  const ROOM_PASSWORD = 'YOUR_ROOM_PASSWORD';
  const CONFIG_URL = `https://api.simplewebrtc.com/config/guest/${API_KEY}`;

  const store = SWRTC.createStore();

  return (
    <Provider store={store}>
      <SWRTC.Provider configUrl={CONFIG_URL}>
        {/* Render based on the connection state */}
        <SWRTC.Connecting>
          <h1>Connecting...</h1>
        </SWRTC.Connecting>

        <SWRTC.Connected>
          <h1>Connected!</h1>
          {/* Request the user's media */}
          <SWRTC.RequestUserMedia audio video auto />

          {/* Enable playing remote audio. */}
          <SWRTC.RemoteAudioPlayer />

          {/* Connect to a room with a name and optional password */}
          <SWRTC.Room name={ROOM_NAME} password={ROOM_PASSWORD}>
            {props => {
              /* Use the rest of the SWRTC React Components to render your UI */
              return(<>
                <SWRTC.RemoteAudioPlayer />
                <p>Main app UI</p>
              </>)
            }}
          </SWRTC.Room>
        </SWRTC.Connected>
      </SWRTC.Provider>
    </Provider>
  );
}

export default WebRTCTest;
