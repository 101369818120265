import React, { useContext, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { PlanContext, ClientContext, ApplicationContext } from './PCAContext';

import './ApplicationBox.css';

import mixpanel from 'mixpanel-browser';
import { ENVContext } from './ENVContext';

import { boldSubstring } from '../utils.js';

const ApplicationBox = (props) => {
    let history = useHistory();

    const [ENV, setENV] = useContext(ENVContext);
    const [plan, setPlan] = useContext(PlanContext);
    const [client, setClient] = useContext(ClientContext);
    const [application, setApplication] = useContext(ApplicationContext);

    const info = props.info;

    // Set PCA context when clicked
    function setApplicationInfo(info) {
        mixpanel.init(ENV.MIXPANEL_TOKEN);
        mixpanel.track("Access application", {where_from: props.location, application_id: info.id, client_id: info.client ? info.client.id : null});
        console.log("ID " + info.id)
        console.log("EFFECTIVE DATE " + info.effective_date)
        setApplication({
            id: info.id,
            status: info.status,
            url: info.url,
            envelope_id: info.docusign_envelope_id,
            download: info.aws_pdf_key,
            csv: info.aws_csv_key,
            access_code: info.ds_access_code,
            effective_date: info.effective_date,
            premium: info.premium,
        });
        if (info.plan && info.carrier) {
          setPlan({
              id: info.plan.id,
              name: info.plan.name,
              type: info.plan.type,
              number: info.plan.plan_number,
              carrier_name: info.carrier.name,
              carrier_id: info.carrier.id,
          });
        }
        if (info.client) {
          setClient(info.client);
        }

        if (info.status) {
          if (info.status === 0) {
            history.push(`/applications/client-form-page/${info.id}`);
          }
          else if (info.status === 1 ||
                   info.status === 2 ||
                   info.status === 3) {
            history.push(`/applications/ready-sign/${info.id}`);
          }
          else if (info.status === 4) {
            history.push(`/applications/ready-submit/${info.id}`);
          }
          else if (info.status === 5) {
            history.push(`/applications/submitted/${info.id}`);
          }
          else {
            console.log("ERROR WITH APPLICATION: app with status = " + info.status);
          }
        }
        else {
          history.push(`/applications/client-form-page/${info.id}`);
        }
    }

    useEffect(() => {
      console.log(info)
    }, []);

    return(
        <tr className = {props.checked ? "applicationBoxChecked" : "applicationBox"}>
          {props.hideCheckbox ? <></> :
            <td onClick={props.handleChange}>
              <input type="checkbox"
                    className="checkBox"
                    value={props.checked}
                    onChange={ null }
                    checked={props.checked}></input>
            </td>
          }
          {info.client ?
            <>
              <td onClick = {() => setApplicationInfo(info)}>
                {props.filterValue ?
                  boldSubstring(info.client.first_name + " " + info.client.last_name, props.filterValue)
                  : info.client.first_name + " " + info.client.last_name}
              </td>
              {props.showBrokerOwner ?
                <td onClick = {() => setApplicationInfo(info)}>
                  {props.filterValue ?
                    boldSubstring(info.broker.first_name + " " + info.broker.last_name, props.filterValue)
                    : info.broker.first_name + " " + info.broker.last_name}
                  
                </td>
                :
                props.showClientInfo ?
                <td onClick = {() => setApplicationInfo(info)}>
                    <>{info.client.email}</>
                    <>{info.client.phone}</>
                </td>
                : <></>
              }
            </>
          : <>
              <td onClick = {() => setApplicationInfo(info)}>
                  <></>
              </td>
              {props.showBrokerOwner || props.showClientInfo ?
                <td onClick = {() => setApplicationInfo(info)}>
                    <></>
                    <></>
                </td>
                : <></>
              }
            </>
          }
          <td onClick = {() => setApplicationInfo(info)}>
            {info.plan !== undefined ?
              <>
              {info.carrier.name.substring(0,13)}{info.carrier.name.length > 13 ? ".." : null}
              {info.plan.type === "1" ? "(MS)" :
                  info.plan.type === "dental" ? "(DEN)" :
                  info.plan.type === "vision" ? "(VIS)" :
                  info.plan.type === "advantage" ? "(MA)" :
                  info.plan.type === "pdp" ? "(PDP)" :
                  info.plan.type === "SOA" ? "Scope of Appointment (SOA)" : info.plan.type}
              {info.has_dental ? <><p>{info.dental_carrier}(DEN)</p></> : <></>}
              {info.has_vision ? <><p>{info.vision_carrier}(VIS)</p></> : <></>}
              </>
            :<></>}
          </td>
          {info.carrier && props.showPlanName ?
            <td onClick = {() => setApplicationInfo(info)}>
                <>{props.filterValue ?
                  boldSubstring(`${info.carrier.name} - ${info.plan.name}`, props.filterValue)
                  : `${info.carrier.name} - ${info.plan.name}`}
                </>
            </td>
          : <></> }

          <td onClick = {() => setApplicationInfo(info)}>
              {
                  info.status === 0 &&
                  (<>Created</>)
              }
              {
                  info.status === 1 &&
                  (<>Ready for signing</>)
              }
              {
                  info.status === 2 &&
                  (<>Signing started</>)
              }
              {
                  info.status === 3 &&
                  (<>Sent for signing</>)
              }
              {
                  info.status === 4 &&
                  (<>Ready to submit</>)
              }
              {
                  info.status === 5 &&
                  (<>Completed</>)
              }
          </td>
          <td onClick = {() => setApplicationInfo(info)}>
              <>{props.create_date}</>
          </td>
        </tr>
    );
}

export default ApplicationBox;
