import React, { useState, createContext, useEffect } from 'react';

export const ENVContext = createContext();

export const ENVProvider = props => {
    
    const [ENV, setENV] = useState({
        environment: '',
        MIXPANEL_TOKEN: '',
    });

    useEffect(() => {
        let root = window.location.origin;
        let MIXPANEL_DEV_TOKEN = "7537c0f5fd9086e829e16bce9a2f04b6";
        let MIXPANEL_PROD_TOKEN = "ef67ab82a39c5a72d7e9659b80131d9c";
        if (root === "http://localhost:5000" || root === "https://localhost:5000" || root === "https://planprovide.herokuapp.com/" || root === "http://planprovide.herokuapp.com/"){
            setENV({environment: "dev", MIXPANEL_TOKEN: MIXPANEL_DEV_TOKEN});
        } else if (root === "https://staging.planprovide.com" || root === "http://staging.planprovide.com"
                || root === "https://plans.medicarebits.com" || root === "http://plans.medicarebits.com") {
            setENV({environment: "staging", MIXPANEL_TOKEN: MIXPANEL_DEV_TOKEN});
        }
        else if (root === "https://app.planprovide.com" || root === "http://app.planprovide.com") {
            setENV({environment:"prod", MIXPANEL_TOKEN: MIXPANEL_PROD_TOKEN});
        } else {
            setENV({environment: "dev", MIXPANEL_TOKEN: MIXPANEL_DEV_TOKEN});
        }
    }, []);

    return(
        <ENVContext.Provider value = {[ENV, setENV]}>
            {props.children}
        </ENVContext.Provider>
    );
}