import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import FindPlans from './FindPlans';

import './pages.css';


function CountyStateSuppPage( props ) {
    return (
        <>
            <div style={{backgroundColor: '#fff0', boxShadow: 'none', width: '40%', marginLeft:"5%", width: "60%", fontSize:"3.5em", lineHeight: "1.2em",
                         paddingBottom: '30px', fontFamily: 'DM Serif Display'}}>
                            Medicare Supplement Plans in {props.countyName}, {props.stateName}
            </div>
            <div style={{marginLeft: '5%'}}>
                <div className="bodyParagraph">
                    Find the top Medicare Supplement Plans available in {props.countyName}, {props.stateName} in {props.planYear}.
                </div>
                <div style={{fontSize:'0.75em', marginTop: '10px'}}>
                    Updated {props.dateUpdated} ago
                </div>
                <div style={{paddingTop: '30px', paddingBottom: '30px'}}>
                    <img src='https://planprovide-resources.s3.us-west-1.amazonaws.com/medicare-bits-img2.png' width='60%'></img>
                </div>
                <div>
                    <p className="bodyParagraph">
                        Medicare supplemental insurance (Medigap) helps cover out-of-pocket costs for parts A and B. These plans are offered through 
                        private insurance providers.
                    </p>
                    <p className="bodyParagraph">
                        In {props.stateName}, many insurance companies offer Medigap plans. For 2022, some of the companies offering Medigap plans 
                        throughout the state include:
                    </p>
                    <p className="bodyParagraph">
                        <h3 style={{marginBottom: '10px'}}>What you should know about Medicare Supplement Plans</h3>

                        Understanding how Medicare Supplement Plans work can take some time. Here are the basics [2]:

                        <ul style={{marginLeft: '20px'}}>
                            <li><b>Medigap plans cover one person.</b> If your spouse or partner also wants a Medicare Supplement Plan, they must buy 
                            a policy of their own.</li>
                            <li><b>Medigap plans can’t cancel you for health issues.</b> All standardized Medigap plans are guaranteed renewable, which 
                            means they can’t cancel your policy if you have health problems, as long as you continue to pay the premiums.</li>
                            <li><b>Medigap doesn’t cover prescription drugs.</b> Medigap policies are no longer allowed to include prescription drug 
                            coverage. For that, you’ll need a Medicare Part D Prescription Drug Plan.</li>
                            <li><b>You can’t buy Medigap if you have Medicare Advantage.</b> In fact, it’s illegal for someone to sell you a Medigap 
                            plan if you have Medicare Advantage. If you change back to Original Medicare, you can purchase a Medigap plan.</li>
                            <li><b>They don’t cover everything.</b> Generally, Medicare Supplement Insurance doesn’t cover dental care, vision care, 
                            hearing aids, long-term care, eyeglasses or private-duty nursing.</li>
                            <li><b>Some plans are no longer available.</b> You can no longer purchase Plans E, H, I and J, but if you purchased one of 
                            those plans before June 1, 2010, you can continue with it. People newly eligible for Medicare after Jan. 1, 2020, also can’t 
                            purchase Plan C and Plan F, which cover the Medicare Part B deductible. (But if you turned 65 prior to that date and haven't 
                            signed up for Medicare Part B because you still have employer group coverage, you may be able to buy Plans C and F when you do 
                            enroll in Part B.)</li>
                        </ul>
                    </p>
                    <p className="bodyParagraph">
                        <h3 style={{marginBottom: '5px'}}>Medicare Supplement Plan G Companies in {props.countyName}, {props.stateName}</h3>
                        {props.ratingTableG}
                    </p>
                    
                    <p className="bodyParagraph">
                        <h3 style={{marginBottom: '5px'}}>Medicare Supplement Plan N Companies in {props.countyName}, {props.stateName}</h3>
                        {props.ratingTableN}
                    </p>
                    
                    <p className="bodyParagraph">
                        <h3 style={{marginBottom: '5px'}}>Medicare Supplement Plan High Deductible G Companies in {props.countyName}, {props.stateName}</h3>
                        {props.ratingTableHDG}
                    </p>
                    
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        <h3 style={{marginBottom: '10px'}}>How do I enroll in a plan?</h3>
                        You can sign up for a Medicare Supplement plan when you first become eligible for Medicare (your initial enrollment period) 
                        or during designated annual Medicare enrollment periods.
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        Once you’ve done your research and found a Medicare Supplement plan that fits your needs, there are various ways to enroll:
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        <ul style={{marginLeft: '20px'}}>
                            <li>Go to the plan’s website to see if you can enroll online. Contact the plan to get a paper enrollment form. Fill it out and return it 
                                to the plan provider.</li>
                            <li>Call the provider for the plan you wish to join.</li>
                            <li>Speak to a licensed broker to review your options. Email us at hi@medicarebits.com to get connected to one.</li>
                        </ul>
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        You will need your Medicare number and the date your Medicare Part A and/or Part B coverage started. You must be enrolled in Medicare Parts A 
                        and B before you can buy a Medicare Advantage plan.
                    </p>
                    
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        <h3 style={{marginBottom: '10px'}}>Need Help Finding the right plan?</h3>
                        If you would like to speak to a licensed broker, email us at hi@medicarebits.com.
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        Alternatively, for questions about Medicare plans and other Medicare issues, contact Medicare at 800-MEDICARE (800-633-4227) or visit Medicare.gov. 
                    </p>
                </div>
            </div>
        </>
    );   
}

export default withRouter(CountyStateSuppPage);
