import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useHistory, useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import { PlanContext, ClientContext, ApplicationContext } from './PCAContext';
import { BrokerContext } from './BrokerContext';

import './PlanBox.css';

import { formatCurrency } from '../utils.js';
import Table from 'react-bootstrap/Table';

import InputField from './InputField';
import CheckBox from './CheckBox';

import { calculateDate } from '../utils.js';

const fetchCountiesFromZip = async (key, zip) => {
    if (zip === null || zip === undefined || zip === '') {
        return [];
    }
    let obj = {
        "zip": zip,
    }

    let json = JSON.stringify(obj);
    const res = await fetch('/api/get_counties_from_zip', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: json
    });
    return res.json();
}
const fetchAllCounties = async (key) => {
  const res = await fetch('/api/get_all_counties', {
      method: 'POST',
      headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      },
  });
  return res.json();
}

function PlanFilters(props) {
    const [plan, setPlan] = useContext(PlanContext);
    const [client, setClient] = useContext(ClientContext);
    const [broker, setBroker] = useContext(BrokerContext);

    const [errorMessage, setErrorMessage] = useState("");
    const [planZip, setPlanZip] = useState(client.zip || "");
    const [planCountyId, setPlanCountyId] = useState(plan.county_id || "");
    const [firstSubmit, setFirstSubmit] = useState(false);

    const [planType, setPlanType] = useState(plan.type === "SNP" ? "snp" :
        plan.type === "HMO" || plan.type === "Local PPO" || plan.type === "Medicare-Medicaid Plan HMO" ||
        plan.type === "HMOPOS" || plan.type === "National PACE" ? "med_adv" :
        plan.type || "1");

    const { data: countyZipQueryData, status: countyZipQueryStatus } = useQuery(['countiesFromZip', planZip], fetchCountiesFromZip);
    const { data: countiesQueryData, status: countiesQueryStatus } = useQuery(['allCounties'], fetchAllCounties);

    useEffect(() => {
        console.log(countyZipQueryData);
        if (countyZipQueryStatus === "success" && firstSubmit) {
          if (countyZipQueryData && countyZipQueryData.length > 0)
            setErrorMessage("");
          else
            setErrorMessage("Please enter a valid zip code.")
        }
      }, [countyZipQueryData]);

    function handleZipChange(event) {
        setPlanZip(event.target.value);
    }
    function handleCountyChange(event) {
        setPlanCountyId(event.target.value);
    }
    function calculate_age(dob) {
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms);

        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    function advance(evt) {
        evt.preventDefault();
        if (countyZipQueryData.length === 0 && planType === '1') {
            setErrorMessage("Please enter a valid zip code.");
            return;
        }
        
        props.onSubmit(evt);
        setFirstSubmit(true);

    }
    return (
        <form className = "pickAppForm" style={props.style} onSubmit = {advance} >
            <div className = "vertCont pickInfo">
                <div className = "horCont">
                    <div className = "vertCont">
                        <label htmlFor = "plan_type">Plan Type</label>
                        <select name = "plan_type"
                                defaultValue = {plan.type || "1"}
                                value = {planType || "1"}
                                onChange = {(event) => {setPlanType(event.target.value);} }
                                >
                            <option value = "1">Medicare Supplement</option>
                            <option value = "dental">Dental</option>
                            <option value = "vision">Vision</option>
                            <option value = "med_adv">Medicare Advantage</option>
                            <option value = "pdp">PDP</option>
                            <option value = "snp">Special Needs Plan (SNP)</option>
                            <option value = "hospital_indemnity">Hospital Indemnity</option>
                            <option value = "final_expense">Final Expense</option>
                            <option value = "other">Other Documents</option>
                        </select>
                    </div>
                </div>
                {planType === '1' || planType === 'med_adv' || planType === 'pdp' || planType === 'snp' ?
                    <>
                    <div className = "horCont">
                        <div className = "vertCont">
                            <InputField name = "zip"
                                        labelInfo = "Zip code"
                                        defaultValue = {client.zip}
                                        placeholder = "00000"
                                        pattern = "\d{5}"
                                        maxLength = "5"
                                        onBlur={handleZipChange}
                                        required = "required"></InputField>
                            {errorMessage ? <div style={{color:"red",fontSize:"10px"}}>{errorMessage}</div> : ""}
                        </div>
                        <div className = "vertCont">
                            <label htmlFor = "county" style={{"marginLeft": "10px"}}>County</label>
                            <select name = "county"
                                    style = {{"marginLeft": "10px"}}
                                    defaultValue = ""
                                    onChange={handleCountyChange}>
                                {countyZipQueryStatus === "success" && countiesQueryStatus === "success" ?
                                [
                                    (countyZipQueryData.length === 0 || countyZipQueryData.length > 1
                                        ? <option key={-1} value={""}>Select a County</option>
                                        : <></>
                                    ),
                                    countyZipQueryData.map((value, index) => {
                                        return <option key={index} value={value}>{countiesQueryData[value]}</option>
                                    })
                                ]
                                : <></>}
                            </select>
                        </div>
                    </div>
                    </> : <></>
                }
                {planType === '1' ?
                    <>
                    <div className = "horCont">
                        <div className = "vertCont fourth">
                            <label htmlFor = "age">Age</label>
                            <select name = "age" defaultValue = {calculate_age(new Date(client.dob))}>
                                <option value = "65">65</option>
                                <option value = "66">66</option>
                                <option value = "67">67</option>
                                <option value = "68">68</option>
                                <option value = "69">69</option>
                                <option value = "70">70</option>
                                <option value = "71">71</option>
                                <option value = "72">72</option>
                                <option value = "73">73</option>
                                <option value = "74">74</option>
                                <option value = "75">75</option>
                                <option value = "76">76</option>
                                <option value = "77">77</option>
                                <option value = "78">78</option>
                                <option value = "79">79</option>
                                <option value = "80">80</option>
                                <option value = "81">81</option>
                                <option value = "82">82</option>
                                <option value = "83">83</option>
                                <option value = "84">84</option>
                                <option value = "85">85</option>
                                <option value = "86">86</option>
                                <option value = "87">87</option>
                                <option value = "88">88</option>
                                <option value = "89">89</option>
                                <option value = "90">90</option>
                                <option value = "91">91</option>
                                <option value = "92">92</option>
                                <option value = "93">93</option>
                                <option value = "94">94</option>
                                <option value = "95">95</option>
                                <option value = "96">96</option>
                                <option value = "97">97</option>
                                <option value = "98">98</option>
                                <option value = "99">99</option>
                                <option value = "100">100</option>
                                <option value = "101">101</option>
                                <option value = "102">102</option>
                                <option value = "103">103</option>
                                <option value = "104">104</option>
                            </select>
                        </div>
                        <div className = "vertCont fourth">
                            <label htmlFor = "tobacco">Tobacco</label>
                            <select name = "tobacco">
                                <option value = "n">No</option>
                                <option value = "y">Yes</option>
                            </select>
                        </div>
                        <div className = "vertCont fourth">
                            <label htmlFor = "gender">Gender</label>
                            <select style = {{width:"100px"}} name = "gender" defaultValue = {client.gender}>
                                <option disabled defaultValue>Select</option>
                                <option value = "male">M</option>
                                <option value = "female">F</option>
                                <option value = "non-binary">Non-binary</option>
                            </select>
                        </div>
                        <div className = "vertCont fourth">
                            <label htmlFor = "plan_number">Plan</label>
                            <select name = "plan_number" required>
                                <option value = "G">G</option>
                                <option value = "G with extras">G with extras</option>
                                <option value = "N">N</option>
                                <option value = "F">F</option>
                                <option value = "HDG">HDG</option>
                                <option value = "HDF">HDF</option>
                            </select>
                        </div>
                    </div>
                    </> : <></>
                }
                {planType === "other" || props.showEffectiveDate === false ? <></> :
                    <div style = {{minWidth: "150px", maxWidth: "150px", marginTop: "10px"}} className = "vertCont adjustDate">
                    <label htmlFor = "effective_date">Effective Date</label>
                    <input style = {{minWidth: "150px", maxWidth: "150px"}} type = "date" name = "effective_date"
                            defaultValue = {calculateDate(new Date())} required></input>
                    </div>
                }
                <div style = {{minWidth: "150px", maxWidth: "150px", marginTop: "10px"}} className = "vertCont adjustDate">
                    <label htmlFor = "app_edit_allowed">Allow user to edit application?</label>
                    <select name = "app_edit_allowed"
                            defaultValue = {broker.app_edit_allowed ? "y" : "n"}>
                        <option value = "n">No</option>
                        <option value = "y">Yes</option>
                    </select>
                    {/*<CheckBox name = "app_edit_allowed"
                                checked = {broker.app_edit_allowed}></CheckBox>*/}
                </div>
                <div className = "horCont continue">
                    {countyZipQueryStatus === "loading" || (firstSubmit && planType === "1" && countyZipQueryData.length === 0) ?
                        <input type="button" className = "disabledButtonStyle" value="Continue"></input>
                        : <input type="submit" className = "buttonStyle" value="Continue"></input>
                    }
                </div>
            </div>
        </form>
    );
}

export default PlanFilters;
