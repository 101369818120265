import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import FindPlans from './FindPlans';

import './pages.css';


function CountyStateSNPPage( props ) {
    return (
        <>
            <div style={{backgroundColor: '#fff0', boxShadow: 'none', width: '40%', marginLeft:"5%", width: "60%", fontSize:"3.5em", lineHeight: "1.2em",
                         paddingBottom: '30px', fontFamily: 'DM Serif Display'}}>
                Medicare Special Needs Plans (SNP) in {props.countyName}, {props.stateName}
            </div>
            <div style={{marginLeft: '5%'}}>
                <div className="bodyParagraph">
                    Find the top Medicare Special Needs plans available in {props.countyName}, {props.stateName} in {props.planYear}.
                </div>
                <div style={{fontSize:'0.75em', marginTop: '10px'}}>
                    Updated {props.dateUpdated} ago
                </div>
                <div style={{paddingTop: '30px', paddingBottom: '30px'}}>
                    <img src='https://planprovide-resources.s3.us-west-1.amazonaws.com/medicare-bits-img1.png' width='60%'></img>
                </div>
                <div>
                    <p className="bodyParagraph">
                        Medicare SNPs are a type of Medicare Advantage (Part C) plan that was created by Congress to help people with 
                        specific types of healthcare needs.
                    </p>
                    <p className="bodyParagraph">
                        <h3 style={{marginBottom: '10px'}}>What you should know about Medicare Special Needs Plans</h3>

                        A Medicare SNP includes all of the Medicare services under one program, offering hospitalization (Part A), medical 
                        care (Part B), and prescription drug coverage (Part D).
                    </p>
                    <p className="bodyParagraph">
                        Under this type of plan, you have coverage for your doctor’s visits, hospital stays, medications, and other services 
                        that you may need to manage your health.
                    </p>
                    <p className="bodyParagraph">
                        The main difference between SNPs and other Medicare Advantage options is that SNPs offer extra services based on your 
                        unique healthcare needs. This may include an allowance for extra days in the hospital, a care management specialist, 
                        or other social services.
                    </p>
                    <p className="bodyParagraph">
                        Most C-SNPs are designed for people with diabetes, heart disease, or lung conditions, as reported by the Kaiser Family 
                        Foundation. Plans tailored for people with dementia, mental health conditions, end-stage renal disease, or HIV/AIDS 
                        are available, but from only a small handful of insurers.
                    </p>
                    <p className="bodyParagraph">
                        Remember, all Medicare SNPs (like all Medicare Advantage plans) provide complete Part A and Part B coverage. In addition, 
                        SNPs are required to include prescription drug coverage (also known as Part D) to ensure members can reliably get the 
                        medications they need. While most other Medicare Advantage plans include drug coverage, not all do. 
                    </p>
                    <p className="bodyParagraph">
                        <h3 style={{marginBottom: '10px'}}>Medicare Special Needs Plans in {props.countyName}, {props.stateName}</h3>
                        {props.ratingTable}
                    </p>

                    

                    <p className="bodyParagraph">
                        <h3 style={{marginBottom: '10px'}}>Who is eligible for Medicare SNPs?</h3>
                        To be eligible for a Special Needs Plan, you must qualify for a C-SNP, I-SNP, or D-SNP, and you must already be enrolled 
                        in both Medicare parts A and B, or a combination also known as Part C.
                    </p>
                    <p className="bodyParagraph">
                        Apart from that, you’ll need to qualify for a specific type of SNP based on the rules of that plan, such as having a 
                        medical diagnosis or certain level of income.
                    </p>
                    <p className="bodyParagraph">
                        <h3 style={{marginBottom: '10px'}}>How do I enroll in a plan?</h3>
                        Automatic enrollment doesn’t occur with SNPs, and typically you would join a SNP during set Medicare Advantage enrollment 
                        periods.
                    </p>
                    <p className="bodyParagraph">
                        Special enrollment periods are also offered for a number of reasons, including a change in your health conditions, 
                        employment status, where you live, or the plan you have.
                    </p>
                    <p className="bodyParagraph">
                        Special enrollment is offered to anyone with both Medicare and Medicaid as long as you are enrolled in both programs. 
                        People who need to move to a higher level of care or into a nursing home, and those with disabling chronic illness, can 
                        enroll in an SNP at any time.
                    </p>
                    <p className="bodyParagraph">
                        Once you’ve done your research and found a Medicare Special Needs Plan that fits your needs, there are various ways to enroll:
                    </p>
                    <p className="bodyParagraph">
                        <ul style={{marginLeft: '20px'}}>
                            <li>Go to the plan’s website to see if you can enroll online. Contact the plan to get a paper enrollment form. Fill it out and 
                            return it to the plan provider.</li>
                            <li>Call the provider for the plan you wish to join.</li>
                            <li>Speak to a licensed broker to review your options. Email us at hi@medicarebits.com to get connected to one.</li>
                        </ul>
                    </p>
                    <p className="bodyParagraph">
                        You will need your Medicare number and the date your Medicare Part A and/or Part B coverage started. You must be enrolled in 
                        Medicare Parts A and B before you can enroll in a Special Needs plan.
                    </p>
                    <p className="bodyParagraph">
                        <h3 style={{marginBottom: '10px'}}>Need Help Finding the right plan?</h3>
                        If you would like to speak to a licensed broker, email us at hi@medicarebits.com.
                    </p>
                    <p className="bodyParagraph">
                        Alternatively, for questions about Medicare plans and other Medicare issues, contact Medicare at 800-MEDICARE (800-633-4227) or 
                        visit Medicare.gov. 
                    </p>




                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        Once you’ve done your research and found a Medicare Advantage plan that fits your needs, there are various ways to enroll:
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        <ul style={{marginLeft: '20px'}}>
                            <li>Use Medicare’s Plan Finder to find the plan in your area. Click on “Enroll.”</li>
                            <li>Go to the plan’s website to see if you can enroll online. Contact the plan to get a paper enrollment form. Fill it out and return it 
                                to the plan provider.</li>
                            <li>Call the provider for the plan you wish to join.</li>
                            <li>Call Medicare at 800-MEDICARE (800-633-4227).</li>
                            <li>Speak to a licensed broker to review your options. Email us at hi@medicarebits.com to get connected to one.</li>
                        </ul>
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        You will need your Medicare number and the date your Medicare Part A and/or Part B coverage started. You must be enrolled in Medicare Parts A 
                        and B before you can buy a Medicare Advantage plan.
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        Keep in mind that you can only enroll in a Medicare Advantage plan during your Initial Enrollment Period (when you first become eligible for Medicare) 
                        or during the Open Enrollment Period from Oct. 15 to Dec. 7. Once you’re enrolled in a Medicare Advantage plan, you can switch plans during Medicare 
                        Advantage Open Enrollment from Jan. 1 to March 31 each year.
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        <h3 style={{marginBottom: '10px'}}>Need Help Finding the right plan?</h3>
                        If you would like to speak to a licensed broker, email us at hi@medicarebits.com.
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        Alternatively, for questions about Medicare plans and other Medicare issues, contact Medicare at 800-MEDICARE (800-633-4227) or visit Medicare.gov. 
                    </p>
                </div>
            </div>
        </>
    );   
}

export default withRouter(CountyStateSNPPage);
