import React, { useState, useEffect } from 'react';

import './Banner.css'

function Banner(props) {
    let successColor = "lightgreen";
    let failColor = "lightcoral";

    let readyToFade;
    let fadingOut;
    const [visible, setVisible] = useState(false);
    const [fadeOut, setFadeOut] = useState(false);
    // const [onByDefault, setOnByDefault] = useState(false);

    let onByDefault = false;
    let yOffset = props.yOffset ? props.yOffset : -50;

    if (!props.turnTriggerOff) {
      // Then this banner should be on by default (for backwards compatability)
      // setOnByDefault(true);
      onByDefault = true;
    }
    useEffect(() => {
      console.log("onByDefault" + onByDefault)
    }, [onByDefault])

    useEffect(() => {
      // Trigger this banner to activate
      if (props.triggerOn) {
        if (props.turnTriggerOff) {
          props.turnTriggerOff();
        }
        // If banner is already visible, clear timeout objects
        if (visible) {
          clearTimeout(readyToFade);
          clearTimeout(fadingOut);
        }
        setVisible(true);
        setFadeOut(false);

        // If banner will fade out, setup timeout objects
        if (props.lifetime !== null) {
          readyToFade = setTimeout(function(){
            setFadeOut(true);
            fadingOut = setTimeout(function(){
              setVisible(false);
              onByDefault = false;
              // setOnByDefault(false);
              // setFadeOut(false);
            }, 1000);
          }, props.lifetime);
        }
      }
    }, [props.triggerOn])

    return (
      <div>
      {
        (visible || onByDefault) ?
        <div style={{top: yOffset + "px"}}>
          <div className={(fadeOut === true) ? 'banner-fadeout' : 'banner'}
               style={{...props.style,
                       top: yOffset + "px",
                       background: (props.isSuccess === false) ? failColor : successColor,
                       position: props.lifetime ? "absolute" : undefined}}>
            {props.message}{props.link ? <u><a style={{paddingLeft: "10px"}} onClick={props.link}>{props.linkText}</a></u> : <></>}
          </div>
        </div> :
        <div></div>
      }
      </div>
    )
}

export default Banner;
