import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory, Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { BrokerContext } from '../../../components/BrokerContext';
import { PlanContext, ClientContext, ApplicationContext } from '../../../components/PCAContext';

import './ReadySign.css';

import AncillaryPlanBox from '../../../components/AncillaryPlanBox';
import Banner from '../../../components/Banner';
import LoadingOverlay from '../../../components/LoadingOverlay';

import mixpanel from 'mixpanel-browser';
import { ENVContext } from '../../../components/ENVContext';

import { formatCurrency } from '../../../utils.js';

const fetchApplicationsInApplicationEnvelope = async (key, application_envelope_id) => {
    if (application_envelope_id == -1) {
      return null;
    }
    let obj = {
        "application_envelope_id": application_envelope_id,
    }

    let json = JSON.stringify(obj);

    const res = await fetch('/api/fetch_applications_in_application_envelope', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: json
    })
    return res.json();
}

function ReadySignPage(props) {
    let history = useHistory();

    const [ENV, setENV] = useContext(ENVContext);
    const [broker, setBroker] = useContext(BrokerContext);
    const [plan, setPlan] = useContext(PlanContext);
    const [client, setClient] = useContext(ClientContext);
    const [application, setApplication] = useContext(ApplicationContext);

    const [showBanner, setShowBanner] = useState(false);
    const [bannerMessage, setBannerMessage] = useState("");
    const [refreshPoll, setRefreshPoll] = useState(0);
    const [loading, setLoading] = useState(true);
    const [householdDiscountAmt, setHouseholdDiscountAmt] = useState("");
    const [directDebitDiscountAmt, setDirectDebitDiscountAmt] = useState("");
    const [newToMedicareDiscountAmt, setNewToMedicareDiscountAmt] = useState("");
    const [tabIsFocused, setTabIsFocused] = useState(true);

    let { application_id } = useParams();

    const { data: multiplePlansQuery, status } = useQuery(['multiplePlans', application.id], fetchApplicationsInApplicationEnvelope);

    function onFocus() {
      setTabIsFocused(true);
    }
    function onBlur() {
      setTabIsFocused(false);
    }

    useEffect(() => {
        let obj = {
            "application_id": application_id
        }
        fetch('/api/manually_check_inbox', {
            method: ['POST'],
            body: JSON.stringify(obj)
        }).then(res => res.json())
        .then(data => {
            setApplication({...application, ...data, url: data.signing_url});
        });
        setLoading(false);

        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        // Specify how to clean up after this effect:
        return () => {
          window.removeEventListener('focus', onFocus);
          window.removeEventListener('blur', onBlur);
        };
    }, []);

    useEffect(() => {
        console.log("application");
        console.log(application);
        if (application.id !== null && application.id !== -1) {
          if (application.url === undefined) {
              let obj = {
                  "application_id": application.id
              }
              fetch('/api/retrieve_signing_url', {
                  method: ['POST'],
                  body: JSON.stringify(obj)
              }).then(res => res.json())
              .then(data => {
                  if (data.signing_url !== null) {
                    setApplication({...application, url: data.signing_url});
                  }
              });
          }
        }
        // Get household discount percentage
        if (application.plan_quote_id && application.plan_quote_id !== null) {
          fetch('/api/get_plan_quote_by_id', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              "plan_quote_id": application.plan_quote_id,
            }),
            referrer: '',
          }
        ).then(res => res.json())
        .then(data => {
          console.log("DATAKK")
          console.log(data)

          if (data.household_discount != "NaN") {
            setHouseholdDiscountAmt((parseFloat(data.premium) - parseFloat(data.discounted_premium)).toFixed(2));
          }

          if (parseFloat(data.premium) * 12 !== parseFloat(data.annual_rate) && (client.billing_preference === "direct_debit")) {
            let amt = Math.abs(((parseFloat(data.annual_rate) - parseFloat(data.premium)*12)/12).toFixed(2))
            console.log("AMT: " + amt);
            if (amt > 0.1) {
              setDirectDebitDiscountAmt(amt);
            }
          }

          if (data.new_to_medicare_discount !== null && application.new_to_medicare_discount === true) {
            setNewToMedicareDiscountAmt(parseFloat(data.new_to_medicare_discount));
          }
        })
      }
    }, [application])

    let refreshObj;

    useEffect(() => {
        console.log("poll " + refreshPoll + "  " + application.status);
        refreshObj = setTimeout(() => {
            setRefreshPoll(refreshPoll+1);
        }, 1000);
        if (tabIsFocused && refreshPoll < 600) { // 600s = 10 minutes
          console.log("db check");
          refresh();
        }
    }, [application, refreshPoll]);

    function resetRefreshPoll() {
      clearTimeout(refreshObj);
      setRefreshPoll(0);
    }

    // Opens new tab with DocuSign document
    function goSign() {
        alert(`Your access code is ${application.access_code}. You will need this to sign the application.`);
        setApplication({...application, status: 2});
        let data = {"id": application.id, "status": 2};

        let json = JSON.stringify(data);
        fetch('/api/update_application', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: json,
        }).then(res => res.json());

        mixpanel.init(ENV.MIXPANEL_TOKEN);
        mixpanel.track("Sign now", {application_id: application.id})
        window.open(application.url);
    }

    function reviseApp() {
      let data = {"id": application.id, "status": 0};

      let json = JSON.stringify(data);
      fetch('/api/update_application', {
          method: 'POST',
          headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          },
          body: json,
      }).then(res => res.json());

      history.push(`/applications/client-form-page/`+application.id);
    }

    // Sends signing url to client email
    function sendEmail() {
        alert(`Email sent to client: ${client.email}`);
        let obj = {
            "recipient_email": client.email,
            "signing_link": application.url,
            plan: {
              ...plan
            },
            carrier: {
                "name": plan.carrier_name,
            },
            client: {
                "first_name": client.first_name,
            },
            broker: {
                "first_name": broker.first_name,
                "last_name": broker.last_name,
                "email": broker.email,
                "phone": broker.phone,
                "broker_npn": broker.broker_npn,
            },
            application: {
                "id": application.id,
                "ds_access_code": application.access_code,
            }
        }

        mixpanel.init(ENV.MIXPANEL_TOKEN);
        mixpanel.track("Sign via email", {application_id: application.id})
        var json = JSON.stringify(obj);
        fetch('/api/send_application_signing_email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: json,
        });
        setApplication({...application, status: 3});
    }

    function refresh() {
        if (application.id && application.id !== -1) {
          let obj = {"app_id": application.id};
          let json = JSON.stringify(obj);
          fetch('/api/get_application_status', {
              method: 'POST',
              body: json,
              signal: props.controller.controller.signal,
          })
          .then(res => res.json())
          .then(data =>{
              if (data["status"] == 4) {
                fetch('/api/get_application_by_id', {
                      method: 'POST',
                      headers : {
                          'Content-Type': 'application/json',
                          'Accept': 'application/json'
                      },
                      body: JSON.stringify(application.id),
                      signal: props.controller.controller.signal,
                  }).then(res => res.json())
                    .then(app_data => {
                      setApplication({...application,
                                      status: data["status"],
                                      download: data["aws_pdf_key"],
                                      envelope_id: data["envelope_id"],
                                      aws_pdf_key: app_data.aws_pdf_key,
                                      dental_aws_pdf_key: app_data.dental_aws_pdf_key,
                                      vision_aws_pdf_key: app_data.vision_aws_pdf_key,
                                      });
                      history.push(`/applications/ready-submit/${application.id}`);
                  });

              }
          });
        }
    }

    function copyLink() {
        window.getSelection().empty();
        if (document.selection) {
            var range = document.body.createTextRange();
            range.moveToElementText(document.getElementById("access_code"));
            range.select();
        } else if (window.getSelection) {
            var range = document.createRange();
            range.selectNode(document.getElementById("access_code"));
            window.getSelection().addRange(range);
        }

        navigator.clipboard.writeText("MEDICARE").then(function() {
          console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
        setBannerMessage("Link copied to clipboard!");
        setShowBanner(true);
    }

    // function calculateHouseholdDiscount() {
    //   let discountPercent = parseFloat(householdDiscountPercentage) / 100;
    //   return ((parseFloat(application.premium) * discountPercent / (1 - discountPercent)).toFixed(2));
    // }

    function generateMedSuppDiscounts() {
      let str = "";
      if (application.household_discount) {
        str += `<li>Household Discount ($${householdDiscountAmt}/mo)</li>`;
      }
      if (newToMedicareDiscountAmt !== "") {
        str += `<li>New to Medicare Discount ($${newToMedicareDiscountAmt}/mo)</li>`;
      }
      if (directDebitDiscountAmt !== "") {
        str += `<li>Direct Debit Discount ($${directDebitDiscountAmt}/mo)</li>`;
      }
      if (str !== "") {
        str = "<b>Monthly Premium Includes:</b><br/><ul>" + str + "</ul><br/>";
      }

      return str;
    }

    return (
      <>
        {loading ?
          <LoadingOverlay/> : <></>
        }
        
        <div className = "mainArea">
            <div className = "mainTitle">
                {
                    application.status == 2
                    ? (<Banner style={{"marginTop": "-49px"}}
                              message ="Signing process initiated! Please wait for signing to finish..."
                              link={resetRefreshPoll}
                              linkText="Refresh"></Banner>)
                    : application.status == 3
                    ? (<Banner style={{"marginTop": "-49px"}}
                              message ="Application sent to client for signing! Please wait for signing to finish..."
                              link={resetRefreshPoll}
                              linkText="Refresh"></Banner>)
                    : showBanner
                    ? (<Banner style={{"marginTop": "-50px"}} message ={bannerMessage}></Banner>)
                    : (<div className = "bannerEmpty"></div>)
                }
                <div className = "titleInfo" style={{left:"5%"}}>
                    {
                        application.status == 1
                        ? <>
                          <h1 className = "titleName">Application Ready for Signing</h1>
                          <p className = "titleDetails">Your application has been generated. Choose how you would like to get it signed.</p>
                        </>
                        : <>
                          <h1 className = "titleName">Application Sent for Signing</h1>
                          <p className = "titleDetails">Your application has been sent for signing. Your application's status should be updated shortly.</p>
                          {application.household_discount_email ?
                            <>
                              <br/>
                              <p className = "titleDetails"><b>NOTE:</b> There are multiple signers for this application for the household discount, please wait for all signers to finish.</p>
                              <p className = "titleDetails">A signing email has been sent to <b>{application.household_discount_email}</b>.</p>
                            </>
                            : <></>
                          }
                        </>
                    }
                </div>
            </div>
            <div className = "vertCont appInfo" style={{left:"5%", width:"60%"}}>
                <div className = "horCont appName">
                    <div className = "vertCont">
                        <label>Name</label>
                        <Link to = {'/clientdetails/'+client.id} className = "linkStyle">{`${client.first_name} ${client.last_name}`}</Link>
                    </div>
                </div>
                <div style = {{marginTop: "10px"}}>
                    <div className="horCont" style={{width: "140px"}}>
                      <div className="vertCont">
                        <label>Access code</label>
                        <p id="access_code" style = {{width: "max-content", background: "yellow"}}>{application.access_code || "MEDICARE"}</p>
                      </div>
                      <div>
                        <a onClick={copyLink}><img src="https://planprovide-resources.s3.us-west-1.amazonaws.com/copy_icon.png" style={{width:"25px"}}></img></a>
                      </div>
                    </div>
                </div>
                <br/>
                <div style = {{width: "150%"}} className = "horCont appDetails">
                    <div style = {{width: "100%"}} className = "vertCont">
                        <label>Plans</label>
                        {plan.type === 'SOA' ? <></> :
                          <div style = {{left: "0px", marginLeft: "0px", width: "100%"}} className = "horCont boxLabels">
                              <div style = {{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                <label style = {{width: "35%", marginLeft:"60px"}}>Carrier</label>
                                <label style = {{width: "10%"}}>Plan</label>
                                <label style = {{width: "10%"}}>Type</label>
                                <label style = {{width: "10%"}}>Monthly Premium</label>
                                <label style = {{width: "20%"}}></label>
                              </div>
                          </div>
                        }

                        {
                          status === 'success' && multiplePlansQuery !== null && multiplePlansQuery !== undefined && Array.isArray(multiplePlansQuery) ?
                          multiplePlansQuery.sort((a, b) => parseFloat(a.premium) < parseFloat(b.premium) ? 1 : -1)
                          .map((p, index) => (
                            <AncillaryPlanBox
                              type={p.type === '1' ? "Medicare Supplement" : p.type === 'dental' ? "Dental" : p.type === 'vision' ? "Vision" : p.type}
                              companyName = {p.carrier_name}
                              planName = {p.name}
                              value = {p.id}
                              notes = {p.notes}
                              premium = {p.premium ? p.premium : "Not available"}
                              addon_plan = {p.addon_plan}
                              discounts = {p.type === '1' ? generateMedSuppDiscounts() : null}
                              style = {{marginLeft: "0%", width:"100%", flex: "1"}}
                              carrierWidth = "35%"
                              planWidth = "10%"
                              typeWidth = "10%"
                              premiumWidth = "10%"
                              buttonWidth = "20%"
                              hideButton
                            ></AncillaryPlanBox>
                          )) : <></>
                        }

                    </div>
                </div>
                <br></br>
                <div style = {{justifyContent: "start", marginBottom: "30px"}} className = "horCont appButtons">
                    <button style = {{marginRight: "40px", width: "150px"}} id = "signNow" className = "buttonStyle" onClick = {goSign}>Sign Now</button>
                    <button style = {{marginRight: "40px", width: "150px"}} id = "emailClient" className = "buttonStyle" onClick = {sendEmail}>{application.status === 3 ? "Resend Email" : "Email to Client"}</button>
                    <button style = {{marginRight: "40px", width: "190px"}} className = "secondaryButtonStyle" onClick = {reviseApp}>Revise Application</button>
                </div>
            </div>
        </div>
      </>
    );
  }

  export default ReadySignPage;
