import React, { useEffect, useState, useContext, useRef } from 'react';
import { withRouter, useHistory, useParams, BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';

import { NavigationContext } from '../components/NavigationContext';
import { BrokerContext } from '../components/BrokerContext';
import { PlanContext, ClientContext, ApplicationContext } from '../components/PCAContext';

import './pages.css';
import './Applications.css';

import Sidebar           from '../components/Sidebar';
import Headbar           from '../components/Headbar';
import SetEnvironment    from '../components/SetEnvironment';
import LoadingOverlay    from '../components/LoadingOverlay';
import PlanBox from '../components/PlanBox';
import PickPlan from '../pages/subpages/Applications/PickPlan';
import PlanFilters from '../components/PlanFilters';

import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';

function LiveSalesSessionBrokerStart(props) {
    let history = useHistory();

    const [broker, setBroker] = useContext(BrokerContext);
    const [plan, setPlan] = useContext(PlanContext);
    const [client, setClient] = useContext(ClientContext);
    const [application, setApplication] = useContext(ApplicationContext);

    const [phoneNumber, setPhoneNumber] = useState("");

    useEffect(() => {
      if (broker.live_session_room_id !== null && broker.live_session_room_id !== undefined) {
        history.push('/meetings/live-client-session?room=' + broker.live_session_room_id);
      }
    }, []);

    function newWebsocketRoom() {
      console.log(broker.agency_name);
      fetch('/api/unique_websocket_id', {
        method: 'GET',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },          
      }).then(res => res.json())
      .then(roomID => {
        console.log(roomID);

        let json = JSON.stringify({
          recipient_number: phoneNumber,
          url: window.location.origin + "/live-client-session?room=" + roomID,
          broker_name: broker.first_name + " " + broker.last_name,
        });
        fetch('/api/invite_client_to_meeting', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: json
        });

        var pad = function(num) { return ('00'+num).slice(-2) };
        var date;
        date = new Date();
        date = date.getUTCFullYear()         + '-' +
                pad(date.getUTCMonth() + 1)  + '-' +
                pad(date.getUTCDate())       + ' ' +
                pad(date.getUTCHours())      + ':' +
                pad(date.getUTCMinutes())    + ':' +
                pad(date.getUTCSeconds());
        
        console.log(date);
        fetch('/api/update_broker', {
          method: 'POST',
          headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          },
          body: JSON.stringify({
            id: broker.id,
            live_session_room_id: roomID,
            live_session_create_date: date,
            live_session_client_name: "",
          })
        }).then(res => res.json())
        .then(data => {
            setBroker({...broker,
              live_session_room_id: data.live_session_room_id,
              live_session_create_date: data.live_session_create_date,
              live_session_client_name: data.live_session_client_name, 
            });
        });
        
        history.push('/meetings/live-client-session?room=' + roomID);
      });
    }

    function existingWebsocketRoom() {
      let roomID = broker.live_session_room_id;

      let json = JSON.stringify({
        recipient_number: phoneNumber,
        url: window.location.origin + "/live-client-session?room=" + roomID,
        broker_name: broker.first_name + " " + broker.last_name,
      });
      fetch('/api/invite_client_to_meeting', {
          method: 'POST',
          headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          },
          body: json
      });
      fetch('/api/update_broker', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
          id: broker.id,
          live_session_room_id: roomID,
          live_session_create_date: new Date(),
          live_session_client_name: "",
        })
      }).then(res => res.json())
      .then(data => {
          setBroker({...broker,
            live_session_room_id: data.live_session_room_id,
            live_session_create_date: data.live_session_create_date,
            live_session_client_name: data.live_session_client_name, 
          });
      });
      history.push('/meetings/live-client-session?room=' + roomID);
    }

    return(
      <>
        <div className = "background">
            <SetEnvironment></SetEnvironment>
            <Sidebar current="meetings" controller={props.controller}></Sidebar>
            <div className = "middle container">
              <Headbar title = "Meetings"></Headbar>
              <div style={{marginLeft: "2%", width: "96%", backgroundColor: "white", borderRadius: "5px", marginTop: "10px"}}>
                <div style={{padding: "5%"}}>
                  <h2>Start a Meeting</h2>
                  <div>Enter a phone number to send an invite:</div>
                  <input type="text" placeholder="(000) 000-0000" value={phoneNumber} onChange={(evt) => {setPhoneNumber(evt.target.value)}}></input>
                  <div className="horCont" style={{width: "40%"}}>
                    <button className="buttonStyle" style={{marginTop: "10px"}} onClick={newWebsocketRoom}>New Meeting</button>
                    {/*broker.live_session_room_id !== null && broker.live_session_room_id !== undefined ?
                      <>
                        <div>OR</div>
                        <button className="buttonStyle" style={{marginTop: "10px"}} onClick={existingWebsocketRoom}>Resume Existing Meeting</button>
                      </>
                      : <></>
                    */}
                  </div>
                </div>
              </div>
            </div>
        </div>
      </>
    );
}

export default withRouter(LiveSalesSessionBrokerStart);
