import React from 'react';

import './StateForm.css'
import { Field, ErrorMessage } from 'formik';


function StateForm(props) {

    const options = [
        { value: "AL", label: "Alabama"},
        { value: "AK", label: "Alaska"},
        { value: "AZ", label: "Arizona"},
        { value: "AR", label: "Arkansas"},
        { value: "CA", label: "California"},
        { value: "CO", label: "Colorado"},
        { value: "CT", label: "Connecticut"},
        { value: "DE", label: "Delaware"},
        { value: "DC", label: "District Of Columbia"},
        { value: "FL", label: "Florida"},
        { value: "GA", label: "Georgia"},
        { value: "HI", label: "Hawaii"},
        { value: "ID", label: "Idaho"},
        { value: "IL", label: "Illinois"},
        { value: "IN", label: "Indiana"},
        { value: "IA", label: "Iowa"},
        { value: "KS", label: "Kansas"},
        { value: "KY", label: "Kentucky"},
        { value: "LA", label: "Louisiana"},
        { value: "ME", label: "Maine"},
        { value: "MD", label: "Maryland"},
        { value: "MA", label: "Massachusetts"},
        { value: "MI", label: "Michigan"},
        { value: "MN", label: "Minnesota"},
        { value: "MS", label: "Mississippi"},
        { value: "MO", label: "Missouri"},
        { value: "MT", label: "Montana"},
        { value: "NE", label: "Nebraska"},
        { value: "NV", label: "Nevada"},
        { value: "NH", label: "New Hampshire"},
        { value: "NJ", label: "New Jersey"},
        { value: "NM", label: "New Mexico"},
        { value: "NY", label: "New York"},
        { value: "NC", label: "North Carolina"},
        { value: "ND", label: "North Dakota"},
        { value: "OH", label: "Ohio"},
        { value: "OK", label: "Oklahoma"},
        { value: "OR", label: "Oregon"},
        { value: "PA", label: "Pennsylvania"},
        { value: "RI", label: "Rhode Island"},
        { value: "SC", label: "South Carolina"},
        { value: "SD", label: "South Dakota"},
        { value: "TN", label: "Tennessee"},
        { value: "TX", label: "Texas"},
        { value: "UT", label: "Utah"},
        { value: "VT", label: "Vermont"},
        { value: "VA", label: "Virginia"},
        { value: "WA", label: "Washington"},
        { value: "WV", label: "West Virginia"},
        { value: "WI", label: "Wisconsin"},
        { value: "WY", label: "Wyoming"},
    ]

    return(
        <div>
            <label for = {props.name}>{props.labelInfo}</label>
                <br></br>
                <Field  as = "select"
                        style = {{minWidth: "150px"}}
                        name = {props.name}>
                    <option disabled value = "">Select</option>
                {
                    options.map((option, index) => (
                        <option value = {option.value}>{option.label}</option>
                    ))
                }
                </Field>
                <div className="errorLabel">
                  <ErrorMessage name={props.name} component="div" />
                </div>
        </div>
    );
}

export default StateForm;
