import React, { useState, useEffect } from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';

import './InputField.css'

function InputField(props) {
    const [valid, setValid] = useState(true);
    const [errorLabel, setErrorLabel] = useState(props.errorLabel);
    const [touched, setTouched] = useState(false);

    // Validate fields on initial page load
    useEffect(() => {
      if (props.name && props.defaultValue) {
        checkValidation(props.name, props.defaultValue);
      }
    }, [props.name, props.defaultValue]);

    useEffect(() => {
      console.log("TOUCHED" + touched);
    }, [touched]);

    useEffect(() => {
      console.log(props.touchAllFields);
      if (props.touchAllFields) {
        console.log("setting touched!");
        setTouched(true);
        console.log("touched: " + touched);
        console.log(valid);
      }
    }, [props.touchAllFields]);

    function requiredValidation(fieldValue) {
      console.log(fieldValue);
      console.log(fieldValue.trim());
      return (fieldValue && fieldValue !== null && fieldValue.trim() !== '');
    }

    function checkValidation(name, value) {
      // check for a new error
      if (props.required === true && !requiredValidation(value)) {
        console.log("required");
        setValid(false);
        setErrorLabel("This field is required");
      }
      else if (props.validationFunc && !props.validationFunc(value)) {
        console.log("invalid");
        setValid(false);
        setErrorLabel("Please enter a valid " + props.errorLabel);
      }
      else {
        console.log("good");
        setValid(true);
      }

      // // validate the field if the value has been touched
      if (props.setFieldValidation) {
        console.log(name + " VALID: " + valid);
        props.setFieldValidation(name, valid);
      }
    }

    function handleBlur(evt) {
      if (props.onBlur) {
        props.onBlur(evt);
      }

      const { name, value } = evt.target;
      console.log(name + " " + value);
      setTouched(true);
      checkValidation(name, value);
    };


    return(
        <div>
            {
                ! props.no_label ?
                <div>
                    <label htmlFor = {props.name}>{props.labelInfo}</label>
                    <br></br>
                </div>
                : <></>
            }
            <input type = "text"
                   style={props.style}
                   onChange = {props.handleChange || ""}
                   onInput = {props.onInput || ""}
                   onBlur = {handleBlur || undefined}
                   className = {(!valid && touched) ? 'errorStyle' : ''}
                   id = {props.name} name = {props.name}
                   defaultValue = {props.defaultValue}
                   placeholder = {props.placeholder  || "Type Here"}
                   pattern = {props.pattern || ".*"}
                   maxLength = {props.maxLength || ""}
                   // required = {props.required || ""}
                   disabled = {props.disabled}
                   readOnly = {props.readOnly}></input>
            {
                (!valid && touched) ?
                <p className = "errorLabel">{errorLabel}</p>
                : <></>
            }
        </div>
    );
}

export default InputField;
