import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import { PlanContext, ClientContext, ApplicationContext } from '../../../components/PCAContext';
import { BrokerContext } from '../../../components/BrokerContext';

import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';

import './coverageQuestions.css';

import AddHealthUnderwritingCondition from './AddHealthUnderwritingCondition';

import CheckBox from '../../../components/CheckBox';
import InputField from '../../../components/InputField';
import DateForm from '../../../components/DateForm';
import FormikRadioButton from '../../../components/FormikRadioButton';
import StateForm from '../../../components/StateForm';
import Banner from '../../../components/Banner';
import LoadingOverlay from '../../../components/LoadingOverlay';
import FormikInputField from '../../../components/FormikInputField';
import FormikCheckBox from '../../../components/FormikCheckBox';
import MedicalConditionBox from '../../../components/MedicalConditionBox';

import mixpanel from 'mixpanel-browser';

import { handleDate, printRFC3339Date } from '../../../utils.js';

function HealthUnderwritingConditions(props) {
  let history = useHistory();

  const [application, setApplication] = useContext(ApplicationContext);
  const [plan, setPlan] = useContext(PlanContext);
  const [client, setClient] = useContext(ClientContext);
  const [broker, setBroker] = useContext(BrokerContext);

  let { app_id } = useParams();

  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState(false);
  const [firstSubmit, setFirstSubmit] = useState(false);
  const [medicalConditions, setMedicalConditions] = useState([])
  const [conditionsFetched, setConditionsFetched] = useState(false);
  const [showPopup, setShowPopup] = useState(false);


  async function fetchMedicalConditions(key, client_id) {
    if (!client_id || conditionsFetched) {
      return null;
    }
    let json = JSON.stringify({"client_id": client_id});

    const res = await fetch('/api/get_client_medical_conditions', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': 0
        },
        body: json,
        signal: props.controller.controller.signal,
    }).then(res=>res.json())
    .then(data => {
      let tmp = [];
      for (let i=0; i < data.length; i++) {
        for (let j=0; j < data[i].drugs.length; j++) {
          data[i].drugs[j].index = j;
        }
        tmp.push({
          index: i,
          ...data[i]
        })
      }
      setMedicalConditions(tmp)
      setConditionsFetched(true);
    });
  }

  const {data: medicalConditionsQuery, status: medicalConditionsStatus} = useQuery(["medical_conditions", client.id], fetchMedicalConditions);

  useEffect(() => {
    console.log(medicalConditions);
  }, [medicalConditions]);

  function doSubmit() {
      console.log("Start submission");
      setLoading(true);

      fetch('/api/update_client_medical_conditions', {
          method: 'POST',
          headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          },
          body: JSON.stringify({
            client_id: client.id,
            medical_conditions: medicalConditions,
          }),
          signal: props.controller.controller.signal,
      }).then(res => res.json())
      .then(data => {
        console.log("Underwriting updated success");
        history.push(`/applications/ancillary-coverage/${application.id}`);
      });
  }

  function setSelectedCondition(e, index, attribute) {
    for (let m of medicalConditions) {
      if (parseInt(m.index) === index) {
        m[attribute] = e.target.value;
      }
    }
  }
  function setSelectedDrug(e, index, drugIndex, attribute) {
    console.log("SET SELECTED DRUG")
    console.log(e);
    console.log(e.target);
    console.log(index);
    console.log(drugIndex);
    for (let c of medicalConditions) {
      console.log(c)
      if (parseInt(c.index) === index) {
        for (let d of c.drugs) {
          if (parseInt(d.index) === drugIndex) {
            console.log("FOUND")
            console.log(d);
            console.log(e.target.value);
            d[attribute] = e.target.value;
          }
        }
        break
      }
    }
  }
  function addMedicalCondition() {
    setShowPopup(true)
    medicalConditions.push({
      index: medicalConditions.length,
      name: "",
      status: "",
      start_date: "",
      end_date: "",
      drugs: [],
    })
  }

  function deleteMedicalCondition(index) {
    let ls = medicalConditions.map((x) => x);
    for (let i = 0; i < ls.length; i++) {
      if (ls[i].index === index) {
        ls.splice(i, 1);
        for (let j = i; j < ls.length; j++) {
          ls[j].index -= 1;
        }
        break;
      }
    }
    console.log(ls)
    setMedicalConditions(ls);
  }

  function addDrug(index) {
    let tmp = [...medicalConditions];
    tmp[index].drugs.push({
      index: tmp[index].drugs.length,
      name: "",
      dosage: "",
      start_date: "",
      end_date: "",
    });
    setMedicalConditions(tmp);
  }
  function removeDrug(index, drugIndex) {
    let tmp = [...medicalConditions];

    for (let i = 0; i < tmp[index].drugs.length; i++) {
      if (tmp[index].drugs[i].index === drugIndex) {
        tmp[index].drugs.splice(i, 1);
        for (let j = i; j < tmp[index].drugs.length; j++) {
          tmp[index].drugs[j].index -= 1;
        }
        break;
      }
    }
    setMedicalConditions(tmp);
  }

  return (
    <>
      {
        loading
        ? <LoadingOverlay></LoadingOverlay>
        : <div style = {{display: "none"}}></div>
      }
      {
        banner
        ? <Banner message="Answering yes to any of the above questions would result in this application being unlikely to pass health underwriting."
                  isSuccess={false}></Banner>
        : <></>
      }
      <div>
        {showPopup ?
          <AddHealthUnderwritingCondition
            toggle={setShowPopup}
            medicalConditions={medicalConditions}
            setMedicalConditions={setMedicalConditions}
            setSelectedCondition={setSelectedCondition}
            index = {medicalConditions.length-1}
          />
        : null}
        <div className = "mainArea clientForm">
          <div className = "mainTitle">
              <div className = "titleInfo">
              <h1 className = "titleName">Medical Conditions</h1>
              <p className = "titleDetails">Please add your conditions and medications.</p>
              </div>
          </div>
          <div style = {{left: "0px", marginLeft: "15px", width: "100%"}} className = "horCont boxLabels">
              <div style = {{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <label style = {{width: "40%", marginLeft: "45px"}}>Condition</label>
                <label style = {{width: "15%"}}>Start Date</label>
                <label style = {{width: "15%"}}>End Date</label>
                <label style = {{width: "10%"}}>Status</label>
                <label style = {{width: "25%"}}></label>
                <div style={{width: "65px", marginRight:"5%"}}></div>
              </div>
          </div>
          <Formik
              initialValues={{

              }}
              validationSchema={Yup.object({

              })}
          >
            {({ isSubmitting, isValid, dirty, handleSubmit, handleChange, handleBlur, errors }) => (
              <form style={{width: "100%", maxWidth: "2000px"}}>
                <div>
                  {medicalConditions && medicalConditions.length > 0 ?
                    medicalConditions.map((condition, index) => (
                      <>
                        <MedicalConditionBox
                          name={condition.name}
                          index={condition.index}
                          start_date={condition.start_date}
                          end_date={condition.end_date}
                          status={condition.status}
                          drugs={condition.drugs}
                          deleteMedicalCondition={deleteMedicalCondition}
                        />
                      </>
                    )) : <></>
                  }
                </div>
              </form>
            )}
          </Formik>
          <div className="horCont" style={{width:"100%"}}>
            <a className="linkStyle"
               style={{color: "#EC3796", fontWeight: "600", marginLeft:"45%", height: "35px", lineHeight:"28px", width: "140px"}}
               onClick={() => {addMedicalCondition()}}><u>+ Add Condition</u></a>
          </div>
          {!(medicalConditions && medicalConditions.length > 0) ?
            <div style={{height:"200px"}}></div> : <></>
          }
          <div>
            <input type="button" className="buttonStyle" onClick={() => {doSubmit()}} value="Continue"></input>
          </div>
        </div>
      </div>
    </>
  );
}

export default HealthUnderwritingConditions;
