import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory, Link } from 'react-router-dom';
import { useQuery, useMutation, queryCache, useInfiniteQuery, usePaginatedQuery } from 'react-query';

import { BrokerContext } from '../components/BrokerContext';
import { ApplicationContext } from '../components/PCAContext';

import './pages.css';
import './Dashboard.css';

import Headbar from '../components/Headbar';
import Sidebar from '../components/Sidebar';
import SetEnvironment from '../components/SetEnvironment';
import DashBoxContainer from '../components/DashBoxContainer';
import Pagination from '../components/Pagination';

import { compareDate } from '../utils.js';

import TooltipPlayer from 'react-tooltip-player';

require('dotenv').config()

const fetchApplications = async (key, broker_id, page_num=1) => {
    if (broker_id != -1) {
        let json = JSON.stringify({"broker_id": broker_id, "page_num": page_num});

        const res = await fetch('/api/get_dashboard_applications_by_broker_id', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: json
        });
        return res.json();
    } else {
        return [];
    }
}

function filterLast30(app) {
    let dateA = new Date(app.auto_create_date);
    let dateB = new Date();
    let time_diff = dateB.getTime() - dateA.getTime();
    let days_diff = time_diff / (1000 * 3600 * 24)
    return days_diff < 30;
}

function Dashboard(props) {
    const [broker, setBroker] = useContext(BrokerContext);
    const [application, setApplication] = useContext(ApplicationContext);

    let { data,
          status,
          isFetching,
          isFetchingMore,
          fetchMore,
          canFetchMore,
          error,
    } = useInfiniteQuery(
      ['dashApps', broker.id], fetchApplications, {
        getFetchMore: (lastPage, allPages) => {
          console.log("GET FETCH MORE");
          console.log(lastPage);
          if (!lastPage.has_next) {
            return false;
          }
          return lastPage.next_num;
        },
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        refetchOnReconnect: true,
      });

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(Math.floor((window.innerHeight + 100) / 150));
    const [currentPosts, setCurrentPosts] = useState({});
    const [recentlyCompletedApps, setRecentlyCompletedApps] = useState([]);
    const [unfinishedApps, setUnfinishedApps] = useState([]);
    const [allApps, setAllApps] = useState([])

    let history = useHistory();

    let indexOfLastPost = currentPage * postsPerPage;
    let indexOfFirstPost = indexOfLastPost - postsPerPage;

    useEffect(() => {
      console.log("infinite query status change" + status);
      if ((!isFetching || !isFetchingMore) && canFetchMore) {
        fetchMore();
      }
    }, [isFetching, isFetchingMore]);

    useEffect(() => {
      console.log("DATA")
      console.log(data)
      if (Array.isArray(data)) {
          let all_apps = [];
          for (let d of data) {
            if (d.items) {
              for (let i of d.items) {
                all_apps.push(i);
              }
            }
          }
          console.log("HEY")
          console.log(all_apps);
          setAllApps(all_apps);
          let u = all_apps.filter((app) => {
              return app.status < 5;
          });
          console.log(u)
          u.sort(compareDate);
          u.reverse();
          setUnfinishedApps(u);
          console.log(u);

          let current_posts = {};
          for (let i = 0; i < Math.ceil(all_apps.length / postsPerPage); i++) {
              current_posts[i] = all_apps.slice(i * postsPerPage, i * postsPerPage + postsPerPage);
          }
          setCurrentPosts(current_posts);
          console.log(currentPosts)

          let r = all_apps.filter((app) => {
              return app.status == 5;
          });
          r.sort(compareDate);
          r.reverse();
          setRecentlyCompletedApps(r.slice(0, 10));
      }
    }, [data])

    useEffect(() => {
      setApplication({
          id: -1,
          status: -1,
          url: '',
          download: '',
          csv: '',
          envelope_id: '',
          access_code: '',
          effective_date: '',
      });
    }, [])

    useEffect(() => {
        console.log(currentPage)
    }, [currentPage])

    useEffect(() => {
        console.log(status);
    }, [status]);


    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const pageDown = () => currentPage > 1 ? setCurrentPage(currentPage-1) : null;

    const pageUp = () => currentPage < unfinishedApps.length / postsPerPage ? setCurrentPage(currentPage+1) : null;

    return(
        <div className = "background">
            <SetEnvironment></SetEnvironment>
            <TooltipPlayer projectId = "076e5f0f-d431-4e7d-81f0-5bbe1f7fa1f7"></TooltipPlayer>
            <Sidebar current = "dashboard" controller={props.controller}></Sidebar>
            <div className = "middle container">
                <Headbar title = "Dashboard"></Headbar>
                <div style = {{height: "90vh", marginBottom: "0px"}} className = "mainArea">
                    <div className = "dashArea">
                        <div className = "appArea">
                            <div style = {{height: "5%"}}>
                                {
                                    status === 'loading' && (<p>Applications loading...</p>)
                                }
                                {
                                    status === 'success' &&(<h3 className = "incompleteApps">Applications awaiting completion ({unfinishedApps.length})</h3>)
                                }
                            </div>
                            <div style = {{height: "90%"}}>
                                <DashBoxContainer apps = {currentPosts[currentPage - 1]} status = {status} size = "big"/>
                            </div>
                            <div style = {{height: "5%"}}>
                                {unfinishedApps.length > 0 ?
                                    <Pagination currentPage = {currentPage} postsPerPage = {postsPerPage} totalPosts = {unfinishedApps.length} paginate = {paginate} pageDown = {pageDown} pageUp = {pageUp}/>
                                : <></>}
                            </div>
                        </div>
                        <div className = "graphArea">
                            <div id = "dashGraph1">
                                <div style = {{marginTop: "10px", marginLeft: "10px", height: "10%"}}>
                                    <h3>Applications in past 30 days</h3>
                                </div>
                                <div style = {{alignSelf: "center", height: "90%", display: "flex"}}>
                                    <h1 style = {{fontSize: "6em", alignSelf: "center"}}>{Array.isArray(allApps) ? allApps.filter(filterLast30).length : []}</h1>
                                </div>
                            </div>
                            <div style = {{marginTop: "10px"}} id = "dashGraph2">
                               <div style = {{marginTop: "10px", marginLeft: "10px"}}>
                                    <h3>Recently completed applications</h3>
                                    <div>
                                        <DashBoxContainer apps = {recentlyCompletedApps} status = {"success"} size = "small"></DashBoxContainer>
                                    </div>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
    );
}

export default withRouter(Dashboard);
