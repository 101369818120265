import React, { useState, useEffect } from 'react';

import './PlanBox.css';

import { formatCurrency } from '../utils.js';
import Table from 'react-bootstrap/Table';

function PlanBox(props) {
    // useEffect(()=>{console.log(props.selectedPlan + ":" + props.value)}, [props.selectedPlan])
    let [premiumMultipleList, setPremiumMultipleList] = useState([]);
    let [drawerOpen, setDrawerOpen] = useState(true);

    useEffect(() => {
      if (props.premiumMultiple) {
        let ls = []
        for (let prem of props.premiumMultiple) {
          ls.push(parseFloat(prem["premium"]));
        }
        setPremiumMultipleList(ls);
      }
      // props.premiumMultiple.map((label, key) => (
      //    <label style={{"float": "right", "marginLeft": "30px"}}
      //           htmlFor={props.value}>
      //       {label[0] ? label[0] : "Standard"}<br />{formatCurrency(label[1])}
      //    </label>
      // ))
    }, [props.premiumMultiple])

    function separateByDelimiter(arr, keyName, delim) {
        let s = "";
        for (let i = 0; i < arr.length; i++) {
            if (arr[i][keyName] == null && keyName == "name") {
                s += "Standard";
            }
            else if (keyName == "premium") {
                s += formatCurrency(arr[i][keyName]);
            }
            else {
                s += arr[i][keyName];
            }
            
            if (i < arr.length - 1) {
                s += delim;
            }
        }
        return s;
    }

    const onClickBox = () => {props.onChange(
        {
            currentTarget: {
                value: JSON.stringify({
                    "index": props.index === props.selectedPlan ? null : props.index,
                    "id": props.index === props.selectedPlan ? null : props.value,
                    "premium": props.index === props.selectedPlan ? null : props.premium,
                    "plan_quote_id": props.index === props.selectedPlan ? null : props.planQuoteId,
                }),
                index: props.index === props.selectedPlan ? null : props.index,
            }
        }
    )}
    

    function createMarkup() {
        if (props.notes === null && props.discounts === null) {
          return {__html: "No details available for this plan."};
        }
        let output = (props.notes ? props.notes : "") + (props.discounts ? props.discounts : "")
        if (props.addon_plan) {
          output = "Add-on plan: " + props.addon_plan.name + " - " + formatCurrency(props.addon_plan.premium) + "<br/>" + output;
        }
        return {__html: output};
      }

    return(
    <>
        <tr style={ props.applicationAwsKey === null ? {background: "none", boxShadow: "none", ...props.style} :
                    props.detailsDrawer ? {height: props.index === props.selectedPlan ? "auto" : "80px",
                                           backgroundColor: props.index === props.selectedPlan ? "#e7e6e6" : undefined,
                                           ...props.style} :
                    props.style}
            onClick={props.mobile ? onClickBox : undefined}>
            {props.applicationAwsKey !== null && props.mobile === false ?
                <td><input type={props.checkbox ? "checkbox" : "radio"}
                        style={{marginTop: "7px"}}
                        value={JSON.stringify({
                            "index":props.index,
                            "id":props.value,
                            "premium": props.premium,
                            "plan_quote_id": props.planQuoteId,
                        })}
                        checked={props.checkbox ? undefined : props.index === props.selectedPlan}
                        onChange={props.onChange}></input></td>
                : <td></td>
            }
            <td style={{width: "60%"}}>
                <label htmlFor={props.value} style={{fontSize: props.mobile ? "12px" : "16px"}}>
                    {props.companyName ? props.companyName + " - " + props.planName : props.planName}
                </label>
            </td>
            {props.mobile ? <></> :
            <td>
                {props.premiumMultiple ?
                <label style={{fontSize: props.mobile ? "12px" : "16px"}}>
                    {separateByDelimiter(props.premiumMultiple, "name", " / ")}
                </label>
                : <label></label>}
            </td>}
            <td>
                {props.premiumMultiple ?
                <label style={props.mobile ? {fontSize: "12px", paddingLeft: "70px", paddingRight: "0px" } : {fontSize: "16px"}}>
                    {separateByDelimiter(props.premiumMultiple, "premium", " / ")}
                </label>
                : <label style={props.mobile ? {fontSize: "12px", paddingLeft: "70px", paddingRight: "0px" } : {fontSize: "16px"}}
                         htmlFor={props.value} onClick={()=> {console.log(props.premium)}}>{isNaN(props.premium) ? "--" : formatCurrency(props.premium)}</label>
                }
            </td>
            {
                props.detailsDrawer ? 
                <td>
                    <img src={props.index === props.selectedPlan ? "https://planprovide-resources.s3.us-west-1.amazonaws.com/up_arrow.png" : "https://planprovide-resources.s3.us-west-1.amazonaws.com/down_arrow.png"}
                        width="15px" />
                </td> : <></>
            }
        </tr>  
        {props.detailsDrawer && props.index === props.selectedPlan ?
        <tr className = "details" style={{backgroundColor: "#e7e6e6"}}>
            <td colspan="4" style={{"height": "auto", "paddingLeft": "5%", "width": "100%", backgroundColor: "#e7e6e6"}}>
                <Table bordered hover style={{width:"95%", marginBottom:"30px", backgroundColor:"#faf9f8"}} size="sm">
                    <thead>
                        <tr>
                            <th>
                                <label style={{fontSize:"12px"}}>Benefits</label>
                            </th>
                            <th>
                                <label style={{fontSize:"12px"}}>Medigap Plan G</label>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <label style={{fontSize:"12px"}} htmlFor={props.value}>Medicare Part A</label>
                            </td>
                            <td>
                                <label style={{fontSize:"12px"}} htmlFor={props.value}>100%</label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style={{fontSize:"12px"}} htmlFor={props.value}>Medicare Part B</label>
                            </td>
                            <td>
                                <label style={{fontSize:"12px"}} htmlFor={props.value}>100%</label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style={{fontSize:"12px"}} htmlFor={props.value}>Blood</label>
                            </td>
                            <td>
                                <label style={{fontSize:"12px"}} htmlFor={props.value}>100%</label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style={{fontSize:"12px"}} htmlFor={props.value}>Part A Hospice Care</label>
                            </td>
                            <td>
                                <label style={{fontSize:"12px"}} htmlFor={props.value}>100%</label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style={{fontSize:"12px"}} htmlFor={props.value}>Skilled Nursing Facility Care</label>
                            </td>
                            <td>
                                <label style={{fontSize:"12px"}} htmlFor={props.value}>100%</label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style={{fontSize:"12px"}} htmlFor={props.value}>Part A Deductible</label>
                            </td>
                            <td>
                                <label style={{fontSize:"12px"}} htmlFor={props.value}>100%</label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style={{fontSize:"12px"}} htmlFor={props.value}>Part B Deductible</label>
                            </td>
                            <td>
                                <label style={{fontSize:"12px"}} htmlFor={props.value}>No</label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style={{fontSize:"12px"}} htmlFor={props.value}>Part B Excess Charges</label>
                            </td>
                            <td>
                                <label style={{fontSize:"12px"}} htmlFor={props.value}>100%</label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style={{fontSize:"12px"}} htmlFor={props.value}>Foreign Travel Emergency</label>
                            </td>
                            <td>
                                <label style={{fontSize:"12px"}} htmlFor={props.value}>80%</label>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div
                     style={{marginLeft: "0px"}}
                     dangerouslySetInnerHTML={createMarkup()}
                ></div>
            </td>
        </tr>
        : <></>
        }
    </>
    );
}

export default PlanBox;
