 import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory, useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import { PlanContext, ClientContext, ApplicationContext } from '../../../components/PCAContext';
import { BrokerContext } from '../../../components/BrokerContext';

import './PlansList.css';

import InputField from '../../../components/InputField';
import PlanBox from '../../../components/PlanBox';

import mixpanel from 'mixpanel-browser';
import { ENVContext } from '../../../components/ENVContext';
import SetEnvironment from '../../../components/SetEnvironment';
import LoadingOverlay from '../../../components/LoadingOverlay';

import Table from 'react-bootstrap/Table';
import { formatCurrency } from '../../../utils.js';

const fetchPlans = async (key, type, num, zip, age, gender, county_id="", agency_id=-1) => {
    let obj = {
        "plan_type": type,
        "plan_number": num,
        "plan_zip": zip,
        "plan_age": age,
        "plan_gender": gender,
        "plan_county_id": county_id,
        "agency_id": agency_id,
    }

    let json = JSON.stringify(obj);
    const res = await fetch('/api/get_plans', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: json
    });
    return res.json();
}

const fetchCountiesFromZip = async (key, zip) => {
    let obj = {
        "zip": zip,
    }

    let json = JSON.stringify(obj);
    const res = await fetch('/api/get_counties_from_zip', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: json
    });
    return res.json();
}

const fetchAllCounties = async (key) => {
  const res = await fetch('/api/get_all_counties', {
      method: 'POST',
      headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      },
  });
  return res.json();
}

function PlansList(props) {
    let history = useHistory();

    const [ENV, setENV] = useContext(ENVContext);
    const [plan, setPlan] = useContext(PlanContext);
    const [client, setClient] = useContext(ClientContext);
    const [application, setApplication] = useContext(ApplicationContext);
    const [broker, setBroker] = useContext(BrokerContext);

    // let { client_id, plan_type, plan_num } = useParams();

    // Takes in planType and planNum variable from pick plans page
    const [planType, setPlanType] = useState(plan.type);// || plan_type );
    const [planNum, setPlanNum] = useState(plan.number || "G");// || plan_num);
    const [planZip, setPlanZip] = useState(plan.zip);
    const [planAge, setPlanAge] = useState(plan.age || "65");
    const [planGender, setPlanGender] = useState(plan.gender);
    const [planCountyId, setPlanCountyId] = useState(plan.county_id || "");
    const [selectedPlan, setSelectedPlan] = useState("");
    const [selectedPlanObject, setSelectedPlanObject] = useState(null);
    const [premium, setPremium] = useState("");
    const [planQuoteId, setPlanQuoteId] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [hideDisabledPlans, setHideDisabledPlans] = useState(true);




    useEffect(() => {
      if (!("new_client" in client)) { // If there's no new/existing client value
        history.push(`/applications/pick-client`)
      }
    }, []);

    // Fetch plans that match plan type from db
    const { data: queryData, status } = useQuery(['plans', planType, planNum, planZip, planAge, planGender, planCountyId, broker.agency_id], fetchPlans);
    const { data: countyZipQueryData, status: countyZipQueryStatus } = useQuery(['countiesFromZip', planZip], fetchCountiesFromZip);
    const { data: countiesQueryData, status: countiesQueryStatus } = useQuery(['allCounties'], fetchAllCounties);

    useEffect(()=>{console.log(queryData)}, [queryData]);

    function handleTypeChange(event) {
        setPlanType(event.target.value);
        setSelectedPlan("");
        setPremium("");
        setPlanQuoteId(-1);
    }

    function handleNumChange(event) {
        setPlanNum(event.target.value);
    }

    function handleZipChange(event) {
        setPlanZip(event.target.value);
    }

    function handleCountyChange(event) {
        setPlanCountyId(event.target.value);
    }

    function handleAgeChange(event) {
        setPlanAge(event.target.value);
    }

    function handleGenderChange(event) {
        setPlanGender(event.target.value);
    }

    function handleSelectPlan(event) {
        console.log(JSON.parse(event.currentTarget.value).index);
        console.log(JSON.parse(event.currentTarget.value).premium);
        console.log(JSON.parse(event.currentTarget.value).plan_quote_id);
        setSelectedPlan(JSON.parse(event.currentTarget.value).index);
        setSelectedPlanObject(event.currentTarget.value);
        setPremium(JSON.parse(event.currentTarget.value).premium);
        setPlanQuoteId(JSON.parse(event.currentTarget.value).plan_quote_id)
    }



    function handleSubmit(event) {
        // Prevent page from reloading
        event.preventDefault();

        setLoading(true);

        if (selectedPlanObject === null) {
          console.log("no plan selected");
          return;
        }
        console.log(selectedPlanObject);
        let object = JSON.parse(selectedPlanObject);

        fetch('/api/get_plan_by_id', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: object.id,
            signal: props.controller.controller.signal,
        }).then(res => res.json()).then(data => {
            mixpanel.init(ENV.MIXPANEL_TOKEN);
            mixpanel.track("Plan selected", {plan_id: data.plan.id});
            // Set plan info in context
            setPlan({
                ...plan,
                id: data.plan.id,
                name: data.plan.name,
                type: data.plan.type,
                number: data.plan.plan_number,
                carrier_name: data.carrier.name,
                carrier_id: data.carrier.id,
                premium: premium,

            });

            let json = JSON.stringify({
              effective_date: application.effective_date,
              broker_id: broker.id,
              plan_id: data.plan.id,
              client_id: client.id,
              plan_quote_id: planQuoteId,
              premium: premium,
            })
            // Uses POST method to send json object of data to Flask
            fetch('/api/create_application', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: json,
              referrer: '',
              signal: props.controller.controller.signal,
            }).then(res => res.json())
            .then(appData => {
              console.log(appData)
              setApplication({...application,
                ...appData,
                id: appData.id,
                status: appData.status,
              });


              let json = JSON.stringify({broker_id: broker.id});

              fetch('/api/get_broker_writing_numbers', {
                  method: 'POST',
                  headers : {
                      'Content-Type': 'application/json',
                      'Accept': 'application/json'
                  },
                  body: json,
                  signal: props.controller.controller.signal,
              }).then(res => res.json())
              .then(wriNumData => {
                let requireWritingNum = true;
                if (data.plan.carrier_id === -1) {
                  requireWritingNum = false;
                }
                else {
                  for (let w of wriNumData) {
                    if (w.carrier_id === data.plan.carrier_id) {
                      if (w.agent_number && w.agent_number !== "") {
                        requireWritingNum = false;
                      }
                    }
                  }
                }

                if (requireWritingNum) {
                  history.push(`/applications/writing-number`);
                }
                else {
                  history.push(`/applications/client-form-page/${appData.id}`);
                }
              });
            })
        });
    }

    return(
      <>
        {loading ? <LoadingOverlay/> : <></>}
        <div className = "mainArea">
          <div className = "mainTitle">
              <div style = {{left: "5%", width: "100%"}} className = "titleInfo">
                  <h1 className = "titleName">Select Plan</h1>
                  <form className = "adjustAppForm" onSubmit={(evt) => {evt.preventDefault();}}>
                      <div className = "horCont adjustInfo">
                          <div style = {{minWidth: "180px", maxWidth: "20%"}} className = "vertCont adjustType">
                              <label htmlFor = "plan_type">Plan Type</label>
                              <select name = "plan_type"
                                      defaultValue = {planType}
                                      onChange = {handleTypeChange}>
                                  <option value = "1">Medicare Supplement</option>
                                  <option value = "dental">Dental</option>
                                  <option value = "vision">Vision</option>
                                  <option value = "med_adv">Medicare Advantage</option>
                                  <option value = "pdp">PDP</option>
                                  <option value = "snp">Special Needs Plan (SNP)</option>
                                  <option value = "hospital_indemnity">Hospital Indemnity</option>
                                  <option value = "final_expense">Final Expense</option>
                                  <option value = "other">Other Documents</option>
                              </select>
                          </div>
                          {planType === "1" || planType === "med_adv" || planType == "pdp" || planType == "snp" ?
                            <>
                              <div style = {{minWidth: "60px"}} className = "vertCont adjustZip">
                                  <InputField name = "zip"
                                              labelInfo = "Zip code"
                                              defaultValue = {window.sessionStorage.getItem("zip") || plan.zip}
                                              placeholder = "00000"
                                              pattern = "\d{5}"
                                              maxLength = "5"
                                              onBlur={handleZipChange}
                                              required></InputField>
                              </div>
                              <div style = {{minWidth: "180px"}} className = "vertCont adjustCounty">
                                  <label htmlFor = "county">County</label>
                                  <select name = "county"
                                          defaultValue = {window.sessionStorage.getItem("county") || plan.county}
                                          onChange={handleCountyChange}>
                                      {countyZipQueryStatus === "success" && countiesQueryStatus === "success" ?
                                      [
                                          (countyZipQueryData.length > 1
                                            ? <option key={-1} value={""}>Select a County</option>
                                            : <></>
                                          ),
                                          countyZipQueryData.map((value, index) => {
                                            return <option key={index} value={value}>{countiesQueryData[value]}</option>
                                          })
                                      ]
                                      : <></>}
                                  </select>
                              </div>
                            </> : <></>
                          }
                          {planType === "1" ?
                            <>
                              <div style = {{minWidth: "60px"}} className = "vertCont adjustAge">
                                  <label htmlFor = "age">Age</label>
                                  <select defaultValue = {window.sessionStorage.getItem("age") || plan.age || "65"}
                                          name = "age"
                                          onChange={handleAgeChange}>
                                      <option value = "65">65</option>
                                      <option value = "66">66</option>
                                      <option value = "67">67</option>
                                      <option value = "68">68</option>
                                      <option value = "69">69</option>
                                      <option value = "70">70</option>
                                      <option value = "71">71</option>
                                      <option value = "72">72</option>
                                      <option value = "73">73</option>
                                      <option value = "74">74</option>
                                      <option value = "75">75</option>
                                      <option value = "76">76</option>
                                      <option value = "77">77</option>
                                      <option value = "78">78</option>
                                      <option value = "79">79</option>
                                      <option value = "80">80</option>
                                      <option value = "81">81</option>
                                      <option value = "82">82</option>
                                      <option value = "83">83</option>
                                      <option value = "84">84</option>
                                      <option value = "85">85</option>
                                      <option value = "86">86</option>
                                      <option value = "87">87</option>
                                      <option value = "88">88</option>
                                      <option value = "89">89</option>
                                      <option value = "90">90</option>
                                      <option value = "91">91</option>
                                      <option value = "92">92</option>
                                      <option value = "93">93</option>
                                      <option value = "94">94</option>
                                      <option value = "95">95</option>
                                      <option value = "96">96</option>
                                      <option value = "97">97</option>
                                      <option value = "98">98</option>
                                      <option value = "99">99</option>
                                      <option value = "100">100</option>
                                      <option value = "101">101</option>
                                      <option value = "102">102</option>
                                      <option value = "103">103</option>
                                      <option value = "104">104</option>
                                  </select>
                              </div>
                              <div style = {{minWidth: "60px"}} className = "vertCont adjustTobacco">
                                  <label htmlFor = "tobacco">Tobacco</label>
                                  <select defaultValue = {window.sessionStorage.getItem("tobacco") || plan.tobacco}
                                          name = "tobacco">
                                      <option value = "n">No</option>
                                      <option value = "y">Yes</option>
                                  </select>
                              </div>
                              <div style = {{minWidth: "50px"}} className = "vertCont adjustGender">
                                  <label htmlFor = "gender">Gender</label>
                                  <select defaultValue = {window.sessionStorage.getItem("gender") ||plan.gender}
                                          name = "gender"
                                          onChange = {handleGenderChange}>
                                      <option value = "M">M</option>
                                      <option value = "F">F</option>
                                  </select>
                              </div>
                              <div style = {{minWidth: "50px"}} className = "vertCont adjustPlan">
                                  <label htmlFor = "plan">Plan</label>
                                  <select name = "plan"
                                          defaultValue = {window.sessionStorage.getItem("plan") || planNum}
                                          onChange = {handleNumChange}>
                                      <option value = "G">G</option>
                                      <option value = "G with extras">G with extras</option>
                                      <option value = "N">N</option>
                                      <option value = "F">F</option>
                                      <option value = "HDG">HDG</option>
                                      <option value = "HDF">HDF</option>
                                  </select>
                              </div>
                            </>
                            : <div style={{width:"460px"}}></div>
                          }
                      </div>
                  </form>
              </div>
          </div>
          <form className = "planSelectForm" onSubmit = {handleSubmit}>

            <Table>
              {planType === "other" ? <></> :
                <thead>
                  <tr>
                    <th></th>
                    <th>Company</th>
                    <th></th>
                    <th>Monthly Premium</th>
                  </tr>
                </thead>
                }
                <tbody>
                  {
                      status === 'error' && (<tr><td colspan="4">Error fetching plans</td></tr>)
                  }
                  {
                      status === 'loading' && (<tr><td colspan="4">Plans are loading...</td></tr>)
                  }
                  {
                      status === 'success' && (
                          <>
                            {
                                queryData.length === 0
                                ? <p>There are no plans that match your search</p>
                                : [].concat(queryData)
                                  .sort((a, b) => a.premium > b.premium ? 1 : -1)
                                  .map((p, index) => (
                                    p.application_aws_key === null ? <></> :
                                    <PlanBox
                                      companyName = {p.carrier.name}
                                      planName = {p.name}
                                      value = {p.id}
                                      premium = {p.premium ? p.premium : "Not available"}
                                      planQuoteId = {p.plan_quote_id}
                                      premiumMultiple = {p.premiumMultiple || ""}
                                      onChange = {handleSelectPlan}
                                      selectedPlan = {selectedPlan}
                                      key = {index}
                                      applicationAwsKey = {p.application_aws_key}
                                      index={index}
                                      style={{ borderTop: "1px solid #dee2e6" }}
                                      mobile={false}></PlanBox>
                                ))
                            }
                            <br/>
                            {
                              hideDisabledPlans ?
                                <tr><td colspan="4"><a onClick={() => {setHideDisabledPlans(false)}}>v Unavailable Plans</a></td></tr> :
                                <tr><td colspan="4"><a onClick={() => {setHideDisabledPlans(true)}}>&gt; Unavailable Plans</a></td></tr>
                            }
                            {
                                queryData.length === 0
                                ? <tr><td colspan="4">There are no plans that match your search</td></tr>
                                : [].concat(queryData)
                                  .sort((a, b) => a.premium > b.premium ? 1 : -1)
                                  .map((p, index) => (
                                    p.application_aws_key !== null || hideDisabledPlans ? <></> :
                                    <PlanBox
                                      companyName = {p.carrier.name}
                                      planName = {p.name}
                                      value = {p.id}
                                      premium = {p.premium ? p.premium : "Not available"}
                                      planQuoteId = {p.plan_quote_id}
                                      premiumMultiple = {p.premiumMultiple || ""}
                                      onChange = {handleSelectPlan}
                                      selectedPlan = {selectedPlan}
                                      key = {index}
                                      applicationAwsKey = {p.application_aws_key}
                                      index={index}
                                      mobile={false}></PlanBox>
                                ))
                            }
                          </>
                      )
                  }
                  {selectedPlan === "" ?
                  <tr><td colspan="4">
                      <input type="button" className = "disabledButtonStyle" value="Continue" disabled></input>
                  </td></tr>
                  :
                  <tr><td colspan="4">
                      <input type="submit" className = "buttonStyle" value="Continue"></input>
                  </td></tr>
                  }
                  {planType === "med_adv" || planType === "pdp" || planType === "snp" ?
                    <tr><td colspan="4">
                      If your plan is not available to select, <a href="mailto:support@planprovide.com"><u>contact us</u></a> to add it to your account.
                    </td></tr>
                    : <></>
                  }
                </tbody>
            </Table>
          </form>
        </div>
      </>
    );
}

export default PlansList;
