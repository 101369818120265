import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { ClientContext } from '../../../components/PCAContext';
import { BrokerContext } from '../../../components/BrokerContext';

import mixpanel from 'mixpanel-browser';


import './../../pages.css';
import './../../ClientFormPage.css';

import ClientDetailsForm from '../../../components/ClientDetailsForm';
import InfiniteQueryList from '../../../components/InfiniteQueryList';
import AgencyNavigation from '../../../components/AgencyNavigation';

// Fetch all applications by broker id
const fetchClientApplications = async (key, broker_id, client_id, page_num=1) => {
  if (client_id != -1) {
    let json = JSON.stringify({"broker_id": broker_id, "client_id": client_id, "page_num": page_num});

    const res = await fetch('/api/fetch_paginated_client_applications', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: json
    });
    return res.json();
  } else {
    return [];
  }
}

function AgencyClientDetails(props) {
  let history = useHistory();

  const [client, setClient] = useContext(ClientContext);
  const [broker, setBroker] = useContext(BrokerContext);

  let { id, brokerId, brokerName } = useParams();

  let bannerSuccessMessage = "Client Updated!";
  let bannerFailureMessage = "An unknown error occurred."

  useEffect(() => {
    if (client.id == -1 && id != -1) {
      let client_json = JSON.stringify(id);
      fetch('/api/get_client_by_id', {
          method: 'POST',
          headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          },
          body: client_json
      }).then(res => res.json())
      .then(client_data => {
        if (client_data["status"] === "error") {
          let errorMessage = client_data["errorMessage"];
          history.push(`/error/${errorMessage}`)
        } else {
          setClient(client_data);
        }
      });
    }
  }, []);

  const handleSubmit = (json) => {
    console.log("submit")
    return new Promise((resolve, reject) => {
      // Uses POST method to send json object of data to Flask
      fetch('/api/update_client' , {
        method: 'POST',
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: json,
      }).then(res => {
        // Checks that external services return ok, throws 400-500 errors automatically made by the system
        if (res.ok) {
          resolve("success");
          return res.json();
        } else {
          if (res.status === 400) {
            resolve("failure");
            return res.json();
          }
        }
      }
      ).then(data => {
        // Checks for errors thrown by internal services, sends to error page
        if (data["status"] === "error") {
          let errorMessage = data["errorMessage"];
          history.push(`/error/${errorMessage}`);
          reject("Internal error.");
        }
      });
    })
  }

  return (
    <>
        <AgencyNavigation current="clients"/>
        <ClientDetailsForm
          client_id={client.id}
          title={client.first_name + " " + client.last_name}
          subTitle="Update client information and view application history below."
          pageTitle="Clients"
          buttonLabel="Update"
          handleSubmit={handleSubmit}
          mixpanelEvent="Update client profile"
          bannerSuccessMessage={bannerSuccessMessage}
          bannerFailureMessage={bannerFailureMessage}
          ownerBroker={brokerName}
          ownerBrokerId={brokerId}
          canEditEmail={true}
        />
        <InfiniteQueryList
            itemName="application"
            title="Application History"
            queryName="agencyClientApplications"
            queryArgs={[broker.id, client.id]}
            queryFunc={fetchClientApplications}
            location="agency client details page"
            mixpanelTrack="New application started"
            mixpanelTimeEvent="Client form submitted"
            newItemDestination="/applications/pick-client/"
            bannerMessage="Application(s) successfully deleted!"
            isApplicationHistory={true}
        ></InfiniteQueryList>
    </>
  );
}

export default withRouter(AgencyClientDetails);
