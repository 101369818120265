import React, { useState, useEffect } from 'react';

import './AncillaryPlanBox.css';

import { formatCurrency } from '../utils.js';

function MedicalConditionBox(props) {
    function selectPlan() {
      props.setPlan({
        "carrier_name": props.companyName,
        "name": props.planName,
        "id": props.value,
        "premium": props.premium,
        "notes": props.notes,
      })
      props.closePopup();
    }

    return(
      <>
        <div style = {props.style ? props.style : {marginLeft: "0%", width:"100%", flex: "1"}}
             className = "vertCont medicalConditionBox">
            <div style={{justifyContent: "initial", alignItems: "center", height: "80px", width: "100%", marginTop: "20px", marginBottom: "-12px"}}>
              <div style = {{width: "95%"}} className = "horCont planInfo">
                  <div style = {{marginLeft: "60px", width: "40%"}} className = "companyName">
                      <label style={{fontSize: "16px"}} htmlFor={props.value}>{props.name}</label>
                  </div>
                  <div style = {{width: "15%"}} className = "companyName">
                      <label htmlFor={props.value}>{props.start_date}</label>
                  </div>
                  <div style = {{width: "15%"}} className = "companyName">
                      <label htmlFor={props.value}>{props.end_date}</label>
                  </div>
                  <div style = {{width: "30%"}} className = "companyName">
                      <label htmlFor={props.value}>{props.status}</label>
                  </div>
                  <div className="horCont">
                    <a style={{color: "#EC3796", fontWeight: "600", margin: "0px", width:"65px"}} onClick = {() => {props.deleteMedicalCondition(props.index)}}>Remove</a>
                  </div>
              </div>
            </div>
            {
              props.drugs && props.drugs.length > 0 ?
              <div style={{height:"100px", width:"100%", color: "#4068a3", fontSize: "12px", }}>
                <div style={{marginLeft: "60px", color: "black"}}><b>Medications</b></div>
                {
                  props.drugs.map((drug, drugIndex) => (
                    <>
                      <div className="horCont" style={{width:"100%"}}>
                        <div style={{marginLeft: "60px", width: "40%"}}>
                          {drug.name} {drug.dosage}
                        </div>
                        <div style={{width: "15%"}}>
                          {drug.start_date}
                        </div>
                        <div style={{width: "15%"}}>
                          {drug.end_date}
                        </div>
                        <div style={{width: "30%"}}>

                        </div>
                        <div style={{width: "85px", marginRight:"5%"}}>

                        </div>
                      </div>
                    </>
                  ))
                }
              </div> :<></>
            }
        </div>

      </>
    );
}

export default MedicalConditionBox;
