import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory, Link } from 'react-router-dom';

import { BrokerContext } from '../components/BrokerContext';

import './pages.css';
import './ReadySign.css';
import '../components/BrokerProfileForm.css';

import Banner from '../components/Banner';
import mixpanel from 'mixpanel-browser';
import { ENVContext } from '../components/ENVContext';
import SetEnvironment from '../components/SetEnvironment';

function BrokerSignPage(props) {
    let history = useHistory();

    const [ENV, setENV] = useContext(ENVContext);
    const [broker, setBroker] = useContext(BrokerContext);

    const [refreshPoll, setRefreshPoll] = useState(0);
    const [clicked, setClicked] = useState(false);

    const [bannerMessage, setBannerMessage] = useState("");
    const [showBanner, setShowBanner] = useState(false);
    const [tabIsFocused, setTabIsFocused] = useState(true);
    const [manuallyChecked, setManuallyChecked] = useState(false);

    function onFocus() {
      setTabIsFocused(true);
    }
    function onBlur() {
      setTabIsFocused(false);
    }

    useEffect(() => {
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        // Specify how to clean up after this effect:
        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
        };
    }, []);

    let refreshObj;

    useEffect(() => {
        if (broker.id != -1) {
            refreshObj = setTimeout(() => {
                setRefreshPoll(refreshPoll+1);
            }, 1000);
            if (tabIsFocused && refreshPoll < 600) { // 600s = 10 minutes
              console.log("db check");
              refresh();
            }
        }
        if (broker.status == 1) {// || broker.status == undefined) {
            history.push('/dashboard');
        }
    }, [broker, refreshPoll]);

    useEffect(() => {
        if (broker.status == 1) {
            history.push('/dashboard');
        }

        if (!manuallyChecked && broker !== undefined && broker.id !== -1) {
            // Do handle gmail stuff on page load to check inbox
            let obj = {
                "broker_id": broker.id
            }
            fetch('/api/manually_check_inbox_broker_sign', {
                method: ['POST'],
                body: JSON.stringify(obj),
                signal: props.controller.controller.signal,

            }).then(res => res.json())
            .then(data => {
                console.log('checked data');
                console.log(data);
                setBroker({ ...broker, ...data });
                setManuallyChecked(true);
            });
        }
        
        if (broker.id != -1 && broker.status == null) {
            let json = JSON.stringify({"broker_id": broker.id});
            fetch('/api/get_broker_by_id', {
                method: 'POST',
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: json,
                signal: props.controller.controller.signal,

            }).then(res => res.json())
            .then(data => {
                console.log("HEY!")
                console.log(data);
                if (data["status"] === "error") {
                    let errorMessage = data["errorMessage"];
                    history.push(`/error/${errorMessage}`)
                } else {
                    setBroker({
                        agency_name: data.agency_name,
                        agency_npn: data.agency_npn,
                        broker_npn: data.broker_npn,
                        ds_access_code: data.ds_access_code,
                        email: data.email,
                        first_name: data.first_name,
                        id: data.id,
                        last_name: data.last_name,
                        phone: data.phone,
                        signing_url: data.signing_url,
                        status: data.status,
                        ds_access_code: data.ds_access_code,
                        role: data.role,
                        agency_id: data.agency_id,
                        live_session_room_id: data.live_session_room_id,
                        live_session_create_date: data.live_session_create_date,
                        live_session_client_name: data.live_session_client_name,
                    });
                    if (!broker.ds_access_code) {
                        history.push('/register/agency-type')
                    }
                }
            });
        }
    }, [broker]);

    // useEffect(() => {
    //     return () => {
    //         history.goForward();
    //     }
    // }, []);

    function resetRefreshPoll() {
      clearTimeout(refreshObj);
      setRefreshPoll(0);
    }

    // Opens new tab with DocuSign document
    function goSign() {
        alert(`Your access code is ${broker.ds_access_code}. You will need this to sign the application.`);
        mixpanel.init(ENV.MIXPANEL_TOKEN);
        mixpanel.track("Broker sign now", {broker_id: broker.id})
        setClicked(true);
        setBannerMessage("Signing process initiated! Please wait for signing to finish...");
        setShowBanner(true);
        window.open(broker.signing_url);
    }

    function refresh() {
        if (!clicked) {
            return;
        }
        let obj = {"broker_id": broker.id};
        let json = JSON.stringify(obj);
        fetch('/api/get_broker_status', {
            method: 'POST',
            body: json,
            signal: props.controller.controller.signal,

        })
        .then(res => res.json())
        .then(data =>{
            console.log(data)
            setBroker({...broker, status: data["status"]});

            if (data["status"] == 1) {
              history.push('/dashboard');
            }
        });
    }

    function copyLink() {
        window.getSelection().empty();
        if (document.selection) {
            var range = document.body.createTextRange();
            range.moveToElementText(document.getElementById("access_code"));
            range.select();
        } else if (window.getSelection) {
            var range = document.createRange();
            range.selectNode(document.getElementById("access_code"));
            window.getSelection().addRange(range);
        }

        navigator.clipboard.writeText("MEDICARE").then(function() {
          console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
        setBannerMessage("Link copied to clipboard!");
        setShowBanner(true);
    }

    return (
        <div className = "background">
            <SetEnvironment></SetEnvironment>
            <div style = {{margin: "0px", padding: "0px", maxWidth: "10000px", minHeight: "930px"}} className = "middle container">
                <div className="horCont" style = {{width: "100%", height: "100px", background: "#109cf1", marginTop: "0px"}}>
                </div>
                <div className = "mainTitle">
                    {
                        showBanner ? (<div style={{"marginTop": "-50px"}}>
                          <Banner message ={bannerMessage}
                                  link={null}//resetRefreshPoll}
                                  linkText={"Refresh"}></Banner>
                        </div>)
                        : (<div className = "bannerEmpty"></div>)
                    }
                </div>
                <div style = {{display: "flex", paddingBottom: "20px"}} className = "formArea">
                  <div className = "fieldArea" style={{marginLeft: "0px", marginBottom: "0px"}}>
                    <div className = "titleInfo">
                        <h1 className = "titleName">Complete your registration</h1>
                        <p className = "titleDetails">Almost there! To complete your registration, please sign our broker agreement with the access code below.</p>
                    </div>
                    <div className = "mainArea">
                        <div className = "vertCont appInfo">
                            <div style = {{marginTop: "10px"}}>
                              <div className="horCont" style={{width: "140px"}}>
                                <div className="vertCont">
                                  <label>Access code</label>
                                  <p id="access_code" style = {{width: "max-content", background: "yellow"}}>{broker.ds_access_code}</p>
                                </div>
                                <div>
                                  <a onClick={copyLink}><img src="https://planprovide-resources.s3.us-west-1.amazonaws.com/copy_icon.png" style={{width:"25px"}}></img></a>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="horCont" style={{width:"220px", marginLeft: "10%"}}>
                  {
                      !clicked ?
                      <div style = {{justifyContent: "start"}} className = "horCont appButtons">
                          <button style = {{marginLeft: "10%"}} id = "signNow" className = "buttonStyle" onClick = {goSign}>Sign Now</button>
                      </div>
                      :
                      <div style = {{justifyContent: "start"}} className = "horCont appButtons">
                          <button style = {{marginLeft: "10%"}} id = "signNow" className = "disabledButtonStyle">Sign Now</button>
                      </div>
                  }
                  <div style={{marginTop: "9px"}}>
                    <a onClick={() => {history.push(`/register/profile-details`)}}>↵ Back</a>
                  </div>
                </div>
            </div>
        </div>
    );
  }

  export default withRouter(BrokerSignPage);
