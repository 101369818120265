import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import { PlanContext, ClientContext, ApplicationContext } from '../../../components/PCAContext';
import { BrokerContext } from '../../../components/BrokerContext';

import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';

import './coverageQuestions.css';

import CheckBox from '../../../components/CheckBox';
import InputField from '../../../components/InputField';
import DateForm from '../../../components/DateForm';
import FormikRadioButton from '../../../components/FormikRadioButton';
import StateForm from '../../../components/StateForm';
import Banner from '../../../components/Banner';
import LoadingOverlay from '../../../components/LoadingOverlay';
import FormikInputField from '../../../components/FormikInputField';
import FormikCheckBox from '../../../components/FormikCheckBox';

import mixpanel from 'mixpanel-browser';

import { handleDate, printRFC3339Date } from '../../../utils.js';

async function fetchHRAQuestions(key, carrier_id, application_id) {
    if (!carrier_id) {
      return null;
    }
    let json = JSON.stringify({"carrier_id": carrier_id, "application_id": application_id});
  
    const res = await fetch('/api/get_hra_questions_by_carrier_id', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': 0
        },
        body: json,
    });
  
    return res.json();
}

function HRAQuestions(props) {
  let history = useHistory();

  const [application, setApplication] = useContext(ApplicationContext);
  const [plan, setPlan] = useContext(PlanContext);
  const [client, setClient] = useContext(ClientContext);
  const [broker, setBroker] = useContext(BrokerContext);

  let { app_id } = useParams();

  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState(false);
  const [firstSubmit, setFirstSubmit] = useState(false);
  const [previousAnswers, setPreviousAnswers] = useState({});
  const [firstFormPopulate, setFirstFormPopulate] = useState(false);
    
  const {data: HRAQuestionQuery, status: HRAQuestionStatus} = useQuery(["hra_questions", plan.carrier_id, application.id], fetchHRAQuestions);

  useEffect(() => {
      console.log(plan);
  }, [plan]);

  useEffect(() => {
    if (!firstFormPopulate && HRAQuestionQuery && HRAQuestionQuery.length > 0) {
        console.log("HRAQuestionQuery");
        console.log(HRAQuestionQuery);
        if (HRAQuestionQuery[1].answer !== undefined) {
            console.log("HAS PREVIOUS ANSWERS!");
            let answers = {};
            for (let q in HRAQuestionQuery) {
                let ans = HRAQuestionQuery[q].answer;
                answers["question_"+HRAQuestionQuery[q].question_number] = {question_id: HRAQuestionQuery[q].id, answer: ans};
            }
            setPreviousAnswers(answers);
            setFirstFormPopulate(true);
        }
        else {
            let answers = {};
            for (let q in HRAQuestionQuery) {
                let ans;
                if (HRAQuestionQuery[q].response_1 === null) {
                    ans = "false";
                }
                else {
                    ans = "response_1";
                }
                answers["question_"+HRAQuestionQuery[q].question_number] = {question_id: HRAQuestionQuery[q].id, answer: ans};
            }
            setPreviousAnswers(answers);
            setFirstFormPopulate(true);
        }
    }
  }, [HRAQuestionQuery]);

  useEffect(() => {
      console.log("previousAnswers");
      console.log(previousAnswers);
  }, [previousAnswers]);
  
  function checkRadio(value, index) {
    console.log(value);
    console.log(index);
    console.log(previousAnswers);
    // Update the answers object to reflect the new answer
    let ans = {...previousAnswers};
    if (value === "true") {
        ans["question_"+index] = {question_id: ans["question_"+index]["question_id"], answer: "true"};
    }
    else if (value === "false") {
        ans["question_"+index] = {question_id: ans["question_"+index]["question_id"], answer: "false"};
    }
    else {
        ans["question_"+index] = {question_id: ans["question_"+index]["question_id"], answer: "response_" + value};
    }
    setPreviousAnswers(ans);
}

  function doSubmit(object) {
      console.log("Start submission");
      setLoading(true);
      // Ignore form object, use the answers object instead
      console.log(object);
      console.log(HRAQuestionQuery)

      let obj = {...previousAnswers};
      console.log("OBJ");
      console.log(obj);

      for (let q in HRAQuestionQuery) {
            let answer;
            let question_num = HRAQuestionQuery[q].question_number;
            if (obj["question_" + question_num] === undefined) {
                if (HRAQuestionQuery[q].response_1 === null) {
                    answer = "false";
                }
                else {
                    answer = "response_1";
                }
                obj["question_" + question_num] = {question_id: HRAQuestionQuery[q].id, answer: answer};
            }
            
      }

      let json = JSON.stringify({
        client_id: client.id,
        application_id: application.id,
        responses: obj,
      });

      fetch('/api/submit_hra_answers', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: json,
        signal: props.controller.controller.signal,
    }).then(res => res.json())
    .then(data => {
        if (data["status"] === "error") {
            let errorMessage = data["errorMessage"];
            history.push(`/error/${errorMessage}`);
        } else {
            console.log("Underwriting updated success");
            history.push(`/applications/health-underwriting-provider/${application.id}`);
        }
    });
  }

  return (
    <>
      {
        loading
        ? <LoadingOverlay></LoadingOverlay>
        : <div style = {{display: "none"}}></div>
      }
      <div style={{marginLeft:"-5%"}}>

        <div className = "mainTitle">
            <div className = "titleInfo">
            <h1 className = "titleName">Health History</h1>
            <p className = "titleDetails">Please answer the following questions about your medical history.</p>
            </div>
        </div>
        <div className = "mainArea">
          <Formik
              initialValues={{

              }}
              validationSchema={Yup.object({

              })}
              onSubmit={(values, actions) => {
                console.log("SUBMIT!");
                doSubmit(values);
                actions.setSubmitting(false);
              }}
          >
            {({ isSubmitting, isValid, dirty, handleSubmit, handleChange, handleBlur, errors }) => (
              <form className = "clientForm" onSubmit={handleSubmit} style={{width: "80%", maxWidth: "2000px"}}>
                {
                  HRAQuestionQuery ? HRAQuestionQuery.sort((a, b) => a.question_number > b.question_number ? 1 : -1)
                    .map((question, index) => (
                    <div className="vertCont" style={{justifyContent:"initial"}}>
                        <div style={{fontSize:"13px"}}>
                            {question.question}
                        </div>
                        <div className="horCont" style={{marginRight: "10px",
                                                        marginTop:"-15px",
                                                        flex: "1 1 auto", 
                                                        justifyContent: "flex-start",
                                                        marginBottom:"20px"}}>
                            {
                                question.response_1 ?
                                    <>
                                        <FormikRadioButton
                                            name = {`question_${index+1}`}
                                            value = {`response_1`}
                                            style = {{marginRight:"10px", zIndex: "1"}}
                                            labelInfo = {question.response_1}
                                            checked={previousAnswers[`question_${index+1}`] ? previousAnswers[`question_${index+1}`]['answer'] === `response_1` : false}
                                            onChange = {e => {checkRadio("1", index+1)}}
                                            // defaultChecked={hasPreviousAnswers ? question.answer === "response_1" : true}
                                            formikHandleChange={handleChange}
                                        />
                                        {question.response_2 ?
                                            <FormikRadioButton
                                                name = {`question_${index+1}`}
                                                value = {`response_2`}
                                                style = {{marginRight:"10px", zIndex: "1"}}
                                                labelInfo = {question.response_2}
                                                checked={previousAnswers[`question_${index+1}`] ? previousAnswers[`question_${index+1}`]['answer'] === `response_2` : false}
                                                onChange = {e => {checkRadio("2", index+1)}}
                                                // defaultChecked={hasPreviousAnswers ? question.answer === "response_2" : false}
                                                formikHandleChange={handleChange}
                                        /> : <></>}
                                        {question.response_3 ?
                                            <FormikRadioButton
                                                name = {`question_${index+1}`}
                                                value = {`response_3`}
                                                style = {{marginRight:"10px", zIndex: "1"}}
                                                labelInfo = {question.response_3}
                                                checked={previousAnswers[`question_${index+1}`] ? previousAnswers[`question_${index+1}`]['answer'] === `response_3` : false}
                                                onChange = {e => {checkRadio("3", index+1)}}
                                                // defaultChecked={hasPreviousAnswers ? question.answer === "response_3" : false}
                                                formikHandleChange={handleChange}
                                        /> : <></>}
                                        {question.response_4 ?
                                            <FormikRadioButton
                                                name = {`question_${index+1}`}
                                                value = {`response_4`}
                                                style = {{marginRight:"10px", zIndex: "1"}}
                                                labelInfo = {question.response_4}
                                                checked={previousAnswers[`question_${index+1}`] ? previousAnswers[`question_${index+1}`]['answer'] === `response_4` : false}
                                                onChange = {e => {checkRadio("4", index+1)}}
                                                // defaultChecked={hasPreviousAnswers ? question.answer === "response_4" : false}
                                                formikHandleChange={handleChange}
                                        /> : <></>}
                                        {question.response_5 ?
                                            <FormikRadioButton
                                                name = {`question_${index+1}`}
                                                value = {`response_5`}
                                                style = {{marginRight:"10px", zIndex: "1"}}
                                                labelInfo = {question.response_5}
                                                checked={previousAnswers[`question_${index+1}`] ? previousAnswers[`question_${index+1}`]['answer'] === `response_5` : false}
                                                onChange = {e => {checkRadio("5", index+1)}}
                                                // defaultChecked={hasPreviousAnswers ? question.answer === "response_5" : false}
                                                formikHandleChange={handleChange}
                                        /> : <></>}
                                        {question.response_6 ?
                                            <FormikRadioButton
                                                name = {`question_${index+1}`}
                                                value = {`response_6`}
                                                style = {{marginRight:"10px", zIndex: "1"}}
                                                labelInfo = {question.response_6}
                                                checked={previousAnswers[`question_${index+1}`] ? previousAnswers[`question_${index+1}`]['answer'] === `response_6` : false}
                                                onChange = {e => {checkRadio("6", index+1)}}
                                                // defaultChecked={hasPreviousAnswers ? question.answer === "response_6" : false}
                                                formikHandleChange={handleChange}
                                        /> : <></>}
                                        {question.response_7 ?
                                            <FormikRadioButton
                                                name = {`question_${index+1}`}
                                                value = {`response_7`}
                                                style = {{marginRight:"10px", zIndex: "1"}}
                                                labelInfo = {question.response_7}
                                                checked={previousAnswers[`question_${index+1}`] ? previousAnswers[`question_${index+1}`]['answer'] === `response_7` : false}
                                                onChange = {e => {checkRadio("7", index+1)}}
                                                // defaultChecked={hasPreviousAnswers ? question.answer === "response_7" : false}
                                                formikHandleChange={handleChange}
                                        /> : <></>}
                                        {question.response_8 ?
                                            <FormikRadioButton
                                                name = {`question_${index+1}`}
                                                value = {`response_8`}
                                                style = {{marginRight:"10px", zIndex: "1"}}
                                                labelInfo = {question.response_8}
                                                checked={previousAnswers[`question_${index+1}`] ? previousAnswers[`question_${index+1}`]['answer'] === `response_8` : false}
                                                onChange = {e => {checkRadio("8", index+1)}}
                                                // defaultChecked={hasPreviousAnswers ? question.answer === "response_8" : false}
                                                formikHandleChange={handleChange}
                                        /> : <></>}
                                        {question.response_9 ?
                                            <FormikRadioButton
                                                name = {`question_${index+1}`}
                                                value = {`response_9`}
                                                style = {{marginRight:"10px", zIndex: "1"}}
                                                labelInfo = {question.response_9}
                                                checked={previousAnswers[`question_${index+1}`] ? previousAnswers[`question_${index+1}`]['answer'] === `response_9` : false}
                                                onChange = {e => {checkRadio("9", index+1)}}
                                                // defaultChecked={hasPreviousAnswers ? question.answer === "response_9" : false}
                                                formikHandleChange={handleChange}
                                        /> : <></>}
                                        {question.response_10 ?
                                            <FormikRadioButton
                                                name = {`question_${index+1}`}
                                                value = {`response_10`}
                                                style = {{marginRight:"10px", zIndex: "1"}}
                                                labelInfo = {question.response_10}
                                                checked={previousAnswers[`question_${index+1}`] ? previousAnswers[`question_${index+1}`]['answer'] === `response_10` : false}
                                                onChange = {e => {checkRadio("10", index+1)}}
                                                // defaultChecked={hasPreviousAnswers ? question.answer === "response_10" : false}
                                                formikHandleChange={handleChange}
                                        /> : <></>}
                                        {question.response_11 ?
                                            <FormikRadioButton
                                                name = {`question_${index+1}`}
                                                value = {`response_11`}
                                                style = {{marginRight:"10px", zIndex: "1"}}
                                                labelInfo = {question.response_11}
                                                checked={previousAnswers[`question_${index+1}`] ? previousAnswers[`question_${index+1}`]['answer'] === `response_11` : false}
                                                onChange = {e => {checkRadio("11", index+1)}}
                                                // defaultChecked={hasPreviousAnswers ? question.answer === "response_11" : false}
                                                formikHandleChange={handleChange}
                                        /> : <></>}
                                    </>
                                :
                                <>
                                    <FormikRadioButton
                                        name = {`question_${index+1}`}
                                        value = {`response_true`}
                                        style = {{marginRight:"10px", zIndex: "1"}}
                                        labelInfo = "Yes"
                                        checked={previousAnswers[`question_${index+1}`] ? previousAnswers[`question_${index+1}`]['answer'] === `true` : false}
                                        onChange = {e => {checkRadio("true", index+1)}}
                                        // defaultChecked={hasPreviousAnswers ? question.answer === "true" : false}
                                        formikHandleChange={handleChange}
                                    />
                                    <FormikRadioButton
                                        name = {`question_${index+1}`}
                                        value = {`response_false`}
                                        style = {{marginRight:"10px", zIndex: "1"}}
                                        labelInfo = "No"
                                        checked={previousAnswers[`question_${index+1}`] ? previousAnswers[`question_${index+1}`]['answer'] === `false` : false}
                                         onChange = {e => {checkRadio("false", index+1)}}
                                        // defaultChecked={hasPreviousAnswers ? question.answer === "false" : true}
                                        formikHandleChange={handleChange}
                                    />
                                </>
                            }
                        </div>
                      </div>
                  )) : <></>
                }
                
                <div className = "continue">
                  {
                    (firstSubmit && !isValid) ?
                    <input type="button" className = "disabledButtonStyle" value="Continue"></input> :
                    <input type="submit"
                            className = "buttonStyle"
                            onClick = {(e) => {
                              setFirstSubmit(true);
                            }}
                            value="Continue"></input>
                  }
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default HRAQuestions;
