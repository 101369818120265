export default class Broker {
    constructor(data) {
        this.update(data);
    }

    update(data) {
        this.id = data.id || this.id;
        this.first_name =  data.first_name || this.first_name; 
        this.last_name =  data.last_name || this.last_name; 
        this.address =  data.address || this.address; 
        this.city =  data.city || this.city; 
        this.state =  data.state || this.state; 
        this.zip =  data.zip || this.zip; 
        this.broker_npn =  data.broker_npn || this.broker_npn; 
        this.email =  data.email || this.email; 
        this.phone =  data.phone || this.phone; 
        this.signature =  data.signature || this.signature; 
        this.consent_form_envelope_id =  data.consent_form_envelope_id || this.consent_form_envelope_id; 
        this.status =  data.status || this.status; 
        this.signing_url =  data.signing_url || this.signing_url; 
        this.ds_access_code =  data.ds_access_code || this.ds_access_code; 
        this.app_edit_allowed =  data.app_edit_allowed || this.app_edit_allowed; 
        this.role =  data.role || this.role; 
        this.live_session_room_id =  data.live_session_room_id || this.live_session_room_id; 
        this.live_session_create_date =  data.live_session_create_date || this.live_session_create_date;
        this.live_session_client_name =  data.live_session_client_name || this.live_session_client_name;

        this.db_clients =  data.db_clients || this.db_clients; 
        this.db_agency =  data.db_agency || this.db_agency; 
        this.db_agency_id =  data.db_agency_id || this.db_agency_id; 

        this.auto_create_date =  data.auto_create_date || this.auto_create_date; 
        this.auto_last_updated =  data.auto_last_updated || this.auto_last_updated;    
    }
}