import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory, Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import { BrokerContext } from '../components/BrokerContext';
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';

import './pages.css';
import './Profile.css';

import Banner from '../components/Banner';
import FormikInputField from '../components/FormikInputField';
import FormikRadioButton from '../components/FormikRadioButton';

import { ENVContext } from '../components/ENVContext';

import * as AgencyServices from '../domain/agency';

function RegisterAgencyType(props) {
    let history = useHistory();

    const [broker, setBroker] = useContext(BrokerContext);

    const [loading, setLoading] = useState(false);
    const [showBanner, setShowBanner] = useState(false);

    const [firstSubmit, setFirstSubmit] = useState(false);

    const { data: agencyQuery, status: agencyQueryStatus } = useQuery(
      ['agency', props.agencyNPN],
      async (key, npn) => {
        return AgencyServices.get(null, npn, props.controller.controller.signal);
      }
    );

    useEffect(() => {
      if (agencyQuery && agencyQuery.name && props.agentType === "existing") {
        console.log("agencyQuery"+agencyQuery);
        props.setNewAgencyName(agencyQuery.name);
        props.setAgencyEmail(agencyQuery.email);
        props.setAgencyPhone(agencyQuery.phone);
      }
    }, [agencyQuery]);

    return(
      <>
        <div className = "mainTitle">
            <div className = "titleInfo">
                <h1 className = "titleName">Your Agency</h1>
                <p className = "titleDetails">Please let us know if you belong to an agency.</p>
            </div>
        </div>
        {showBanner ?
            <Banner message={"Profile created!"}></Banner>
            : <></>
        }
        <div style={{marginLeft: "5%"}}>
          <Formik
            initialValues={{
              new_agency_name: '',
              agency_email: '',
              agency_npn: props.agencyNPN ? props.agencyNPN : '',
              agency_phone: '',
            }}
            validationSchema={Yup.object({
              new_agency_name: (props.agentType === "new") ? Yup.string()
                .required('This field is required') : undefined,
              agency_email: (props.agentType === "new") ? Yup.string()
                .email('Invalid email address')
                .required('This field is required') : undefined,
              agency_npn: Yup.string()
                .matches(/^[a-zA-Z0-9_]{1,20}/, 'Invalid NPN.'),
              agency_phone: (props.agentType === "new") ? Yup.string()
                .matches(/\d{10}/, 'Invalid phone number')
                .required('This field is required') : undefined,
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              console.log("formik on submit");
              setBroker({...broker, agency_name: props.agentType === "existing" ? agencyQuery.name : props.newAgencyName})
              setSubmitting(false);
              resetForm();
              history.push('/register/add-writing-nums');
            }}
            >
            {({ isSubmitting, isValid, dirty, errors, handleSubmit, handleChange, handleBlur }) => (
            <form onSubmit = {handleSubmit}>
              <div className="vertCont">
                <div className="horCont" style={{"width": "280px", "marginTop": "-20px"}}>
                  <div className="horCont" style={{"width":"120px"}}>
                    <FormikRadioButton
                      name="agentType"
                      value="existing"
                      labelInfo="Existing Agency"
                      checked={props.agentType === "existing"}
                      style={{"width":"20px", "height":"20px", lineHeight: "20px"}}
                      onChange={e => {
                        props.setAgentType("existing");
                      }}
                      formikHandleChange={handleChange}>
                    </FormikRadioButton>
                  </div>
                  <div className="horCont" style={{"width":"138px"}}>
                    <FormikRadioButton
                      name="agentType"
                      value="new"
                      labelInfo="Add a new Agency"
                      checked={props.agentType === "new"}
                      style={{"width":"20px", "height":"20px", lineHeight: "20px"}}
                      onChange={e => {
                        props.setAgentType("new");
                      }}
                      formikHandleChange={handleChange}>
                    </FormikRadioButton>
                  </div>
                </div>
                <br/>
                {
                  props.agentType === "existing" ?
                  <div style={{ marginTop: "10px" }} >
                    <FormikInputField
                      type="text"
                      name="agency_npn"
                      labelInfo="Please enter your agency's NPN"
                      placeholder="Agency NPN"
                      value={props.agencyNPN}
                      maxLength="20"
                      onChange={e => {
                        console.log(e);
                        props.setAgencyNPN(e.currentTarget.value);
                        console.log("NPN"+props.agencyNPN);
                        handleChange(e);
                      }}
                      style={{width: "25%"}}></FormikInputField><br/>
                    {
                      agencyQueryStatus === "loading" ?
                        <div>
                          Searching...
                        </div>
                      : agencyQuery && !agencyQuery.name ?
                        <div>
                          An agency with that NPN could not be found.
                        </div>
                      : agencyQuery ?
                        <div>
                          Agency Name: {agencyQuery.name}<br/>
                          Email Address: {agencyQuery.email}<br/>
                          Phone Number: {agencyQuery.phone}<br/>
                        </div>

                      : <></>
                    }
                  </div>
                  : props.agentType === "new" ?
                  <div className = "vertCont half">
                    <div className="horCont">
                      <FormikInputField
                          type="text"
                          name="new_agency_name"
                          labelInfo="*Agency Name"
                          errorLabel="agency name"
                          placeholder="Agency Name"
                          maxLength="50"
                          value={props.newAgencyName}
                          onBlur={e => props.setNewAgencyName(e.currentTarget.value)}
                          parentStyle={{width: "100%", marginRight: "10px"}}></FormikInputField>
                      <FormikInputField
                        type="text"
                        name="agency_npn"
                        labelInfo="*Agency NPN"
                        errorLabel="agency NPN"
                        placeholder="Agency NPN"
                        maxLength="20"
                        value={props.agencyNPN}
                        onBlur={e => props.setAgencyNPN(e.currentTarget.value)}
                        parentStyle={{ width: "100%", marginRight: "10px" }}></FormikInputField>
                      {
                        props.agentType === "new" && (agencyQuery && agencyQuery.name) ?
                          <div style={{ color: "red", fontSize: "12px", marginTop: "-15px" }}>An agency with this NPN already exists</div>
                          : <></>
                      }
                    </div>
                    <div className="horCont">
                      <FormikInputField
                        type="text"
                        name="agency_email"
                        labelInfo="*Email Address"
                        errorLabel="email address"
                        placeholder="example@email.com"
                        value={props.agencyEmail}
                        onBlur={e => props.setAgencyEmail(e.currentTarget.value)}
                        parentStyle={{ width: "100%", marginRight: "10px" }}></FormikInputField>
                      
                      <FormikInputField
                          type="text"
                          name="agency_phone"
                          labelInfo="*Phone Number"
                          errorLabel="phone number"
                          placeholder="(555) 555-5555"
                          maxLength="11"
                          value={props.agencyPhone}
                          onBlur={e => props.setAgencyPhone(e.currentTarget.value)}
                          parentStyle={{ width: "100%", marginRight: "10px" }}></FormikInputField>
                    </div>
                  </div>
                  : <></>
                }
              </div>
              <br/>
              {
                (isValid || !firstSubmit) &&
                ((props.agentType === "existing" && agencyQuery && agencyQuery.name) ||
                (props.agentType === "new" && agencyQuery && !agencyQuery.name)) ?
                <button type="submit" className="buttonStyle" onClick = {() => {console.log("click sub button"); console.log(errors); setFirstSubmit(true); handleSubmit();}}>
                  Next
                </button>
                :
                <button className="disabledButtonStyle" disabled>
                  Next
                </button>
              }
              <Link onClick={() => {
                history.push('/register/add-writing-nums');
                props.setNewAgencyName("");
                props.setAgencyNPN("");
                props.setAgencyPhone("");
                props.setAgencyEmail("");
                props.setAgentType("new")
              }} className="linkStyle" style={{"marginLeft":"30px"}}>
                Skip to next step
              </Link>
            </form>
          )}
          </Formik>
        </div>
      </>
    );
}

export default withRouter(RegisterAgencyType);
