import React, { useEffect, useState, useContext, useRef } from 'react';
import { withRouter, useHistory, useParams, BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import { io } from "socket.io-client";
import $ from 'jquery';
import { Document, Page, pdfjs } from 'react-pdf'
import testPDF from '../Anthem HRA 2021.pdf'
import { Table } from 'react-bootstrap';


import { NavigationContext } from '../components/NavigationContext';
import { BrokerContext } from '../components/BrokerContext';
import { PlanContext, ClientContext, ApplicationContext } from '../components/PCAContext';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import './pages.css';
import './Applications.css';

import Sidebar           from '../components/Sidebar';
import Headbar           from '../components/Headbar';
import SetEnvironment    from '../components/SetEnvironment';
import LoadingOverlay    from '../components/LoadingOverlay';
import PlanBox from '../components/PlanBox';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function LiveSalesSessionClientView(props) {
    let history = useHistory();

    const [broker, setBroker] = useContext(BrokerContext);
    const [plan, setPlan] = useContext(PlanContext);
    const [client, setClient] = useContext(ClientContext);
    const [application, setApplication] = useContext(ApplicationContext);

    const [loading, setLoading] = useState(false);
    const [webSocketId, setWebsocketId] = useState(null);
    const [logMessageRoom, setLogMessageRoom] = useState('');
    const [messages, setMessages] = useState([]);
    const [receivedPlans, setReceivedPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState("");
    const [brokerIsInRoom, setBrokerIsInRoom] = useState(false);
    const [agencyName, setAgencyName] = useState("");
    const [pdfResource, setPdfResource] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumArray, setPageNumArray] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [roomState, setRoomState] = useState("client_info");
    const [showChat, setShowChat] = useState(false);
    const [unreadMessages, setUnreadMessages] = useState(false);
    const hiddenRef = useRef();
    const messagesEndRef = useRef();

    const inRoom = useRef(false);
    const [clientFirst, setClientFirst] = useState('');
    const [clientLast, setClientLast] = useState('');
    const clientNameRef = useRef("");
    const [clientZip, setClientZip] = useState('');

    const [socket, setSocket] = useState(null);
    const socketRef = useRef(null);
    const urlParamsRef = useRef(null);

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
      
      if (pdfResource !== null) {
        let page = 1;//prompt("show page: ");
        if (page) {
          setPageNumber(parseInt(page));
        }
      }
    }

    const urlParams = new URLSearchParams(window.location.search);
    urlParamsRef.current = urlParams;
    const room = urlParams.get('room');
    let client_name = urlParams.get('name');

    useEffect(() => {
      init();
      
      // Set the name of the hidden property and the change event for visibility
      let visibilityChange;
      let hidden;
      if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
      } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
      } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
      }

      // Warn if the browser doesn't support addEventListener or the Page Visibility API
      if (typeof document.addEventListener === "undefined" || hidden === undefined) {
        console.log("This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
      } else {
        window.addEventListener(visibilityChange, handleVisibilityChange, false);
      }
      hiddenRef.current = hidden;
    }, []);

    useEffect(() => {
      if (socket !== null) {
        if (client_name !== null && roomState === "client_info") {
          enterWebsocketRoom();
        }
      }
    }, [socket, client_name]);

    useEffect(() => {
      urlParamsRef.current = urlParams;
    }, [urlParams]);

    useEffect(() => {
      if (showChat) {
        setUnreadMessages(false);
      }
    }, [unreadMessages, showChat]);

    useEffect(() => {
      console.log(messages);
    }, [messages]);

    useEffect(() => {
      console.log(receivedPlans);
    }, [receivedPlans]);

    useEffect(() => {
      console.log(roomState);
    }, [roomState]);

    function init() {
      let sock = io();
      sock.connect();
      setSocket(sock);
      socketRef.current = sock;

      // Event Listeners
      sock.on('connect', function () {
        console.log("Connected!");
        fetch('/api/unique_websocket_id', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }).then(res => res.json()).then(data => {
          console.log("Unique Websocket ID: " + data);
          setWebsocketId(data);
        });
      });

      sock.on('current_room_state', function (data) {
        console.log("receive current_room_state: ", data);

        setBrokerIsInRoom(true);

        if (data["plans"] !== undefined && data["plans"] !== null && data["plans"].length > 0) {
          setReceivedPlans(prevPl => data["plans"]);
          setPdfResource(null);
          if (inRoom.current) {
            setRoomState("plans");
          }
        }
        else if (data["document"] !== '') {
          createNotification("info", "Document Received", data["document"]);
          console.log(data["document"]);
          var xhr = new XMLHttpRequest();
          xhr.open("GET", data["document"]);
          xhr.responseType = "arraybuffer";

          xhr.onload = function () {
            if (this.status === 200) {
              var blob = new Blob([xhr.response], { type: "application/pdf" });
              createNotification("info", "Blob Received", blob);
              var objectUrl = URL.createObjectURL(blob);
              setPdfResource(blob);
              setPageNumArray(data["pages"]);
              console.log(data["pages"]);
              setReceivedPlans([]);
            }
          };
          xhr.send();
          if (inRoom.current) {
            setRoomState("document");
          }
        }

        if (data["messages"] && data["messages"].length > messages.length) {
          setMessages(data["messages"]);
        }
      });

      sock.on('broker_join', function (data) {
        console.log("broker just joined: ", data);
        setBrokerIsInRoom(true);
        setAgencyName(data["agency_name"]);
        if (inRoom.current) {
          sock.emit('request_join', { username: clientNameRef.current, room: room });
          setRoomState("waiting_for_plans");
        }
      });

      sock.on('pdf_file', function (data) {
        console.log("pdf file received: ", data["file"]);
        if (data["file"] !== "") {
          console.log(data["file"]);
          var xhr = new XMLHttpRequest();
          xhr.open("GET", data["file"]);
          xhr.responseType = "arraybuffer";

          xhr.onload = function () {
            if (this.status === 200) {
              var blob = new Blob([xhr.response], { type: "application/pdf" });
              var objectUrl = URL.createObjectURL(blob);
              setPdfResource(blob);
              setPageNumArray(data["pages"]);
              console.log(data["pages"]);
              setReceivedPlans([]);
            }
          };
          xhr.send();
          if (inRoom.current) {
            setRoomState("document");
          }
        }
      });

      sock.on('print_to_log', function (msg) {
        console.log("print_to_log: ", msg);
        setMessages(prevMessages => [...prevMessages, msg]);
        setUnreadMessages(true);
        if (messagesEndRef.current !== null && messagesEndRef.current !== undefined) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      });

      sock.on('receive_plans_list', function (pl) {
        console.log("receive_plans_list: ", pl);
        setSelectedPlan(null);
        sock.emit('highlight_plan', { plan: null, room: room });
        setReceivedPlans(prevPl => pl);
        setPdfResource(null);
        if (inRoom.current) {
          setRoomState("plans");
        }
      });

      sock.on('end_meeting', function (data) {
        console.log("end_meeting");
        setBrokerIsInRoom(false);
        if (inRoom.current) {
          setRoomState("meeting_ended");
        }
      });

      console.log("load");
      console.log(roomState);
    }

    function enterWebsocketRoom() {
      localStorage.debug = '*';
      console.log("Starting socket.io client...");

      client_name = urlParamsRef.current.get('name');
      clientNameRef.current = client_name;
      socketRef.current.emit('log', {data: clientNameRef.current + ' connected to the room.', room: room, broadcast: true});

      socketRef.current.emit('request_join', {username: clientNameRef.current, room: room});
      inRoom.current = true;
      setRoomState("waiting_for_plans");
      createNotification("Connected!", "success");
    }

    const printToLogRoom = () => {
      console.log("printToLogRoom: ", logMessageRoom);
      socket.emit('log', {data: logMessageRoom, room: room, websocket_id: webSocketId, sender: clientNameRef.current, broadcast: true});
    }

    function handleSelectPlan(event) {
      let selPlan = JSON.parse(event.currentTarget.value);
      setSelectedPlan(selPlan.index);
      
      socket.emit('highlight_plan', {plan: selPlan, room: room});
    }

    function handleVisibilityChange() {
      if (document[hiddenRef.current]) {
        console.log("hidden");
        // createNotification("This window is hidden");
      } else {
        console.log("not hidden");
        // createNotification("Welcome back! Now checking if your connection is still active...", "info");
        // Check if the client's websocket connection is still active. If not, reconnect.
        if (socketRef.current !== null) {
          try {
            socket.emit('dummy', { data: '', room: room, broadcast: true });
          } catch (e) {
            console.log("Socket connection is not active. Reconnecting...");
            createNotification("Reconnecting...", "error");
            init();
            enterWebsocketRoom();
            // setTimeout(() => {
            //   enterWebsocketRoom();
            // }, /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? 5000 : 0);  // Safari needs a longer delay to reconnect
          }
        }
      }
    }

    function createNotification(message, type, title="") {
      console.log("createNotification: " + message + " " + type + " " + title);
      
      switch (type) {
        case 'info':
          console.log("MESSAGE!!!");
          NotificationManager.info(message, title, 5000);
          break;
        case 'success':
          NotificationManager.success(message, title, 5000);
          break;
        case 'warning':
          NotificationManager.warning(message, title, 5000);
          break;
        case 'error':
          NotificationManager.error(message, title, 5000);
          break;
      }
    }

    return(
      <>
        <div className="horCont">
          <div style={{height: "30px", textTransform: "uppercase", fontWeight: "900", fontSize: "14px", padding: "5px", paddingLeft: "30px"}}>
            {agencyName}
          </div>
          <img src={unreadMessages ? "https://planprovide-resources.s3.us-west-1.amazonaws.com/bell-notification-on.png" 
                                   : "https://planprovide-resources.s3.us-west-1.amazonaws.com/bell-notification-off.png"}
               width="30px" style={{paddingRight:"10px"}}
               onClick={() => {setShowChat(!showChat)}} />
        </div>
        <div style={{height: "50px", alignItems: "center", display: "flex", justifyContent: "center"}}>
          <img src="https://planprovide-resources.s3.us-west-1.amazonaws.com/Progress+bar.png"/>
        </div>
        <h2 style={{textTransform: "uppercase", fontWeight: "900", alignItems: "center", display: "flex", justifyContent: "center", marginTop: "30px"}}>
          {roomState === "client_info" || roomState === "waiting_for_plans" ?
            "Meeting"
          : roomState === "plans" ?
            "Plans"
          : roomState === "document" ?
            "Document"
          : roomState === "meeting_ended" ?
            "Meeting Ended"
          : <></>}
        </h2>
        <div style={{alignItems: "center", display: "flex", justifyContent: "center", color: "#575F6E", fontSize: "14px"}}>
          {roomState === "client_info" ?
            "Please provide your information to get started."
          : roomState === "waiting_for_plans" ?
            brokerIsInRoom ?
              "Waiting for the broker to send a plan..."
            : "Waiting for the broker to join the room..."
          : roomState === "plans" ?
            "Select a plan to view details."
          : roomState === "document" ?
            "Please review the document below."
          : ""
          }
        </div>
        <div>
          {
            roomState === "waiting_for_plans" ?
              <div style={{alignItems: "center", display: "flex", justifyContent: "center"}}>
                <LoadingOverlay style={{marginTop: "170px", width: "100px", height: "100px"}} />
              </div> :
            roomState === "client_info" ?
              <div style={{padding: "20px", paddingTop: "40px", marginTop: "40px", borderTop: "1px solid #BBBFC1"}}>
                <div style={{fontSize: "22px", fontWeight: "800"}}>Your Info</div>
                <div style={{fontSize: "14px", color: "#575F6E"}}>Your name and details</div>
                <div style={{marginTop: "20px"}}>First name</div>
                <input style={{width: "200px"}} type="text" value={clientFirst} onChange={(evt) => {setClientFirst(evt.target.value)}}></input>
                <div>Last name</div>
                <input style={{width: "200px"}} type="text" value={clientLast} onChange={(evt) => {setClientLast(evt.target.value)}}></input>
                {/* {<div>Zip code</div>
                <input style={{width: "200px"}} type="text" value={clientZip} onChange={(evt) => {setClientZip(evt.target.value)}}></input>} */}
                <div style={{alignItems: "center", display: "flex", justifyContent: "center", marginTop: "60px"}}>
                  <input className="buttonStyle" type="button" value="Start"
                        onClick={() => {
                          history.push(`/live-client-session?room=${room}&name=${encodeURIComponent(clientFirst + " " + clientLast)}`);
                        }}></input>
                </div>
              </div> :
            roomState === "plans" && receivedPlans !== undefined && receivedPlans.length !== 0 ? 
              <Table bordered hover={false} style={{width:"100%", marginBottom:"30px"}}>
                <thead>
                  <tr style={{border: "none"}}>
                    <th style={{border:"none", width:"30px", paddingTop:"14px", fontSize: "14px"}}></th>
                    <th style={{border:"none", width:"30px", paddingTop:"14px", fontSize: "14px"}}>Name</th>
                    <th style={{border:"none", width:"30px", paddingTop:"14px", fontSize: "14px", paddingLeft: "70px", paddingRight: "0px"}}>Premium</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {
                  [].concat(receivedPlans)
                  .sort((a, b) => a.premium > b.premium ? 1 : -1)
                  .map((p, index) => (
                    p === undefined || p.application_aws_key === null ? <></> :
                    <PlanBox
                      companyName = {p.carrier.name}
                      planName = {p.name}
                      value = {p.id}
                      premium = {p.premium ? p.premium : "Not available"}
                      planQuoteId = {p.plan_quote_id}
                      premiumMultiple = {p.premiumMultiple || ""}
                      onChange = {handleSelectPlan}
                      selectedPlan = {selectedPlan}
                      key = {index}
                      applicationAwsKey = {p.application_aws_key}
                      index={index}
                      notes={p.notes}
                      detailsDrawer
                      mobile></PlanBox>
                  ))
                }
                </tbody>
              </Table>
            :
            roomState === "document" && pdfResource !== null ?
              <div style={{marginTop: "40px"}}>
                <Document file={pdfResource}
                          onLoadSuccess={onDocumentLoadSuccess}
                          onLoadError={console.error}>
                    {pageNumArray.map((p) => (
                      <>
                        <Page pageNumber={p + 1}
                              width={window.innerWidth} />
                        <div>Page {p + 1}</div>
                      </>
                    ))}
                    {/* <Page pageNumber={pageNumber}
                          width={window.innerWidth} /> */}
                </Document>
              </div>
            :
            roomState === "meeting_ended" ?
            <div style={{padding: "20px", paddingTop: "40px", marginTop: "40px", borderTop: "1px solid #BBBFC1"}}>
              This meeting has been ended by the broker. Thank you for joining!
            </div>
            : <></>
          }
        </div>
        {
          showChat ?
            <div className="modal" style={{ marginTop: "45px", zIndex: "3", height: "100%", display: "flex", flexDirection: "column"}}>
              <div style={{ display: "flex", height: "65%" }}>
                <div style={{ width: "20%", height: "100%" }} onClick={() => { setShowChat(false) }}></div>
                <div style={{ top: "-10px", width: "80%", backgroundColor: "#faf9f8",
                    borderRadius: "10px", padding: "20px", fontSize: "12px"}}>
                  
                  <div style={{display: "flex", width: "100px"}}>
                    <h2>Chat</h2>
                    <a style={{marginTop: "9px", marginLeft: "6px"}} onClick={() => {setShowChat(false)}}><u>(close)</u></a>
                  </div>
                  <div id="log" style={{height: "75%", overflowY: "scroll", paddingRight: "15px"}}>
                    {messages.map((message, index) => {
                      return(<div style={{marginLeft: webSocketId === message.websocket_id ? "20%" : "0px",
                                          marginRight: webSocketId === message.websocket_id ? "0px" : "20%",}}>
                        <div className="horCont">
                          <div>{webSocketId === message.websocket_id ? "You" : message.sender}</div>
                          <div>{message.time}</div>
                        </div>
                        <div style={{border: message.websocket_id === "" ? undefined : "1px solid",
                                    borderRadius: "5px", borderColor: "#E4E9EE",
                                    padding: message.websocket_id === "" ? "0px" : "10px",
                                    backgroundColor: webSocketId === message.websocket_id ? "#109CF1" : undefined,
                                    color: webSocketId === message.websocket_id ? "white" : undefined,
                                    fontWeight: message.websocket_id === "" ? "bold" : undefined}}>
                          {message.data}
                        </div>
                      </div>);
                    })}
                    <div style={{ float:"left", clear: "both", height: "25%"}}
                        ref={messagesEndRef}>
                    </div>
                  </div>
                  <div className="horCont" style={{marginTop: "0px"}}>
                    <form style={{height:"100%"}} onSubmit={(e) => {
                      e.preventDefault();
                      if(logMessageRoom !== "") {
                        setLogMessageRoom("");
                        printToLogRoom();
                      }
                    }}>
                      <input style={{ height: "25%" }} type="text" value={logMessageRoom} onChange={(evt) => {setLogMessageRoom(evt.target.value)}} placeholder="Message"/>
                      <input style={{ height: "25%" }} type="submit" className="buttonStyle" value="Send"/>
                    </form>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", height: "35%" }} onClick={() => { setShowChat(false) }}></div>
            </div>
              
              : <></>
        }
        <NotificationContainer />
      </>
    );
}

export default withRouter(LiveSalesSessionClientView);
