import React, { useState, createContext } from 'react';

export const BrokerContext = createContext();

export const BrokerProvider = props => {
    
    const [broker, setBroker] = useState({
        id: -1,
        first_name: '',
        last_name: '',
        email: '',
        agency_name: '',
        agency_npn: '',
        broker_npn: '',
        phone: '',
        status: 0,
        signing_url: '',
        ds_access_code: '',
    });

    return(
        <BrokerContext.Provider value = {[broker, setBroker]}>
            {props.children}
        </BrokerContext.Provider>
    );
}