import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory, useParams, BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';

import { NavigationContext } from '../components/NavigationContext';
import { BrokerContext } from '../components/BrokerContext';
import { PlanContext, ClientContext, ApplicationContext } from '../components/PCAContext';

import './pages.css';
import './Applications.css';

import Sidebar           from '../components/Sidebar';
import Headbar           from '../components/Headbar';
import SetEnvironment    from '../components/SetEnvironment';

import AgencyApplicationsListPage   from '../pages/subpages/Brokers/AgencyApplicationsListPage';
import AgencyClientDetails          from '../pages/subpages/Brokers/AgencyClientDetails';
import AgencyClientsPage            from '../pages/subpages/Brokers/AgencyClientsPage';
import AgencyDashboard              from '../pages/subpages/Brokers/AgencyDashboard';
import AgencyProfilePage            from '../pages/subpages/Brokers/AgencyProfilePage';
import AgencySettings               from '../pages/subpages/Brokers/AgencySettings';
import BrokerDetails                from '../pages/subpages/Brokers/BrokerDetails';
import BrokersListPage              from '../pages/subpages/Brokers/BrokersListPage';
import InviteBroker                 from '../pages/subpages/Brokers/InviteBroker';

function Agency(props) {
    let history = useHistory();

    let { subpage, application_id } = useParams();

    const [broker, setBroker] = useContext(BrokerContext);
    const [plan, setPlan] = useContext(PlanContext);
    const [client, setClient] = useContext(ClientContext);
    const [application, setApplication] = useContext(ApplicationContext);

    // const [subpage, setSubpage] = useContext(NavigationContext);

    useEffect(() => {
        // Set PCA context to default
        if (!plan.id) {
          setPlan({
              id: -1,
              name: '',
              type: '',
              number: '',
              carrier_name: '',
          });
        }
        if (!client.id) {
          setClient({
              id: -1,
              first_name: '',
              middle_initial: '',
              last_name: '',
              gender: '',
              title: '',
              email: '',
              phone: '',
              address: '',
              city: '',
              state: '',
              zip: '',
              mailing_address: '',
              mailing_city: '',
              mailing_state: '',
              mailing_zip: '',
              dob: '',
              medicare_claim_number: '',
              part_a_start_date: '',
              part_b_start_date: '',
              billing_preference: '',
              bank_name: '',
              bank_routing: '',
              bank_account: '',
          });
        }
        if (!application.id) {
          setApplication({
            id: application_id || -1,
            status: -1,
            url: '',
            download: '',
            csv: '',
            envelope_id: '',
            access_code: '',
            effective_date: '',
        });
        }
        history.push(`/agency/dashboard`)
    }, []);

    useEffect(() => {
       if (history) {
         return history.listen((location) => {
            props.controller.abort();
            console.log(`You changed the page to: ${location.pathname}`);
         });
       }
    },[history]);

    return(
        <>
          <div className = "background">
              <SetEnvironment></SetEnvironment>
              <Sidebar current="agency" controller={props.controller}></Sidebar>
              <div className = "middle container">
                  <Headbar title = "Agency"></Headbar>
                  <Route path={`${props.match.path}/dashboard`}                                 render={() => (<AgencyDashboard controller={props.controller}/>)} />
                  <Route path={`${props.match.path}/brokers-list/`}                             render={() => (<BrokersListPage controller={props.controller}/>)} />
                  <Route path={`${props.match.path}/applications-list/:brokerId?/:brokerName?`} render={() => (<AgencyApplicationsListPage controller={props.controller}/>)} />
                  <Route path={`${props.match.path}/clients/:brokerId?/:brokerName?`}           render={() => (<AgencyClientsPage controller={props.controller}/>)} />
                  <Route path={`${props.match.path}/profile`}                                   render={() => (<AgencyProfilePage controller={props.controller}/>)} />
                  <Route path={`${props.match.path}/settings`}                                  render={() => (<AgencySettings controller={props.controller}/>)} />

                  <Route path={`${props.match.path}/brokerdetails/:id`}                         render={() => (<BrokerDetails controller={props.controller}/>)} />
                  <Route path={`${props.match.path}/clientdetails/:id/:brokerId?/:brokerName?`} render={() => (<AgencyClientDetails controller={props.controller}/>)} />

                  <Route path={`${props.match.path}/invite-broker/`}                            render={() => (<InviteBroker controller={props.controller}/>)} />
                  <Route path={`${props.match.path}/applications/:subpage?/:application_id?`}   render={({ match: { url } }) => (<AgencyApplicationsListPage controller={props.controller}/>)} />
              </div>
          </div>
        </>
    );
}

export default withRouter(Agency);
