import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';
import { ENVContext } from './ENVContext';

import Button from 'react-bootstrap/Button';

function ProfileNavigation(props) {
    let history = useHistory();
    let current = props.current;

    const [ENV, setENV] = useContext(ENVContext);

    return (
      <>
        <div className="horCont" style={{height: "50px", width: "96%", marginLeft: "2%", marginBottom: "7px"}}>
            <div className="horCont" style={{width: "290px", marginTop: "25px"}}>
              <Button style={{backgroundColor: current === "profile" ? "white" :"#8c8c8c",
                              color: current === "profile" ? "black" : "white", 
                              width: "130px", height: "60px", border: "none", textAlign: "center", fontSize: "16px",
                              fontWeight: "bold"}}
                              onClick={() => {history.push('/profile')}}>
                Profile
              </Button>
              <Button style={{backgroundColor: current === "writing-numbers" ? "white" :"#8c8c8c",
                              color: current === "writing-numbers" ? "black" : "white", 
                              width: "130px", height: "60px", border: "none", textAlign: "center", fontSize: "16px",
                              fontWeight: "bold"}}
                              onClick={() => {history.push('/writing-numbers')}}>
                Contracting
              </Button>
              <div></div>
            </div>
        </div>
      </>
    );
}

export default ProfileNavigation;
