import React, { useState, useEffect } from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';

import './InputField.css'

function FormikInputField(props) {
    let handleDateInput = evt => {
        let lst = evt.target.value.split('-');
        if (lst[0].length > 4) {
          evt.target.value = lst[0].slice(0, 4) + '-' + lst[1] + '-' + lst[2];
          evt.target.blur();
        }
        else if (lst[0].length < 4) {
          if (lst[0].length === 3) {
            evt.target.value = lst[0] + '0' + '-' + lst[1] + lst[2]
          }
          else if (lst[0].length === 2) {
            evt.target.value = lst[0] + '00' + '-' + lst[1] + lst[2]
          }
          else if (lst[0].length === 1) {
            evt.target.value = lst[0] + '000' + '-' + lst[1] + lst[2]
          }
          evt.target.blur();
        }
    }

    return(
        <div style={props.parentStyle}>
            {
                props.no_label || !props.labelInfo ? <></> :
                <div>
                    <label htmlFor = {props.name}>{props.labelInfo}</label>
                    <br></br>
                </div>
            }
            {
                props.onChange ?
                <Field name={props.name}
                       type={props.type}
                       style={props.style ? props.style : props.type === 'date' ?  {width: '150px'} : null}
                       placeholder={props.placeholder || "Type Here"}
                       maxLength={props.maxLength}
                       pattern={props.pattern}
                       disabled={props.disabled}
                       readOnly={props.readOnly}
                       value={props.value ? props.value : null}
                       onInput={props.type === 'date' ? handleDateInput : props.onInput || null}
                       onChange={props.onChange ? props.onChange : null}
                       onBlur={props.onBlur ? props.onBlur : null}
                       required={props.required}>
                </Field>
                :
                <Field name={props.name}
                       type={props.type}
                       style={props.style ? props.style : props.type === 'date' ?  {width: '150px'} : null}
                       placeholder={props.placeholder || "Type Here"}
                       maxLength={props.maxLength}
                       pattern={props.pattern}
                       disabled={props.disabled}
                       readOnly={props.readOnly}
                       onInput={props.type === 'date' ? handleDateInput : null}
                       onBlur={props.onBlur || null}
                       required={props.required}>
                </Field>
            }
            <div className="errorLabel">
              <ErrorMessage name={props.name} component="div" />
            </div>
        </div>
    );
}

export default FormikInputField;
