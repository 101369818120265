import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import Select from 'react-select';

import { BrokerContext } from '../../../components/BrokerContext';
import { ClientContext, ApplicationContext } from '../../../components/PCAContext';
import { ENVContext } from '../../../components/ENVContext';

import './PickClient.css';

import mixpanel from 'mixpanel-browser';

import { compareNames } from '../../../utils.js';


// API call to fetch clients by broker id
const fetchClients = async (key, broker_id) => {
    let json = JSON.stringify({"broker_id": broker_id});
    const res = await fetch('/api/fetch_clients', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: json
    });
    return res.json();
}

function PickClient(props) {
    let history = useHistory();

    let [oldClient, setOldClient] = useState(false);
    let [selectedClient, setSelectedClient] = useState(false);

    const [broker, setBroker] = useContext(BrokerContext);
    const [client, setClient] = useContext(ClientContext);
    const [application, setApplication] = useContext(ApplicationContext)
    const [ENV, setENV] = useContext(ENVContext);

    // Fetch clients by broker id, store in data
    const { data, status } = useQuery(['clients', broker.id], fetchClients);

    const options = [];

    // Theme for select search box

    useEffect(() => {
      console.log(selectedClient)
    }, [selectedClient])


    function handleSubmit(event) {
        // Prevent page from reloading
        event.preventDefault();

        // Read in information from the form
        const formData = new FormData(event.target);

        var object = {};
        formData.forEach((value, key) => {object[key] = value});

        if (object.client_type === "old") {
            data.map(v => {
                if (v.id == object.client) {
                    mixpanel.init(ENV.MIXPANEL_TOKEN);
                    mixpanel.track("Existing client application", {client_id: v.id})

                    setClient({...v, new_client: false});
                    history.push(`/applications/pick-plan`);
                }

            });
        } else {
            mixpanel.init(ENV.MIXPANEL_TOKEN);
            mixpanel.track("New client application");
            setClient({id: -1, new_client: true});
            history.push(`/applications/pick-plan`);
        }
    }

    return(
      <>    
        <div className = "mainArea">
            <div className = "mainTitle">
                <div style = {{left: "5%", width: "95%"}} className = "titleInfo">
                    <h1 className = "titleName">Who is this application for?</h1>
                </div>
            </div>
            <form className = "clientSelectForm" style={{marginTop: "50px"}} onSubmit = {handleSubmit}>
                <div style = {{justifyContent: "start"}} className = "horCont">
                    <input style = {{rightMargin: "20px"}} type="radio" id = "radioPlan" name="client_type" value= "new" onChange = {() => setOldClient(!oldClient)} defaultChecked></input>
                    <label>New Client</label>
                </div>
                <div style = {{justifyContent: "start"}} className = "horCont">
                    <input style = {{rightMargin: "20px"}} type="radio" id = "radioPlan" name="client_type" value= "old" onChange = {() => setOldClient(!oldClient)} ></input>
                    <label>Existing Client</label>
                </div>
                {
                    oldClient && status === 'error' && (<div style = {{marginTop: "10px"}} className = "vertCont">Error fetching clients</div>)
                }
                {
                    oldClient && status === 'loading' && (<div style = {{marginTop: "10px"}} className = "vertCont">Clients are loading...</div>)
                }
                {
                    oldClient && status === 'success' && (
                        data !== undefined && data.length > 0 &&
                        data.sort(compareNames) &&
                        data.map(client => {
                            options.push({
                              label: `${client.first_name} ${client.last_name}`,
                              value: client.id,
                            })
                        }) &&
                        <div style = {{marginTop: "10px", marginLeft: "70px"}} className = "vertCont">
                            <Select
                                options = {options}
                                theme = {
                                  (theme) => {
                                    return {
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#f0f0f0',
                                            primary: '#199830',
                                        }
                                    }
                                  }
                                }
                                name = "client"
                                className = "searchSelect"
                                placeholder = "Search for a client..."
                                noOptionsMessage = {() => 'No clients match'}
                                onChange = {() => { setSelectedClient(true); }}
                                isSearchable>
                            </Select>
                        </div>
                    )
                }
                {oldClient && !selectedClient ?
                  <div className = "horCont continue">
                      <button className = "disabledButtonStyle" disabled>Continue</button>
                  </div>
                  :
                  <div className = "horCont continue">
                      <button className = "buttonStyle">Continue</button>
                  </div>
                }
            </form>
        </div>
      </>
    );
}

export default PickClient;
