import Agency from '../data/agency';
import ApplicationEnvelope from '../data/applicationEnvelope';
import Broker from '../data/broker';
import Client from '../data/client';

export const getConsent = async (id, signal) => {
    const res = await fetch(`/register/obtain_broker_consent/${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        referrer: '',
        signal: signal,
    })

    return res.json();
}

export const create = async (data, signal) => {
    const res = await fetch('/api/broker', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(data),
        signal: signal,
    })

    return res.json();
}

export const get = async (id, npn, signal) => {
    if (id == null) {
        id = -1;
    }

    const res = await fetch(`/api/broker?id=${id}&npn=${npn}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        signal: signal,
    })
    
    return res.json();
}

export const getWritingNumbers = async (id, signal) => {
    if (id == null) {
        return null;
    }

    const res = await fetch(`/api/broker/writing_numbers?id=${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        signal: signal,
    })

    return res.json();
}

export const update = async (data) => {
    const res = await fetch('/api/broker/update', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(data),
    })
    
    return res.json();
}

export const updateWritingNumbers = async (id, writingNums, updateAll, signal) => {
    const res = await fetch('/api/update_broker_writing_numbers', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            broker_id: id,
            writing_nums: writingNums,
            update_all: updateAll,
        }),
        signal: signal,
    });

    return res.json();
}