import React, { useContext, useState, useEffect } from 'react';
import { withRouter, useHistory, useParams, Link } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import { BrokerContext } from './BrokerContext';

import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';

import mixpanel from 'mixpanel-browser';

import './BrokerProfileForm.css';

import InputField from '../components/InputField';
import StateForm from '../components/StateForm';
import Banner from '../components/Banner';
import SetEnvironment from '../components/SetEnvironment';
import LoadingOverlay from '../components/LoadingOverlay';
import FormikInputField from '../components/FormikInputField';
import WritingNumbers from '../components/WritingNumbers';

import { printRFC3339Date } from '../utils.js';

const BrokerProfileForm = (props) => {
    let history = useHistory();

    let broker = props.broker; //const [broker, setBroker] = useContext(BrokerContext);

    return(
      <>
        <div className = "mainArea">
          <Formik
            initialValues={{
              first_name: broker.first_name || '',
              last_name: broker.last_name || '',
              address: broker.address || '',
              email: broker.email || '',
              city: broker.city || '',
              state: broker.state || '',
              zip: broker.zip || '',
              phone: broker.phone || '',

              broker_npn: broker.broker_npn || '',
              agency_name: broker.agency_name || '',
              // agency_npn: broker.agency_npn || '',
            }}
            validationSchema={Yup.object({
              first_name: Yup.string()
                .required('This field is required'),
              last_name: Yup.string()
                .required('This field is required'),
              address: Yup.string()
                .required('This field is required'),
              city: Yup.string()
                .required('This field is required'),
              zip: Yup.string()
                .matches(/\d{5}/, 'Invalid zip code.')
                .required('This field is required'),
              phone: Yup.string()
                .matches(/\d{10}/, 'Invalid phone number')
                .required('This field is required'),
              broker_npn: Yup.string()
                .matches(/\d{8,9}/, 'Invalid NPN. Please enter an 8-9 digit number.')
                .required('This field is required')
            })}
            onSubmit={(values, actions) => {
              props.handleSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({ isSubmitting, isValid, dirty, errors, handleSubmit, handleChange, handleBlur }) => (
              <form className = "profileForm" onSubmit = {handleSubmit}>
                <div style = {{display: "flex"}} className = {props.className ? props.className : "formArea"}>
                  <div className = "fieldArea">
                    <div style={{fontFamily: "Raleway"}}>{props.agencyName ? "Agency: "+props.agencyName : ""}</div>
                    <div className = "horCont">
                      <div className = "half">
                        <FormikInputField
                            name = "first_name"
                            labelInfo = "*First Name"
                            errorLabel="name"
                            type="text"
                            maxLength = "50"
                            required></FormikInputField>
                      </div>
                      <div className = "half">
                        <FormikInputField
                            name = "last_name"
                            labelInfo = "*Last Name"
                            errorLabel="last name"
                            type="text"
                            maxLength = "50"
                            required></FormikInputField>
                      </div>
                    </div>
                    <div className = "horCont">
                      <div className = "half">
                        <FormikInputField
                            name = "address"
                            labelInfo = "*Address"
                            errorLabel="last name"
                            type="text"
                            maxLength = "50"
                            required></FormikInputField>
                      </div>
                      <div className = "half">
                        <FormikInputField
                            name = "city"
                            labelInfo = "*City"
                            errorLabel="last name"
                            type="text"
                            maxLength = "50"
                            required></FormikInputField>
                      </div>
                    </div>
                    <div className = "horCont">
                      <div className = "half">
                        <StateForm
                            name = "state"
                            labelInfo = "*State"
                            defaultValue = {broker.state}
                            required></StateForm>
                      </div>
                      <div className = "half">
                        <FormikInputField
                            name = "zip"
                            labelInfo = "*Zip"
                            errorLabel="last name"
                            type="text"
                            pattern = "\d{5}"
                            maxLength = "5"
                            required></FormikInputField>
                      </div>
                    </div>
                    <div className = "horCont">
                      <div className = "half">
                        <FormikInputField
                            type = "text"
                            name = "email"
                            labelInfo = "*Email address"
                            value = {broker.email}
                            readOnly={true}>
                        </FormikInputField>
                      </div>
                      <div className = "half">
                        <FormikInputField
                            type="text"
                            name = "phone"
                            labelInfo = "*Phone number"
                            errorLabel="last name"
                            placeholder = "1234567890"
                            maxLength = "10"
                            required></FormikInputField>
                      </div>
                    </div>
                    <div className = "horCont">
                      <div className = "half">
                        <FormikInputField
                            name = "broker_npn"
                            labelInfo = "*Broker NPN"
                            errorLabel="last name"
                            type="text"
                            maxLength = "9"
                            required></FormikInputField>
                      </div>
                    </div>
                    <br></br>
                    {props.hideWritingNums ? <></> :
                      <div style={{width:"100%", marginLeft: "-5%"}}>
                        <WritingNumbers
                          broker={broker}
                          buttonLabel="Next"
                          isBrokerProfile={props.isBrokerProfile}
                          setWritingNumbers={props.setWritingNumbers}
                          carrierLabelWidth="34%"
                          controller={props.controller}/>
                      </div>
                    }
                  </div>
                </div>
                {props.hideButtons ? <></> :
                  <div style = {{position: "relative", left: "5%", justifyContent: "start", marginTop: "40px", marginBottom: "40px"}} className = "horCont">
                    <button type="submit"
                            style = {{width: "160px", marginRight: "40px"}}
                            className = "buttonStyle">{props.buttonText}</button>
                    {props.logoutFunc ?
                      <Link onClick = {props.logoutFunc} to = "" className = "linkStyle">Logout</Link>
                      : <></>
                    }
                  </div>
                }
              </form>
            )}
          </Formik>
        </div>
      </>
    );
}

export default BrokerProfileForm;
