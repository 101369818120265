import React, { useContext } from 'react';

import { useHistory, Link } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

import { ENVContext } from './ENVContext';

import { printDate, printRFC3339Date } from '../utils';

const DashBox = ({broker, size}) => {
    let history = useHistory();

    const [ENV, setENV] = useContext(ENVContext);

    let create_date = printDate(new Date(broker.auto_create_date.replace(" GMT", "")));

    // Set PCA context when clicked
    function setInfo(info) {
        mixpanel.init(ENV.MIXPANEL_TOKEN);
        mixpanel.track("Access broker", {where_from: "agency dashboard page", broker_id: info.id});

        history.push(`/agency/brokerdetails/${info.id}`);
    }

    return(
        <div className = "horCont dashBox" style={{height:"60px"}} onClick = {() => setInfo(broker)}>
            <div style = {{margin: "10px"}}>
                <div className = "vertCont">
                    <div>
                        <p style = {{fontWeight: "600"}}>{broker.first_name + " " + broker.last_name} ({broker.applications.length})</p>
                    </div>
                    {
                        size === "big"
                        ?
                        <div style = {{display: "flex", marginTop: "5px"}}>
                            <p style = {{color: "#707683"}}>Date of last submitted application:&nbsp;</p>
                            <p>{broker.applications[0] ? printRFC3339Date(broker.applications[0].auto_create_date) : <></>}</p>
                        </div>
                        : <></>
                    }
                    <div>

                    </div>
                </div>
                <div></div>
            </div>
        </div>
    )
}

const DashBoxBrokerContainer = ( {brokers, status, size} ) => {
    function compareApplications(a, b) {
      if (a.applications.length < b.applications.length) {
          return 1;
      } else {
          return -1;
      }
    }
    return(
        <div>
        {
            status === 'success' && (
                <div className = "vertCont">
                    {
                        // Check if there are applications to display
                        brokers.length === 0
                        ? <div style = {{marginTop: "10px", marginLeft: "10px"}}><p>No brokers to display.</p></div>
                        : brokers.sort(compareApplications).slice(0,9).map((broker, index) => (
                            <DashBox broker = {broker} key = {index} size = {size}></DashBox>
                        ))
                    }
                </div>
            )
        }
        </div>
    )
}

export default DashBoxBrokerContainer;
