export default class Application {
    constructor(data) {
        this.update(data);
    }
    update(data) {
        this.auto_create_date = data.auto_create_date || this.auto_create_date;
        this.auto_last_updated = data.auto_last_updated || this.auto_last_updated;
        this.aws_pdf_key = data.aws_pdf_key || this.aws_pdf_key;
        this.id = data.id || this.id;
        this.is_archived = data.is_archived || this.is_archived;
        this.download_link = data.download_link || this.download_link;
        this.phone = data.phone  || this.phone;
        this.plan_id = data.plan_id || this.plan_id;
        this.status = data.status || this.status;
        this.submission_email = data.submission_email || this.submission_email;
        this.submission_email_other = data.submission_email_other || this.submission_email_other;
        this.submission_fax = data.submission_fax || this.submission_fax;
        this.type = data.type || this.type;
    }
}