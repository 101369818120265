import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';
import { ENVContext } from './ENVContext';

import Button from 'react-bootstrap/Button';

function AgencyNavigation(props) {
    let history = useHistory();
    let current = props.current;

    const [ENV, setENV] = useContext(ENVContext);

    return (
      <>
        <div className="horCont" style={{height: "50px", width: "96%", marginLeft: "2%", marginBottom: "7px"}}>
            <div className="horCont" style={{width: "810px", marginTop: "25px"}}>
              <Button style={{backgroundColor: current === "dashboard" ? "white" :"#8c8c8c",
                              color: current === "dashboard" ? "black" : "white", 
                              width: "130px", height: "60px", border: "none", textAlign: "center", fontSize: "16px",
                              fontWeight: "bold"}}
                              onClick={() => {history.push('/agency/dashboard')}}>
                Dashboard
              </Button>
              <Button style={{backgroundColor: current === "brokers" ? "white" :"#8c8c8c",
                              color: current === "brokers" ? "black" : "white", 
                              width: "130px", height: "60px", border: "none", textAlign: "center", fontSize: "16px",
                              fontWeight: "bold"}}
                              onClick={() => {history.push('/agency/brokers-list')}}>
                Brokers
              </Button>
              <Button style={{backgroundColor: current === "clients" ? "white" :"#8c8c8c",
                              color: current === "clients" ? "black" : "white", 
                              width: "130px", height: "60px", border: "none", textAlign: "center", fontSize: "16px",
                              fontWeight: "bold"}}
                              onClick={() => {history.push('/agency/clients')}}>
                Clients
              </Button>
              <Button style={{backgroundColor: current === "applications" ? "white" :"#8c8c8c",
                              color: current === "applications" ? "black" : "white", 
                              width: "130px", height: "60px", border: "none", textAlign: "center", fontSize: "16px",
                              fontWeight: "bold"}}
                              onClick={() => {history.push('/agency/applications-list')}}>
                Applications
              </Button>
              <Button style={{backgroundColor: current === "profile" ? "white" :"#8c8c8c",
                              color: current === "profile" ? "black" : "white", 
                              width: "130px", height: "60px", border: "none", textAlign: "center", fontSize: "16px",
                              fontWeight: "bold"}}
                              onClick={() => {history.push('/agency/profile')}}>
                Profile
              </Button>
              <Button style={{backgroundColor: current === "settings" ? "white" :"#8c8c8c",
                              color: current === "settings" ? "black" : "white", 
                              width: "130px", height: "60px", border: "none", textAlign: "center", fontSize: "16px",
                              fontWeight: "bold"}}
                              onClick={() => {history.push('/agency/settings')}}>
                Contracting
              </Button>
            </div>
            <div></div>
        </div>
      </>
    );
}

export default AgencyNavigation;
