import React, { useContext, useState, useEffect } from 'react';
import { withRouter, useHistory, Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import { ApplicationContext } from '../components/PCAContext';

import './pages.css';
import './ReadySubmit.css';

import Headbar from '../components/Headbar';
import Sidebar from '../components/Sidebar';
import SetEnvironment from '../components/SetEnvironment';

function Help(props) {
    const [application, setApplication] = useContext(ApplicationContext);

    useEffect(() => {
      setApplication({
          id: -1,
          status: -1,
          url: '',
          download: '',
          csv: '',
          envelope_id: '',
          access_code: '',
          effective_date: '',
      });
    }, [])

    return(
        <div className = "background">
            <SetEnvironment></SetEnvironment>
            <Sidebar current = "help" controller={props.controller}></Sidebar>
            <div className = "middle container">
                <Headbar title = "Support"></Headbar>
                <div className = "mainArea">
                    <div className = "titleInfo" style={{marginTop: "60px", marginBottom: "60px"}}>
                        <h1 className = "titleName">Customer Assistance</h1>
                        <p className = "titleDetails">For help, please email us at hello@planprovide.com with details of your problem.</p>
                    </div>
                </div>
            </div>
         </div>
    );
}

export default withRouter(Help);
