import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import { PlanContext, ClientContext, ApplicationContext } from '../../../components/PCAContext';
import { BrokerContext } from '../../../components/BrokerContext';

import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';

import './coverageQuestions.css';
import './AncillaryPlansList.css';

import CheckBox from '../../../components/CheckBox';
import InputField from '../../../components/InputField';
import DateForm from '../../../components/DateForm';
import FormikRadioButton from '../../../components/FormikRadioButton';
import StateForm from '../../../components/StateForm';
import Banner from '../../../components/Banner';
import LoadingOverlay from '../../../components/LoadingOverlay';
import FormikInputField from '../../../components/FormikInputField';
import FormikCheckBox from '../../../components/FormikCheckBox';

import mixpanel from 'mixpanel-browser';

import { handleDate, printRFC3339Date } from '../../../utils.js';

function AddHealthUnderwritingCondition(props) {
  let history = useHistory();

  const [application, setApplication] = useContext(ApplicationContext);
  const [plan, setPlan] = useContext(PlanContext);
  const [client, setClient] = useContext(ClientContext);
  const [broker, setBroker] = useContext(BrokerContext);

  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState(false);
  const [firstSubmit, setFirstSubmit] = useState(false);

  function doSubmit(object) {
      for (let key in object) {
        for (let m of props.medicalConditions) {
          if (parseInt(m.index) === props.conditionIndex) {
            m[key] = object[key].value;
          }
        }
      }
      props.toggle(false)
  }

  function setSelectedDrug(e, drugIndex, attribute) {
    for (let c of props.medicalConditions) {
      console.log(c)
      for (let d of c.drugs) {
        if (parseInt(d.index) === drugIndex) {
          d[attribute] = e.target.value;
        }
        break
      }
    }
  }

  function deleteMedicalCondition(index) {
    let ls = props.medicalConditions.map((x) => x);
    for (let i = 0; i < ls.length; i++) {
      if (ls[i].index === index) {
        ls.splice(i, 1);
        for (let j = i; j < ls.length; j++) {
          ls[j].index -= 1;
        }
        break;
      }
    }
    console.log(ls)
    props.setMedicalConditions(ls);
  }

  function addDrug(index) {
    let tmp = [...props.medicalConditions];
    tmp[index].drugs.push({
      index: tmp[index].drugs.length,
      name: "",
      dosage: "",
      start_date: "",
      end_date: "",
    });
    props.setMedicalConditions(tmp);
  }
  function removeDrug(index, drugIndex) {
    let tmp = [...props.medicalConditions];

    for (let i = 0; i < tmp[index].drugs.length; i++) {
      if (tmp[index].drugs[i].index === drugIndex) {
        tmp[index].drugs.splice(i, 1);
        for (let j = i; j < tmp[index].drugs.length; j++) {
          tmp[index].drugs[j].index -= 1;
        }
        break;
      }
    }
    props.setMedicalConditions(tmp);
  }

  return (
      <>
        <div className = "modal">
          {
            loading
            ? <LoadingOverlay></LoadingOverlay>
            : <div style = {{display: "none"}}></div>
          }

          <div style = {{left: "5%", top: "300px", width: "90%"}} className = "mainArea modalContent">
            <div className = "titleInfo" style={{marginTop:"50px"}}>
              <h1 className = "titleName">Add Condition</h1>
              <p className = "titleDetails">Please provide your condition and medications.</p>
              <div style={{float:"right", marginTop: "-110px"}}>
                <button style = {{width:"50px", height:"50px", fontSize: "1.5em", borderStyle: "none"}}
                        onClick = {() => {props.toggle(false)}}>X</button>
              </div>
            </div>

            <Formik
                initialValues={{
                  condition: "",
                  status: "",
                  condition_start_date: "",
                  condition_end_date: "",
                }}
                validationSchema={Yup.object({
                  condition: Yup.string()
                    .required('This field is required'),
                  status: Yup.string()
                    .required('This field is required'),
                  condition_start_date: Yup.string()
                    .required('This field is required'),
                })}
                onSubmit={(values, actions) => {
                  console.log("SUBMIT!");
                  doSubmit(values);
                  actions.setSubmitting(false);
                }}
            >
              {({ isSubmitting, isValid, dirty, handleSubmit, handleChange, handleBlur, errors }) => (
                <form className = "clientForm" onSubmit={handleSubmit} style={{width: "80%", maxWidth: "2000px"}}>
                  <div>
                    <div className="horCont" style={{width:"100%"}}>
                      <div className="half">
                        <FormikInputField
                          name = {`condition`}
                          labelInfo = "Condition"
                          errorLabel = "Condition"
                          type="text"
                          value={props.medicalConditions[props.index].name}
                          onChange={(e) => {
                            props.setSelectedCondition(e, props.index, "name");
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          maxLength = "100"
                        ></FormikInputField>
                      </div>
                    </div>
                    <div className="horCont" style={{width:"100%"}}>
                      <div style={{width:"50%", marginRight:"10px"}}>
                        <FormikInputField
                          name = {`status`}
                          labelInfo = "Status"
                          errorLabel = "Status"
                          type="text"
                          value={props.medicalConditions[props.index].status}
                          onChange={(e) => {
                            props.setSelectedCondition(e, props.index, "status");
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          maxLength = "100"
                        ></FormikInputField>
                      </div>
                      <div style={{width:"15%", minWidth:"150px", marginRight:"10px"}}>
                        <FormikInputField
                          name = {`condition_start_date`}
                          labelInfo = "Start Date"
                          errorLabel = "Start Date"
                          type="date"
                          style={{width:"100%"}}
                          value={props.medicalConditions[props.index].start_date}
                          onChange={(e) => {
                            props.setSelectedCondition(e, props.index, "start_date");
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        ></FormikInputField>
                      </div>
                      <div style={{width:"15%", minWidth:"150px", marginRight:"10px"}}>
                        <FormikInputField
                          name = {`condition_end_date`}
                          labelInfo = "End Date"
                          errorLabel = "End Date"
                          type="date"
                          style={{width:"100%"}}
                          value={props.medicalConditions[props.index].end_date}
                          onChange={(e) => {
                            props.setSelectedCondition(e, props.index, "end_date");
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        ></FormikInputField>
                      </div>
                      <div style={{width:"10%", marginRight:"10px"}}></div>
                      <div style={{width:"10%"}}></div>
                      <div style={{marginLeft: "10px", width:"70px"}}></div>
                    </div>
                    {
                      props.medicalConditions[props.index].drugs && props.medicalConditions[props.index].drugs.length > 0 ?
                      <div>
                        <div style = {{marginTop:"45px", marginBottom:"-10px", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                          <label style = {{width: "50%", marginRight:"10px"}}>Medications</label>
                          <label style = {{width: "15%", minWidth:"150px", marginRight:"10px"}}>Start Date</label>
                          <label style = {{width: "15%", minWidth:"150px", marginRight:"10px"}}>End Date</label>
                          <label style = {{width: "10%", marginRight:"10px"}}>Dosage</label>
                          <label style = {{width: "10%"}}>Frequency</label>
                          <label style = {{width: "80px"}}></label>
                        </div>
                        {
                          props.medicalConditions[props.index].drugs.map((drug, drugIndex) => (
                            <div className="horCont" style={{width:"100%", marginTop: "0px", height:"40px"}}>
                              <div style={{width:"50%", marginRight:"10px"}}>
                                <FormikInputField
                                  name = {`drug_${drugIndex}`}
                                  errorLabel = "Medication"
                                  type="text"
                                  maxLength = "100"
                                  value={drug.name}
                                  onChange={(e) => {
                                    setSelectedDrug(e, drug.index, "name");
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                ></FormikInputField>
                              </div>
                              <div style={{width:"15%", minWidth:"150px", marginRight:"10px"}}>
                                <FormikInputField
                                  name = {`drug_start_date_${drugIndex}`}
                                  errorLabel = "Start Date"
                                  type="date"
                                  style={{width:"100%"}}
                                  value={drug.start_date}
                                  onChange={(e) => {
                                    setSelectedDrug(e, drug.index, "start_date");
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                ></FormikInputField>
                              </div>
                              <div style={{width:"15%", minWidth:"150px", marginRight:"10px"}}>
                                <FormikInputField
                                  name = {`drug_end_date_${drugIndex}`}
                                  errorLabel = "End Date"
                                  type="date"
                                  style={{width:"100%"}}
                                  value={drug.end_date}
                                  onChange={(e) => {
                                    setSelectedDrug(e, drug.index, "end_date");
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                ></FormikInputField>
                              </div>
                              <div style={{width:"10%", marginRight:"10px"}}>
                                <FormikInputField
                                  name = {`dosage_${drugIndex}`}
                                  errorLabel = "Dosage"
                                  type="text"
                                  value={drug.dosage}
                                  onChange={(e) => {
                                    setSelectedDrug(e, drug.index, "dosage");
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                ></FormikInputField>
                              </div>
                              <div style={{width:"10%"}}>
                                <FormikInputField
                                  name = {`frequency_${drugIndex}`}
                                  errorLabel = "Frequency"
                                  type="text"
                                  value={drug.frequency}
                                  onChange={(e) => {
                                    setSelectedDrug(e, drug.index, "frequency");
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                ></FormikInputField>
                              </div>
                              <a style={{color: "#EC3796", fontWeight: "600", margin: "0px", marginLeft: "10px", marginTop: "20px"}}
                                 name={`remove_drug_${drugIndex}`}
                                 onClick={() => { removeDrug(props.index, drug.index) }}
                                 value="Remove">Remove</a>
                            </div>
                          ))
                        }
                      </div> :<></>
                    }
                    <div style={{marginTop: "20px"}}>
                      <a style={{color: "#EC3796", fontWeight: "600", margin: "0px"}}
                         onClick={() => { addDrug(props.index) }}
                         value="Add Medication">Add Medication</a>
                    </div>
                  </div>
                  <div className = "continue">
                    {
                      (firstSubmit && !isValid) ?
                      <input type="button" className = "disabledButtonStyle" value="Continue"></input> :
                      <input type="submit"
                             className = "buttonStyle"
                             onClick = {(e) => {
                                setFirstSubmit(true);
                                handleSubmit(e);
                             }}
                             value="Add Condition"></input>
                    }
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </>
  );
}

export default AddHealthUnderwritingCondition;
