import React, { useState, useEffect } from 'react';

import './AncillaryPlanBox.css';

import { formatCurrency } from '../utils.js';

function AncillaryPlanBox(props) {
    let [drawerOpen, setDrawerOpen] = useState(false);

    function selectPlan() {
      props.setPlan({
        "carrier_name": props.companyName,
        "carrier_id": props.carrierId,
        "name": props.planName,
        "id": props.value,
        "premium": props.premium,
        "notes": props.notes,
      })
      props.closePopup();
    }

    function createMarkup() {
      if (props.notes === null && props.discounts === null) {
        return {__html: "No details available for this plan."};
      }
      let output = (props.notes ? props.notes : "") + (props.discounts ? props.discounts : "")
      if (props.addon_plan) {
        output = "Add-on plan: " + props.addon_plan.name + " - " + formatCurrency(props.addon_plan.premium) + "<br/>" + output;
      }
      return {__html: output};
    }

    return(
      <>
        <div style = {props.style ? props.style : {marginLeft: "5%", width:"90%", flex: "1"}}
             className = "vertCont ancillaryPlanBox">
            <div style={{justifyContent: "initial", alignItems: "center", height: "80px", width: "100%", marginTop: "20px", marginBottom: "-12px"}}>
              <div className = "horCont planInfo" style={{marginRight: "0px", width: "100%", justifyContent: "space-between"}}>
                  {props.type === 'SOA' ?
                    <div style = {{"marginLeft": "60px", width: props.carrierWidth ? props.carrierWidth : "35%"}} className = "companyName">
                      <label htmlFor={props.value}>{props.planName}</label>
                    </div>
                    :
                    <>
                      <div style = {{"marginLeft": "60px", width: props.carrierWidth ? props.carrierWidth : "35%"}} className = "companyName">
                          <label htmlFor={props.value}>{props.companyName}</label>
                      </div>
                      <div style = {{whiteSpace: "normal", overflow: "hidden", textOverflow: "ellipsis",
                                     width: props.planWidth ? props.planWidth : "10%"}} className = "companyName">
                          <label htmlFor={props.value}>{props.planName}</label>
                      </div>
                      {
                        props.type ?
                        <div style = {{whiteSpace: "normal", overflow: "hidden", textOverflow: "ellipsis",
                                       width: props.typeWidth ? props.typeWidth : "15%"}} className = "companyName">
                            <label htmlFor={props.value}>{props.type}</label>
                        </div> : <></>
                      }
                      {
                        props.hidePremium ? <></> :
                        <div style = {{width: props.premiumWidth ? props.premiumWidth : "10%"}} className = "companyName">
                            <label htmlFor={props.value}>{formatCurrency(props.premium) === '$NaN' ? "--" : formatCurrency(props.premium)}</label>
                        </div>
                      }
                    </>
                  }
                  {props.hideDetailsButton ? <></> :
                    <div style = {{width: props.buttonWidth ? props.buttonWidth : "10%"}} className = "companyName">
                      {drawerOpen ?
                          <div className="horCont">
                            <button class="linkStyle"
                               style={{width: "85px", minWidth: "50px", color: "#EC3796", backgroundColor: "white", border: "none"}}
                               onClick = {() => {setDrawerOpen(!drawerOpen);}}>Hide Details
                            </button>
                            <img style={{position:"absolute", marginLeft:"88px"}} width="10px" src="https://planprovide-resources.s3.us-west-1.amazonaws.com/up_arrow.png"></img>
                          </div>
                          :
                          <div className="horCont">
                            <button class="linkStyle"
                               style={{width: "85px", minWidth: "50px", color: "#EC3796", backgroundColor: "white", border: "none"}}
                               onClick = {() => {setDrawerOpen(!drawerOpen);}}>View Details
                            </button>
                            <img className="arrowIcon" style={{position:"absolute", marginLeft:"88px"}} width="10px" src="https://planprovide-resources.s3.us-west-1.amazonaws.com/down_arrow.png"></img>
                          </div>
                      }
                    </div>
                  }
                  {
                    props.hideButton ? <></> :
                      props.removePlan ?
                        <div style={{width: props.buttonWidth ? props.buttonWidth : "10%"}} className = "companyName">
                            <button class="secondaryButtonStyle"
                                    onClick = {props.removePlan}
                                    style={{height: "35px", lineHeight: "35px", marginRight: "60px", width: "130px", minWidth: "100px"}}>Remove Plan</button>
                        </div>
                        :
                        <div style={{width: props.buttonWidth ? props.buttonWidth : "10%"}} className = "companyName">
                            <button class="buttonStyle"
                                    onClick = {selectPlan}
                                    style={{height: "35px", lineHeight: "35px", marginRight: "60px", width: "130px", minWidth: "80px"}}>Select Plan</button>
                        </div>
                  }
                  {
                    props.hideDetailsButton && props.hideButton ?
                      <div class="companyName" style={{width: props.buttonWidth ? props.buttonWidth : "10%"}}></div> : <></>
                  }
              </div>
            </div>
            {
              drawerOpen ?
              <div style={{marginLeft: props.discounts ? "150px" : "60px", width: props.discounts ? "100%" : undefined, marginRight: "30px", marginBottom: "20px"}}>
                <div
                     style={{marginLeft: "0px"}}
                     dangerouslySetInnerHTML={createMarkup()}
                ></div>
              </div>
              : <></>
            }
        </div>

      </>
    );
}

export default AncillaryPlanBox;
