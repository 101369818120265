import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { ClientContext } from './PCAContext';

import './ClientBox.css';

import mixpanel from 'mixpanel-browser';
import { ENVContext } from './ENVContext';

import { boldSubstring } from '../utils.js';

function ClientBox(props) {
    let history = useHistory();

    const [ENV, setENV] = useContext(ENVContext);
    const [client, setClient] = useContext(ClientContext);

    const info = props.info;

    function setClientInfo(info) {
        mixpanel.init(ENV.MIXPANEL_TOKEN);
        mixpanel.track("Access client", {client_id: info.id});
        setClient(info);
        if (props.destination === "agency")
        {
          history.push(`/agency/clientdetails/${info.id}/${info.broker.id}/${encodeURI(info.broker.first_name + " " + info.broker.last_name)}`)
        }
        else {
          history.push(`/clientdetails/${info.id}`);
        }
    }

    return(
        <tr className = {props.checked ? "clientBoxChecked" : "clientBox"}>
            {props.hideCheckbox ? <></> :
                <td onClick={props.handleChange}>
                    <input type="checkbox"
                        className="checkBox"
                        value={props.checked}
                        onChange={ null }
                        checked={props.checked}></input>
                </td>
            }
            <td onClick = {() => setClientInfo(info)}>
                {props.filterValue ?
                    boldSubstring(`${info.first_name} ${info.last_name}`, props.filterValue)
                    : `${info.first_name} ${info.last_name}`}
            </td>
            <td onClick = {() => setClientInfo(info)}>
                {info.email}
                <div style = {{marginTop: "5px"}}>{info.phone}</div>
            </td>
            <td onClick = {() => setClientInfo(info)}>
                {info.zip}
            </td>
            <td onClick = {() => setClientInfo(info)}>
                {props.dob}
            </td>
            <td onClick = {() => setClientInfo(info)}>
                {props.create_date}
            </td>
        </tr>
    );
}

export default ClientBox;
