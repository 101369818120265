import Application from '../data/application';
import ApplicationEnvelope from '../data/applicationEnvelope';
import Broker from '../data/broker';
import Client from '../data/client';

export const get = async (id, signal) => {
    if (id == -1) {
        return null;
    }

    const res = await fetch('/api/application/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        signal: signal,
    })
    
    return res.json();
}

export const update = async (data) => {
    fetch('/api/application/update', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(data),
    }).then(res => res.json());
}

export const submit = async (method, application_envelope, application, broker, client) => {
    let route, recipient;
    if (method === "fax") {
        route = '/api/application/submit/fax';
        recipient = application.submission_fax;
    }
    else {
        route = '/api/application/submit/email';
        recipient = application.submission_email;
    }

    fetch(route, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            recipient: recipient,
            subject: `Submission: Application for ${client.first_name + ' ' + client.last_name}`,
            message: `Hello, \r
                \r
                This is a test submission.\r
                \r
                Thanks, \r
                ${broker.first_name + ' ' + broker.last_name}`,
            aws_pdf_key: application.aws_pdf_key,
            broker_email: broker.email,
            client_email: client.email,
            application_id: application.id,
        }),
    }).then(res => {
        if (application.submission_email_other !== '' && method == "email") {
            fetch('/api/application/submit/email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    recipient: application.submission_email_other,
                    subject: `Submission: Application for ${client.first_name + ' ' + client.last_name}`,
                    message: `Hello, \r
                        \r
                        This is a test submission.\r
                        \r
                        Thanks, \r
                        ${broker.first_name + ' ' + broker.last_name}`,
                    aws_pdf_key: application.aws_pdf_key,
                    broker_email: broker.email,
                    client_email: client.email,
                    application_id: application.id,
                }),
            }).then(res => { return res.json() })
        }
        return res.json();
    });
}