import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import FindPlans from './FindPlans';

import './pages.css';


function CountyStatePDPPage( props ) {
    return (
        <>
            <div style={{backgroundColor: '#fff0', boxShadow: 'none', width: '40%', marginLeft:"5%", width: "60%", fontSize:"3.5em", lineHeight: "1.2em",
                         paddingBottom: '30px', fontFamily: 'DM Serif Display'}}>
                            Medicare Prescription Drug Plans in {props.countyName}, {props.stateName}
            </div>
            <div style={{marginLeft: '5%'}}>
                <div className="bodyParagraph">
                    Find the top Medicare Prescription Drug Plans available in {props.countyName}, {props.stateName} in {props.planYear}.
                </div>
                <div style={{fontSize:'0.75em', marginTop: '10px'}}>
                    Updated {props.dateUpdated} ago
                </div>
                <div style={{paddingTop: '30px', paddingBottom: '30px'}}>
                    <img src='https://planprovide-resources.s3.us-west-1.amazonaws.com/medicare-bits-img1.png' width='60%'></img>
                </div>
                <div>
                    <p className="bodyParagraph">
                        <h3>How to compare Medicare Part D plans</h3>
                    </p>
                    <p className="bodyParagraph">
                        The interactive tool on Medicare.gov can help find a Medicare Part D plan that covers your prescriptions and help you compare 
                        costs among Medicare Part D and Medicare Advantage plans available to you.
                    </p>
                    <p className="bodyParagraph">
                        Some things to keep in mind when comparing plans:

                        <ul style={{marginLeft: '20px'}}>
                            <li><b>Check the formulary:</b> You’ll want to make sure the medicines you currently take and, importantly, any you think 
                            you might need in the future, are covered under each of the plans you are considering. Talk to your health care providers 
                            before you sign up about what brand-name and generic medicines to look for and any alternatives that may also work in case 
                            you can’t find the medicines you currently take on the plans available in your area.</li>
                            <li><b>Look for plan changes:</b> Formularies change frequently. Your insurer should send you a Notice of Plan Change when 
                            the formulary changes. Read this document carefully.</li>
                            <li><b>Check the pharmacy network:</b> Most Medicare Part D plans negotiate with 
                            a network of pharmacies for the lowest cost. Check to see if your pharmacy or an equally convenient one is in the plan’s 
                            network. Also, compare prices for using mail order.</li>
                        </ul>
                    </p>
                    <p className="bodyParagraph">
                        If your plan does change, and the change affects the prescription drugs you need, you can switch plans during Medicare's open 
                        enrollment period, Oct. 15 to Dec. 7. Changes go into effect on the following Jan. 1.
                    </p>
                    <p className="bodyParagraph">
                        <h3 style={{marginBottom: '10px'}}>Prescription Drug Plans in {props.countyName}, {props.stateName}</h3>
                        {props.ratingTable}
                    </p>
                    
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        <h3 style={{marginBottom: '10px'}}>How do I enroll in a plan?</h3>
                        You can sign up for a Medicare Prescription Drug Plan when you first become eligible for Medicare (your initial enrollment 
                        period) or during designated annual Medicare enrollment periods. 
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        Once you’ve done your research and found a Prescription Drug Plan that fits your needs, there are various ways to enroll:
                    </p>
                    
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        <ul style={{marginLeft: '20px'}}>
                            <li>Go to the plan’s website to see if you can enroll online. Contact the plan to get a paper enrollment form. Fill it out and return it 
                                to the plan provider.</li>
                            <li>Call the provider for the plan you wish to join.</li>
                            <li>Speak to a licensed broker to review your options. Email us at hi@medicarebits.com to get connected to one.</li>
                        </ul>
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        You will need your Medicare number and the date your Medicare Part A and/or Part B coverage started. You must be enrolled in Medicare Parts A 
                        and B before you can buy a Medicare Prescription Drug Plan.
                    </p>
                    
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        <h3 style={{marginBottom: '10px'}}>Need Help Finding the right plan?</h3>
                        If you would like to speak to a licensed broker, email us at hi@medicarebits.com.
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        Alternatively, for questions about Medicare plans and other Medicare issues, contact Medicare at 800-MEDICARE (800-633-4227) or visit Medicare.gov. 
                    </p>
                </div>
            </div>
        </>
    );   
}

export default withRouter(CountyStatePDPPage);
