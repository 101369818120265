import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import FindPlans from './FindPlans';

import './pages.css';


function CountyStateMAPage( props ) {
    return (
        <>
            <div style={{backgroundColor: '#fff0', boxShadow: 'none', width: '40%', marginLeft:"5%", width: "60%", fontSize:"3.5em", lineHeight: "1.2em",
                         paddingBottom: '30px', fontFamily: 'DM Serif Display'}}>
                Top Medicare Advantage plans in {props.countyName}, {props.stateName}
            </div>
            <div style={{marginLeft: '5%'}}>
                <div className="bodyParagraph">
                    Find the top Medicare Advantage plans available in {props.countyName}, {props.stateName} in {props.planYear}.
                </div>
                <div style={{fontSize:'0.75em', marginTop: '10px'}}>
                    Updated {props.dateUpdated} ago
                </div>
                <div style={{paddingTop: '30px', paddingBottom: '30px'}}>
                    <img src='https://planprovide-resources.s3.us-west-1.amazonaws.com/medicare-bits-img4.png' width='60%'></img>
                </div>
                <div>
                    <p className="bodyParagraph">
                        Medicare Advantage is an all-in-one alternative to Original Medicare that includes all the benefits of Original Medicare 
                        and often a few extras, such as dental and vision coverage. Medicare Advantage plans are offered by private insurers, and 
                        plan availability depends on location.
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        Medicare Advantage plans are a popular option for people who are eligible for Medicare: In {props.planYear}, about 4 in 10 
                        Medicare-eligible people are in a Medicare Advantage plan. But each plan has different strengths and weaknesses. Here’s a 
                        rundown of the top Medicare Advantage plans in {props.countyName} for {props.planYear}.
                    </p>
                    <p style={{marginBottom: '30px', transition: '2s'}} className="bodyParagraph">
                        <h3 style={{width: "300px", backgroundColor: "#1B9CE2", color: 'white', padding: '5px', paddingLeft: '20px',
                                    fontSize: '1em', marginLeft: '20px', marginBottom: '0px'}}>Most Popular</h3>
                        <div style={{transition: '2s', border: "black 2px solid", padding: "20px", marginLeft: "20px", width: "105%"}}> 
                            {props.highestEnrolledPlan ? 
                                <><b>{props.highestEnrolledPlan}</b> is the most popular Medicare Advantage plan in {props.countyName}.<br/><br/>
                                When you're trying to find the right plan for you, it can be helpful to know which plan is the most popular 
                                in your area. Last year, the most popular Medicare Advantage plan in {props.countyName} was {props.highestEnrolledPlan},
                                with {props.highestEnrolledPlanValue} of enrollees in {props.countyName} selecting this 
                                plan. It's no guarantee that this plan will fit your exact needs, however, so it's a good idea to check out
                                the plan details before enrolling. 
                                {props.highestEnrolledPlanURL ? 
                                    <>You can visit the provider's website to learn more <a href={props.highestEnrolledPlanURL}>here</a>.</>
                                    : <></>
                                }</>
                            :
                            <>
                                <img width="100%" height="20px" src="https://planprovide-resources.s3.us-west-1.amazonaws.com/gray_bar.png"/>
                                <img width="100%" height="20px" src="https://planprovide-resources.s3.us-west-1.amazonaws.com/gray_bar.png"/>
                                <img width="100%" height="20px" src="https://planprovide-resources.s3.us-west-1.amazonaws.com/gray_bar.png"/>
                                <img width="100%" height="20px" src="https://planprovide-resources.s3.us-west-1.amazonaws.com/gray_bar.png"/>
                            </>
                            }
                        </div>
                    </p>
                    
                    <p style={{marginBottom: '30px', transition: '2s'}} className="bodyParagraph">
                        <h3 style={{width: "300px", backgroundColor: "#1B9CE2", color: 'white', padding: '5px', paddingLeft: '20px',
                                    fontSize: '1em', marginLeft: '20px', marginBottom: '0px'}}>Highest Rated</h3>
                        <div style={{transition: '2s', border: "black 2px solid", padding: "20px", marginLeft: "20px", width: "105%"}}>
                            {props.highestRatedPlan ? 
                                <><b>{props.highestRatedPlan}</b> is the highest rated Medicare Advantage plan in {props.countyName}.<br/><br/>
                                
                                Every year, the the Centers for Medicare and Medicaid Services, or CMS, releases
                                a <b><u><a href="https://medicarebits.com/?p=163">star rating score</a></u></b> from zero to five
                                stars for every Medicare Advantage plan. Right now, the Medicare Advantage plan with the highest rating 
                                in {props.countyName} is props.highestRatedPlan}, with a rating of {props.highestRatedPlanValue} (to 
                                see other plans that may have tied for the highest rating, check the table below). CMS uses several metrics to 
                                determine this rating, including the plan's quality of care and measurements of customer satisfaction.
                                {props.highestRatedPlanURL ?
                                    <>Visit the provider's website to learn more about this plan <a href={props.highestRatedPlanURL}>here</a>.</>
                                    : <></>
                                }</>
                            :
                            <>
                                <img width="100%" height="20px" src="https://planprovide-resources.s3.us-west-1.amazonaws.com/gray_bar.png"/>
                                <img width="100%" height="20px" src="https://planprovide-resources.s3.us-west-1.amazonaws.com/gray_bar.png"/>
                                <img width="100%" height="20px" src="https://planprovide-resources.s3.us-west-1.amazonaws.com/gray_bar.png"/>
                                <img width="100%" height="20px" src="https://planprovide-resources.s3.us-west-1.amazonaws.com/gray_bar.png"/>
                            </>}
                        </div>
                    </p>
                    <p style={{marginBottom: '30px', transition: '2s'}} className="bodyParagraph">
                        <h3 style={{width: "300px", backgroundColor: "#1B9CE2", color: 'white', padding: '5px', paddingLeft: '20px',
                                        fontSize: '1em', marginLeft: '20px', marginBottom: '0px'}}>Lowest Max Out of Pocket</h3>
                        <div style={{transition: '2s', border: "black 2px solid", padding: "20px", marginLeft: "20px", width: "105%"}}> 
                            {props.lowestMoopPlan ? 
                                <><b>{props.lowestMoopPlan}</b> has the lowest Max-Out of Pocket of all Medicare Advantage plans in {props.countyName}.<br/><br/>
                                
                                "Maximum Out of Pocket", or MOOP, refers to the highest amount that you'll be required to pay each year, aside from your
                                premiums. When it comes to MOOP, lower is better. After you reach your MOOP in any given year, your Medicare Advantage plan
                                will pay for 100% of covered services. The Medicare Advantage plan in {props.countyName} with the lowest MOOP 
                                is {props.lowestMoopPlan}, with a MOOP of {props.lowestMoopPlanValue}. 
                                {props.lowestMoopPlanURL ? 
                                    <>You can learn more about this plan by visiting the provider's website <a href={props.lowestMoopPlanURL}>here</a>.</>
                                    : <></>
                                }</>
                            :
                                <>
                                    <img width="100%" height="20px" src="https://planprovide-resources.s3.us-west-1.amazonaws.com/gray_bar.png"/>
                                    <img width="100%" height="20px" src="https://planprovide-resources.s3.us-west-1.amazonaws.com/gray_bar.png"/>
                                    <img width="100%" height="20px" src="https://planprovide-resources.s3.us-west-1.amazonaws.com/gray_bar.png"/>
                                    <img width="100%" height="20px" src="https://planprovide-resources.s3.us-west-1.amazonaws.com/gray_bar.png"/>
                                </>
                            }
                        </div>
                    </p>
                    <p className="bodyParagraph">
                        <h3 style={{marginBottom: '10px'}}>Medicare Advantage Plans in {props.countyName}, {props.stateName}</h3>
                        {props.ratingTable}
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        <h3 style={{marginBottom: '10px'}}>How to shop for Medicare Advantage plans</h3>

                        The right Medicare Advantage plan for you will depend on your health history, prescription medications and where you live, 
                        among other things. Here are some strategies for selecting the best plan:
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        <ul style={{marginLeft: '20px'}}>
                            <li><b>Check star ratings.</b> The CMS collects data on Medicare Advantage plans from member surveys, the plans themselves 
                            and medical providers, and then assigns a star rating based on the results. The star rating is on a scale of 1 to 5, with 5 being best.</li>
                            <li><b>Compare out-of-pocket costs.</b> Each plan will have a monthly premium (many Medicare Advantage plans have no premium) and a maximum 
                            out-of-pocket cost, which is the most you’ll pay in a year for covered health care.</li>
                            <li><b>Keep your meds in mind.</b> Your medications may seem like an afterthought, but make sure you investigate how each plan will cover your 
                            medications — or whether they’re covered at all.</li>
                            <li><b>Look for your doctors.</b> If you’ve got a list of caregivers and medical facilities you use and prefer, look for plans that include them.</li>
                            <li><b>Consider the plan type.</b> If you see specialists frequently and you don’t want to seek a referral for every office visit, a PPO plan is 
                            probably the better fit. If you’re a light health care user and see mostly your primary care physician, an HMO might be more affordable.</li>
                        </ul>
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        <h3 style={{marginBottom: '10px'}}>How do I enroll in a plan?</h3>
                        You can sign up for a Medicare Advantage plan when you first become eligible for Medicare (your initial enrollment period) 
                        or during designated annual Medicare enrollment periods.
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        Once you’ve done your research and found a Medicare Advantage plan that fits your needs, there are various ways to enroll:
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        <ul style={{marginLeft: '20px'}}>
                            <li>Use Medicare’s Plan Finder to find the plan in your area. Click on “Enroll.”</li>
                            <li>Go to the plan’s website to see if you can enroll online. Contact the plan to get a paper enrollment form. Fill it out and return it 
                                to the plan provider.</li>
                            <li>Call the provider for the plan you wish to join.</li>
                            <li>Call Medicare at 800-MEDICARE (800-633-4227).</li>
                            <li>Speak to a licensed broker to review your options. Email us at hi@medicarebits.com to get connected to one.</li>
                        </ul>
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        You will need your Medicare number and the date your Medicare Part A and/or Part B coverage started. You must be enrolled in Medicare Parts A 
                        and B before you can buy a Medicare Advantage plan.
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        Keep in mind that you can only enroll in a Medicare Advantage plan during your Initial Enrollment Period (when you first become eligible for Medicare) 
                        or during the Open Enrollment Period from Oct. 15 to Dec. 7. Once you’re enrolled in a Medicare Advantage plan, you can switch plans during Medicare 
                        Advantage Open Enrollment from Jan. 1 to March 31 each year.
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        <h3 style={{marginBottom: '10px'}}>Need Help Finding the right plan?</h3>
                        If you would like to speak to a licensed broker, email us at hi@medicarebits.com.
                    </p>
                    <p style={{marginBottom: '30px'}} className="bodyParagraph">
                        Alternatively, for questions about Medicare plans and other Medicare issues, contact Medicare at 800-MEDICARE (800-633-4227) or visit Medicare.gov. 
                    </p>
                </div>
            </div>
        </>
    );   
}

export default withRouter(CountyStateMAPage);
