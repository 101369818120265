import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, Link, useParams, Route } from 'react-router-dom';
import { useQuery } from 'react-query';


import './pages.css';
import './FindPlans.css';

function SearchPlans( props ) {
    // let history = useHistory();
    let [ zip, setZip ] = useState(props.zip);
    let [ errorMessage, setErrorMessage ] = useState("");

    useEffect(() => {
        if (zip.length <= 5) {
            setZip(props.zip);
        }
    }, [props.zip]);

    function submitForm(e) {
        console.log("submitForm");
        e.preventDefault();
        if (zip.length === 5) {
            let geocoder = new window.google.maps.Geocoder();
            let county, state = "";
            geocoder.geocode({ 'address': zip }, function(results, status) {
                if (status === 'OK') {
                    console.log("GOOGLE GEOCODER ");
                    console.log(results);
                    for (let res of results[0].address_components) {
                        if (res.types[0] === "administrative_area_level_2" || res.types[0].includes("administrative_area_level_2")) {
                            county = res.long_name;
                        }
                        if (res.types[0] === "administrative_area_level_1" || res.types[0].includes("administrative_area_level_1")) {
                            state = res.long_name;
                        }
                    }
                    state = state.replace(" ", "-").toLowerCase();
                    county = county.replace(" County","-county").replace(" ", "-").toLowerCase();

                    console.log(window.location.pathname);

                    props.history.push(`/findplans/${state}/${county}`);
                }
                else {
                    console.log("error");
                    setErrorMessage("Please enter a valid zip code.");
                }
            });
            
        }
        else {
            console.log("error");
            setErrorMessage("Please enter a valid zip code.");
        }
    }

    return(
         <div>
             <div style={{backgroundColor: '#fff0', boxShadow: 'none', width: "60%", fontSize:"3em", lineHeight: "1.2em",
                         paddingBottom: '30px', fontFamily: 'DM Serif Display'}}>
                Find your Medicare plan
            </div>
            <div className="bodyParagraph">
                Get info on the Medicare Advantage, Medicare Supplement, Prescription Drug and Special Needs plans available in your area.
            </div>
             <form style={{width: '45%', minWidth: "400px", padding: "30px", paddingTop: "50px", paddingBottom: "50px", backgroundColor: "white", borderRadius: "35px", 
                           marginTop: "20px"}}>
                <h1 style={{fontSize: "1.5em", marginBottom: "20px", fontFamily: "DM Sans"}}>What is your Zip Code?</h1>
                {errorMessage ? <p style={{color: "red", textAlign: "center"}}>{errorMessage}</p> : null}
                <div className="horCont">
                    <input type="text" placeholder="Enter your zip" value={zip} onChange={(e) => setZip(e.target.value)} maxlength="5" pattern="\d{5}"></input>
                    <button className="submitButton" type="submit" onClick={submitForm}>Find Plans</button>
                </div>
             </form>
            
         </div>
    );
}



export default withRouter(SearchPlans);
