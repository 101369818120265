import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useHistory, useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import { PlanContext, ClientContext, ApplicationContext } from '../../../components/PCAContext';
import { BrokerContext } from '../../../components/BrokerContext';

import './PickPlan.css';


import PlanFilters from '../../../components/PlanFilters';

function PickPlan(props) {
    let history = useHistory();

    const [application, setApplication] = useContext(ApplicationContext);
    const [client, setClient] = useContext(ClientContext);
    const [plan, setPlan] = useContext(PlanContext);
    const [broker, setBroker] = useContext(BrokerContext);

    

    useEffect(() => {
      if (!("new_client" in client)) { // If there's no new/existing client value
        history.push(`/applications/pick-client`)
      }
    }, []);

    useEffect(() => {
        let client_id = client.id;
        if (client_id != -1) {//(client.id == -1 && client_id != -1) {
            let client_json = JSON.stringify(client_id);
            fetch('/api/get_client_by_id', {
                method: 'POST',
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: client_json
            }).then(res => res.json())
            .then(client_data => {
                if (client_data["status"] === "error") {
                    let errorMessage = client_data["errorMessage"];
                    history.push(`/error/${errorMessage}`)
                } else {
                    setClient({...client, ...client_data});
                }
            });
        }
    }, []);

    // Advance to display plans page
    function advance(event) {
        event.preventDefault();
        

        const data = new FormData(event.target);

        var object = {};
        data.forEach((value, key) => {
            object[key] = value;
            window.sessionStorage.setItem(key, value);
        });

        setApplication({...application, effective_date: object["effective_date"]});

        setPlan({...plan,
            type: object["plan_type"],
            number: object["plan_number"],
            zip: object["zip"],
            age: object["age"],
            tobacco: object["tobacco"],
            gender: object["gender"],
        })

        setBroker({...broker,
            app_edit_allowed: object["app_edit_allowed"] === 'y' ? true : false,
        })

        fetch('/api/update_broker', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                'id': broker.id,
                'app_edit_allowed': object["app_edit_allowed"] === 'y' ? true : false,
            })
        })

        history.push(`/applications/plans-list`);
    }

    return(
        <div className = "mainArea">
            <div className = "mainTitle">
                <div className = "titleInfo">
                    <h1 className = "titleName">Add Application</h1>
                    <p className = "titleDetails">Select the plan to enroll into.</p>
                </div>
            </div>
            <PlanFilters 
                onSubmit={advance}
                showEffectiveDate={true}
                style={{padding: "50px", paddingLeft: "5%", paddingTop: "10px"}}
            />
        </div>
    );
}

export default PickPlan;
