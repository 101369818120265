import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import { PlanContext, ClientContext, ApplicationContext } from '../../../components/PCAContext';
import { BrokerContext } from '../../../components/BrokerContext';

import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';

import './coverageQuestions.css';

import CheckBox from '../../../components/CheckBox';
import InputField from '../../../components/InputField';
import DateForm from '../../../components/DateForm';
import FormikRadioButton from '../../../components/FormikRadioButton';
import StateForm from '../../../components/StateForm';
import Banner from '../../../components/Banner';
import LoadingOverlay from '../../../components/LoadingOverlay';
import FormikInputField from '../../../components/FormikInputField';
import FormikCheckBox from '../../../components/FormikCheckBox';

import mixpanel from 'mixpanel-browser';

import { handleDate, printRFC3339Date } from '../../../utils.js';

function HealthUnderwritingProvider(props) {
  let history = useHistory();

  const [application, setApplication] = useContext(ApplicationContext);
  const [plan, setPlan] = useContext(PlanContext);
  const [client, setClient] = useContext(ClientContext);
  const [broker, setBroker] = useContext(BrokerContext);

  let { app_id } = useParams();

  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState(false);
  const [firstSubmit, setFirstSubmit] = useState(false);

  useEffect(() => {
    console.log("CLIENT");
    console.log(client);

  }, [client]);

  function doSubmit(object) {
      console.log("Start submission");
      setLoading(true);
      console.log(object);

      let json = JSON.stringify({
        client_id: client.id,
        ...object
      });
      console.log(json);
      fetch('/api/update_client', {
          method: 'POST',
          headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          },
          body: json,
          signal: props.controller.controller.signal,
      }).then(res => res.json())
      .then(data => {
          if (data["status"] === "error") {
              let errorMessage = data["errorMessage"];
              history.push(`/error/${errorMessage}`);
          }
          else if (plan.type === "1") {
              console.log("Underwriting updated success");
              history.push(`/applications/health-underwriting-conditions/${application.id}`);
          }
          else {
            console.log("Underwriting updated success");
            setLoading(true);
            let json = JSON.stringify({
              application_id: application.id,
              app_edit_allowed: broker.app_edit_allowed || false,
              ancillary_plan_ids: [],
            });

            fetch('/api/create_docusign_envelope_from_application', {
                method: 'POST',
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: json,
                signal: props.controller.controller.signal,
            }).then(res => res.json())
            .then(data => {
                if (data["status"] === "error") {
                  let errorMessage = data["errorMessage"];
                  history.push(`/error/${errorMessage}`);
                  return;
                }
                console.log("Application create docusign success");
                json = JSON.stringify({
                  destination_email: data.destination_email,
                  application_id: data.id,
                });

                fetch('/api/get_docusign_envelope_signing_link_from_email', {
                    method: 'POST',
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: json,
                    signal: props.controller.controller.signal,
                }).then(res => res.json())
                .then(data => {
                  if (data["status"] === "error") {
                    let errorMessage = data["errorMessage"];
                    history.push(`/error/${errorMessage}`);
                    return;
                  }
                  setApplication({...application,
                    ...data,
                    url: data.signing_url,
                    envelope_id: data.docusign_envelope_id,
                    download: data.aws_pdf_key,
                    csv: data.aws_csv_key,
                    access_code: data.ds_access_code,
                  });
                  history.push(`/applications/ready-sign/${application.id}`);
                });
            });
          }
      });
  }

  return (
    <>
      {
        loading
        ? <LoadingOverlay></LoadingOverlay>
        : <div style = {{display: "none"}}></div>
      }
      {
        banner
        ? <Banner message="Answering yes to any of the above questions would result in this application being unlikely to pass health underwriting."
                  isSuccess={false}></Banner>
        : <></>
      }
      <div>
        <div className = "mainArea">
          <div className = "mainTitle">
              <div className = "titleInfo">
              <h1 className = "titleName">Medical Provider Information</h1>
              <p className = "titleDetails">Please provide your doctors.</p>
              </div>
          </div>
          <Formik
              initialValues={{
                height_feet: client.height_feet || '',
                height_inches: client.height_inches || '',
                weight: client.weight || '',
                physician_id: client.physician_id || '',
                physician_group_name: client.physician_group_name || '',
                deduct_premium_from_ss: '',
                primary_physician_name: client.primary_physician_name || '',
                primary_physician_phone: client.primary_physician_phone || '',
                primary_physician_address: client.primary_physician_address || '',
                primary_physician_city: client.primary_physician_city || '',
                primary_physician_state: client.primary_physician_state || '',
                primary_physician_zip: client.primary_physician_zip || '',
              }}
              validationSchema={Yup.object({
                height_feet: plan.type === "1" ? Yup.string()
                  .required('This field is required')
                  .matches(/\d{1,2}/, 'Invalid height') : undefined,
                height_inches: plan.type === "1" ? Yup.string()
                  .required('This field is required')
                  .matches(/\d{1,2}/, 'Invalid height') : undefined,
                weight: plan.type === "1" ? Yup.string()
                  .required('This field is required')
                  .matches(/\d{1,3}/, 'Invalid weight') : undefined,
                physician_id: plan.type === "1" ? undefined : Yup.string()
                  .required('This field is required'),
                physician_group_name: plan.type === "1" ? undefined : Yup.string()
                  .required('This field is required'),
                primary_physician_name: Yup.string()
                  .required('This field is required'),
                primary_physician_phone: Yup.string()
                  .required('This field is required')
                  .matches(/\d{7,11}/, 'Invalid phone number'),
                primary_physician_address: Yup.string()
                  .required('This field is required'),
                primary_physician_city: Yup.string()
                  .required('This field is required'),
                primary_physician_state: Yup.string()
                  .required('This field is required'),
                primary_physician_zip: Yup.string()
                  .required('This field is required')
                  .matches(/\d{5}/, 'Invalid zip code.'),
              })}
              onSubmit={(values, actions) => {
                console.log("SUBMIT!");
                doSubmit(values);
                actions.setSubmitting(false);
              }}
              enableReinitialize
          >
            {({ isSubmitting, isValid, dirty, handleSubmit, handleChange, handleBlur, errors }) => (
              <form className = "clientForm" onSubmit={(e) => { handleSubmit(e); setFirstSubmit(true); }} style={{width: "80%", maxWidth: "2000px"}}>
                <div className="vertCont">
                  {plan.type === "1" ?
                    <div className="horCont" style={{width:"400px"}}>
                      <div className="vertCont">
                        <div style={{width: "100%"}}>Height</div> 
                        <div style={{marginTop: "0px"}} className="horCont">
                          <FormikInputField
                            name = "height_feet"
                            labelInfo = ""
                            errorLabel = "height"
                            type="text"
                            maxLength = "2"
                          ></FormikInputField>
                          <div style={{marginTop:"10px", marginLeft: "5px", marginRight:"10px"}}>
                            <label>feet</label>
                          </div>
                          <FormikInputField
                            name = "height_inches"
                            labelInfo = ""
                            errorLabel = "height"
                            type="text"
                            maxLength = "2"
                          ></FormikInputField>
                          <div style={{marginTop:"10px", marginLeft: "5px"}}>
                            <label>inches</label>
                          </div>
                        </div>
                      </div>
                      <div style={{marginLeft:"20px"}}>
                        <FormikInputField
                          name = "weight"
                          labelInfo = "Weight (lbs)"
                          errorLabel = "weight"
                          type="text"
                          maxLength = "3"
                        ></FormikInputField>
                      </div>
                    </div>
                  :
                    <div className="vertCont">
                      <FormikInputField
                        name = "physician_id"
                        labelInfo = "*Physician ID #"
                        errorLabel = "Physician ID"
                        type="text"
                        maxLength = "50"
                      ></FormikInputField>
                      <FormikInputField
                        name = "physician_group_name"
                        labelInfo = "*Physician Group Name"
                        errorLabel = "Physician Group Name"
                        type="text"
                        maxLength = "50"
                      ></FormikInputField>
                      <FormikInputField
                        name = "physician_currently_seeing"
                        labelInfo = "*I am currently seeing or have recently seen this doctor."
                        errorLabel = "I am currently seeing or have recently seen this doctor."
                        type="checkbox"
                        maxLength = "50"
                      ></FormikInputField>
                    </div>
                  }
                  <div className="horCont" style={{width:"100%"}}>
                    <div className="half">
                      <FormikInputField
                        name = "primary_physician_name"
                        labelInfo = "*Primary Physician"
                        errorLabel = "Primary physician name"
                        type="text"
                        maxLength = "50"
                      ></FormikInputField>
                    </div>
                    <div className="half">
                      <FormikInputField
                        name = "primary_physician_phone"
                        labelInfo = "*Phone"
                        errorLabel = "Phone"
                        placeholder = "(000) 000-0000"
                        type="text"
                        maxLength = "11"
                      ></FormikInputField>
                    </div>
                  </div>
                  <div className="horCont" style={{width:"100%"}}>
                    <div className="half">
                      <FormikInputField
                        name = "primary_physician_address"
                        labelInfo = "*Address"
                        errorLabel = "Address"
                        type="text"
                        maxLength = "50"
                      ></FormikInputField>
                    </div>
                    <div className="half">
                      <FormikInputField
                        name = "primary_physician_city"
                        labelInfo = "*City"
                        errorLabel = "City"
                        type="text"
                        maxLength = "50"
                      ></FormikInputField>
                    </div>
                  </div>
                  <div className="horCont" style={{width:"100%"}}>
                    <div className="half">
                      <StateForm name = "primary_physician_state"
                                 labelInfo = "*State"
                      ></StateForm>
                    </div>
                    <div className="half">
                      <FormikInputField
                        name = "primary_physician_zip"
                        labelInfo = "*Zip"
                        errorLabel = "Zip"
                        placeholder = "00000"
                        type="text"
                        maxLength = "5"
                      ></FormikInputField>
                    </div>
                  </div>
                </div>
                <div className = "continue">
                  {
                    (firstSubmit && !isValid) ?
                    <input type="button" className = "disabledButtonStyle" value="Continue"></input> :
                    <input type="submit"
                            className = "buttonStyle"
                            value="Continue"></input>
                  }
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default HealthUnderwritingProvider;
