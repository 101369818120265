import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { ClientContext } from '../components/PCAContext';

import mixpanel from 'mixpanel-browser';

import Sidebar           from '../components/Sidebar';
import Headbar           from '../components/Headbar';
import SetEnvironment    from '../components/SetEnvironment';

import './pages.css';
import './ClientFormPage.css';

import ClientDetailsForm from '../components/ClientDetailsForm';

function AddClient(props) {
  let history = useHistory();

  const [client, setClient] = useContext(ClientContext);

  let { id } = useParams();
  // let bannerSuccessMessage = "New Client Added!";

  let bannerFailureMessage = "A client with this email already exists."

  // Action to complete upon submitting the form
  const handleSubmit = (json) => {
    return new Promise((resolve, reject) => {
      // Uses POST method to send json object of data to Flask
      fetch('/api/create_client' , {
        method: 'POST',
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: json,
      }).then(res => {
        // Checks that external services return ok, throws 400-500 errors automatically made by the system
        if (res.ok) {
          resolve("success");
          history.push(`/clients/${true}`) // Return to Clients page
          return res.json();
        } else {
          if (res.status === 400) {
            resolve("failure");
            return res.json();
          }
        }
      }
      ).then(data => {
        // Checks for errors thrown by internal services, sends to error page
        if (data["status"] === "error") {
          let errorMessage = data["errorMessage"];
          history.push(`/error/${errorMessage}`);
          reject("Internal error.");
        }
      });
    })
  }

  return (
    <div className = "background">
        <SetEnvironment></SetEnvironment>
        <Sidebar current="clients" controller={props.controller}></Sidebar>
        <div className = "middle container">
            <Headbar title = "Clients"></Headbar>
            <ClientDetailsForm
              client_id={id}
              title="Add a New Client"
              subTitle="Enter information for your client below."
              pageTitle="Clients"
              currentSidebar="clients"
              buttonLabel="Add Client"
              handleSubmit={handleSubmit}
              mixpanelEvent="Add new client via Clients page"
              bannerFailureMessage={bannerFailureMessage}
              canEditEmail={true}
              showApplicationHistory={false}
            />
        </div>
    </div>
  );
}

export default withRouter(AddClient);
