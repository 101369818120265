import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory, Link, Route, useParams} from 'react-router-dom';

import { BrokerContext } from '../components/BrokerContext';

import './pages.css';
import './Profile.css';

import LoadingOverlay from '../components/LoadingOverlay';
import RegisterAddWritingNums from './RegisterAddWritingNums';
import RegisterAgencyType from './RegisterAgencyType';
import RegisterProfileDetails from './RegisterProfileDetails';

import { ENVContext } from '../components/ENVContext';
import SetEnvironment from '../components/SetEnvironment';

let qs = require('qs');

function Register(props) {
    let history = useHistory();

    const [broker, setBroker] = useContext(BrokerContext);

    const [loading, setLoading] = useState(false);

    const [agentType, setAgentType] = useState(null);
    const [newAgencyName, setNewAgencyName] = useState();
    const [agencyEmail, setAgencyEmail] = useState();
    const [agencyPhone, setAgencyPhone] = useState();

    let queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const [agencyNPN, setAgencyNPN] = useState(queryParams ? queryParams.npn : "");

    const [writingNumbers, setWritingNumbers] = useState([]);

    function auth0Logout() {
      window.open(window.location.origin + '/auth0/logout', '_self');
    }

    useEffect(() => {
      if (agentType === null) {
        history.push('/register/agency-type');
      }
    }, [])

    useEffect(() => {
       if (history) {
         return history.listen((location) => {
            props.controller.abort();
            console.log(`You changed the page to: ${location.pathname}`);
         });
       }
    },[history]);

    return (
      <div className = "background">
        <SetEnvironment></SetEnvironment>
        {
          loading && <LoadingOverlay></LoadingOverlay>
        }
        <div style = {{margin: "0px", padding: "0px", maxWidth: "10000px", minHeight: "930px"}} className = "middle container">
          <div className="horCont" style = {{width: "100%", height: "100px", background: "#109cf1", marginTop: "0px"}}></div>
          {
            broker.status === 0 ?
              <div className="mainArea" style={{paddingBottom: "40px", marginBottom: "20px", marginTop: "20px"}}>
                <Route path={`${props.match.path}/agency-type`}       render={() => (
                  <RegisterAgencyType     agencyNPN={agencyNPN} setAgencyNPN={setAgencyNPN} agentType={agentType} setAgentType={setAgentType}
                                          newAgencyName={newAgencyName} setNewAgencyName={setNewAgencyName}
                                          agencyEmail={agencyEmail} setAgencyEmail={setAgencyEmail}
                                          agencyPhone={agencyPhone} setAgencyPhone={setAgencyPhone}
                                          controller={props.controller}/>
                      )} />
                <Route path={`${props.match.path}/add-writing-nums`} render={() => (
                  <RegisterAddWritingNums agencyNPN={agencyNPN} setAgencyNPN={setAgencyNPN} agentType={agentType}
                                          writingNumbers={writingNumbers} setWritingNumbers={setWritingNumbers}
                                          controller={props.controller}/>
                      )} />
                <Route path={`${props.match.path}/profile-details`}   render={() => (
                  <RegisterProfileDetails agencyNPN={agencyNPN} setAgencyNPN={setAgencyNPN}
                                          agentType={agentType} setAgentType={setAgentType}
                                          newAgencyName={newAgencyName} setNewAgencyName={setNewAgencyName}
                                          agencyEmail={agencyEmail} setAgencyEmail={setAgencyEmail}
                                          agencyPhone={agencyPhone} setAgencyPhone={setAgencyPhone}
                                          writingNumbers={writingNumbers} setWritingNumbers={setWritingNumbers}
                                          controller={props.controller}/>
                      )} />
              </div>
            :
              <div className = "mainTitle titleDetails mainArea" style={{margin: "50px", padding: "20px"}}>
                <p>You are already logged in as {broker.email}.</p>
                <p>Click here to <u><a onClick={auth0Logout}>↵ Logout</a></u>.</p>
              </div>
          }
        </div>
      </div>
    )
}

export default withRouter(Register);
