import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory, Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { BrokerContext } from '../components/BrokerContext';
import { PlanContext, ClientContext, ApplicationContext } from '../components/PCAContext';

import Sidebar           from '../components/Sidebar';
import Headbar           from '../components/Headbar';
import SetEnvironment    from '../components/SetEnvironment';

import InfiniteQueryList from '../components/InfiniteQueryList';

import './pages.css';
import './ClientsPage.css';

// Fetch all applications by broker id
const fetchClients = async (key, broker_id, page_num=1) => {
    if (broker_id != -1) {
        let json = JSON.stringify({"broker_id": broker_id, "page_num": page_num});

        const res = await fetch('/api/get_paginated_clients_by_broker_id', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 0
            },
            body: json
        });

        return res.json();
    } else {
        return [];
    }
}

function Clients(props) {
    let history = useHistory();
    let { showBanner } = useParams();

    const [broker, setBroker] = useContext(BrokerContext);
    const [plan, setPlan] = useContext(PlanContext);
    const [client, setClient] = useContext(ClientContext);
    const [application, setApplication] = useContext(ApplicationContext);

    useEffect(() => {
        // Set PCA context to default
        setPlan({
            id: -1,
            name: '',
            type: '',
            number: '',
            carrier_name: '',
        });

        setClient({
            id: -1,
            first_name: '',
            middle_initial: '',
            last_name: '',
            gender: '',
            title: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            mailing_address: '',
            mailing_city: '',
            mailing_state: '',
            mailing_zip: '',
            dob: '',
            medicare_claim_number: '',
            part_a_start_date: '',
            part_b_start_date: '',
            billing_preference: '',
            bank_name: '',
            bank_routing: '',
            bank_account: '',
        });

        setApplication({
            id: -1,
            status: -1,
            url: '',
            download: '',
            csv: '',
            envelope_id: '',
            access_code: '',
            effective_date: '',
        });
    }, []);

    useEffect(() => {
       if (history) {
         return history.listen((location) => {
            props.controller.abort();
            console.log(`You changed the page to: ${location.pathname}`);
         });
       }
    },[history]);

    return(
        <div className = "background">
            <SetEnvironment></SetEnvironment>
            <Sidebar current="clients" controller={props.controller}></Sidebar>
            <div className = "middle container">
                <Headbar title = "Clients"></Headbar>
                <InfiniteQueryList
                    itemName="client"
                    title="Clients"
                    queryName="clients"
                    queryArgs={[broker.id]}
                    queryFunc={fetchClients}
                    mixpanelTrack="New client from Clients page started"
                    newItemDestination="/addclient/"
                    bannerMessage="Client was successfully added!"
                    showBanner={showBanner}
                ></InfiniteQueryList>
            </div>
        </div>
    );
}

export default withRouter(Clients);
