import React, { useEffect, useState, useContext } from 'react';
import { useQuery, useMutation, queryCache, useInfiniteQuery, usePaginatedQuery } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';

import SelectableEntityBox from './SelectableEntityBox';
import LoadingOverlay from './LoadingOverlay';
import Banner from './Banner';

import { BrokerContext } from './BrokerContext';
import { ApplicationContext, ClientContext } from './PCAContext';
import { ENVContext } from './ENVContext';

import mixpanel from 'mixpanel-browser';

import './InfiniteQueryList.css';

import { compareDate } from '../utils.js';
import Table from 'react-bootstrap/Table';

function InfiniteQueryList(props) {
  let history = useHistory();

  const [broker, setBroker] = useContext(BrokerContext);
  const [application, setApplication] = useContext(ApplicationContext);
  const [client, setClient] = useContext(ClientContext);
  const [ENV, setENV] = useContext(ENVContext);
  const [isLoading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [showOptions, setShowOptions] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [filterType, setFilterType] = useState(props.filterBrokerName ? "broker" : "");
  const [filterValue, setFilterValue] = useState(props.filterBrokerName ? props.filterBrokerName : "");
  const { data,
          status,
          isFetching,
          isFetchingMore,
          fetchMore,
          canFetchMore,
          error,
  } = useInfiniteQuery([props.queryName, ...props.queryArgs], props.queryFunc, {
    getFetchMore: (lastPage, allPages) => {
      console.log("GET FETCH MORE");
      console.log(lastPage);
      if (!lastPage.has_next) {
        return false;
      }
      return lastPage.next_num;
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  const [deleteItems] = useMutation(deleteItemsMutation);
  const [transferItems] = useMutation(transferItemsMutation);

  const bannerTriggerOn = () => { setShowBanner(true); }
  const bannerTriggerOff = () => { setShowBanner(false); }
  useEffect(() => {
    console.log(filterType);
    console.log(filterValue);
  }, [filterType, filterValue])

  useEffect(() => {
    const handleScroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;

      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;

      const scrolled = winScroll / height;

      if(scrolled >= 1) {
        console.log("Loading more apps...")
        console.log(canFetchMore);
        console.log(isFetchingMore);
        if (!isFetchingMore) {
          fetchMore();
        }
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    console.log("selectedItems");
    console.log(selectedItems);
    for (const [grp_key, grp] of Object.entries(selectedItems)) {
      for (const [key, value] of Object.entries(grp)) {
        if (value === true) {
          setShowOptions(true);
          return;
        }
      }
    }
    setShowOptions(false);
  }, [selectedItems]);

  if (status === 'error') {
      let errorMessage = 'Uh oh. Error.';
      history.push(`/error/${errorMessage}`);
      return;
  } else if (status === 'success' && data.status === 'error') {
      let errorMessage = data.errorMessage;
      history.push(`/error/${errorMessage}`);
      return;
  }

  function newItem() {
      mixpanel.init(ENV.MIXPANEL_TOKEN);
      mixpanel.track(props.mixpanelTrack);
      if (props.mixpanelTimeEvent)
        mixpanel.time_event(props.mixpanelTimeEvent);
      console.log("NEW")

      history.push(props.newItemDestination);
  }

  function selectItemBox(groupIndex, index) {
    if (selectedItems[groupIndex]) {
      setSelectedItems({
        ...selectedItems,
        [groupIndex]: {
          ...selectedItems[groupIndex],
          [index]: !selectedItems[groupIndex][index],
        }
      });
    }
    else {
      setSelectedItems({
        ...selectedItems,
        [groupIndex]: {
          [index]: true,
        }
      });
    }
  }

  async function deleteItemsMutation(itemsToDelete) {
    let json;
    let fetchTarget;
    if (props.itemName === "application") {
      json = JSON.stringify({"applications": itemsToDelete});
      fetchTarget = '/api/archive_applications';
    }
    else if (props.itemName === "client") {
      json = JSON.stringify({"clients": itemsToDelete});
      fetchTarget = '/api/delete_clients';
    }

    fetch(fetchTarget, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: json,
    }).then(res => res.json())
    .then(data => {
        if (data["status"] === "error") {
            let errorMessage = data["errorMessage"];
            history.push(`/error/${errorMessage}`)
        } else {
            console.log(props.itemName + "s successfully archived");
            for (const [grp_key, grp] of Object.entries(selectedItems)) {
              for (const [key, value] of Object.entries(grp)) {
                if (value === true) {
                  selectedItems[grp_key][key] = false;
                }
              }
            }
            bannerTriggerOn();

            try {
              queryCache.refetchQueries([props.queryName, ...props.queryArgs], {
                exact: false,
                stale: true,
                throwOnError: true,
                active: true,
              }).then(()=>{ setLoading(false) });
              console.log("queries refetched");
            } catch (error) {
              console.log("ERROR! failed to refresh items")
              console.log(error)// Uh oh, something went wrong
            }
        }
    })
  }

  async function transferItemsMutation(obj) {
    let json = JSON.stringify(obj);

    fetch('/api/transfer_clients_to_broker', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: json,
      referrer: '',
    }).then(res => res.json())
    .then(data => {
        if (data["status"] === "error") {
            let errorMessage = data["errorMessage"];
            history.push(`/error/${errorMessage}`)
        } else {
            for (const [grp_key, grp] of Object.entries(selectedItems)) {
              for (const [key, value] of Object.entries(grp)) {
                if (value === true) {
                  selectedItems[grp_key][key] = false;
                }
              }
            }
            bannerTriggerOn();

            try {
              queryCache.refetchQueries([props.queryName, ...props.queryArgs], {
                exact: false,
                stale: true,
                throwOnError: true,
                active: true,
              }).then(()=>{ setLoading(false) });
              console.log("queries refetched");
            } catch (error) {
              console.log("ERROR! failed to refresh items")
              console.log(error)// Uh oh, something went wrong
            }
        }
    })
  }

  const deleteSelectedItems = async () => {
    console.log("delete");
    let count = 0;
    let itemsToDelete = [];
    for (const [grp_key, grp] of Object.entries(selectedItems)) {
      for (const [key, value] of Object.entries(grp)) {
        if (value === true) {
          count += 1;
          itemsToDelete.push({"id": data[grp_key].items[key].id});
        }
      }
    }
    let alertString;
    if (count === 1) {
      alertString = `Are you sure you want to delete this ${props.itemName}?`;
    }
    else {
      alertString = `Are you sure you want to delete these ${count} ${props.itemName}s?`;
    }
    if (props.itemName === "client") {
      alertString += "\n\nWARNING: This will also permanently delete all the client's applications!";
    }
    if (!window.confirm(alertString)) {
      return;
    }

    setLoading(true);
    setShowOptions(false);

    try {
      await deleteItems(itemsToDelete);
    } catch (error) {
      console.log("ERROR! failed to delete items")// Uh oh, something went wrong
    }
  }

  const transferSelectedItems = async (e) => {
    let itemsToTransfer = [];
    let count = 0;
    for (const [grp_key, grp] of Object.entries(selectedItems)) {
      for (const [key, value] of Object.entries(grp)) {
        if (value === true) {
          count += 1;
          itemsToTransfer.push({"id": data[grp_key].items[key].id});
        }
      }
    }

    let alertString;
    if (count === 1) {
      alertString = `Are you sure you want to transfer this ${props.itemName} to another broker?`;
    }
    else {
      alertString = `Are you sure you want to transfer these ${count} ${props.itemName}s to another broker?`;
    }
    if (!window.confirm(alertString)) {
      return;
    }

    setLoading(true);
    setShowOptions(false);

    try {
      await transferItems({
        clients: itemsToTransfer,
        broker_id: e.target.value,
      });
    } catch(error) {
      console.log("ERROR! Failed to transfer items")
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // Returns true to allow the item through, and false to block the item
  function filterItem(item) {
    if (!filterValue) {
      return true;
    }

    if (props.itemName === "client") {
        return (!item.broker) || (item.first_name + " " + item.last_name).includes(filterValue) ||
               (item.broker.first_name + " " + item.broker.last_name).includes(filterValue);
    }

    else if (props.itemName === "broker") {
        return (item.first_name + " " + item.last_name).includes(filterValue);
    }

    else if (props.itemName === "application") {
        if (item.broker) {
          return (!item.client) || (!item.broker) || (!item.carrier) ||
                 (item.client.first_name + " " + item.client.last_name).includes(filterValue) ||
                 (item.broker.first_name + " " + item.broker.last_name).includes(filterValue) ||
                 (item.carrier.name).includes(filterValue);
        }
        else {
          return (!item.client) || (!item.carrier) ||
                 (item.client.first_name + " " + item.client.last_name).includes(filterValue) ||
                 (item.carrier.name).includes(filterValue);
        }

    }
    return true;
  }

  return (
    <>
      <div className = "mainArea" style={props.style}>
          <div style = {{marginTop: "0px"}} className = "mainTitle">
              <div style = {{left: "5%", width: "93%"}} className = "titleInfo horCont">
                  <div style = {{width: "max(200px, 30%)"}}>
                      <h1 className = "titleName">{props.title}</h1>
                  </div>
                  {props.isApplicationHistory ? <></> :
                    <div className="horCont">
                      {
                        showOptions && props.showTransfer ?
                        <div style = {{display: "flex"}} >
                            <select style = {{float: "right", width: "150px", left: "400px", marginRight: "10px", lineHeight: "50px"}}
                                    onChange={transferSelectedItems}>
                              <option>Transfer {capitalizeFirstLetter(props.itemName)}(s)</option>
                              {props.agencyBrokers ?
                                props.agencyBrokers.map((agencyBroker, i) => (
                                <option value={agencyBroker.id}>{agencyBroker.first_name + " " + agencyBroker.last_name}</option>
                              ))
                              : <></>}
                            </select>
                        </div>
                        : <></>
                      }
                      {
                        showOptions ?
                        <div style = {{display: "flex"}} >
                            <Link style = {{float: "right", width: "150px", left: "400px", lineHeight: "50px"}}
                                  onClick = {deleteSelectedItems}
                                  className = "linkStyle">Delete {capitalizeFirstLetter(props.itemName)}(s)</Link>
                        </div>
                        : <></>
                      }
                      <div className = "filterControls horCont" style={{float: "right", marginRight: "30px", width: "80%"}}>
                        {/*<select style={{width: "35%"}}
                                onChange={(e) => setFilterType(e.target.value)}
                                value={filterType}
                                disabled={props.filterBrokerName ? true : undefined}>
                          <option value="" selected="selected">-- Select a filter --</option>
                          {props.filterByBroker ? <option value="broker">Broker</option> : <></>}
                          {props.itemName !== "broker" ? <option value="client">Client</option> : <></>}
                          {props.itemName === "application" ? <option value="plan">Plan</option> : <></>}
                        </select>*/}
                        <img style={{position:"absolute", marginLeft:"10px"}} width="10px" src="https://upload.wikimedia.org/wikipedia/commons/5/55/Magnifying_glass_icon.svg"></img>
                        <input type="text"
                              style={{width:"100%", paddingLeft:"15px"}}
                              onChange={(e) => setFilterValue(e.target.value)}
                              value={filterValue}
                              placeholder={`Search ${props.itemName}s`}
                              disabled={props.filterBrokerName ? true : undefined}></input>
                      </div>
                      {
                        props.hideButton ? <></> :
                        <div style = {{display: "flex"}} >
                            <input type="button" style = {{width: "240px", lineHeight: "50px"}}
                                  onClick = {(e) => {e.preventDefault(); newItem()}}
                                  className = "buttonStyle"
                                  value={props.buttonLabel ? props.buttonLabel : "Add " + capitalizeFirstLetter(props.itemName)}>
                            </input>
                        </div>
                      }
                    </div>
                  }
              </div>
          </div>
          {
            (showBanner || props.showBanner) ?
            <Banner triggerOn={showBanner}
                    turnTriggerOff={bannerTriggerOff}
                    lifetime={5000}
                    isSuccess={true}
                    message={props.bannerMessage}
                    yOffset={props.showBanner ? -138 : undefined}
            ></Banner>
            :<></>
          }
          <Table borderless hover style={{width:"100%", marginBottom:"30px"}}>
            {
              props.itemName === "application" ?
                <thead>
                        <tr>
                            <th></th>
                            <th>Client Name</th>
                            {props.showBrokerOwner ?
                              <th>Broker Name</th> :
                            props.showClientInfo ?
                              <th>Contact Information</th> :
                            <></>
                            }
                            <th>Carrier</th>
                            <th>Status</th>
                            <th>Date Created</th>
                        </tr>
                </thead>
              : props.itemName === "client" ?
                <thead>
                        <tr>
                          <th></th>
                            <th>Name</th>
                            <th>Contact Information</th>
                            <th>Zip</th>
                            <th>Date of Birth</th>
                            <th>Date Created</th>
                        </tr>
                </thead>
              : props.itemName === "broker" ?
                <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Contact Information</th>
                            <th>Zip</th>
                            <th>Date Created</th>
                        </tr>
                </thead>
              : <></>
            }
            <tbody>
                {
                  (status === 'loading' || isLoading) ? (
                    <div style={{marginLeft: "-30px"}}>
                      <LoadingOverlay width = "calc(100vw - 200px - 10%)" height = "calc(100vh - 15vh - 100px - 50px)"></LoadingOverlay>
                    </div>
                  ) : status === 'error' ? (
                    <p>Error: {error.message}</p>
                  ) : <>
                        { data ?
                            data.map((group, i) => (
                              <React.Fragment key={i}>
                                {
                                  group.items && group.items.length === 0
                                  ? props.isApplicationHistory
                                    ? <p style={{marginLeft: "5%"}}>There are no {props.itemName}s that match your search</p>
                                    : <p style={{marginLeft: "5%"}}>To get started, add a <Link to={props.newItemDestination} className = "linkStyle">new {props.itemName}</Link>.</p>
                                  : group && group.items
                                  ? group.items.filter(item => filterItem(item)).length > 0
                                    ? group.items.sort(compareDate).reverse().filter(item => filterItem(item)).map((item, index) => (
                                        <SelectableEntityBox
                                            type={props.itemName}
                                            key={index}
                                            info={item}
                                            location={props.location}
                                            checked={selectedItems[i] ? selectedItems[i][index] : false}
                                            destination={props.itemBoxDestination}
                                            onChange={ () => selectItemBox(i, index) }
                                            hideCheckbox={props.isApplicationHistory}
                                            showBrokerOwner={props.showBrokerOwner}
                                            filterValue={filterValue}
                                            clientNameFilter={filterType === "client" ? filterValue : undefined}
                                            brokerNameFilter={filterType === "broker" ? filterValue : undefined}
                                            planNameFilter={filterType === "plan" ? filterValue : undefined}
                                        ></SelectableEntityBox>
                                    )) : <p style={{marginLeft: "5%"}}>There are no {props.itemName}s that match your search</p>
                                  : <></>
                                }
                              </React.Fragment>
                            ))
                        :<>No {props.itemName}s in query!</>}
                      {
                        (data && isFetchingMore) ? (
                          <tr>
                            <td colspan="4">Loading more {props.itemName}s...</td>
                          </tr>
                        ) : <></>
                      }
                  </>
              }
            </tbody>
          </Table>
      </div>
    </>
  )
}

export default InfiniteQueryList;
