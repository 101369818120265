import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { BrokerContext } from '../../../components/BrokerContext';

import InfiniteQueryList from '../../../components/InfiniteQueryList';
import AgencyNavigation from '../../../components/AgencyNavigation';

// Fetch all applications by broker id
const fetchBrokers = async (key, broker_id, page_num=1) => {
    if (broker_id != -1) {
        let json = JSON.stringify({"broker_id": broker_id, "page_num": page_num});

        const res = await fetch('/api/get_paginated_agency_brokers_by_broker_id', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 0
            },
            body: json
        });
        return res.json();
    } else {
        return [];
    }
}

function BrokersListPage(props) {
  let history = useHistory();

  const [broker, setBroker] = useContext(BrokerContext);

  return (
    <>
      <AgencyNavigation current="brokers"/>
      <InfiniteQueryList
          itemName="broker"
          title=""
          queryName="brokers"
          queryArgs={[broker.id]}
          queryFunc={fetchBrokers}
          location={"brokers list"}
          buttonLabel="Invite Broker"
          mixpanelTrack="New broker started"
          mixpanelTimeEvent="Broker form submitted"
          newItemDestination="/agency/invite-broker/"
          bannerMessage="Broker(s) successfully deleted!"
      ></InfiniteQueryList>
    </>
  )
}

export default BrokersListPage;
