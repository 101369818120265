import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory, Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import { BrokerContext } from '../../../components/BrokerContext';
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';

import '../../pages.css';
import '../../Profile.css';

import AgencyNavigation from '../../../components/AgencyNavigation';
import Banner from '../../../components/Banner';
import BrokerProfileForm from '../../../components/BrokerProfileForm';
import FormikInputField from '../../../components/FormikInputField';
import FormikRadioButton from '../../../components/FormikRadioButton';
import InputField from '../../../components/InputField';
import LoadingOverlay from '../../../components/LoadingOverlay';
import StateForm from '../../../components/StateForm';
import WritingNumbers from '../../../components/WritingNumbers';

import mixpanel from 'mixpanel-browser';
import { ENVContext } from '../../../components/ENVContext';
import SetEnvironment from '../../../components/SetEnvironment';

let qs = require('qs');

const fetchAgency = async (key, agency_npn) => {
    console.log("fetchAgency" + agency_npn); 
    if (!agency_npn) {
      return null;
    }
    let json = JSON.stringify({"agency_npn": agency_npn});

    const res = await fetch('/api/get_agency_by_npn', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': 0
        },
        body: json
    });

    return res.json();
}

function AgencyProfilePage(props) {
    let history = useHistory();

    const [ENV, setENV] = useContext(ENVContext)
    const [broker, setBroker] = useContext(BrokerContext);

    const [loading, setLoading] = useState(false);
    const [showBanner, setShowBanner] = useState(false);

    const [firstSubmit, setFirstSubmit] = useState(false);
    const [agencyName, setAgencyName] = useState("");
    const [agencyEmail, setAgencyEmail] = useState("");
    const [agencyPhone, setAgencyPhone] = useState("");
    const [agencyNPN, setAgencyNPN] = useState("");

    const { data: agencyQuery, status: agencyQueryStatus } = useQuery(["agency", broker.agency_npn], fetchAgency);

    function handleFormSubmit(object) {
        console.log(object);


        let data = {
          agency_id: agencyQuery.id,
          name: agencyName,
          email: agencyEmail,
          phone: agencyPhone,
        }

        let json = JSON.stringify(data);

        fetch('/api/update_agency', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: json
        }).then(res => res.json())
        .then(data => {
          setShowBanner(true);
        })

        window.scrollTo(0,0);

        return Promise.resolve("Dummy response to keep the console quiet");
    }

    useEffect(() => {
      if (agencyQuery && agencyQuery.name) {
        console.log(agencyQuery);
        console.log(broker);
        setAgencyName(agencyQuery.name);
        setAgencyEmail(agencyQuery.email);
        setAgencyPhone(agencyQuery.phone);
        setAgencyNPN(agencyQuery.npn);
      }
    }, [agencyQuery]);
    useEffect(() => {
      console.log(agencyName)
    }, [agencyName]);
    useEffect(() => {
      console.log(agencyEmail)
    }, [agencyEmail]);
    useEffect(() => {
      console.log(agencyPhone)
    }, [agencyPhone]);
    useEffect(() => {
      console.log(agencyNPN)
    }, [agencyNPN]);

    return(
      <>
        {showBanner ?
            <Banner message={"Agency profile updated!"}></Banner>
            : <></>
        }
        <AgencyNavigation current="profile"/>

        {agencyQueryStatus === "success" && agencyQuery !== null ?
            <Formik
              initialValues={{
                new_agency_name: agencyQueryStatus == 'success' ? agencyQuery.name : "a",
                agency_email: agencyQueryStatus == 'success' ? agencyQuery.email : "b",
                agency_npn: agencyQueryStatus == 'success' ? agencyQuery.npn : "c",
                agency_phone: agencyQueryStatus == 'success' ? agencyQuery.phone : "d",
              }}
              validationSchema={Yup.object({
                new_agency_name: Yup.string()
                  .required('This field is required'),
                agency_email: Yup.string()
                  .email('Invalid email address')
                  .required('This field is required'),
                agency_npn: Yup.string()
                  .matches(/^[a-zA-Z0-9_]{1,20}/, 'Invalid NPN.')
                  .required('This field is required'),
                agency_phone: Yup.string()
                  .required('This field is required'),
              })}
              onSubmit={(values, actions) => {
                console.log("formik on submit");
                setBroker({...broker, agency_name: agencyName, agency_npn: agencyNPN})
                actions.setSubmitting(false);
                handleFormSubmit(values);
              }}
              enableReinitialize>
              {({ isSubmitting, isValid, dirty, errors, handleSubmit, handleChange, handleBlur }) => (
              <form onSubmit = {handleSubmit}>
                <div className="formArea" style={{left: "2%", padding: "5%"}}>
                  <div className="vertCont">
                    <div className = "horCont">
                      <div className="vertCont" style={{width:"95%", marginRight: "10%"}}>
                        <FormikInputField
                            type="text"
                            name="new_agency_name"
                            labelInfo="*Agency Name"
                            errorLabel="agency name"
                            placeholder="Agency Name"
                            maxLength="50"
                            value={agencyName}
                            onChange={e => {handleChange(e); setAgencyName(e.currentTarget.value);}}></FormikInputField><br/>
                        <FormikInputField
                            type="text"
                            name="agency_email"
                            labelInfo="*Email Address"
                            errorLabel="email address"
                            placeholder="example@email.com"
                            value={agencyEmail}
                            onChange={e => {handleChange(e); setAgencyEmail(e.currentTarget.value);}}></FormikInputField><br/>
                      </div>
                      <div className="vertCont" style={{width:"95%"}}>
                        <FormikInputField
                            type="text"
                            name="agency_npn"
                            labelInfo="*Agency NPN"
                            errorLabel="agency NPN"
                            placeholder="Agency NPN"
                            maxLength="20"
                            value={agencyNPN}
                            readOnly
                            disabled></FormikInputField><br/>
                        <FormikInputField
                            type="text"
                            name="agency_phone"
                            labelInfo="*Phone Number"
                            errorLabel="phone number"
                            placeholder="(555) 555-5555"
                            maxLength="11"
                            value={agencyPhone}
                            onChange={e => {handleChange(e); setAgencyPhone(e.currentTarget.value);}}></FormikInputField><br/>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  (isValid || !firstSubmit) &&
                  (agencyQuery && agencyQuery.name) ?
                  <button type="submit" style={{marginLeft: "5%", marginTop: "20px"}} className="buttonStyle" onClick = {() => {setFirstSubmit(true); handleSubmit();}}>
                    Update
                  </button>
                  :
                  <button style={{marginLeft: "5%", marginTop: "20px"}} className="disabledButtonStyle" disabled>
                    Update
                  </button>
                }
              </form>
            )}
            </Formik>
          : <LoadingOverlay />
        }

      </>
    );
}

export default withRouter(AgencyProfilePage);
