import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import ApplicationBox from './ApplicationBox';
import BrokerBox from './BrokerBox';
import ClientBox from './ClientBox';

import './ApplicationBox.css';
import  { printDate } from '../utils.js';

const SelectableEntityBox = (props) => {
    // Expects props passed from Applications page including app, client, and plan info
    const info = props.info;

    let create_date = printDate(new Date(info.auto_create_date.replace(" GMT", "")));

    let dob;
    if (info.dob) {
      dob = printDate(new Date(info.dob.replace(" GMT", "")));
    }

    function handleChange() {
        props.onChange( props.index );
    };

    return(
        <>
          {props.type === "application" ?
            <ApplicationBox
              {...props}
              create_date={create_date}
              handleChange={handleChange}
            ></ApplicationBox>
          : props.type === "client" ?
            <ClientBox
              {...props}
              create_date={create_date}
              dob={dob}
              handleChange={handleChange}
            ></ClientBox>
          : props.type === "broker" ?
            <BrokerBox
              {...props}
              create_date={create_date}
              handleChange={handleChange}
            ></BrokerBox>
          : <></>
          }
        </>
    );
}

export default SelectableEntityBox;
