import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory, Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { BrokerContext } from '../../../components/BrokerContext';
import { PlanContext, ClientContext, ApplicationContext } from '../../../components/PCAContext';

import Sidebar           from '../../../components/Sidebar';
import Headbar           from '../../../components/Headbar';
import SetEnvironment    from '../../../components/SetEnvironment';

import InfiniteQueryList from '../../../components/InfiniteQueryList';
import AgencyNavigation from '../../../components/AgencyNavigation';

import '../../pages.css';
import '../../ClientsPage.css';

const fetchAgencyClients = async (key, broker_id, page_num=1) => {
    if (broker_id != -1) {
        let json = JSON.stringify({"broker_id": broker_id, "page_num": page_num});

        const res = await fetch('/api/get_paginated_clients_by_owner_broker_id', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 0
            },
            body: json
        });

        return res.json();
    } else {
        return [];
    }
}

const fetchAgencyBrokers = async (key, agency_id) => {
    if (agency_id && agency_id != -1) {
        let json = JSON.stringify({"agency_id": agency_id});

        const res = await fetch('/api/get_brokers_by_agency_id', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 0
            },
            body: json
        });

        return res.json();
    } else {
        return [];
    }
}

function AgencyClientsPage() {
    let history = useHistory();
    let { brokerId, brokerName } = useParams();

    const [broker, setBroker] = useContext(BrokerContext);
    const [plan, setPlan] = useContext(PlanContext);
    const [client, setClient] = useContext(ClientContext);
    const [application, setApplication] = useContext(ApplicationContext);

    const {data: agencyBrokers} = useQuery(['agencyBrokers', broker.agency_id], fetchAgencyBrokers, {
      refetchOnWindowFocus: false, refetchOnMount: false,
      refetchOnReconnect: false,
    });

    useEffect(() => {
        // Set PCA context to default
        setPlan({
            id: -1,
            name: '',
            type: '',
            number: '',
            carrier_name: '',
        });

        setClient({
            id: -1,
            first_name: '',
            middle_initial: '',
            last_name: '',
            gender: '',
            title: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            mailing_address: '',
            mailing_city: '',
            mailing_state: '',
            mailing_zip: '',
            dob: '',
            medicare_claim_number: '',
            part_a_start_date: '',
            part_b_start_date: '',
            billing_preference: '',
            bank_name: '',
            bank_routing: '',
            bank_account: '',
        });

        setApplication({
            id: -1,
            status: -1,
            url: '',
            download: '',
            csv: '',
            envelope_id: '',
            access_code: '',
            effective_date: '',
        });
    }, []);

    return(
        <>
          {
            brokerId ?
            <div style={{marginLeft:"30px", marginTop:"15px"}}><Link to={`/agency/brokerdetails/${brokerId}`}>↵ Back to Broker View</Link></div>
            :<></>
          }
          <AgencyNavigation current="clients"/>
          <InfiniteQueryList
              itemName="client"
              title={""}//brokerName ? `Clients for Broker: ${brokerName}` : "Clients for your agency"}
              queryName="clients"
              queryArgs={[broker.id]}
              queryFunc={fetchAgencyClients}
              mixpanelTrack="New from Clients page started"
              newItemDestination="/addclient/"
              itemBoxDestination="agency"
              bannerMessage="Client was successfully added!"
              filterBrokerName={brokerName}
              agencyBrokers = {agencyBrokers}
              filterByBroker
              hideButton
              showTransfer
          ></InfiniteQueryList>
        </>
    );
}

export default withRouter(AgencyClientsPage);
