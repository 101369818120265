import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';

import { ENVContext } from '../components/ENVContext';
import { BrokerContext } from '../components/BrokerContext';

import mixpanel from 'mixpanel-browser';

import SetEnvironment from '../components/SetEnvironment';
import './login.css'
import './pages.css';


const getUsername = async () => {
    const res = await fetch('/auth0/get_username', {});
    return res.json();
}

function Login() {

    let history = useHistory();

    const [ENV, setENV] = useContext(ENVContext);
    const [broker, setBroker] = useContext(BrokerContext);
    const [username, setUsername] = useState();

    const { data, status } = useQuery(['username'], getUsername);

    useEffect(() => {
        if (status === 'success') {
            if (data != '') {
                let obj = {
                    broker_email: data,
                }
                let json = JSON.stringify(obj);
                fetch('/api/get_broker_by_email', {
                    method: 'POST',
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: json,
                }).then(res => res.json())
                .then(info => {
                    if (info == "No broker") {
                        mixpanel.init(ENV.MIXPANEL_TOKEN);
                        mixpanel.track("Sent to registration")
                        setBroker({...broker, email: data});
                        history.push('/register/agency-type');
                    } else {
                        mixpanel.init(ENV.MIXPANEL_TOKEN);
                        mixpanel.track("User logged into app");
                        mixpanel.identify(info.id);
                        setBroker({
                            id: info.id,
                            first_name: info.first_name,
                            last_name: info.last_name,
                            address: info.address,
                            city: info.city,
                            state: info.state,
                            zip: info.zip,
                            email: info.email,
                            agency_name: info.agency_name,
                            agency_npn: info.agency_npn,
                            broker_npn: info.broker_npn,
                            phone: info.phone,
                            status: info.status,
                            signing_url: info.signing_url,
                            ds_access_code: info.ds_access_code,
                            app_edit_allowed: info.app_edit_allowed || false,
                            role: info.role,
                            agency_id: info.agency_id,
                            live_session_room_id: info.live_session_room_id,
                            live_session_create_date: info.live_session_create_date,
                            live_session_client_name: info.live_session_client_name,
                        });
                        if (info.status === 0) {
                            history.push('/brokersign');
                        } else {
                            history.push('/dashboard');
                        }
                    }
                })
            }
        }
    }, [status])

    function auth0Login() {
        mixpanel.init(ENV.MIXPANEL_TOKEN);
        mixpanel.track("User sent to Auth0 login");
        window.open(window.location + 'auth0/login', "_self");
    }

    function auth0Signup() {
        mixpanel.init(ENV.MIXPANEL_TOKEN);
        mixpanel.track("User sent to Auth0 signup");
        window.open(window.location + 'auth0/signup', "_self");
    }

    return(
        <div className = "loginBackground">
            <div className = "loginContainer">
                <div style = {{marginTop: "40px"}}>
                    <img src = 'https://planprovide-resources.s3-us-west-1.amazonaws.com/PlanProvide-logo-black.png'  alt = "black logo" width = "400px" height = "120px"></img>
                </div>
                <p>Welcome back!</p>
                <button style = {{marginTop: "10px", width: "180px", height: "100px"}} className = "buttonStyle" onClick = {auth0Login}>Sign in</button>
                <div style = {{marginTop: "95px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <p>Don't have an account?</p>
                    <button style = {{marginTop: "20px", marginBottom: "40px"}} className = "buttonStyle" id = "signUp" onClick = {auth0Signup}>Sign Up</button>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Login);
