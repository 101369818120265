import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams, Link } from 'react-router-dom';

import { BrokerContext } from '../../../components/BrokerContext';

import InfiniteQueryList from '../../../components/InfiniteQueryList';
import AgencyNavigation from '../../../components/AgencyNavigation';

// Fetch all applications by broker id
const fetchApplicationsByAgencyOwner = async (key, broker_id, page_num=1) => {
    if (broker_id != -1) {
        let json = JSON.stringify({"broker_id": broker_id, "page_num": page_num});

        const res = await fetch('/api/get_paginated_applications_by_owner_broker_id', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 0
            },
            body: json
        });
        return res.json();
    } else {
        return [];
    }
}

function AgencyApplicationsListPage(props) {
  let history = useHistory();
  let { brokerId, brokerName } = useParams();

  const [broker, setBroker] = useContext(BrokerContext);

  return (
    <>
      {
        brokerId ?
        <div style={{marginLeft:"30px", marginTop:"15px"}}><Link to={`/agency/brokerdetails/${brokerId}`}>↵ Back to Broker View</Link></div>
        :<></>
      }
      <AgencyNavigation current="applications"/>
      <InfiniteQueryList
          itemName="application"
          title={""}//brokerName ? `Applications for Broker: ${brokerName}` : "Applications for your agency"}
          filterBrokerName={brokerName}
          queryName="AgencyApplications"
          queryArgs={[broker.id]}
          queryFunc={fetchApplicationsByAgencyOwner}
          location="agency applications list"
          mixpanelTrack="New application started"
          mixpanelTimeEvent="Client form submitted"
          newItemDestination="/applications/pick-client/"
          bannerMessage="Application(s) successfully deleted!"
          isApplicationHistory={props.isApplicationHistory}
          hideButton
          showBrokerOwner
          filterByBroker
      ></InfiniteQueryList>
    </>
  )
}

export default AgencyApplicationsListPage;
