import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';

import { PlanContext, ClientContext, ApplicationContext } from '../../../components/PCAContext';
import { BrokerContext } from '../../../components/BrokerContext';


import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';

import './coverageQuestions.css';

import CheckBox from '../../../components/CheckBox';
import InputField from '../../../components/InputField';
import DateForm from '../../../components/DateForm';
import FormikRadioButton from '../../../components/FormikRadioButton';
import StateForm from '../../../components/StateForm';
import Banner from '../../../components/Banner';
import LoadingOverlay from '../../../components/LoadingOverlay';
import FormikInputField from '../../../components/FormikInputField';
import FormikCheckBox from '../../../components/FormikCheckBox';


import mixpanel from 'mixpanel-browser';

import { handleDate, printRFC3339Date } from '../../../utils.js';

function CoverageQuestions(props) {
  let history = useHistory();

  const [application, setApplication] = useContext(ApplicationContext);
  const [plan, setPlan] = useContext(PlanContext);
  const [client, setClient] = useContext(ClientContext);
  const [broker, setBroker] = useContext(BrokerContext);

  let { app_id } = useParams();

  const [loading, setLoading] = useState(false);

  const [otherCoverage, setOtherCoverage] = useState(application.other_coverage || false);
  const [policyType, setPolicyType] = useState(application.policy_type || '');
  const [guaranteedIssue, setGuaranteedIssue] = useState(application.guaranteed_issue || false);
  const [guaranteedIssueValue, setGuaranteedIssueValue] = useState("65_or_first_time")
  const [householdDiscount, setHouseholdDiscount] = useState(application.household_discount || false);
  const [pdp, setPdp] = useState(application.pdp || false);
  const [showHouseholdDiscountQuestion, setShowHouseholdDiscountQuestion] = useState(false);

  useEffect(() => {
    console.log(application);
    if (application.plan_quote_id && application.plan_quote_id !== null) {
      fetch('/api/get_plan_quote_by_id', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "plan_quote_id": application.plan_quote_id,
        }),
        referrer: '',
      }
    ).then(res => res.json())
    .then(data => {
      console.log("DATAKK")
      console.log(data)
      if (data.household_discount != "NaN") {
        setShowHouseholdDiscountQuestion(true);
      }
    })
  }
  }, [])

  useEffect(() => {
    if (turning65OrFirstTimePartB() === true) {
      console.log("It was true!")
      setGuaranteedIssue(true);
    }
    else {
      console.log("it was false...")
    }
  }, [client]);

  useEffect(() => {
    console.log("APPLICATION");
    console.log(application);
    setGuaranteedIssue(application.guaranteed_issue);
  }, [application]);

  useEffect(() => {
    console.log("COV Q PROPS");
    console.log(props);
  }, [props])

  let handlePolicyChange = event => {
      setPolicyType(event.target.value);
  }

  function turning65OrFirstTimePartB () {
    console.log(client);
    let clientDOB = new Date(Date.parse(client.dob));
    let client65Bday = new Date(clientDOB);
    client65Bday.setFullYear( clientDOB.getFullYear() + 65);
    console.log("client65bday")
    console.log(client65Bday);

    let partBStartDate = new Date(Date.parse(client.part_b_start_date));
    console.log("partBStartDate");
    console.log(partBStartDate);

    let effectiveDate = new Date(Date.parse(application.effective_date));
    console.log("effective_date")
    console.log(effectiveDate);

    let effectiveDateMinus6Mo = new Date(effectiveDate);
    effectiveDateMinus6Mo.setMonth(effectiveDate.getMonth() - 6);
    console.log("effectiveDateMinus6Mo")
    console.log(effectiveDateMinus6Mo);

    return (
            (effectiveDateMinus6Mo < client65Bday && client65Bday < effectiveDate)
            || (client65Bday.getMonth() === effectiveDate.getMonth() && client65Bday.getYear() === effectiveDate.getYear())
            || (client65Bday.getDay() === 1 && client65Bday.getMonth() - 1 === effectiveDate.getMonth()) //or your birthday is the 1st and its also one month after the ED
            || (effectiveDateMinus6Mo < partBStartDate && partBStartDate < effectiveDate)
            || (partBStartDate.getMonth() === effectiveDate.getMonth() && partBStartDate.getYear() === effectiveDate.getYear())
            || (partBStartDate.getDay() === 1 && partBStartDate.getMonth() - 1 === effectiveDate.getMonth())
        )
  }

  function doSubmit(object) {
      console.log("Start submission");
      setLoading(true);
      console.log(object);

      if ("other_start" in object) {
          object["other_start"] = new Date(object.other_start);
      }
      if ("other_end" in object && object["other_end"] != "-") {
          object["other_end"] = new Date(object.other_end);
      }

      object["id"] = application.id;

      if (!householdDiscount) {
        object["household_discount"] = false;
        object["household_discount_relationship"] = null;
        object["household_discount_first_name"] = null;
        object["household_discount_middle_initial"] = null;
        object["household_discount_last_name"] = null;
        object["household_discount_street_address"] = null;
        object["household_discount_city"] = null;
        object["household_discount_state"] = null;
        object["household_discount_zip"] = null;
        object["household_discount_policy_num"] = null;
        object["household_discount_mbi_num"] = null;
        object["household_discount_email"] = null;
      }
      if (!otherCoverage) {
        object["other_coverage"] = false
        object["drop_supplement"] = false
        object["ending_reason"] = null
        object["first_time"] = false
        object["medi_cal_other_benefits"] = false
        object["medi_cal_pay_premiums"] = false
        object["other_end"] = null
        object["other_start"] = null
        object["policy_number"] = null
        object["policy_type"] = null
        object["previous_company"] = null
      }
      if (!guaranteedIssue) {
        object["gi_other"] = null
        object["gi_situation"] = null
        object["guaranteed_issue"] = false
      }
      if (!pdp) {
        object["pdp"] = false
        object["pdp_company"] = null
        object["pdp_effective_date"] = null
      }


      console.log("Application form cleaned");

      console.log("Application request sent");
      let json = JSON.stringify(object);
      console.log(props.controller);
      fetch('/api/update_application_in_envelope', {
          method: 'POST',
          headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          },
          body: json,
          signal: props.controller.controller.signal,
      }).then(res => res.json())
      .then(data => {
          if (data["status"] === "error") {
              let errorMessage = data["errorMessage"];
              history.push(`/error/${errorMessage}`);
          } else {
              console.log("Application updated success");
              if (plan.type === "HMO" || plan.type === "Local PPO" || plan.type === "Medicare-Medicaid Plan HMO" || plan.type === "HMOPOS" || plan.type === "National PACE" || plan.type === "SNP") {
                history.push(`/applications/health-underwriting-provider/${application.id}`)
              }
              else if (guaranteedIssue === true) {
                history.push(`/applications/ancillary-coverage/${application.id}`);
              }
              else {
                history.push(`/applications/health-underwriting-questions/${application.id}`);
              }
          }
      });
  }

  return (
    <>
      {
        loading
        ? <LoadingOverlay></LoadingOverlay>
        : <div style = {{display: "none"}}></div>
      }
      <div>
        <div className = "mainArea">
          <div className = "mainTitle">
              <div className = "titleInfo">
              <h1 className = "titleName">Coverage Questions</h1>
              <p className = "titleDetails">Questions to determine underwriting and guaranteed issue rights. Check the box if the answer is Yes.</p>
              </div>
          </div>
          <Formik
              initialValues={{
                other_coverage: application.other_coverage || false,
                policy_type: application.policy_type || '',
                other_start: printRFC3339Date(application.other_start) || '',
                other_end: printRFC3339Date(application.other_end) || '',
                previous_company: application.previous_company || '',
                policy_number: application.policy_number || '',
                ending_reason: application.ending_reason || '',
                first_time: application.first_time || false,
                drop_supplement: application.drop_supplement || false,
                medi_cal_pay_premiums: application.medi_cal_pay_premiums || false,
                medi_cal_other_benefits: application.medi_cal_other_benefits || false,

                guaranteed_issue: application.guaranteedIssue !== null ? application.guaranteedIssue : turning65OrFirstTimePartB(),
                gi_situation: application.gi_situation || '65_or_first_time',
                gi_other: application.gi_other || '',

                household_discount: application.household_discount || false,
                household_discount_relationship: application.household_discount_relationship || '',
                household_discount_first_name: application.household_discount_first_name || '',
                household_discount_middle_initial: application.household_discount_middle_initial || '',
                household_discount_last_name: application.household_discount_last_name || '',
                household_discount_street_address: client.address || '',
                household_discount_city: client.city || '',
                household_discount_state: client.state || '',
                household_discount_zip: client.zip || '',
                household_discount_policy_num: application.household_discount_policy_num || '',
                household_discount_mbi_num: application.household_discount_mbi_num || '',
                household_discount_email: application.household_discount_email || '',

                pdp: application.pdp || false,
                pdp_company: application.pdp_company || '',
                pdp_effective_date: printRFC3339Date(application.effective_date) || '',
              }}
              validationSchema={Yup.object({
                gi_other: Yup.string()
                  .matches(/\d{1,2}/, 'Invalid input.')
              })}
              onSubmit={(values, actions) => {
                console.log("SUBMIT!");
                doSubmit(values);
                actions.setSubmitting(false);
              }}
              enableReinitialize
          >
            {({ isSubmitting, isValid, dirty, handleSubmit, handleChange }) => (
              <form className='clientForm' onSubmit={handleSubmit}>
                {plan.type === "1" ? <>
                  <FormikCheckBox name = "other_coverage"
                            labelInfo = "Did you have coverage from any health plan other than Original Medicare within the past 63 days?"
                            checked = {otherCoverage}
                            onChange = {setOtherCoverage}
                            formikHandleChange = {handleChange}></FormikCheckBox>
                  {
                      otherCoverage ?
                          <div className = "indentedQ">
                              <div>
                                  <label htmlFor = "policy_type">*Policy Type</label>
                                  <br></br>
                                  <Field name="policy_type"
                                         as="select"
                                         style={{width: '170px'}}
                                         onChange={(evt) => { handlePolicyChange(evt); handleChange(evt); }}
                                         value={policyType}
                                         required>
                                      <option disabled value=''>Select</option>
                                      <option value = "Individual">Individual</option>
                                      <option value = "Medicare Advantage">Medicare Advantage</option>
                                      <option value = "Medicare Supplement">Medicare Supplement</option>
                                      <option value = "Group">Group</option>
                                      <option value = "Medi-Cal">Medi-Cal</option>
                                  </Field>
                                  <div className="errorLabel">
                                    <ErrorMessage name="policy_type" component="div" />
                                  </div>
                              </div>
                              {
                                  policyType !== "Medi-Cal" && policyType !== "" ?
                                  <>
                                      <div className = "horCont">
                                          <div className = "half">
                                              <FormikInputField
                                                  type="date"
                                                  name="other_start"
                                                  labelInfo="*Start Date"
                                                  required>
                                              </FormikInputField>
                                          </div>
                                          <div className = "half">
                                              <FormikInputField
                                                  type="date"
                                                  name="other_end"
                                                  labelInfo="End Date (Leave blank if no end date or unknown)">
                                              </FormikInputField>
                                          </div>
                                      </div>
                                      <div className = "horCont">
                                          <div className = "half">
                                              <FormikInputField
                                                  type="text"
                                                  name="previous_company"
                                                  labelInfo="*Previous Health Insurance Company"
                                                  maxLength = "50"
                                                  required>
                                              </FormikInputField>
                                          </div>
                                          <div className = "half">
                                              <FormikInputField
                                                  type="text"
                                                  name="policy_number"
                                                  labelInfo="*Policy Number"
                                                  maxLength = "20"
                                                  required>
                                              </FormikInputField>
                                          </div>
                                      </div>
                                      <div className = "horCont">
                                          <FormikInputField
                                              type="text"
                                              name="ending_reason"
                                              labelInfo="If ending, indicate reason why your coverage is ending:"
                                              maxLength = "50">
                                          </FormikInputField>
                                      </div>
                                  </>
                                  : <></>
                              }
                              {
                                  policyType === "Medicare Advantage" || policyType === "Medicare Supplement" ?
                                  <>
                                      <FormikCheckBox
                                        name = "first_time"
                                        labelInfo = "Was this your first time in this type of Medicare plan?"
                                        formikHandleChange={handleChange}></FormikCheckBox>
                                  </>
                                  : <></>
                              }
                              {
                                  policyType === "Medicare Supplement" ?
                                  <>
                                      <FormikCheckBox
                                        name = "drop_supplement"
                                        labelInfo = "Did you drop a Medicare Supplement policy to enroll in the Medicare plan?"
                                        formikHandleChange={handleChange}></FormikCheckBox>
                                  </>
                                  : <></>
                              }
                              {
                                  policyType === "Medi-Cal" ?
                                  <>
                                      <FormikCheckBox
                                        name = "medi_cal_pay_premiums"
                                        labelInfo = "Will Medi-Cal pay your premiums for this Medicare Supplement policy?"
                                        formikHandleChange={handleChange}></FormikCheckBox>
                                      <FormikCheckBox
                                        name = "medi_cal_other_benefits"
                                        labelInfo = "Do you receive any benefits from Medi-Cal other than payments toward your Medicare Part B premium?"
                                        formikHandleChange={handleChange}></FormikCheckBox>
                                  </>
                                  : <></>
                              }
                          </div>
                      : <></>
                  }
                </>: <></>}
                <FormikCheckBox
                    name = "guaranteed_issue"
                    onChange={setGuaranteedIssue}
                    formikHandleChange={handleChange}
                    checked={guaranteedIssue}
                    labelInfo={"Do you qualify for a Guaranteed Issue situation?"}></FormikCheckBox>
                {
                    guaranteedIssue ?
                    <>
                      <div className = "indentedQ">
                          <div className = "vertCont">
                              <FormikRadioButton
                                  name = "gi_situation"
                                  value = "65_or_first_time"
                                  labelInfo = "Turning age 65 OR first time enrolling in Medicare Part B"
                                  checked={guaranteedIssueValue === "65_or_first_time"}
                                  defaultChecked={true}
                                  onChange={e => {
                                    setGuaranteedIssueValue("65_or_first_time");
                                  }}
                                  formikHandleChange={handleChange}
                                  ></FormikRadioButton>

                              <FormikRadioButton
                                  name = "gi_situation"
                                  value = "employer_terminating"
                                  labelInfo = "You or your spouse’s employer-sponsored health plan is terminating"
                                  checked={guaranteedIssueValue === "employer_terminating"}
                                  onChange={e => {
                                    setGuaranteedIssueValue("employer_terminating");
                                  }}
                                  formikHandleChange={handleChange}></FormikRadioButton>
                              <FormikRadioButton
                                  name = "gi_situation"
                                  value = "advantage_terminating"
                                  labelInfo = "Your Medicare Advantage plan is being terminated"
                                  checked={guaranteedIssueValue === "advantage_terminating"}
                                  onChange={e => {
                                    setGuaranteedIssueValue("advantage_terminating");
                                  }}
                                  formikHandleChange={handleChange}></FormikRadioButton>
                              <FormikRadioButton
                                  name = "gi_situation"
                                  value = "move_out"
                                  labelInfo = "You have moved out of the Medicare Advantage service area"
                                  checked={guaranteedIssueValue === "move_out"}
                                  onChange={e => {
                                    setGuaranteedIssueValue("move_out");
                                  }}
                                  formikHandleChange={handleChange}></FormikRadioButton>
                              {plan.type === "HMO" || plan.type === "Local PPO" || plan.type === "Medicare-Medicaid Plan HMO" || plan.type === "HMOPOS" || plan.type === "National PACE" || plan.type === "SNP" ? <>
                                <FormikRadioButton
                                    name = "gi_situation"
                                    value = "open_enrollment"
                                    labelInfo = "You are enrolling during the Open Enrollment Period"
                                    checked={guaranteedIssueValue === "open_enrollment"}
                                    onChange={e => {
                                      setGuaranteedIssueValue("open_enrollment");
                                    }}
                                    formikHandleChange={handleChange}></FormikRadioButton>
                                <FormikRadioButton
                                    name = "gi_situation"
                                    value = "medicare_and_medicaid"
                                    labelInfo = "You have both Medicare and Medicaid, or get Extra Help for Medicare prescription drug coverage with no change"
                                    checked={guaranteedIssueValue === "medicare_and_medicaid"}
                                    onChange={e => {
                                      setGuaranteedIssueValue("medicare_and_medicaid");
                                    }}
                                    formikHandleChange={handleChange}></FormikRadioButton>
                                <FormikRadioButton
                                    name = "gi_situation"
                                    value = "emergency_disaster"
                                    labelInfo = "You were affected by an emergency or major disaster and were unable to make my enrollment request because of the disaster"
                                    checked={guaranteedIssueValue === "emergency_disaster"}
                                    onChange={e => {
                                      setGuaranteedIssueValue("emergency_disaster");
                                    }}
                                    formikHandleChange={handleChange}></FormikRadioButton>
                                <FormikRadioButton
                                    name = "gi_situation"
                                    value = "ma_open_enrollment"
                                    labelInfo = "You are enrolled in an MA plan and want to make a change during the Medicare Advantage Open Enrollment Period"
                                    checked={guaranteedIssueValue === "ma_open_enrollment"}
                                    onChange={e => {
                                      setGuaranteedIssueValue("ma_open_enrollment");
                                    }}
                                    formikHandleChange={handleChange}></FormikRadioButton>
                                </> : <></>
                              }
                              <FormikRadioButton
                                  name = "gi_situation"
                                  value = "other"
                                  labelInfo = "Other: provide the situation from Medicare Supplement Guaranteed Issue Guideline"
                                  checked={guaranteedIssueValue === "other"}
                                  defaultChecked={true}
                                  onChange={e => {
                                    setGuaranteedIssueValue("other");
                                  }}
                                  formikHandleChange={handleChange}></FormikRadioButton>
                              <div className = "half" style = {{marginTop: "10px"}}>
                                  <FormikInputField
                                      type="text"
                                      name="gi_other"
                                      labelInfo="Situation Number"
                                      maxLength="2">
                                  </FormikInputField>
                              </div>
                              {/*
                              {
                                giSituationOther
                                ?
                                <div className = "half" style = {{marginTop: "10px"}}>
                                    <InputField name = "gi_other" no_label = {true} required></InputField>
                                </div>
                                :
                                <div className = "half" style = {{marginTop: "10px"}}>
                                    <InputField name = "gi_other" no_label = {true} readOnly></InputField>
                                </div>
                              }
                              */}
                          </div>
                      </div>
                    </>
                    : <></>
                }
                {plan.type === "1" ? <>
                  {showHouseholdDiscountQuestion ?
                    <>
                      <FormikCheckBox name = "household_discount"
                                labelInfo = {`Do you qualify for a Household discount by
                                              residing with another person for the last 12
                                              months with ${plan.carrier_name}?`}
                                checked = {householdDiscount}
                                onChange = {setHouseholdDiscount}
                                formikHandleChange = {handleChange}></FormikCheckBox>
                      {
                          householdDiscount ?
                          <>
                            <div className = "indentedQ">
                                <div className = "vertCont">
                                    <div className="horCont">
                                      <div className="half">
                                        <FormikInputField
                                            type="text"
                                            name="household_discount_relationship"
                                            labelInfo="*Relationship to Applicant"
                                            maxLength = "50"
                                            required>
                                        </FormikInputField>
                                      </div>
                                    </div>
                                    <div className = "horCont">
                                        <div className="horCont half">
                                          <div className="fname">
                                            <FormikInputField
                                                type="text"
                                                name="household_discount_first_name"
                                                labelInfo="*First Name"
                                                maxLength = "50"
                                                required>
                                            </FormikInputField>
                                          </div>
                                          <div className="mi">
                                            <FormikInputField
                                                type="text"
                                                name="household_discount_middle_initial"
                                                labelInfo="*Middle Initial"
                                                maxLength = "1"
                                                required>
                                            </FormikInputField>
                                          </div>
                                        </div>
                                        <div className="half">
                                          <FormikInputField
                                              type="text"
                                              name="household_discount_last_name"
                                              labelInfo="*Last Name"
                                              maxLength = "50"
                                              required>
                                          </FormikInputField>
                                        </div>
                                    </div>
                                    <div className = "horCont">
                                        <div className="half">
                                          <FormikInputField
                                              type="text"
                                              name = "household_discount_street_address"
                                              labelInfo = "*Street address"
                                              errorLabel = "address"
                                              maxLength = "50"
                                              required></FormikInputField>
                                        </div>
                                        <div className="half">
                                          <FormikInputField
                                              type="text"
                                              name = "household_discount_city"
                                              labelInfo = "*City"
                                              errorLabel = "city"
                                              maxLength = "50"
                                              required></FormikInputField>
                                        </div>
                                    </div>
                                    <div className = "horCont">
                                        <div className="half">
                                          <StateForm name = "household_discount_state"
                                                      labelInfo = "*State"
                                                      required></StateForm>
                                        </div>
                                        <div className="half">
                                          <FormikInputField
                                              type="text"
                                              name = "household_discount_zip"
                                              labelInfo = "*Zip code"
                                              errorLabel = "5 digit zip code"
                                              placeholder = "00000"
                                              maxLength = "5"
                                              required></FormikInputField>
                                        </div>
                                    </div>
                                    <div className="horCont">
                                        <div className="half">
                                          <FormikInputField
                                              type="text"
                                              name = "household_discount_policy_num"
                                              labelInfo = "Policy Number/Member ID"
                                              maxLength = "50"></FormikInputField>
                                        </div>
                                        <div className="half">
                                          <FormikInputField
                                              type="text"
                                              name = "household_discount_mbi_num"
                                              labelInfo = "Medicare Beneficiary Identification (MBI) Number"
                                              maxLength = "50"></FormikInputField>
                                        </div>
                                    </div>
                                    { plan.id === 5 ?
                                      <div className="horCont">
                                          <div className="half">
                                            <FormikInputField
                                                type="email"
                                                name = "household_discount_email"
                                                labelInfo = "Email"
                                                maxLength = "100"
                                                required></FormikInputField>
                                          </div>
                                      </div>
                                      : <></>
                                    }
                                </div>
                            </div>
                          </>
                          : <></>
                      }
                    </>
                    : <></>
                  }
                  {
                    plan.id === 3 ?
                    <>
                      <FormikCheckBox name = "pdp"
                                labelInfo = {`Have you purchased a stand-alone Prescription
                                              Drug Plan (PDP)?`}
                                checked = {pdp}
                                onChange = {setPdp}
                                formikHandleChange={handleChange}></FormikCheckBox>
                      {
                        pdp ?
                        <div className = "indentedQ">
                            <div className = "vertCont">
                                <div className="horCont">
                                  <div className="half">
                                    <FormikInputField
                                        type="text"
                                        name = "pdp_company"
                                        labelInfo = "*Company"
                                        maxLength = "50"
                                        required></FormikInputField>
                                  </div>

                                  <div className="half">
                                    <FormikInputField
                                        type="date"
                                        name="pdp_effective_date"
                                        labelInfo="*Effective Date"
                                        maxLength="50"
                                        required></FormikInputField>
                                  </div>
                                </div>
                            </div>
                        </div>
                        : <></>
                      }
                    </>
                    : <></>
                  }
                </> : <></>}
                <div className = "continue">
                    <button type="submit" className = "buttonStyle">Continue</button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CoverageQuestions;
