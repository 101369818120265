import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useHistory, useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import { BrokerContext } from '../../../components/BrokerContext';
import { PlanContext, ClientContext, ApplicationContext } from '../../../components/PCAContext';
import { ENVContext } from '../../../components/ENVContext';


import mixpanel from 'mixpanel-browser';

import './../../ClientFormPage.css';

import InputField from '../../../components/InputField';
import DateForm from '../../../components/DateForm';
import StateForm from '../../../components/StateForm';
import Banner from '../../../components/Banner';
import LoadingOverlay from '../../../components/LoadingOverlay';
import ClientDetailsForm from '../../../components/ClientDetailsForm';

function ClientFormPage(props) {
  let history = useHistory();

  const [ENV, setENV] = useContext(ENVContext);
  const [plan, setPlan] = useContext(PlanContext);
  const [client, setClient] = useContext(ClientContext);
  const [application, setApplication] = useContext(ApplicationContext);
  const [broker, setBroker] = useContext(BrokerContext);

  let { application_id } = useParams();

  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   console.log("welcome to" + props.currentPage)
  //   console.log(application.effective_date);
  //   console.log(application);
  //   if (application.effective_date === undefined) {
  //     setEffectiveDate("Thu Sep 02 2021 00:00:00 GMT-0700 (Pacific Daylight Time)");
  //   }
  // }, [props.currentPage]);

  const handleSubmit = (json) => {
    return new Promise((resolve, reject) => {
      // Uses POST method to send json object of data to Flask
      fetch('/api/client_form_submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "application_id": application.id,
          "data": json
        }),
        referrer: '',
        signal: props.controller.controller.signal,
      }
      ).then(res => {
        console.log("fetch returned")
        // Checks that external services return ok, throws 400-500 errors automatically made by the system
        if (res.ok) {
          console.log("form submit success");
          resolve("success");
          return res.json();
        } else {
          console.log("There are errors");
          if (res.status === 400) {
            resolve("failure");
           }
          else {
            reject("Error");
          }
          return res.json();
      }}
      ).then(data => {
        // Checks for errors thrown by internal services, sends to error page
        if (data["status"] === "error") {
          let errorMessage = data["errorMessage"];
          history.push(`/error/${errorMessage}`);
          reject("Internal error.");
        } else {
          mixpanel.init(ENV.MIXPANEL_TOKEN);
          mixpanel.track("Client form submitted", {client_id: data.client.id, application_id: data.application_id});
          if (client.new_client) {
            mixpanel.init(ENV.MIXPANEL_TOKEN);
            mixpanel.people.increment("no_clients");
          }
          mixpanel.init(ENV.MIXPANEL_TOKEN);
          mixpanel.people.increment("no_apps");

          console.log("Client request sent and return successful");
          // Set client and application info in context
          setClient({...client, ...data.client});
          setApplication({...application,
            id: data["id"],
            status: 0,
            // url: data["signing_url"],
            // envelope_id: data["docusign_envelope_id"],
            // access_code: data["ds_access_code"],
            effective_date: data["effective_date"],
          });

          console.log("Client context set");

          if (plan.type === "1" || plan.type === "HMO" || plan.type === "Local PPO" || plan.type === "Medicare-Medicaid Plan HMO" || plan.type === "HMOPOS" || plan.type === "National PACE" || plan.type === "SNP") { // Med Supp
            history.push(`/applications/coverage-questions/${data["id"]}`);
          }
          else if (plan.type === "HRA") {
            history.push(`/applications/hra-questions/${data["id"]}`);
          }


          else { // Standalone
            setLoading(true);
            let json = JSON.stringify({
              application_id: application.id,
              app_edit_allowed: broker.app_edit_allowed || false,
              ancillary_plan_ids: [],
            });

            fetch('/api/create_docusign_envelope_from_application', {
                method: 'POST',
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: json,
                signal: props.controller.controller.signal,
            }).then(res => res.json())
            .then(data => {
                if (data["status"] === "error") {
                  let errorMessage = data["errorMessage"];
                  history.push(`/error/${errorMessage}`);
                  return;
                }
                console.log("Application create docusign success");
                json = JSON.stringify({
                  destination_email: data.destination_email,
                  application_id: data.id,
                });

                fetch('/api/get_docusign_envelope_signing_link_from_email', {
                    method: 'POST',
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: json,
                    signal: props.controller.controller.signal,
                }).then(res => res.json())
                .then(data => {
                  if (data["status"] === "error") {
                    let errorMessage = data["errorMessage"];
                    history.push(`/error/${errorMessage}`);
                    return;
                  }
                  setApplication({...application,
                    ...data,
                    url: data.signing_url,
                    envelope_id: data.docusign_envelope_id,
                    download: data.aws_pdf_key,
                    csv: data.aws_csv_key,
                    access_code: data.ds_access_code,
                  });
                  history.push(`/applications/ready-sign/${application.id}`);
                });
            });
          }
        }
      });
    })
  };
  return (
    <div>
      {loading ? <LoadingOverlay/> : <></>}
      <ClientDetailsForm
        client_id={client.id}
        title="Client Details"
        subTitle="Details about the individual client."
        pageTitle="Applications"
        currentSidebar="applications"
        buttonLabel="Continue"
        handleSubmit={handleSubmit}
        bannerSuccessMessage="Application being created. Please wait..."
        bannerFailureMessage="A Client with this email already exists."
        canEditEmail={true} // allow editable email
        formStyle={{paddingLeft: "5%"}}
      />
    </div>
  )
}

export default ClientFormPage;
