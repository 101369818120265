 import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { PlanContext, ClientContext, ApplicationContext } from '../../../components/PCAContext';
import { BrokerContext } from '../../../components/BrokerContext';

import './AncillaryPlansList.css';

import InputField from '../../../components/InputField';
import AncillaryPlanBox from '../../../components/AncillaryPlanBox';

import mixpanel from 'mixpanel-browser';
import { ENVContext } from '../../../components/ENVContext';
import SetEnvironment from '../../../components/SetEnvironment';
import LoadingOverlay from '../../../components/LoadingOverlay';

import { formatCurrency } from '../../../utils.js';

const fetchAncillaryPlans = async (key, plan_id, agency_id, type) => {
    if (type === '') {
      return;
    }
    let obj = {
        "plan_id": plan_id,
        "agency_id": agency_id,
        "type": type,
    }

    let json = JSON.stringify(obj);
    const res = await fetch('/api/get_ancillary_plans_by_plan_id', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: json
    });
    return res.json();
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function AncillaryPlansList(props) {
    let history = useHistory();

    const [ENV, setENV] = useContext(ENVContext);
    const [plan, setPlan] = useContext(PlanContext);
    const [client, setClient] = useContext(ClientContext);
    const [application, setApplication] = useContext(ApplicationContext);
    const [broker, setBroker] = useContext(BrokerContext);

    const { data: queryData, status } = useQuery(['ancillaryPlans', plan.id, broker.agency_id, props.type], fetchAncillaryPlans);

    return(
      <div className = "modal">
        <div style = {{left: "5%", top: "300px", width: "90%"}} className = "mainArea modalContent">
            <button style = {{float:"right", width:"50px", height:"50px", fontSize: "1.5em", borderStyle: "none"}}
                    onClick = {() => {props.toggle(false)}}>X</button>
            <div className = "titleInfo" style={{left:"5%"}}>
                <h1 className = "titleName" style={{"marginTop": "30px", marginLeft:"0px"}}>{capitalizeFirstLetter(props.type)} Coverage</h1>
            </div>
            <div style = {{left: "0px", marginLeft: "5%", width: "100%"}} className = "horCont boxLabels">
                <div style = {{width: "90%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <label style = {{width: "35%", marginLeft: "60px"}}>Carrier</label>
                    <label style = {{width: "15%"}}>Plan</label>
                    <label style = {{width: "15%"}}>Monthly Premium</label>
                    <label style = {{width: "30%"}}></label>
                </div>
            </div>
            {
                status === 'error' && (<div style = {{marginTop: "10px"}} className = "vertCont">Error fetching plans</div>)
            }
            {
                status === 'loading' && (<div style={{height:"100px"}}><LoadingOverlay width="90%" height="100px"/></div>)
            }
            {
                status === 'success' && (
                    <div style = {{marginTop: "10px", marginLeft: "5%"}} className = "vertCont">
                        {
                            queryData.length === 0
                            ? <p>There are no plans that match your search</p>
                            : [].concat(queryData)
                              .sort((a, b) => a.premium > b.premium ? 1 : -1)
                              .map((anc_plan, index) => (
                                <AncillaryPlanBox
                                  companyName = {anc_plan.carrier.name}
                                  carrierId = {anc_plan.carrier.id}
                                  planName = {anc_plan.name}
                                  value = {anc_plan.id}
                                  premium = {anc_plan.premium ? anc_plan.premium : "Not available"}
                                  notes = {anc_plan.notes}
                                  setPlan = {
                                    props.type === 'dental' ? props.setDentalPlan :
                                    props.type === 'vision' ? props.setVisionPlan :
                                    null
                                  }
                                  closePopup = {() => {props.toggle(false)}}
                                  key = {index}
                                  index={index}
                                  style = {{width:"90%", flex: "1"}}
                                  carrierWidth = "35%"
                                  planWidth = "15%"
                                  premiumWidth = "15%"
                                  buttonWidth = "15%"
                                    >
                                </AncillaryPlanBox>
                            ))
                        }
                    </div>
                )
            }
            <div style={{height:"30px"}}></div>
        </div>
      </div>
    );
}

export default AncillaryPlansList;
