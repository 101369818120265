import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory, useParams, BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';

import { NavigationContext } from '../components/NavigationContext';
import { BrokerContext } from '../components/BrokerContext';
import { PlanContext, ClientContext, ApplicationContext } from '../components/PCAContext';

import './pages.css';
import './Applications.css';

import Sidebar           from '../components/Sidebar';
import Headbar           from '../components/Headbar';
import SetEnvironment    from '../components/SetEnvironment';
import LoadingOverlay    from '../components/LoadingOverlay';

import AncillaryCoverage        from '../pages/subpages/Applications/AncillaryCoverage';
import AncillaryPlansList       from '../pages/subpages/Applications/AncillaryPlansList';
import ApplicationsListPage     from '../pages/subpages/Applications/ApplicationsListPage';
import ApplicationWritingNumber from '../pages/subpages/Applications/ApplicationWritingNumber';
import PickClient        from '../pages/subpages/Applications/PickClient';
import PickPlan          from '../pages/subpages/Applications/PickPlan';
import PlansList         from  '../pages/subpages/Applications/PlansList';
import CoverageQuestions from '../pages/subpages/Applications/CoverageQuestions';
import ClientFormPage    from '../pages/subpages/Applications/ClientFormPage';
import HealthUnderwritingQuestions from '../pages/subpages/Applications/HealthUnderwritingQuestions';
import HealthUnderwritingProvider from '../pages/subpages/Applications/HealthUnderwritingProvider';
import HealthUnderwritingConditions from '../pages/subpages/Applications/HealthUnderwritingConditions';
import HRAQuestions from '../pages/subpages/Applications/HRAQuestions';
import ReadyToSign       from '../pages/subpages/Applications/ReadyToSign';
import ReadyToSubmit     from '../pages/subpages/Applications/ReadyToSubmit';
import Submitted         from '../pages/subpages/Applications/Submitted';

function Applications(props) {
    let history = useHistory();

    let { subpage, application_id } = useParams();

    const [broker, setBroker] = useContext(BrokerContext);
    const [plan, setPlan] = useContext(PlanContext);
    const [client, setClient] = useContext(ClientContext);
    const [application, setApplication] = useContext(ApplicationContext);

    const [currentPage, setCurrentPage] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
       if (history) {
         return history.listen((location) => {
            props.controller.abort();
            console.log(`You changed the page to: ${location.pathname}`);
            setCurrentPage(location.pathname);
         });
       }
    },[history]);

    useEffect(() => {
      console.log("APP!")
      console.log(application);
    }, [application]);

    useEffect(() => {
        // If application ID is in URL, update the context object
        if (application_id !== null && application_id !== undefined && application_id != '-1' && application_id != 'undefined' && ( !application.id || application.id !== parseInt(application_id))) {
          setLoading(true);
          let json = JSON.stringify(application_id);
          fetch('/api/get_application_by_id', {
              method: 'POST',
              headers : {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
              },
              body: json
          }).then(res => res.json())
          .then(app_data => {
                if (app_data["status"] === "error") {
                  let errorMessage = app_data["errorMessage"];
                  history.push(`/error/${errorMessage}`)
                } else {
                    setApplication({
                        ...app_data,
                        url: app_data.signing_url,
                        envelope_id: app_data.docusign_envelope_id,
                        download: app_data.aws_pdf_key,
                        csv: app_data.aws_csv_key,
                        access_code: app_data.ds_access_code,
                    });
                    if (app_data.plan_id) {
                      let plan_json = JSON.stringify(app_data.plan_id);
                      fetch('/api/get_plan_by_id', {
                          method: 'POST',
                          headers : {
                              'Content-Type': 'application/json',
                              'Accept': 'application/json'
                          },
                          body: plan_json
                      }).then(res => res.json())
                        .then(plan_data => {
                            if (plan_data["status"] === "error") {
                                let errorMessage = plan_data["errorMessage"];
                                history.push(`/error/${errorMessage}`)
                            } else {
                                setPlan({
                                    id: plan_data.plan.id,
                                    name: plan_data.plan.name,
                                    type: plan_data.plan.type,
                                    number: plan_data.plan.plan_number,
                                    carrier_name: plan_data.carrier.name,
                                    carrier_fax: plan_data.carrier.fax,
                                    carrier_id: plan_data.carrier.id,
                                });
                                if (app_data.status !== null) {
                                  setLoading(false);
                                  if (app_data.status === 0 && broker.id && broker.id !== -1) {
                                    let json = JSON.stringify({broker_id: broker.id});
                                    fetch('/api/get_broker_writing_numbers', {
                                        method: 'POST',
                                        headers : {
                                            'Content-Type': 'application/json',
                                            'Accept': 'application/json'
                                        },
                                        body: json,
                                    }).then(res => res.json())
                                    .then(wriNumData => {
                                      if (wriNumData)
                                      {
                                        let requireWritingNum = true;
                                        for (let w of wriNumData) {
                                          if (w.carrier_id === plan_data.carrier.id) {
                                            if (w.agent_number && w.agent_number !== "") {
                                              requireWritingNum = false;
                                            }
                                          }
                                        }
                                        // if (requireWritingNum) {
                                        //   setLoading(false);
                                        //   history.push(`/applications/writing-number/`);
                                        // }
                                        // else {
                                        //   setLoading(false);
                                        //   history.push(`/applications/client-form-page/${app_data.id}`);
                                        // }
                                      }
                                    });
                                  }
                                  // else if (app_data.status === 1 ||
                                  //          app_data.status === 2 ||
                                  //          app_data.status === 3) {
                                  //   setLoading(false);
                                  //   history.push(`/applications/ready-sign/${app_data.id}`)
                                  // }
                                  // else if (app_data.status === 4) {
                                  //   setLoading(false);
                                  //   history.push(`/applications/ready-submit/${app_data.id}`)
                                  // }
                                  // else if (app_data.status === 5) {
                                  //   setLoading(false);
                                  //   history.push(`/applications/submitted/${app_data.id}`)
                                  // }
                                  // else {
                                  //   setLoading(false);
                                  //   history.push(`/applications/`)
                                  // }
                                  setLoading(false);
                                }
                            }
                        });
                    }
                    if (app_data.client_id) {
                        let client_json = JSON.stringify(app_data.client_id);
                        fetch('/api/get_client_by_id', {
                            method: 'POST',
                            headers : {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            body: client_json
                        }).then(res => res.json())
                        .then(client_data => {
                            if (client_data["status"] === "error") {
                                let errorMessage = client_data["errorMessage"];
                                history.push(`/error/${errorMessage}`)
                            } else {
                                setClient(client_data);
                            }
                        });
                    }
                  }
              });
        }
        else {
          // Set PCA context to default
          if (!plan.id) {
            setPlan({
                id: -1,
            });
          }
          if (!client.id) {
            setClient({
                id: -1,
            });
          }
          if (!application.id) {
            setApplication({
              id: application_id || -1,
              status: -1,
          });
          }

          if (!subpage) {
            console.log("no subpage in URL!");
            setLoading(false);
            history.push(`/applications/applications-list`);
          }
        }
    }, [application_id, subpage]);

    useEffect(() => {
      console.log("APPLICATION PROPS")
      console.log(props);
    }, [props])

    return(
        <>
          <div className = "background">
              <SetEnvironment></SetEnvironment>
              <Sidebar current="applications" controller={props.controller}></Sidebar>
              <div className = "middle container">
                  {loading ? <LoadingOverlay/> : <></>}
                  <Headbar title = "Applications"></Headbar>
                  <Route path={`${props.match.path}/applications-list/`}                  component={ApplicationsListPage}/>
                  <Route path={`${props.match.path}/pick-client`}                         component={PickClient}/>
                  <Route path={`${props.match.path}/pick-plan`}                component={PickPlan}/>
                  <Route path={`${props.match.path}/plans-list`}               render={() => (<PlansList controller={props.controller}/>)}/>
                  <Route path={`${props.match.path}/writing-number/:existing?/:application_id?/:plan_id_1?/:plan_id_2?`} render={() => (<ApplicationWritingNumber controller={props.controller}/>)}/>

                  <Route path={`${props.match.path}/client-form-page/:application_id?/:existing?`}   render={() => (<ClientFormPage controller={props.controller} currentPage={currentPage}/>)}/>

                  <Route path={`${props.match.path}/coverage-questions/:application_id?`} render={() => (<CoverageQuestions controller={props.controller}/>)}/>
                  <Route path={`${props.match.path}/health-underwriting-questions/:application_id?`} render={() => (<HealthUnderwritingQuestions controller={props.controller}/>)}/>
                  <Route path={`${props.match.path}/health-underwriting-provider/:application_id?`} render={() => (<HealthUnderwritingProvider controller={props.controller}/>)}/>
                  <Route path={`${props.match.path}/health-underwriting-conditions/:application_id?`} render={() => (<HealthUnderwritingConditions controller={props.controller}/>)}/>
                  <Route path={`${props.match.path}/hra-questions/:application_id?`} render={() => (<HRAQuestions controller={props.controller}/>)}/>
                  <Route path={`${props.match.path}/ancillary-coverage/:application_id?`} render={() => (<AncillaryCoverage controller={props.controller}/>)}/>
                  <Route path={`${props.match.path}/ready-sign/:application_id?`}         render={() => (<ReadyToSign controller={props.controller}/>)}/>
                  <Route path={`${props.match.path}/ready-submit/:application_id?`}       render={() => (<ReadyToSubmit controller={props.controller}/>)}/>
                  <Route path={`${props.match.path}/submitted/:application_id?`}          component={Submitted}/>                  
              </div>
          </div>
        </>
    );
}

export default withRouter(Applications);
