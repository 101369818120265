import React, { useContext, useEffect } from 'react';

import { useHistory, Link } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

import { ENVContext } from './ENVContext';

import { printDate } from '../utils';

const DashBox = ({app, size}) => {
    let history = useHistory();

    const [ENV, setENV] = useContext(ENVContext);

    let create_date = printDate(new Date(app.auto_create_date.replace(" GMT", "")));

    // Set PCA context when clicked
    function setInfo(info) {
        mixpanel.init(ENV.MIXPANEL_TOKEN);
        mixpanel.track("Access application", {where_from: "dashboard page", application_id: info.id, client_id: info.client.id});

        if (info.status) {
          if (info.status === 0) {
            history.push(`/applications/client-form-page/${info.id}`);
          }
          else if (info.status === 1 ||
                   info.status === 2 ||
                   info.status === 3) {
            history.push(`/applications/ready-sign/${info.id}`);
          }
          else if (info.status === 4) {
            history.push(`/applications/ready-submit/${info.id}`);
          }
          else if (info.status === 5) {
            history.push(`/applications/submitted/${info.id}`);
          }
          else {
            console.log("ERROR WITH APPLICATION: app with status = " + info.status);
          }
        }
        else {
          history.push(`/applications/client-form-page/${info.id}`);
        }
    }

    return(
        <div className = "horCont dashBox" onClick = {() => setInfo(app)}>
            <div style = {{margin: "10px"}}>
                <div className = "vertCont">
                    <div>
                        {app.client.first_name === undefined ? <p></p> :
                          <p style = {{fontWeight: "600"}}>{app.client.first_name + " " + app.client.last_name}</p>
                        }
                    </div>
                    {
                        size === "big"
                        ?
                        <div style = {{display: "flex", marginTop: "5px"}}>
                            <p style = {{color: "#707683"}}>Date created:&nbsp;</p>
                            <p>{create_date}</p>
                        </div>
                        : <></>
                    }
                    <div>
                        {
                            app.status === 0 &&
                            (<p className = "dashStatus">Created</p>)
                        }
                        {
                            app.status === 1 &&
                            (<p className = "dashStatus">Ready to Sign</p>)
                        }
                        {
                            app.status === 2 &&
                            (<p className = "dashStatus">Signing Started</p>)
                        }
                        {
                            app.status === 3 &&
                            (<p className = "dashStatus">Sent for Signing</p>)
                        }
                        {
                            app.status === 4 &&
                            (<p className = "dashStatus">Ready to Submit</p>)
                        }
                        {
                            app.status === 5 &&
                            (<p className = "dashStatus">Completed</p>)
                        }
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    )
}

const DashBoxContainer = ( {apps, status, size} ) => {
    useEffect(() => {
      console.log("DASHBOXCONT")
      console.log(apps)
    }, [apps])
    return(
        <div>
        {
            status === 'success' && (
                <div className = "vertCont">
                    {
                        apps === undefined ? <></> :
                        // Check if there are applications to display
                        apps.length === 0
                        ? <div style = {{marginTop: "10px", marginLeft: "10px"}}><p>To get started, add a <Link to = "/applications/pick-client/" className = "linkStyle">new application</Link>.</p></div>
                        : size === "small" ?
                        apps.slice(0, 5).map((app, index) => (
                            <DashBox app = {app} key = {index} size = {size}></DashBox>
                        ))
                        :
                        apps.map((app, index) => (
                            <DashBox app={app} key={index} size={size}></DashBox>
                        ))
                    }
                </div>
            )
        }
        </div>
    )
}

export default DashBoxContainer;
