import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { ApplicationContext } from '../../../components/PCAContext';
import { BrokerContext } from '../../../components/BrokerContext';

import InfiniteQueryList from '../../../components/InfiniteQueryList';

// Fetch all applications by broker id
const fetchApplications = async (key, broker_id, page_num=1) => {
    console.log("FETCH APPS")
    if (broker_id != -1) {
        let json = JSON.stringify({"broker_id": broker_id, "page_num": page_num});

        const res = await fetch('/api/get_paginated_applications_by_broker_id', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 0,
            },
            body: json
        });

        console.log("AAAAA")
        let x = res.json();
        console.log(x)
        return x;
    } else {
        return [];
    }
}

function ApplicationsListPage(props) {
  let history = useHistory();

  const [broker, setBroker] = useContext(BrokerContext);
  const [application, setApplication] = useContext(ApplicationContext);

  useEffect(() => {
    setApplication({
      id: -1,
      status: -1,
      url: '',
      download: '',
      csv: '',
      envelope_id: '',
      access_code: '',
      effective_date: '',
    })
  }, []);

  return (
    <InfiniteQueryList
        itemName="application"
        title="Applications"
        queryName="applications"
        queryArgs={[broker.id]}
        queryFunc={fetchApplications}
        location="applications list"
        mixpanelTrack="New application started"
        mixpanelTimeEvent="Client form submitted"
        newItemDestination="/applications/pick-client/"
        bannerMessage="Application(s) successfully deleted!"
    ></InfiniteQueryList>
  )
}

export default ApplicationsListPage;
