import React from 'react';
import './RadioButton.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';

function FormikRadioButton(props) {

    const handleChange = (evt) => {
      if (props.onChange) {
        props.onChange(!props.checked);
      }

      if (props.formikHandleChange) {
        props.formikHandleChange(evt);
      }
    }

    return(
        <div className = "radioDiv" style={props.style}>
            <label className="horCont">
                <Field type = "radio"
                       value = {props.value}
                       name = {props.name}
                       checked={props.checked}
                       defaultChecked = {props.checked || props.defaultChecked || ""}
                       style = {{marginRight: "10px"}}
                       onChange = {handleChange}
                />
                <div style={{lineHeight: "20px"}}>
                  {props.labelInfo}
                </div>
            </label>
        </div>
    );
}

export default FormikRadioButton;
