import React from 'react';

import './LoadingOverlay.css';

function LoadingOverlay(props) {

    return(
        <div style = {{marginLeft: "-20px", width: props.width ? props.width : "100%", height: props.height ? props.height : "100%", ...props.style}} className = "loadingOverlay">
            <div className="sk-chase">
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            </div>
      </div>
    )
}

export default LoadingOverlay;