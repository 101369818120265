import React, { useContext, useState, useEffect } from 'react';
import { withRouter, useHistory, useParams, Link } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import { BrokerContext } from '../../../components/BrokerContext';
import { ApplicationContext, PlanContext } from '../../../components/PCAContext';

import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';

import mixpanel from 'mixpanel-browser';

import AncillaryPlansList from './AncillaryPlansList';
import AncillaryPlanBox from '../../../components/AncillaryPlanBox';
import InputField from '../../../components/InputField';
import StateForm from '../../../components/StateForm';
import Banner from '../../../components/Banner';
import SetEnvironment from '../../../components/SetEnvironment';
import LoadingOverlay from '../../../components/LoadingOverlay';
import FormikInputField from '../../../components/FormikInputField';

async function fetchSelectedAncillaryPlans(key, application_id) {
  if (!application_id) {
    return null;
  }
  let json = JSON.stringify({"application_id": application_id});

  const res = await fetch('/api/fetch_selected_ancillary_plans', {
      method: 'POST',
      headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': 0
      },
      body: json,
  });

  return res.json();
}

const AncillaryCoverage = (props) => {
    let history = useHistory();
    const [application, setApplication] = useContext(ApplicationContext);
    const [plan, setPlan] = useContext(PlanContext);
    const [broker, setBroker] = useContext(BrokerContext);

    const [loading, setLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedDentalPlan, setSelectedDentalPlan] = useState(null);
    const [selectedVisionPlan, setSelectedVisionPlan] = useState(null);

    const [ancillaryType, setAncillaryType] = useState('');

    let { application_id } = useParams();

    const { data: ancillaryPlansQuery, status: ancillaryPlansStatus } = useQuery(["ancillaryPlans", application.id], fetchSelectedAncillaryPlans,
      {
        refetchOnWindowFocus: false, refetchOnMount: false,
        refetchOnReconnect: false,
      });

    useEffect(() => {
      if (ancillaryPlansQuery) {
        console.log(ancillaryPlansQuery);
        setSelectedDentalPlan(ancillaryPlansQuery.dental_plan);
        setSelectedVisionPlan(ancillaryPlansQuery.vision_plan);
      }
    }, [ancillaryPlansQuery]);

    useEffect(() => {
        if (application_id !== undefined && application_id !== "undefined" && ( !application.id || (application.id !== parseInt(application_id)) )) {
          console.log("redir")
          console.log(application.id)
          let json = JSON.stringify(application_id);
          fetch('/api/get_application_by_id', {
              method: 'POST',
              headers : {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
              },
              body: json,
              signal: props.controller.controller.signal,
          }).then(res => res.json())
          .then(app_data => {
                if (app_data["status"] === "error") {
                  let errorMessage = app_data["errorMessage"];
                  history.push(`/error/${errorMessage}`)
                } else {
                    console.log("STATUS");
                    console.log(app_data.status);
                    console.log(app_data)

                    setApplication({
                        ...app_data,
                        url: app_data.signing_url,
                        envelope_id: app_data.docusign_envelope_id,
                        download: app_data.aws_pdf_key,
                        csv: app_data.aws_csv_key,
                        access_code: app_data.ds_access_code,
                    });
                    if (app_data.plan_id) {
                      let plan_json = JSON.stringify(app_data.plan_id);
                      fetch('/api/get_plan_by_id', {
                          method: 'POST',
                          headers : {
                              'Content-Type': 'application/json',
                              'Accept': 'application/json'
                          },
                          body: plan_json,
                          signal: props.controller.controller.signal,
                      }).then(res => res.json())
                        .then(plan_data => {
                            if (plan_data["status"] === "error") {
                                let errorMessage = plan_data["errorMessage"];
                                history.push(`/error/${errorMessage}`)
                            } else {
                                setPlan({
                                    id: plan_data.plan.id,
                                    name: plan_data.plan.name,
                                    type: plan_data.plan.type,
                                    number: plan_data.plan.plan_number,
                                    carrier_name: plan_data.carrier.name,
                                    carrier_fax: plan_data.carrier.fax,
                                    carrier_id: plan_data.carrier.id,
                                });
                            }
                        });
                    }
                  }
              });
        }
    }, [application_id]);

    function selectPlan(type) {
        setAncillaryType(type);
        setShowPopup(true);
    }

    function handleSubmit() {
        setLoading(true);
        let json = JSON.stringify({broker_id: broker.id});

        fetch('/api/get_broker_writing_numbers', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: json,
            signal: props.controller.controller.signal,
        }).then(res => res.json())
        .then(wriNumData => {
          let plans = [selectedDentalPlan ? selectedDentalPlan : null, selectedVisionPlan ? selectedVisionPlan : null];
          let plansNeedingWritingNums = []
          for (let [key, p] of Object.entries(plans)) {
            console.log("PLANS")
            console.log(p);
            let needsWritingNum = true;
            if (p === null) {
              continue
            }
            for (let w of wriNumData) {
              if (w.carrier_id === p.carrier_id) {
                if (w.agent_number && w.agent_number !== "") {
                  needsWritingNum = false;
                  break;
                }
              }
            }
            if (needsWritingNum === true) {
              plansNeedingWritingNums.push(p.id);
            }
          }
          console.log(plans);
          console.log(wriNumData);
          console.log("PLANSNEEDEDINGWRITINGNUSM")
          console.log(plansNeedingWritingNums);

          if (plansNeedingWritingNums.length >= 1) {
            json = JSON.stringify({
              application_id: application.id,
              app_edit_allowed: broker.app_edit_allowed || false,
              ancillary_plan_ids: [selectedDentalPlan ? selectedDentalPlan.id : null, selectedVisionPlan ? selectedVisionPlan.id : null],
            });

            fetch('/api/add_ancillary_plans_to_application', {
                method: 'POST',
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: json,
            }).then(res => res.json())
            .then(data => {
              if (plansNeedingWritingNums.length === 1) {
                history.push(`/applications/writing-number/${application.id}/${plansNeedingWritingNums[0]}`);
              }
              else if (plansNeedingWritingNums.length === 2) {
                history.push(`/applications/writing-number/${application.id}/${plansNeedingWritingNums[0]}/${plansNeedingWritingNums[1]}`);
              }
            })
            return;
          }
          else {
            json = JSON.stringify({
              application_id: application.id,
              app_edit_allowed: broker.app_edit_allowed || false,
              ancillary_plan_ids: [selectedDentalPlan ? selectedDentalPlan.id : null, selectedVisionPlan ? selectedVisionPlan.id : null],
            });

            fetch('/api/create_docusign_envelope_from_application', {
                method: 'POST',
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: json,
                signal: props.controller.controller.signal,
            }).then(res => res.json())
            .then(data => {
                if (data["status"] === "error") {
                  let errorMessage = data["errorMessage"];
                  history.push(`/error/${errorMessage}`);
                  return;
                }
                console.log("Application create docusign success");
                json = JSON.stringify({
                  destination_email: data.destination_email,
                  application_id: data.id,
                });

                fetch('/api/get_docusign_envelope_signing_link_from_email', {
                    method: 'POST',
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: json,
                    signal: props.controller.controller.signal,
                }).then(res => res.json())
                .then(data => {
                  if (data["status"] === "error") {
                    let errorMessage = data["errorMessage"];
                    history.push(`/error/${errorMessage}`);
                    return;
                  }
                  setApplication({...application,
                    ...data,
                    url: data.signing_url,
                    envelope_id: data.docusign_envelope_id,
                    download: data.aws_pdf_key,
                    csv: data.aws_csv_key,
                    access_code: data.ds_access_code,
                  });
                  setLoading(true);

                  history.push(`/applications/ready-sign/${application.id}`);

                });
            });
          }

        });
    }

    return(
      <>
        {loading || ancillaryPlansStatus === "loading" ? <LoadingOverlay/> : <></>}
        {showPopup ?
          <AncillaryPlansList
            toggle={setShowPopup}
            setDentalPlan={setSelectedDentalPlan}
            setVisionPlan={setSelectedVisionPlan}
            type={ancillaryType}/>
        : null}
        
        <div className = "mainArea clientForm">
          <div className = "mainTitle">
              <div style = {{left: "5%", width: "100%"}} className = "titleInfo">
                  <h1 className = "titleName">Additional Coverage</h1>
                  <p className = "titleDetails">Choose the additional benefits you would like to add.</p>
              </div>
          </div>
          <div>
              <div className = "vertCont">
                <div className = "vertCont">
                  <b>Dental</b>
                  {
                    selectedDentalPlan ?
                    <>
                      <div style = {{left: "0px", marginLeft: "0px", width: "100%"}} className = "horCont boxLabels">
                          <div style = {{width: "90%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                              <label style = {{width: "35%", marginLeft: "60px"}}>Carrier</label>
                              <label style = {{width: "15%"}}>Plan</label>
                              <label style = {{width: "15%"}}>Monthly Premium</label>
                              <label style = {{width: "30%"}}></label>
                          </div>
                      </div>
                      <AncillaryPlanBox
                        companyName = {selectedDentalPlan.carrier_name}
                        planName = {selectedDentalPlan.name}
                        value = {selectedDentalPlan.id}
                        notes = {selectedDentalPlan.notes}
                        removePlan = {() => {setSelectedDentalPlan(null)}}
                        premium = {selectedDentalPlan.premium ? selectedDentalPlan.premium : "Not available"}
                        style = {{marginLeft: "0%", width:"90%", flex: "1", border: "solid 1px #BDBDBD", boxShadow: "none"}}
                        carrierWidth = "35%"
                        planWidth = "15%"
                        premiumWidth = "15%"
                        buttonWidth = "15%"
                        ></AncillaryPlanBox>
                    </>
                    : <a style={{color: "#EC3796", fontWeight: "600", margin: "0px"}} onClick={() => {selectPlan('dental')}}><u>+ Add Coverage</u></a>
                  }
                </div>
                <br/><br/>
                <div className = "vertCont">
                  <b>Vision</b>
                  {
                    selectedVisionPlan ?
                    <>
                    <div style = {{left: "0px", marginLeft: "0px", width: "100%"}} className = "horCont boxLabels">
                        <div style = {{width: "90%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                              <label style = {{width: "35%", marginLeft: "60px"}}>Carrier</label>
                              <label style = {{width: "15%"}}>Plan</label>
                              <label style = {{width: "15%"}}>Monthly Premium</label>
                              <label style = {{width: "30%"}}></label>
                          </div>
                      </div>
                      <AncillaryPlanBox
                        companyName = {selectedVisionPlan.carrier_name}
                        planName = {selectedVisionPlan.name}
                        value = {selectedVisionPlan.id}
                        notes = {selectedVisionPlan.notes}
                        removePlan = {() => {setSelectedVisionPlan(null)}}
                        premium = {selectedVisionPlan.premium ? selectedVisionPlan.premium : "Not available"}
                        style = {{marginLeft: "0%", width:"90%", flex: "1", border: "solid 1px #BDBDBD", boxShadow: "none"}}
                        carrierWidth = "35%"
                        planWidth = "15%"
                        premiumWidth = "15%"
                        buttonWidth = "15%"
                        ></AncillaryPlanBox>
                    </>
                    : <a style={{color: "#EC3796", fontWeight: "600", margin: "0px"}} onClick={() => {selectPlan('vision')}}><u>+ Add Coverage</u></a>
                  }
                </div>
              </div>
          </div>
          <div style={{marginTop: "15px"}}>
            <button
              onClick={handleSubmit}
              className="buttonStyle">
                Continue
            </button>
          </div>
        </div>
      </>
    );
}

export default AncillaryCoverage;
