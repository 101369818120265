import Application from '../data/application';
import ApplicationEnvelope from '../data/applicationEnvelope';
import Broker from '../data/broker';
import Client from '../data/client';

export const get = async (id, signal) => {
    if (id == -1) {
        return null;
    }

    const res = await fetch('/api/application_envelope/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        signal: signal,
    })
    
    return res.json();
}

export const update = async (data) => {
    fetch('/api/application_envelope/update', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(data),
    }).then(res => res.json());
}