import React, { useEffect, useState, useContext, useRef } from 'react';
import { withRouter, useHistory, useParams, BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';

import { BrokerContext } from '../components/BrokerContext';
import { PlanContext, ClientContext, ApplicationContext } from '../components/PCAContext';

import './pages.css';
import './Applications.css';

import Headbar           from '../components/Headbar';

function StandaloneSalesSessionBrokerStart(props) {
    let history = useHistory();

    return(
      <>
        <div className = "background">
            <div style={{width: "100%"}}>
              <Headbar title = "Meetings"></Headbar>
              <div style={{marginLeft: "2%", width: "96%", backgroundColor: "white", borderRadius: "5px", marginTop: "10px"}}>
                <div style={{padding: "5%"}}>
                  <h2>Welcome</h2>
                  <div className="horCont" style={{width: "40%"}}>
                    <button className="buttonStyle" style={{ marginTop: "10px" }} onClick={() => {
                      window.open(window.location.origin + '/auth0/standalone_login', "_self");
                    }}>Login</button>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </>
    );
}

export default withRouter(StandaloneSalesSessionBrokerStart);
