import React, { useState, createContext } from 'react';

export const PlanContext = createContext();
export const ClientContext = createContext();
export const ApplicationContext = createContext();


export const PlanClientApplicationProvider = props => {
    const [plan, setPlan] = useState({
        id: -1,
        name: '',
        type: '',
        number: '',
        carrier_name: '',
    });
    
    const [client, setClient] = useState({
        id: -1,
        first_name: '',
        middle_initial: '',
        last_name: '',
        title: '',
        gender: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        same_address: false,
        mailing_address: '',
        mailing_city: '',
        mailing_state: '',
        mailing_zip: '',
        dob: '',
        medicare_claim_number: '',
        part_a_start_date: '',
        part_b_start_date: '',
        billing_preference: '',
        bank_name: '',
        bank_routing: '',
        bank_account: '',
    });

    const [application, setApplication] = useState({
        id: -1,
        status: -1,
        url: '',
        download: '',
        envelope_id: '',
        csv: '',
        access_code: '',
        effective_date: '',
    });

    return(
        <PlanContext.Provider value = {[plan, setPlan]}>
            <ClientContext.Provider value = {[client, setClient]}>
                <ApplicationContext.Provider value = {[application, setApplication]}>
                    {props.children}
                </ApplicationContext.Provider>
            </ClientContext.Provider>
        </PlanContext.Provider>
    );
}