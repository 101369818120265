import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory, Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import { BrokerContext } from '../components/BrokerContext';

import './pages.css';
import './Profile.css';

import Banner from '../components/Banner';
import WritingNumbers from '../components/WritingNumbers';

import * as AgencyServices from '../domain/agency';

function RegisterAddWritingNums(props) {
    const [broker, setBroker] = useContext(BrokerContext);

    const [loading, setLoading] = useState(false);
    const [showBanner, setShowBanner] = useState(false);

    const { data: agencyQuery, status: agencyQueryStatus } = useQuery(
        ['agency', props.agencyNPN],
        async (key, npn) => {
            console.log("get agency", npn);
            let res = AgencyServices.get(null, npn, props.controller.controller.signal);
            console.log(res);
            return res;
        }
    );

    useEffect(() => {
        console.log("agencyQuery");
        console.log(agencyQuery);
    }, [agencyQuery]);

    return(
      <>
        <div className = "mainTitle">
            <div className = "titleInfo">
                <h1 className = "titleName">Add your insurance carriers</h1>
                <p className = "titleDetails">Add the writing numbers that will be applied to your applications.</p>
            </div>
        </div>
        {showBanner ?
            <Banner message={"Profile created!"}></Banner>
            : <></>
        }
        <div style={{marginLeft: "3%", width: "95%"}}>
            <WritingNumbers
                broker={broker}
                buttonLabel="Next"
                agency_id={agencyQuery !== undefined && agencyQuery.error === undefined ? agencyQuery.id : broker.agency_id}
                type={props.agentType === "new" ? "agency" : undefined}
                controller={props.controller}
                setWritingNumbers={props.setWritingNumbers}
                showBothColumns={props.agentType === "new" ? true : undefined}
                isRegistration
            />
        </div>
      </>
    );
}

export default withRouter(RegisterAddWritingNums);
