import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import './headbar.css'

import mixpanel from 'mixpanel-browser';
import { ENVContext } from './ENVContext';

function Headbar(props) {

  const [ENV, setENV] = useContext(ENVContext);

    return (
        <div className = "topBar" style={props.style}>
          <div style={{color: "#90A0B7", paddingLeft: "40px"}}></div>
        </div>
    );
}

export default Headbar;
