import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import { PlanContext, ClientContext, ApplicationContext } from '../../../components/PCAContext';
import { BrokerContext } from '../../../components/BrokerContext';

import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';

import './coverageQuestions.css';

import CheckBox from '../../../components/CheckBox';
import InputField from '../../../components/InputField';
import DateForm from '../../../components/DateForm';
import FormikRadioButton from '../../../components/FormikRadioButton';
import StateForm from '../../../components/StateForm';
import Banner from '../../../components/Banner';
import LoadingOverlay from '../../../components/LoadingOverlay';
import FormikInputField from '../../../components/FormikInputField';
import FormikCheckBox from '../../../components/FormikCheckBox';

import mixpanel from 'mixpanel-browser';

import { handleDate, printRFC3339Date } from '../../../utils.js';

async function fetchUnderwritingQuestions(key, carrier_id) {
  if (!carrier_id) {
    return null;
  }
  let json = JSON.stringify({"carrier_id": carrier_id});

  const res = await fetch('/api/get_underwriting_questions_by_carrier_id', {
      method: 'POST',
      headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': 0
      },
      body: json,
  });

  return res.json();
}

function HealthUnderwritingQuestions(props) {
  let history = useHistory();

  const [application, setApplication] = useContext(ApplicationContext);
  const [plan, setPlan] = useContext(PlanContext);
  const [client, setClient] = useContext(ClientContext);
  const [broker, setBroker] = useContext(BrokerContext);

  let { app_id } = useParams();

  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState(false);
  const [firstSubmit, setFirstSubmit] = useState(false);
  const [yesNoQuestions, setYesNoQuestions] = useState({});
  const [underwritingInvalid, setUnderwritingInvalid] = useState(false);

  const {data: underwritingQuestionQuery, status: underwritingQuestionStatus} = useQuery(["underwriting_questions", plan.carrier_id], fetchUnderwritingQuestions);

  useEffect(() => {
    if (Object.values(yesNoQuestions).some((item)=>{return item;})) {
      setUnderwritingInvalid(true);
      setBanner(true);
    }
    else {
      setUnderwritingInvalid(false);
      setBanner(false);
    }
  }, [yesNoQuestions]);

  function checkYesNo(value, index) {
      console.log(value);
      console.log(index);
      if (value === "yes") {
        setYesNoQuestions({
          ...yesNoQuestions,
          [index]: true,
        })
      }
      else if (value === "no") {
        setYesNoQuestions({
          ...yesNoQuestions,
          [index]: false,
        })
      }
  }

  function doSubmit(object) {
      console.log("Start submission");
      setLoading(true);
      console.log(object);

      let json = JSON.stringify({
        client_id: client.id,
        ...object
      });
      fetch('/api/update_client', {
          method: 'POST',
          headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          },
          body: json,
          signal: props.controller.controller.signal,
      }).then(res => res.json())
      .then(data => {
          if (data["status"] === "error") {
              let errorMessage = data["errorMessage"];
              history.push(`/error/${errorMessage}`);
          } else {
              console.log("Underwriting updated success");
              history.push(`/applications/health-underwriting-provider/${application.id}`);
          }
      });
  }

  return (
    <>
      {
        loading
        ? <LoadingOverlay></LoadingOverlay>
        : <div style = {{display: "none"}}></div>
      }
      {
        banner
        ? <Banner message="Answering yes to any of the above questions would result in this application being unlikely to pass health underwriting."
                  isSuccess={false}></Banner>
        : <></>
      }
      <div>
        <div className = "mainArea">
          <div className = "mainTitle">
              <div className = "titleInfo">
              <h1 className = "titleName">Health History</h1>
              <p className = "titleDetails">Please answer the following questions about your medical history.</p>
              </div>
          </div>
          <Formik
              initialValues={{

              }}
              validationSchema={Yup.object({

              })}
              onSubmit={(values, actions) => {
                console.log("SUBMIT!");
                doSubmit(values);
                actions.setSubmitting(false);
              }}
          >
            {({ isSubmitting, isValid, dirty, handleSubmit, handleChange, handleBlur, errors }) => (
              <form className = "clientForm" onSubmit={handleSubmit} style={{width: "80%", maxWidth: "2000px"}}>
                {
                  underwritingQuestionQuery ? underwritingQuestionQuery.map((question, index) => (
                    <div className="horCont" style={{justifyContent:"initial"}}>
                        <div className="horCont" style={{marginRight: "10px", marginTop:"-24px"}}>
                            <FormikRadioButton
                                name = {`health_underwriting_${index}`}
                                value = {`health_underwriting_${index}_true`}
                                style = {{marginRight:"10px"}}
                                onChange = {e => {checkYesNo("yes", index)}}
                                labelInfo = "Yes"
                                defaultChecked={false}
                                formikHandleChange={handleChange}
                             />
                             <FormikRadioButton
                                 name = {`health_underwriting_${index}`}
                                 value = {`health_underwriting_${index}_false`}
                                 style = {{marginRight:"10px"}}
                                 onChange = {e => {checkYesNo("no", index)}}
                                 labelInfo = "No"
                                 defaultChecked={true}
                                 formikHandleChange={handleChange}
                              />
                          </div>
                          <div style={{fontSize:"13px"}}>
                              {question.question}
                          </div>
                      </div>
                  )) : <></>
                }
                <div className = "continue">
                  {
                    (firstSubmit && !isValid) || underwritingInvalid ?
                    <input type="button" className = "disabledButtonStyle" value="Continue"></input> :
                    <input type="submit"
                            className = "buttonStyle"
                            onClick = {(e) => {
                              setFirstSubmit(true);
                            }}
                            value="Continue"></input>
                  }
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default HealthUnderwritingQuestions;
