import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { PlanContext, ClientContext, ApplicationContext } from './PCAContext';
import { BrokerContext } from './BrokerContext';

import './ApplicationBox.css';

import mixpanel from 'mixpanel-browser';
import { ENVContext } from './ENVContext';

import { boldSubstring } from '../utils.js';

const BrokerBox = (props) => {
    let history = useHistory();

    const [ENV, setENV] = useContext(ENVContext);
    // const [broker, setBroker] = useContext(BrokerContext);

    const info = props.info;
    console.log(info);

    // Set PCA context when clicked
    function setBrokerInfo(info) {
        // mixpanel.init(ENV.MIXPANEL_TOKEN);
        // mixpanel.track("Access application", {where_from: props.location, application_id: info.id, client_id: info.client ? info.client.id : null});
        // setBroker({
        //     id: info.id,
        //
        // });
        history.push(`/agency/brokerdetails/${info.id}`)
    }

    return(
        <tr className = {props.checked ? "applicationBoxChecked" : "applicationBox"}
             onClick = {() => setBrokerInfo(info)}>
            <td></td>
            <td>
                {props.filterValue ?
                    boldSubstring(`${info.first_name} ${info.last_name}`, props.filterValue)
                    : `${info.first_name} ${info.last_name}`}
            </td>
            <td>
                {info.email}
                {info.phone}
            </td>
            <td>
                {info.zip}
            </td>
            <td>
                {props.create_date}
            </td>
        </tr>
    );
}

export default BrokerBox;
