import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory, Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { BrokerContext } from '../../../components/BrokerContext';
import { ApplicationContext } from '../../../components/PCAContext';

import '../../pages.css';
import '../../Profile.css';

import AgencyApplicationsListPage from './AgencyApplicationsListPage';
import AgencyClientsPage from './AgencyClientsPage';

import InputField from '../../../components/InputField';
import StateForm from '../../../components/StateForm';
import Banner from '../../../components/Banner';
import SetEnvironment from '../../../components/SetEnvironment';
import BrokerProfileForm from '../../../components/BrokerProfileForm';
import AgencyNavigation from '../../../components/AgencyNavigation';
import WritingNumbers from '../../../components/WritingNumbers';

import mixpanel from 'mixpanel-browser';
import { ENVContext } from '../../../components/ENVContext';

function BrokerDetails(props) {
    let history = useHistory();
    let { id } = useParams();

    const [broker, setBroker] = useState({});
    const [showBanner, setShowBanner] = useState(false);
    const [brokerSubpage, setBrokerSubpage] = useState("profile");
    const [writingNumbers, setWritingNumbers] = useState([])

    const fetchBrokerId = async (key, broker_id) => {
        if (broker_id === -1) { return; }
        let json = JSON.stringify({broker_id: broker_id});
        const res = await fetch('/api/get_broker_by_id', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: json
        }).then(res => res.json())
        .then(data => {
          if (data && data.id !== -1) {
            setBroker({
                ...broker,
                id: data.id,
                first_name: data.first_name,
                last_name: data.last_name,
                address: data.address,
                city: data.city,
                state: data.state,
                zip: data.zip,
                email: data.email,
                agency_name: data.agency_name,
                agency_npn: data.agency_npn,
                broker_npn: data.broker_npn,
                phone: data.phone,
                status: data.status,
                signing_url: data.signing_url,
                ds_access_code: data.ds_access_code,
                role: data.role,
                agency_id: data.agency_id,
                live_session_room_id: data.live_session_room_id,
                live_session_create_date: data.live_session_create_date,
                live_session_client_name: data.live_session_client_name,
            });
        }
      })
    };

    const { data, status } = useQuery(['broker', id], fetchBrokerId, {
      refetchOnWindowFocus: false, refetchOnMount: false,
      refetchOnReconnect: false,
    });

    return(
      <>
          <AgencyNavigation current="brokers"/>
          <div className="vertCont">
            {
              status === 'error' && (<div style = {{marginTop: "10px"}} className = "vertCont">Error fetching broker information</div>)
            }
            {
              status === 'loading' && (<div style = {{marginTop: "10px"}} className = "vertCont">Broker information is loading...</div>)
            }
            {
              status === 'success' && (
                <>
                  {showBanner ?
                      <Banner message={"Profile updated!"}></Banner>
                      : <></>
                  }
                  <div className = "mainArea">
                  <div className="horCont" style={{ width: "200px", marginLeft: "7%", marginTop: "10px", fontSize: "14px", color: "rgb(16, 156, 241)"}}>
                        <a style={{marginRight:"15px"}} onClick={() => {setBrokerSubpage("profile")}}>
                          {brokerSubpage === "profile" ? <><u><b>PROFILE</b></u></> : <b>PROFILE</b>}
                        </a>
                        <a style={{marginRight:"15px"}} onClick={() => {setBrokerSubpage("contracting")}}>
                          {brokerSubpage === "writing-numbers" ? <b><u>CONTRACTING</u></b> : <b>CONTRACTING</b>}
                        </a>
                    </div>
                    {brokerSubpage === "profile" ?
                    <BrokerProfileForm
                        broker={broker}
                        canEditEmail={false}
                        buttonText="Update information"
                        hideButtons
                        hideWritingNums
                        controller={props.controller}
                        className="none"></BrokerProfileForm>
                    :
                    <div style={{marginTop: "30px"}}>
                      <WritingNumbers
                        broker={broker}
                        buttonLabel="Next"
                        isBrokerProfile={true}
                        setWritingNumbers={setWritingNumbers}
                        carrierLabelWidth="34%"
                        controller={props.controller}/>
                    </div>
                    }
                    <div style={{marginLeft: "50px", marginBottom: "30px"}}>
                      <Link className="linkStyle"
                            onClick={() => {
                              history.push(`/agency/clients/${broker.id}/${encodeURI(broker.first_name + " " + broker.last_name)}`)
                            }}>
                        View {broker.first_name + " " + broker.last_name}'s Clients
                      </Link><br/><br/>
                      <Link className="linkStyle"
                            onClick={() => {
                              history.push(`/agency/applications-list/${broker.id}/${encodeURI(broker.first_name + " " + broker.last_name)}`)
                            }}>
                        View {broker.first_name + " " + broker.last_name}'s Applications
                      </Link>
                    </div>
                  </div>
                </>
              )
            }
          </div>

      </>
    );
}

export default withRouter(BrokerDetails);
