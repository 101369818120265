import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { BrokerContext } from '../../../components/BrokerContext';

import Banner from '../../../components/Banner';
import AgencyNavigation from '../../../components/AgencyNavigation';

// Fetch all applications by broker id
const fetchBrokers = async (key, broker_id, page_num=1) => {
    if (broker_id != -1) {
        let json = JSON.stringify({"broker_id": broker_id, "page_num": page_num});

        const res = await fetch('/api/get_paginated_agency_brokers_by_broker_id', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 0
            },
            body: json
        });
        return res.json();
    } else {
        return [];
    }
}



function InviteBroker(props) {
  let history = useHistory();

  const [broker, setBroker] = useContext(BrokerContext);

  let [email, setEmail] = useState("");
  let [emailSent, setEmailSent] = useState(false);
  let [linkCopied, setLinkCopied] = useState(false);
  let [showBanner, setShowBanner] = useState(false);
  let [bannerMessage, setBannerMessage] = useState("")
  let [showEmailPreview, setShowEmailPreview] = useState(false);

  let inviteLink = `${window.location.origin}/auth0/signup/${broker.agency_npn}`;

  async function sendEmail() {
    setEmailSent(true);

    let obj = {
      recipient_email: email,
      link: inviteLink,
      agency_id: broker.agency_id,
    }

    let json = JSON.stringify(obj);
    const res = await fetch('/api/send_broker_invite_email', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: json
    });
    setBannerMessage("Email sent!");
    setShowBanner(true);

    return res.json();
  }

  function copyLink() {
    setLinkCopied(true);
    window.getSelection().empty();
    if (document.selection) {
        var range = document.body.createTextRange();
        range.moveToElementText(document.getElementById("invite-link"));
        range.select();
    } else if (window.getSelection) {
        var range = document.createRange();
        range.selectNode(document.getElementById("invite-link"));
        window.getSelection().addRange(range);
    }

    navigator.clipboard.writeText(inviteLink).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
    setBannerMessage("Link copied to clipboard!");
    setShowBanner(true);
  }

  return (
    <>
      {showBanner ? <Banner message={bannerMessage}></Banner> : <></>}
      {showEmailPreview ?
        <div style={{backgroundColor: "#0000008f", position: "absolute", zIndex: 2, width: "100%", height: "100%"}}>
          <div style = {{marginTop: "150px", marginLeft: "15%"}}>
            <a style={{color:"black", fontSize: "50px", marginLeft: "555px", position: "absolute",}} onClick={() => {setShowEmailPreview(false);}}>X</a>
            <img src="https://planprovide-resources.s3.us-west-1.amazonaws.com/Broker_Invite_Email_Preview.png"></img>
          </div>
        </div> : <></>
      }
      <AgencyNavigation current="brokers"/>
      <div style = {{left: "5%", width: "90%", marginTop: "40px"}} className="mainArea">
          <div>
              <h3>Invite broker by email</h3>
              <div style={{"width":"70%"}} className = "horCont">
                <input style={{"width":"75%"}}
                       type="text"
                       placeholder="example@email.com"
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}></input>
                <div style={{"width":"20%"}} className = "vertCont">
                  {emailSent ? <button className="disabledButtonStyle" disabled>Email sent!</button>
                  : <button className="buttonStyle" onClick={sendEmail}>Send Email</button>}
                </div>
              </div>
              <div className = "horCont" style={{width:"70%", marginTop:"5px"}} >
                  <span style={{"width":"75%"}}></span>
                  <div style={{"width":"20%"}} className = "vertCont">
                    <a style={{fontSize: "12px", width: "100px", marginLeft: "25px"}} onClick={() => {setShowEmailPreview(true);}}>Preview email</a>
                  </div>
              </div>
          </div>
          <div>
              <h3>Share your invite link</h3>
              <div style={{"width":"70%"}} className = "horCont">
                <input style={{"width":"75%"}} type="text" value={inviteLink} id="invite-link" readonly></input>
                <div style={{"width":"20%"}} className = "vertCont">
                  {linkCopied ? <button className="disabledButtonStyle" disabled>Link copied!</button>
                  : <button className="buttonStyle" onClick={copyLink}>Copy Link</button>}
                </div>
              </div>
          </div>
      </div>
    </>
  )
}

export default InviteBroker;
