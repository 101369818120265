import React, { useContext, useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import { PlanContext, ClientContext, ApplicationContext } from './PCAContext';

import mixpanel from 'mixpanel-browser';
import { ENVContext } from './ENVContext';

function CheckAuth0Account(props) {
    let history = useHistory();
    let params = useParams();

    const [ENV, setENV] = useContext(ENVContext);
    const [plan, setPlan] = useContext(PlanContext);
    const [client, setClient] = useContext(ClientContext);
    const [application, setApplication] = useContext(ApplicationContext);

    useEffect(() => {
      console.log("AUTH0ACCOUNT: PARAMS")
      console.log(params)
    }, [params])

    return(
        <>
          {props.children}
        </>
    );
}

export default CheckAuth0Account;
