import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useHistory, useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import { PlanContext, ClientContext, ApplicationContext } from '../../../components/PCAContext';
import { BrokerContext } from '../../../components/BrokerContext';

import './PickPlan.css';

import InputField from '../../../components/InputField';
import CheckBox from '../../../components/CheckBox';
import LoadingOverlay from '../../../components/LoadingOverlay';

function ApplicationWritingNumber(props) {
    let history = useHistory();

    let { application_id, plan_id_1, plan_id_2 } = useParams();

    const [plan, setPlan] = useContext(PlanContext);
    const [application, setApplication] = useContext(ApplicationContext);
    const [broker, setBroker] = useContext(BrokerContext);
    const [writingNum1, setWritingNum1] = useState("");
    const [writingNum2, setWritingNum2] = useState("");

    const [plan1, setPlan1] = useState();
    const [plan2, setPlan2] = useState();
    const [loading, setLoading] = useState();

    async function updateWritingNums(carrier_id, writingNum) {
      if (carrier_id === null || carrier_id === undefined) {
        return;
      }
      console.log("UPDATING")
      console.log(carrier_id);
      console.log(writingNum);
      let json = JSON.stringify({
        broker_id: broker.id,
        agency_id: broker.agency_id,
        writing_nums: [{
          "index": 0,
          "carrier_id": carrier_id,
          "agent_number": writingNum,
        }],
        update_all: false,
      });

      const res = await fetch('/api/update_broker_writing_numbers', {
          method: 'POST',
          headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          },
          body: json,
      });
    }
//plan.carrier-id
    async function handleSubmit() {
      setLoading(true);
      console.log("submit")
      if (plan_id_1 === undefined) {
        updateWritingNums(plan.carrier_id, writingNum1);
      }
      else {
        console.log("anc");
        updateWritingNums(plan1.carrier_id, writingNum1);

        if (plan_id_2 !== null && plan_id_2 !== undefined) {
          console.log("Second anc");
          updateWritingNums(plan2.carrier_id, writingNum2);
        }
      }
      if (application_id) {
        let json = JSON.stringify({
          application_id: application.id,
          app_edit_allowed: broker.app_edit_allowed || false,
          ancillary_plan_ids: [plan_id_1, plan_id_2],
          dont_add_ancillarys: true,
        });

        fetch('/api/create_docusign_envelope_from_application', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: json,
            signal: props.controller.controller.signal,
        }).then(res => res.json())
        .then(data => {
            if (data["status"] === "error") {
              let errorMessage = data["errorMessage"];
              history.push(`/error/${errorMessage}`);
              return;
            }
            console.log("Application create docusign success");
            json = JSON.stringify({
              destination_email: data.destination_email,
              application_id: data.id,
            });

            fetch('/api/get_docusign_envelope_signing_link_from_email', {
                method: 'POST',
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: json,
                signal: props.controller.controller.signal,
            }).then(res => res.json())
            .then(data => {
              if (data["status"] === "error") {
                let errorMessage = data["errorMessage"];
                history.push(`/error/${errorMessage}`);
                return;
              }
              setApplication({...application,
                ...data,
                url: data.signing_url,
                envelope_id: data.docusign_envelope_id,
                download: data.aws_pdf_key,
                csv: data.aws_csv_key,
                access_code: data.ds_access_code,
              });
              setLoading(true);

              history.push(`/applications/ready-sign/${application.id}`);

            });
        });
      }
      else {
        history.push(`/applications/client-form-page/${application.id}`);
      }
    }

    useEffect(() => {
      if (plan_id_1 === 'undefined') {
        plan_id_1 = undefined;
      }
      if (plan_id_2 === 'undefined') {
        plan_id_2 = undefined;
      }
      if (plan_id_1 !== null && plan_id_1 !== undefined && plan_id_1 !== "undefined") {
        let plan_json = JSON.stringify(plan_id_1);
        fetch('/api/get_plan_by_id', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: plan_json
        }).then(res => res.json())
        .then(plan_data => {
          console.log("HEY it's me , plan1");
          console.log(plan_data);
          if (plan_data["status"] === "error") {
              let errorMessage = plan_data["errorMessage"];
              history.push(`/error/${errorMessage}`)
          } else {
              setPlan1({
                  id: plan_data.plan.id,
                  name: plan_data.plan.name,
                  type: plan_data.plan.type,
                  number: plan_data.plan.plan_number,
                  carrier_name: plan_data.carrier.name,
                  carrier_fax: plan_data.carrier.fax,
                  carrier_id: plan_data.carrier.id,
              });
          }
        });
      }
      if (plan_id_2 !== null && plan_id_2 !== undefined && plan_id_2 !== "undefined") {
        let plan_json = JSON.stringify(plan_id_2);
        fetch('/api/get_plan_by_id', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: plan_json
        }).then(res => res.json())
        .then(plan_data => {
          if (plan_data["status"] === "error") {
              let errorMessage = plan_data["errorMessage"];
              history.push(`/error/${errorMessage}`)
          } else {
              setPlan2({
                  id: plan_data.plan.id,
                  name: plan_data.plan.name,
                  type: plan_data.plan.type,
                  number: plan_data.plan.plan_number,
                  carrier_name: plan_data.carrier.name,
                  carrier_fax: plan_data.carrier.fax,
                  carrier_id: plan_data.carrier.id,
              });
          }
        });
      }

    }, [plan_id_1, plan_id_2]);

    return(
      <>
        {loading ? <LoadingOverlay/> : <></>}
        <div className = "mainArea clientForm">
            <div className = "mainTitle">
                <div className = "titleInfo" style = {{width: "80%"}}>
                    {plan_id_1 !== undefined && plan1 !== undefined ?
                      <h2 className = "titleName">Please provide your writing number for {plan1.carrier_name}.</h2>
                      : <h2 className = "titleName">Please provide your writing number for {plan.carrier_name}.</h2>
                    }
                </div>
            </div>
            <div>
                <p>
                  <InputField
                    style={{width: "200px"}}
                    value={writingNum1}
                    maxLength="20"
                    handleChange={(e) => {setWritingNum1(e.target.value)}}
                  ></InputField>
                </p>
            </div>
            {plan_id_2 !== undefined && plan2 !== undefined ?
              <>
                <div>
                    <div className = "titleInfo" style = {{width: "80%"}}>
                        <h2 className = "titleName">Please provide your writing number for {plan2.carrier_name}.</h2>
                    </div>
                </div>
                <div>
                    <p>
                      <InputField
                        style={{width: "200px"}}
                        value={writingNum2}
                        maxLength="20"
                        handleChange={(e) => {setWritingNum2(e.target.value)}}
                      ></InputField>
                    </p>
                </div>
              </> : <></>
            }
            <div>
                <button class={writingNum1 === "" || (plan_id_2 !== undefined && writingNum2 === "") ? "disabledButtonStyle" : "buttonStyle"}
                        style={{marginTop: "50px"}}
                        onClick={writingNum1 === "" || (plan_id_2 !== undefined && writingNum2 === "") ? undefined : handleSubmit}
                        >Continue</button>
            </div>
        </div>
      </>
    );
}

export default ApplicationWritingNumber;
