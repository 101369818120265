import React from 'react';
import { withRouter, useHistory, Link, useParams } from 'react-router-dom';

import './pages.css';

import Headbar from '../components/Headbar';
import Sidebar from '../components/Sidebar';
import SetEnvironment from '../components/SetEnvironment';

function ErrorPage( props ) {

    let history = useHistory();
    let { errorMessage, hideSidebar } = useParams();

    return(
         <div className = "background">
            <SetEnvironment></SetEnvironment>
            {
              hideSidebar ? <Sidebar hideContents controller={props.controller}></Sidebar> : <Sidebar controller={props.controller}></Sidebar>
            }
            <div className = "middle container">
                <Headbar title = "Whoops"></Headbar>
                <div className = "mainArea" style={{paddingBottom: "20px"}}>
                    <div className = "mainTitle">
                        <div className = "titleInfo">
                            <h1 className = "titleName">Error</h1>
                            <p className = "titleDetails">{errorMessage}</p>
                        </div>
                    </div>
                    {
                    hideSidebar ?
                    <button style = {{position: "relative", left: "10%", marginLeft: "0px", width: "200px"}}
                            className = "buttonStyle" onClick = {() => history.push('/')}>Back</button>
                    :
                    <button style = {{position: "relative", left: "10%", marginLeft: "0px", width: "200px"}}
                            className = "buttonStyle" onClick = {() => history.push('/dashboard')}>Return to Dashboard</button>
                    }
                </div>
            </div>
         </div>
    );
}

export default withRouter(ErrorPage);
