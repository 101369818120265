import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory, Link } from 'react-router-dom';

import { BrokerContext } from '../components/BrokerContext';

import './pages.css';
import './Profile.css';

import LoadingOverlay from '../components/LoadingOverlay';
import BrokerProfileForm from '../components/BrokerProfileForm';
import Banner from '../components/Banner';

import mixpanel from 'mixpanel-browser';
import { ENVContext } from '../components/ENVContext';

import * as AgencyServices from '../domain/agency';
import * as BrokerServices from '../domain/broker';
import Broker from '../data/broker';
import Agency from '../data/agency';

function RegisterProfileDetails(props) {
    let history = useHistory();

    const [ENV, setENV] = useContext(ENVContext)
    const [broker, setBroker] = useContext(BrokerContext);

    const [loading, setLoading] = useState(false);
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
      console.log("NPN: ", props.agencyNPN);
      BrokerServices.get(broker.id).then(res => {

        if (res["status"] === "error") {
          history.push(`/error/${res["errorMessage"]}`)

        } else {
          setBroker({...broker, ...res});
          if (res.agency_npn) {
            console.log("new NPN: ", res.agency_npn);
            props.setAgencyNPN(res.agency_npn);
          }
          if (res.agency_name) {
            props.setNewAgencyName(res.agency_name);
          }
          if (res.email) {
            props.setAgencyEmail(res.email);
          }
          if (res.phone) {
            props.setAgencyPhone(res.phone);
          }
        }

      })
    }, []);

    const createProfile = async (object) => {
      setLoading(true);
      console.log("NPN: ", props.agencyNPN);
      console.log("agenttype: ", props.agentType);
      let agencyId;

      if (props.agentType === "new" || props.agentType === "independent") {
         let agency = new Agency({
            name: props.newAgencyName,
            npn: props.agencyNPN,
            email: props.agencyEmail,
            phone: props.agencyPhone,
         });
        AgencyServices.create(agency, props.controller.controller.signal).then(data => {
          agencyId = data.id;
          AgencyServices.updateWritingNumbers(agencyId, props.writingNumbers, props.controller.controller.signal);
          createBroker(object, agencyId);
          if (data["status"] === "error") {
            let errorMessage = data["errorMessage"];

            history.push(`/error/${errorMessage}/true`)
            return;
          }
        });
      }
      else {
        AgencyServices.get(null, props.agencyNPN, props.controller.controller.signal).then(data => {
          agencyId = data.id;
          if (data["status"] === "error") {
            console.log(data["errorMessage"]);

            alert("There was an error with your NPN. Please try again.");
            return;
          }
        }).then(() => {
          createBroker(object, agencyId);
        });
      }
    }

    const createBroker = async (object, agencyId) => {
      console.log("AGENCY ID: ", agencyId);

      if (agencyId == null) {
        alert("There was an error with your registration. Please try again.");
        return;
      }

      let broker = new Broker({
        ...object,
        db_agency_id: agencyId,
        role: props.agentType === "new" || props.agentType === "independent" ? "owner" : "",
        status: 0,
      });

      BrokerServices.create(broker, props.controller.controller.signal).then(data => {
          if (data["status"] === "error") {
            let errorMessage = data["errorMessage"];

            history.push(`/error/${errorMessage}/true`)
            return;
          }


          mixpanel.init(ENV.MIXPANEL_TOKEN);
          mixpanel.track("Registration complete");
          mixpanel.identify(data.id);
          mixpanel.people.set({
            "$email": data.email,
            "Sign up date": new Date(),
            "USER_ID": data.id,
            "Agency NPN": data.agency_npn,
            "no_clients": 0,
            "no_apps": 0,
            "no_apps_submitted": 0,
          });
          mixpanel.time_event("New application started");

          console.log("UPDATE BROKER WRITING NUMS");
          console.log(props.writingNumbers);
          // BrokerServices.updateWritingNumbers();
          BrokerServices.updateWritingNumbers(data.id, props.writingNumbers, props.controller.controller.signal);


          BrokerServices.getConsent(data.id, props.controller.controller.signal).then(info => {
            if (info["status"] === "error") {
              let errorMessage = info["errorMessage"];

              history.push(`/error/${errorMessage}/true`)
              return;
            }
            else {
              broker.update({ ...data, ...info });

              if (props.agentType === "new") {
                AgencyServices.updateWritingNumbers(data.agency_id, props.writingNumbers, props.controller.controller.signal);
              }

              BrokerServices.updateWritingNumbers(data.id, props.writingNumbers, true, props.controller.controller.signal);

              history.push('/brokersign');
            }
          })
      });
    }

    return(
      <>
        {loading ? <LoadingOverlay/> : <></>}
        <div className = "mainTitle">
            <div className = "titleInfo">
                <h1 className = "titleName">Complete your registration</h1>
                <p className = "titleDetails">Please input your information so we can create your profile.</p>
            </div>
        </div>
        {showBanner ?
            <Banner message={"Profile created!"}></Banner>
            : <></>
        }
        <BrokerProfileForm
            handleSubmit={createProfile}
            canEditEmail={false}
            agencyName={props.newAgencyName}
            broker={broker}
            buttonText="Register"
            controller={props.controller}
            hideWritingNums>
        </BrokerProfileForm>
      </>
    );
}

export default withRouter(RegisterProfileDetails);
