import Agency from '../data/agency';
import ApplicationEnvelope from '../data/applicationEnvelope';
import Broker from '../data/broker';
import Client from '../data/client';

export const create = async (data, signal) => {
    const res = await fetch('/api/agency', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(data),
        signal: signal,
    })

    return res.json();
}

export const get = async (id, npn, signal) => {
    console.log("get agency", id, npn);
    if (id == null) {
        id = -1;
    }

    const res = await fetch(`/api/agency?id=${id}&npn=${npn}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        signal: signal,
    })

    return res.json();
}

export const getSupportedCarriers = async (id, signal) => {
    console.log("get supported carriers", id);
    if (id == null) {
        return null;
    }

    const res = await fetch(`/api/agency/carriers?id=${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        signal: signal,
    })
    console.log(res);

    return res.json();
}

export const getWritingNumbers = async (id, signal) => {
    if (id == null) {
        return null;
    }

    const res = await fetch(`/api/agency/writing_numbers?id=${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        signal: signal,
    })

    return res.json();
}

export const update = async (data) => {
    const res = await fetch('/api/agency/update', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(data),
    })
    
    return res.json();
}

export const updateWritingNumbers = async (id, writingNums, signal) => {
    console.log(id);
    console.log(writingNums);
    console.log(signal);
    const res = fetch('/api/update_agency_writing_numbers', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            agency_id: id,
            writing_nums: writingNums,
        }),
        signal: signal,
    });

    // return res.json();
}

