import React from 'react';

export function compareDate(a, b) {
    let dateA = new Date(a.auto_create_date.replace(" GMT", ""));
    let dateB = new Date(b.auto_create_date.replace(" GMT", ""));
    if (dateA > dateB) {
        return 1;
    } else {
        return -1;
    }
}

// Sort helper function, sorts by last name then first name
export function compareNames(a, b) {
    const a_last = a.last_name.toUpperCase();
    const b_last = b.last_name.toUpperCase();
    const a_first = a.first_name.toUpperCase();
    const b_first = b.first_name.toUpperCase();

    let comparison = 0;
    if (a_last > b_last) {
        comparison = 1;
    } else if (a_last < b_last) {
        comparison = -1;
    } else {
        if (a_first > b_first) {
            comparison = 1;
        } else {
            comparison = -1;
        }
    }
    return comparison;
}

export function handleDate(num, type) {
    if (type == 'm') {
        num++;
    }
    if (num < 10) {
        return "0" + num
    } else {
        return "" + num
    }
}

// Formats a date object as a string
export function printDate(date) {
    if (!date || !(date instanceof Date)) {
      return ""
    }
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let year = date.getFullYear();
    if (month < 10) {
        month = "0" + month;
    }
    if (day < 10) {
        day = "0" + day;
    }
    return month + '/' + day + '/' + year;
}

export function printRFC3339Date(date) {
    if (!date) {
      console.log("Could not convert date to RFC3339: " + date);
      return date;
    }
    if (typeof date === "string") {
      if (date.includes("GMT")) {
        date = date.replace("GMT", "");
        let newDate = new Date(Date.parse(date));
        date = newDate;
      }
      else {
        console.log("Could not convert date to RFC3339: " + date);
        return date;
      }
    }
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let year = date.getFullYear();

    if (month < 10) {
        month = "0" + month;
    }
    if (day < 10) {
        day = "0" + day;
    }
    return year + '-' + month + '-' + day;
}

export function calculateDate(date) {
    if (!date || !(date instanceof Date)) {
      return ""
    }
    var month = date.getMonth();
    var year = date.getFullYear();
    month += 2;
    if (month >= 12) {
        year += 1;
        month = 1;
    }
    if (month < 10) {
        month = "0"+month.toString();
    }
    console.log(`${year}-${month}-01`);
    return(`${year}-${month}-01`);
}

export function formatCurrency(currStr) {
  return parseFloat(currStr).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
  })
}

// Checks if an object is iterable
export function isIterable(obj) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}

export function boldSubstring(str, substr) {
  const textArray = str.split(substr);
  return (
    <>
      {textArray.map((item, index) => (
        <>
          {item}
          {index !== textArray.length - 1 && (
            <b><mark style={{padding: "0px"}}>{substr}</mark></b>
          )}
        </>
      ))}
    </>
  );
}
