import React, { PureComponent, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import '../index.css';

function usePage() {
  useEffect(() => {
		fetch('/api/pdf_test').then(res => res.json()).then(data => { });
	}, []);

  return;
}

function GetPage() {
  usePage();
  return;
}

class Disp extends PureComponent {
  render() {
    return (
      <div>
        <div id="page">
          <GetPage />
        </div>
      </div>
    );
  }
}

const PDFTest = () => {
    return ReactDOM.render(<Disp />, document.body);
}

export default PDFTest;
