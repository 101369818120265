import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, Link, useParams, Route, Redirect } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import { useQuery } from 'react-query';
import MetaTags from 'react-meta-tags';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import './pages.css';
import './FindPlans.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Headbar from '../components/Headbar';
import Sidebar from '../components/Sidebar';
import SetEnvironment from '../components/SetEnvironment';
import LoadingOverlay from '../components/LoadingOverlay';
import CountyStateMAPage from './CountyStateMAPage';
import CountyStatePDPPage from './CountyStatePDPPage';
import CountyStateSNPPage from './CountyStateSNPPage';
import CountyStateSuppPage from './CountyStateSuppPage';
import NotFoundErrorPage from './NotFoundErrorPage';
import SearchPlans from './SearchPlans';

const isValidCountyState = async (key, county_name="", state_name="", carrier="", carrier_name="") => {
    if (county_name === undefined || state_name === undefined || carrier_name === undefined) {
        return false;
    }
    if (county_name === "" && state_name === "" && carrier_name === "") {
        return true;
    }
    if (state_name === "Medicare Advantage" 
        || state_name === "Medicare Supplement" 
        || state_name === "Prescription Drug Plan" 
        || state_name === "Special Needs Plan") {
        return true;
    }
    let obj = {
        "county_name": state_name === "Alaska" || county_name === "All Counties" ? "" : county_name,
        "state_name": state_name,
        "carrier_name": carrier_name,
    }
    let json = JSON.stringify(obj);
    const res = await fetch('/api/is_valid_county_state', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: json
    });
    return res.json();
}

const fetchPlans = async (key, type, county_name="", state_name="") => {
    if (type === undefined || type === null || type == "") {
        return null;
    }
    let obj = {
        "plan_type": type,
        "plan_county_name": state_name === "Alaska" || county_name === "All Counties" ? "" : county_name,
        "plan_state_name": state_name,
    }

    let json = JSON.stringify(obj);
    const res = await fetch('/api/get_plans_by_state_county', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: json
    });
    return res.json();
}

function FindPlans( props ) {
    const history = createBrowserHistory({forceRefresh:true});
    let { state, county, type, carrier } = useParams();

    let [ loading, setLoading ] = useState(true);
    let [ ipSearchLoaded, setIpSearchLoaded ] = useState(false);
    let [ redirectLoc, setRedirectLoc ] = useState("");
    let [ hamburgerOpen, setHamburgerOpen ] = useState(false);
    let [ stateName, setStateName ] = useState("");
    let [ countyName, setCountyName ] = useState("");
    let [ carrierName, setCarrierName ] = useState("");
    let [ carriersInState, setCarriersInState ] = useState([]);

    let [ IPState, setIPState ] = useState("");
    let [ IPCounty, setIPCounty ] = useState("");
    let [ IPZip, setIPZip ] = useState("");
    let [ typeName, setTypeName ] = useState("");
    let [ typeVariable, setTypeVariable ] = useState("");
    let [ typeAbbrev, setTypeAbbrev ] = useState("");
    let [ highestEnrolledPlan, setHighestEnrolledPlan ] = useState("");
    let [ highestRatedPlan, setHighestRatedPlan ] = useState("");
    let [ lowestMoopPlan, setLowestMoopPlan ] = useState("");

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyB8_IuyFWqBIoTCUf39pXfc9bXVdW7JIug"
    })

    const fetchCountiesList = async (key, state) => {
        if (state === null || state === undefined) {
            return;
        }
            
        console.log(state);

        // Data provided by https://simplemaps.com/data/us-counties
        let res = await fetch('/api/get_counties_from_state', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({state: state[0].toUpperCase() + state.slice(1)})
        })
        
        console.log("counties fetch success");
        return res.json()
    }

    const fetchIPLocation = async (key, googleMapsLoaded) => {
        if (window.location.pathname === '/' || state === "medicare-advantage"
                                             || state === "medicare-supplement"
                                             || state === "prescription-drug-plan" 
                                             || state === "special-needs-plan") {
            if (googleMapsLoaded) {
                await fetch(
                    `https://ipapi.co/json`
                ).then(res => {
                    if (!res.ok) {
                        throw Error(res.statusText);
                    }
                    return res;
                }).then(res => res.json()).then(data => {
                    console.log("ok");
                    console.log("IP Location: ");
                    console.log(data);

                    // City to county
                    let geocoder = new window.google.maps.Geocoder();
                    geocoder.geocode({ 'address': data.city }, function(results, status) {
                        if (status === 'OK') {
                            let county_name = results[0].address_components[1].long_name;
                            console.log("GOOGLE GEOCODER ");
                            console.log(results);
                            setIPState(data.region);
                            setIPCounty(county_name);
                            setIPZip(data.postal);

                            console.log(window.location.pathname);

                            county_name = county_name.replaceAll("-", "_").replaceAll(" County","-county").replaceAll(" ", "-").toLowerCase();
                            let state_name = data.region.replaceAll(" ", "-").toLowerCase();

                            if (window.location.pathname === '/' ) {
                                history.push(`/findplans/${state_name}/${county_name}`);
                            }
                            else if (state === "medicare-advantage" 
                                  || state === "medicare-supplement" 
                                  || state === "prescription-drug-plan" 
                                  || state === "special-needs-plan") {
                                history.push(`/findplans/${state_name}/${county_name}/${state}`);
                            }
                            else {
                                setIpSearchLoaded(true);
                            }
                        }
                    });
                }).catch(err => {
                    console.log(err);
                    setIpSearchLoaded(true);
                });
            }
        }
        else {
            setIpSearchLoaded(true);
        }
    };

    const { data: queryData, status } = useQuery(['plans', typeVariable, countyName, stateName], fetchPlans);
    const { data: countiesList, countiesListStatus } = useQuery(['counties_list', stateName], fetchCountiesList);
    const { data: validCountyState, setValidCountyState } = useQuery(['valid_county_state', countyName, stateName, carrierName], isValidCountyState);
    const { data: ipLocation, ipLocationStatus } = useQuery(['ip_location', isLoaded], fetchIPLocation);

    // Returns a plain English string describing how long ago the date was
    function timeSince(date) {
        var seconds = Math.floor((new Date() - date) / 1000);
        var interval = Math.floor(seconds / 31536000);
        if (interval > 1) {
            if (interval == 1) {
                return interval + " year";
            }
            return interval + " years";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            if (interval == 1) {
                return interval + " month";
            }
            else {
                return interval + " months";
            }
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            if (interval == 1) {
                return "1 day";
            }
            else if (interval < 7) {
                return interval + " days";
            }
            else if (interval < 14) {
                return "1 week";
            }
            else if (interval < 21) {
                return "2 weeks";
            }
            else {
                return "3 weeks";
            }
        }
        return "Earlier today";            
    }
     
    useEffect(() => {
        if (validCountyState === false) {
            setRedirectLoc('/404');
        }
        else if (validCountyState === true) {
            setLoading(false);
        }
    }, [validCountyState])

    useEffect(() => {
        console.log(queryData);
        if (queryData && queryData.length > 0) {
            let hep = "";
            let hrp = "";
            let lmp = "";
            let fixedCarrierName = "";
            let carriers = [];
            for (let plan of queryData) {
                if (plan === undefined) {
                    continue;
                }
                if (type === "medicare-advantage") {
                    if (hep === "" || (!isNaN(parseFloat(plan.enrollment)) && (parseFloat(plan.enrollment) > parseFloat(hep.enrollment)) ) ) {
                        hep = plan;
                    }
                    if (hrp === "" || (!isNaN(parseFloat(plan.star_rating)) && (parseFloat(plan.star_rating) > parseFloat(hrp.star_rating)))) {
                        hrp = plan;
                    }
                    if (plan.moop) {
                        let moop = parseFloat(plan.moop.replace('$', '').replace(',', ''));
                        let lmp_moop = 0;
                        if (lmp !== "") {
                            lmp_moop = parseFloat(lmp.moop.replace('$', '').replace(',', ''));
                        }
                        if (lmp === "" || (!isNaN(moop) && (moop < lmp_moop))) {
                            lmp = plan;
                            console.log(parseFloat(plan.moop))
                            console.log(parseFloat(lmp.moop))
                            console.log(lmp.name)
                        }
                    }
                }

                if (carrier !== undefined && carrier !== "") {
                    
                    if (plan.carrier !== undefined && plan.carrier.name !== undefined) {
                        if (plan.carrier.name.toLowerCase() === carrier.replaceAll("-", " ").replaceAll("_", "-").toLowerCase()) {
                            fixedCarrierName = plan.carrier.name;
                        }
                    }
                }

                console.log(plan);
                if (plan.carrier !== undefined && !carriers.includes(plan.carrier.name)) {
                    carriers.push(plan.carrier.name);
                }
            }

            if (carrier !== undefined && carrier !== "" && fixedCarrierName === "") {
                setRedirectLoc('/404');
            }                
            else {
                setCarrierName(fixedCarrierName);
            }
            
            console.log(hep);
            console.log(hrp);
            console.log(lmp);
            setHighestEnrolledPlan(hep);
            setHighestRatedPlan(hrp);
            setLowestMoopPlan(lmp);

            setCarriersInState(carriers);
            console.log(carriers);
        }
    }, [queryData]);

    useEffect(() => {
        if (county !== undefined && county !== "") {
            let cn = county.split("-");
            for (let i = 0; i < cn.length; i++) {
                cn[i] = cn[i].charAt(0).toUpperCase() + cn[i].slice(1);
                cn[i] = cn[i].replaceAll("_", "-");
            }
            setCountyName(cn.join(" "));
        }
    }, [county]);

    useEffect(() => {
        if (state !== undefined && state !== "") {
            let sn = state.split("-");
            for (let i = 0; i < sn.length; i++) {
                sn[i] = sn[i].charAt(0).toUpperCase() + sn[i].slice(1);
            }
            setStateName(sn.join(" "));
        }
    }, [state]);

    useEffect(() => {
        if (type) {
            if (type === "ma") {
                history.push(`/findplans/${state}/${county}/medicare-advantage`);
            }
            else if (type === "supp") {
                history.push(`/findplans/${state}/${county}/medicare-supplement`);
            }
            else if (type === "pdp") {
                history.push(`/findplans/${state}/${county}/prescription-drug-plan`);
            }
            else if (type === "snp") {
                history.push(`/findplans/${state}/${county}/special-needs-plan`);
            }
             
            else if (type === "medicare-advantage") {
                setTypeName("Medicare Advantage plan");
                setTypeVariable("ma");
                setTypeAbbrev("");
            }
            else if (type === "medicare-supplement") {
                setTypeName("Medicare Supplement plan");
                setTypeVariable("supp");
                setTypeAbbrev("");
            }
            else if (type === "prescription-drug-plan") {
                setTypeName("Prescription Drug Plan");
                setTypeVariable("pdp");
                setTypeAbbrev("(PDP)");
            }
            else if (type === "special-needs-plan") {
                setTypeName("Special Needs Plan");
                setTypeVariable("snp");
                setTypeAbbrev("(SNP)");
            }
            // else push to 404 error
            else {
                console.log("404 error");
                setRedirectLoc("/404");
            }
        }
    }, [type]);

    function generateStarRating(rating) {
        let fl_rating = parseFloat(rating);
        if (fl_rating) {
            let whole = Math.floor(fl_rating);
            let out = [];
            for (let i = 0; i < whole; i++) {
                out.push(<img width="20px" src="https://planprovide-resources.s3.us-west-1.amazonaws.com/star_icon.png"></img>);
            }
            if (fl_rating - whole === 0.5) {
                out.push(<img width="20px" src="https://planprovide-resources.s3.us-west-1.amazonaws.com/star_icon_half.png"></img>);
            }
            for (let i = 0; i < 5 - whole - (fl_rating - whole === 0.5 ? 1 : 0); i++) {
                out.push(<img width="20px" src="https://planprovide-resources.s3.us-west-1.amazonaws.com/star_icon_empty.png"></img>);
            }
            return out;
        }
        if (rating === "Not Applicable") {
            return "Not available";
        }
        return rating;
    }
    function totalEnrollment() {
        let total = 0;
        if (queryData !== undefined) {
            for (let i = 0; i < queryData.length; i++) {
                if (!isNaN(parseFloat(queryData[i].enrollment))) {
                    total += parseFloat(queryData[i].enrollment);
                }
            }
            return total;
        }
    }

    function ratingTable(plan_letter = "", carrier_name = "") {      
        return (
            <div className="horiz-scroll-mobile" style={{marginLeft: '0px'}}>
                <Table striped bordered hover style={{width:"100%", marginBottom:"30px"}}>
                    <thead>
                        <tr style={{backgroundColor: 'black', color: 'white'}}>
                            {type !== "medicare-supplement" && carrier_name === "" ? <th className="desktop-only-cell">Carrier</th> : carrier_name === "" ? <th>Carrier</th> : <></>}
                            {type !== "medicare-supplement" ? <th>Plan Name</th> : <></>}
                            {type !== "medicare-supplement" ? <th>Premium</th> : <></>}
                            {type !== "medicare-supplement" ? <th className="desktop-only-cell">Plan Type</th> : <></>}
                            {type !== "medicare-supplement" && county === "all-counties" ? <th className="desktop-only-cell">County</th> : <></>}
                            {type === "medicare-advantage" ? county === "all-counties" ? <th>Enrolled in State</th> : <th>Enrolled in County</th> : <></>}
                            {type === "medicare-advantage" ? <th>Max Out of Pocket</th> : <></>}
                            {type !== "medicare-supplement" ? <th>Star Rating</th> : <></>}
                        </tr>
                    </thead>
                    <tbody>
                {
                    status === 'success' && queryData !== undefined ? queryData
                    .sort((a, b) => (a.premium && b.premium ? parseFloat(a.premium.trim().replace('$','')) > parseFloat(b.premium.trim().replace('$','')) ? 1 : -1 : 0))
                    .sort((a, b) => (parseFloat(a.enrollment) < parseFloat(b.enrollment)) || isNaN(parseFloat(a.enrollment)) ? 1 : -1)
                        
                    .filter(plan_letter !== "" ? plan => plan.plan_number === plan_letter : plan => true)
                    .filter(carrierName !== "" ? plan => plan.carrier !== undefined && plan.carrier.name !== undefined ? plan.carrier.name.toLowerCase() === carrierName.toLowerCase() : false : plan => true)
                    .map((plan, index) => {
                        return (
                            <tr id = {index} style={{height: "50px"}}>
                                {type === "medicare-supplement" ? 
                                    <td><a href={"http://" + plan.carrier.web_addr + "/"} target = "_blank" rel="noopener">{plan.carrier.name}</a>
                                    <img style={{marginTop: '-10px'}} width='10px' src="https://img.icons8.com/offices/10/000000/external-link.png"/></td>
                                :<>
                                    {carrier_name === "" && plan.carrier !== undefined && plan.carrier.name !== undefined ? 
                                        <td className="desktop-only-cell">
                                            <a href={`${window.location.origin}/findplans/${state}/${county}/${type}/${plan.carrier.name.replaceAll("-", "_").replaceAll(" ", "-").toLowerCase()}`}>
                                                {plan.carrier.name}
                                            </a>
                                        </td>
                                    : carrier_name === "" ? <td className="desktop-only-cell"></td> : <></>}
                                    <td>{plan.name} ({plan.plan_number})</td>
                                    <td>${plan.premium == " $-   " ? "0.00" : parseFloat(plan.premium.trim().replace('$','')).toFixed(2)}</td>
                                    <td className="desktop-only-cell">{plan.type}</td>
                                    {type !== "medicare-supplement" && county === "all-counties" ? <td className="desktop-only-cell">{plan.county}</td> : <></>}
                                    {type === "medicare-advantage" ? <>
                                        <td>{isNaN(parseFloat(plan.enrollment)) ? "Not available" :
                                                ((parseFloat(plan.enrollment) / totalEnrollment())*100).toFixed(2) + "% (" + plan.enrollment + " enrolled)"}</td>
                                        <td>{plan.moop}</td>
                                    </> : <></>}
                                    <td style={{minWidth: "130px"}}>{generateStarRating(plan.star_rating)}</td>
                                </>}
                            </tr>
                        )
                    }) :  <tr id = {1} style={{height: "50px"}}>Loading plans...</tr>
                }
                    </tbody>
                </Table>
                {
                    status === 'success' && queryData.length === 0 ?
                    <div style={{marginTop: '-20px', width: '80%'}}>Sorry, we couldn't find any {typeName}s available in your
                    county. <a href={`/findplans/${state}/${county}/medicare-supplement`}>Looking for a Medicare Supplement plan instead?</a></div>
                    : <></>
                }
            </div>
        );
    }

    return(
        redirectLoc !== "" ? 
        <Redirect push to={redirectLoc} /> :
        <>
            <link
                rel="stylesheet"
                href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
                integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
                crossorigin="anonymous"
            />
            <div style={{fontFamily: "DM Sans", backgroundColor: '#faf9f8',}}>
                <MetaTags>
                    <title>
                        {stateName !== "" && countyName !== "" && typeName !== "" ? `Top ${(typeName + "s " + typeAbbrev).trim()} in ${countyName.trim()}, ${stateName} - MedicareBits` :
                                
                            stateName === "" ? "Find Medicare plans in your area - MedicareBits" :
                            countyName === "" ? "Find Medicare plans in " + stateName + " - MedicareBits" : 
                            typeName === "" ? "Find Medicare plans in " + countyName + ", " + stateName + " - MedicareBits" :
                            carrier === "" ?  "Find Medicare plans in " + countyName + ", " + stateName + " - MedicareBits" :
                            "Find " + carrierName + " plans in " + countyName + ", " + stateName + " - MedicareBits"
                        }
                    </title>
                    <meta name="description" content=
                        {
                            stateName === "" ? "See Top Medicare Plans in your area. Find a Medicare Advantage, Medicare Supplement, Special Needs Plan, or Prescription Drug Plan." :
                            countyName === "" ? "See Top Medicare Plans in " + stateName + ". Find a Medicare Advantage, Medicare Supplement, Special Needs Plan, or Prescription Drug Plan." : 
                            typeName === "" ? "See Top Medicare Plans in " + countyName + ", " + stateName + ". Find a Medicare Advantage, Medicare Supplement, Special Needs Plan, or Prescription Drug Plan." :
                            carrier === "" ?  "See Top " + (typeName + "s " + typeAbbrev).trim() + " in " + countyName + ", " + stateName + ". Find a Medicare Advantage, Medicare Supplement, Special Needs Plan, or Prescription Drug Plan." :
                            "See " + carrierName + " " + (typeName + "s " + typeAbbrev).trim() + " in " + countyName + ", " + stateName + ". Find a Medicare Advantage, Medicare Supplement, Special Needs Plan, or Prescription Drug Plan."
                        } />
                    <meta property="og:title" content=
                        {stateName !== "" && countyName !== "" && typeName !== "" ? `Top ${(typeName + "s " + typeAbbrev).trim()} in ${countyName.trim()}, ${stateName} - MedicareBits` :
                                
                            stateName === "" ? "Find Medicare plans in your area - MedicareBits" :
                            countyName === "" ? "Find Medicare plans in " + stateName + " - MedicareBits" : 
                            typeName === "" ? "Find Medicare plans in " + countyName + ", " + stateName + " - MedicareBits" :
                            carrier === "" ?  "Find Medicare plans in " + countyName + ", " + stateName + " - MedicareBits" :
                            "Find " + carrierName + " plans in " + countyName + ", " + stateName + " - MedicareBits"
                        } />
                    <meta name="robots" content="index, follow" />
                    <link rel="canonical" href={`https://plans.medicarebits.com${window.location.pathname+window.location.search}`}></link>
                </MetaTags>
                <div className="mobile-margin">
                    <div className="headbar">
                        <div className="logo-desktop" style={{marginLeft: "24.4%", fontFamily: "DM Serif Display", fontSize: "2em"}}>MedicareBits</div>
                        <div className="headbar-links" style={{color: 'black', width: '100%', height: '30px', paddingTop: '10px', fontSize: '16px', fontWeight: '700', marginLeft: "24.4%"}}>
                            <a href="https://www.medicarebits.com" style={{marginRight: "20px", textDecoration: "none", color: 'black'}}>HOME</a>
                            <a href="https://medicarebits.com/?p=171" style={{marginRight: "20px", textDecoration: "none", color: 'black'}}>MEDICARE ADVANTAGE</a>
                            <a href="https://medicarebits.com/?p=179" style={{marginRight: "20px", textDecoration: "none", color: 'black'}}>MEDICARE SUPPLEMENT</a>
                            <a href="https://medicarebits.com/?p=184" style={{marginRight: "20px", textDecoration: "none", color: 'black'}}>DRUG PLANS</a>
                            <a href="https://medicarebits.com/?p=279" style={{marginRight: "20px", textDecoration: "none", color: 'black'}}>SPECIAL NEEDS</a>
                            <a href="https://www.plans.medicarebits.com/findplans" style={{marginRight: "20px", textDecoration: "none", color: 'black'}}>FIND PLANS</a>
                        </div>
                        
                        <div className="hamburger-menu" style={{position: 'absolute', left: "0%", top: "40px", width: "100%"}}>
                            <button onClick={() => {setHamburgerOpen(true)}} style={{backgroundColor: 'black', borderColor: 'black', marginLeft: '20px', marginTop: '-12px'}}>
                                <img width='25px' height='25px' src="https://planprovide-resources.s3.us-west-1.amazonaws.com/hamburger-icon.png" style={{color: 'white'}}></img>
                            </button>
                            <div style={{marginTop: "-10px", fontFamily: "DM Serif Display", fontSize: "2em", width: "100%",
                                         color: 'white', display: 'flex', justifyContent: 'center'}}>MedicareBits</div>
                            {
                                hamburgerOpen ?
                                    <div className="hamburger-menu-bg" style={{position: 'fixed', width: '320px', height: '100%', backgroundColor: 'white', 
                                                                        left: "0px", top: "0px", transition: '2s'}}>
                                        <div className="hamburger-menu-links" style={{padding: '20px', marginTop: '82px', flexDirection: 'column',
                                                fontFamily: 'DM Sans', fontSize: '18px', lineHeight: '1.444em', fontWeight: '700', zIndex: '2'}}>
                                            <Nav defaultActiveKey="https://www.medicarebits.com" className="flex-column">
                                                <Nav.Link style={{color: 'black'}} href="https://www.medicarebits.com">Home</Nav.Link>
                                                <Nav.Link style={{color: 'black'}} href="https://medicarebits.com/?p=171">Medicare Advantage</Nav.Link>
                                                <Nav.Link style={{color: 'black'}} href="https://medicarebits.com/?p=179">Medicare Supplement</Nav.Link>
                                                <Nav.Link style={{color: 'black'}} href="https://medicarebits.com/?p=184">Drug Plans</Nav.Link>
                                                <Nav.Link style={{color: 'black'}} href="https://medicarebits.com/?p=279">Special Needs</Nav.Link>
                                                <Nav.Link style={{color: 'black'}} href="https://www.plans.medicarebits.com/findplans">Find Plans</Nav.Link>
                                            </Nav>
                                        </div>
                                        <div style={{position: 'fixed', backgroundColor: 'rgba(0, 0, 0, 0.8)', width: '100%', height: '100%', top: '0px', left: '320px', zIndex: '1'}}
                                        onClick={() => {setHamburgerOpen(false)}}></div>
                                    </div>
                               
                                : <></>
                            }
                        </div>
                    </div>      
                    <Breadcrumb className='breadcrumbs-bootstrap' style={{marginLeft: "5.4%", marginTop: "10px"}}>
                        <Breadcrumb.Item href={`${window.location.origin}/findplans`}
                                         active={state === undefined && county === undefined && type === undefined && carrier === undefined}
                                         linkProps={{className: "breadcrumb"}}>
                            Find Plans</Breadcrumb.Item>
                        {state !== undefined ?   
                            <Breadcrumb.Item href={`${window.location.origin}/findplans/${state}`}
                                             active={state !== undefined && county === undefined && type === undefined && carrier === undefined}
                                             linkProps={{className: "breadcrumb"}}>
                                {stateName}</Breadcrumb.Item> : <></>}
                        {county !== undefined ?  
                            <Breadcrumb.Item href={`${window.location.origin}/findplans/${state}/${county}`}
                                             active={state !== undefined && county !== undefined && type === undefined && carrier === undefined}
                                             linkProps={{className: "breadcrumb"}}>
                                {countyName}</Breadcrumb.Item>  : <></>}
                        {type !== undefined ?  
                            <Breadcrumb.Item href={`${window.location.origin}/findplans/${state}/${county}/${type}`}
                                             active={state !== undefined && county !== undefined && type !== undefined && carrier === undefined}
                                             linkProps={{className: "breadcrumb"}}>
                                {typeName}</Breadcrumb.Item> : <></>}
                        {carrier !== undefined ?  
                            <Breadcrumb.Item href={`${window.location.origin}/findplans/${state}/${county}/${type}/${carrier}`}
                                             active={state !== undefined && county !== undefined && type !== undefined && carrier !== undefined}
                                             linkProps={{className: "breadcrumb"}}>
                                {carrierName}</Breadcrumb.Item> : <></>}
                    </Breadcrumb>
                    {state === undefined && county === undefined && type === undefined && carrier === undefined ?
                        <>
                        {ipSearchLoaded ?
                            <div style={{marginLeft: "5%"}}>
                                {ipSearchLoaded ?
                                    <SearchPlans
                                        history = {history}
                                        state={IPState}
                                        county={IPCounty}
                                        zip={IPZip}
                                    ></SearchPlans>
                                    : <LoadingOverlay/>
                                }                    
                                <div style={{marginTop: "20px"}}>
                                    <h1 style={{fontFamily: "DM Sans", marginBottom: "20px", fontSize: "1.5em"}}>Or select a state from the list below to find plans</h1>
                                    <div style={{marginLeft: "20px", width: "40%"}}>
                                        <Table striped bordered hover style={{width:"100%", marginBottom:"30px"}}>
                                        <tbody>
                                            <tr><td><a href = {`/findplans/alabama`}>Alabama</a></td></tr>
                                            <tr><td><a href = {`/findplans/alaska`}>Alaska</a></td></tr>
                                            <tr><td><a href = {`/findplans/arizona`}>Arizona</a></td></tr>
                                            <tr><td><a href = {`/findplans/arkansas`}>Arkansas</a></td></tr>
                                            <tr><td><a href = {`/findplans/california`}>California</a></td></tr>
                                            <tr><td><a href = {`/findplans/colorado`}>Colorado</a></td></tr>
                                            <tr><td><a href = {`/findplans/connecticut`}>Connecticut</a></td></tr>
                                            <tr><td><a href = {`/findplans/delaware`}>Delaware</a></td></tr>
                                            <tr><td><a href = {`/findplans/florida`}>Florida</a></td></tr>
                                            <tr><td><a href = {`/findplans/georgia`}>Georgia</a></td></tr>
                                            <tr><td><a href = {`/findplans/hawaii`}>Hawaii</a></td></tr>
                                            <tr><td><a href = {`/findplans/idaho`}>Idaho</a></td></tr>
                                            <tr><td><a href = {`/findplans/illinois`}>Illinois</a></td></tr>
                                            <tr><td><a href = {`/findplans/indiana`}>Indiana</a></td></tr>
                                            <tr><td><a href = {`/findplans/iowa`}>Iowa</a></td></tr>
                                            <tr><td><a href = {`/findplans/kansas`}>Kansas</a></td></tr>
                                            <tr><td><a href = {`/findplans/kentucky`}>Kentucky</a></td></tr>
                                            <tr><td><a href = {`/findplans/louisiana`}>Louisiana</a></td></tr>
                                            <tr><td><a href = {`/findplans/maine`}>Maine</a></td></tr>
                                            <tr><td><a href = {`/findplans/maryland`}>Maryland</a></td></tr>
                                            <tr><td><a href = {`/findplans/massachusetts`}>Massachusetts</a></td></tr>
                                            <tr><td><a href = {`/findplans/michigan`}>Michigan</a></td></tr>
                                            <tr><td><a href = {`/findplans/minnesota`}>Minnesota</a></td></tr>
                                            <tr><td><a href = {`/findplans/mississippi`}>Mississippi</a></td></tr>
                                            <tr><td><a href = {`/findplans/missouri`}>Missouri</a></td></tr>
                                            <tr><td><a href = {`/findplans/montana`}>Montana</a></td></tr>
                                            <tr><td><a href = {`/findplans/nebraska`}>Nebraska</a></td></tr>
                                            <tr><td><a href = {`/findplans/nevada`}>Nevada</a></td></tr>
                                            <tr><td><a href = {`/findplans/new-hampshire`}>New Hampshire</a></td></tr>
                                            <tr><td><a href = {`/findplans/new-jersey`}>New Jersey</a></td></tr>
                                            <tr><td><a href = {`/findplans/new-mexico`}>New Mexico</a></td></tr>
                                            <tr><td><a href = {`/findplans/new-york`}>New York</a></td></tr>
                                            <tr><td><a href = {`/findplans/north-carolina`}>North Carolina</a></td></tr>
                                            <tr><td><a href = {`/findplans/north-dakota`}>North Dakota</a></td></tr>
                                            <tr><td><a href = {`/findplans/ohio`}>Ohio</a></td></tr>
                                            <tr><td><a href = {`/findplans/oklahoma`}>Oklahoma</a></td></tr>
                                            <tr><td><a href = {`/findplans/oregon`}>Oregon</a></td></tr>
                                            <tr><td><a href = {`/findplans/pennsylvania`}>Pennsylvania</a></td></tr>
                                            <tr><td><a href = {`/findplans/rhode-island`}>Rhode Island</a></td></tr>
                                            <tr><td><a href = {`/findplans/south-carolina`}>South Carolina</a></td></tr>
                                            <tr><td><a href = {`/findplans/south-dakota`}>South Dakota</a></td></tr>
                                            <tr><td><a href = {`/findplans/tennessee`}>Tennessee</a></td></tr>
                                            <tr><td><a href = {`/findplans/texas`}>Texas</a></td></tr>
                                            <tr><td><a href = {`/findplans/utah`}>Utah</a></td></tr>
                                            <tr><td><a href = {`/findplans/vermont`}>Vermont</a></td></tr>
                                            <tr><td><a href = {`/findplans/virginia`}>Virginia</a></td></tr>
                                            <tr><td><a href = {`/findplans/washington`}>Washington</a></td></tr>
                                            <tr><td><a href = {`/findplans/west-virginia`}>West Virginia</a></td></tr>
                                            <tr><td><a href = {`/findplans/wisconsin`}>Wisconsin</a></td></tr>
                                            <tr><td><a href = {`/findplans/wyoming`}>Wyoming</a></td></tr>
                                        </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        : <LoadingOverlay/>}
                        </>
                    : 
                    loading ? <LoadingOverlay/> :
                    county === undefined && type === undefined && carrier === undefined ?
                    <>
                        {ipSearchLoaded ?
                            <div style={{marginLeft: "5%", width: "70%"}}>
                                <div style={{fontSize: "3em", fontFamily: "DM Serif Display"}}>Medicare plans in {stateName}</div><br/><br/>
                                <div style={{marginBottom: '20px', width: '60%'}}>
                                    Searching for plans available 
                                    in {stateName}? Please choose your county below.
                                </div>
                                <div>
                                    <h3 style={{marginBottom: '20px', fontSize: '2em'}}><b>Which county are you in?</b></h3>
                                    {countiesList !== undefined ?
                                        <div style={{width: '100%'}}>
                                            <Table striped bordered hover style={{width:"100%", marginBottom:"30px"}}>
                                            <tbody>
                                                {countiesList
                                                .sort((a, b) => (a > b) ? 1 : -1)
                                                .map((item, index) => {
                                                    return <><tr><td><a href = {`/findplans/${state}/${item.replaceAll("-", "_").replaceAll(" ", "-").replaceAll(" County","").toLowerCase()}`}>{item}</a></td></tr></>
                                                    
                                                    /*
                                                    return <><a class={
                                                        index < countiesList.length / 3 ? 'whiteButton col1' :
                                                        index < countiesList.length  * 2 / 3 ? 'whiteButton col2' :
                                                        'whiteButton col3'
                                                    } href = {`/findplans/${state}/${item.replaceAll(" ", "-").replaceAll(" County","").toLowerCase()}`}>{item}</a></>
                                                    */
                                                })}
                                            </tbody>
                                            </Table>
                                            <div style={{backgroundColor: 'white', minWidth: '330px', maxWidth: '60%', padding: '60px', paddingRight: '0px', borderRadius: "35px"}}>
                                                <h3 style={{marginBottom: '20px', fontSize: '2em'}}><b>Or select a plan type</b></h3>
                                                <a style={{padding: '10px 60px 10px 60px', minWidth: '265px', maxWidth: '60%', borderWidth: '1px', fontSize: '14px', fontWeight: 'bold'}} class='whiteButton' href = {`/findplans/${state}/all-counties/medicare-advantage`}>Medicare Advantage</a>
                                                <a style={{padding: '10px 60px 10px 60px', minWidth: '265px', maxWidth: '60%', borderWidth: '1px', fontSize: '14px', fontWeight: 'bold'}} class='whiteButton' href = {`/findplans/${state}/all-counties/medicare-supplement`}>Medicare Supplement</a>
                                                <a style={{padding: '10px 60px 10px 60px', minWidth: '265px', maxWidth: '60%', borderWidth: '1px', fontSize: '14px', fontWeight: 'bold'}} class='whiteButton' href = {`/findplans/${state}/all-counties/prescription-drug-plan`}>Prescription Drug Plan (PDP)</a>
                                                <a style={{padding: '10px 60px 10px 60px', minWidth: '265px', maxWidth: '60%', borderWidth: '1px', fontSize: '14px', fontWeight: 'bold'}} class='whiteButton' href = {`/findplans/${state}/all-counties/special-needs-plan`}>Special Needs Plan (SNP)</a>
                                            </div>
                                        </div> : <></>
                                    }
                                </div>
                            </div>
                        : <LoadingOverlay/>}
                    </>
                    : type === undefined && carrier === undefined ?
                    <div style={{marginLeft: "5%"}}>
                        <div style={{fontSize: "3em", fontFamily: "DM Serif Display", marginBottom: '20px', width: '60%'}}>Medicare plans in {countyName}, {stateName}</div>
                        <div className="bodyParagraph" style={{marginBottom: '20px'}}>
                            There are Medicare Advantage, Medicare Supplement, Prescription Drug and Special Needs plans available 
                            in {countyName}, {stateName}. Please choose the type of plan you're looking for below.
                        </div>
                        <div style={{backgroundColor: 'white', minWidth: '330px', maxWidth: '60%', padding: '60px', paddingRight: '0px', borderRadius: "35px"}}>
                            <h3 style={{marginBottom: '20px', fontSize: '2em'}}><b>Which type of plan are you looking for?</b></h3>
                            <a style={{padding: '10px 60px 10px 60px', minWidth: '265px', maxWidth: '60%', borderWidth: '1px', fontSize: '14px', fontWeight: 'bold'}} class='whiteButton' href = {`/findplans/${state}/${county}/medicare-advantage`}>Medicare Advantage</a>
                            <a style={{padding: '10px 60px 10px 60px', minWidth: '265px', maxWidth: '60%', borderWidth: '1px', fontSize: '14px', fontWeight: 'bold'}} class='whiteButton' href = {`/findplans/${state}/${county}/medicare-supplement`}>Medicare Supplement</a>
                            <a style={{padding: '10px 60px 10px 60px', minWidth: '265px', maxWidth: '60%', borderWidth: '1px', fontSize: '14px', fontWeight: 'bold'}} class='whiteButton' href = {`/findplans/${state}/${county}/prescription-drug-plan`}>Prescription Drug Plan (PDP)</a>
                            <a style={{padding: '10px 60px 10px 60px', minWidth: '265px', maxWidth: '60%', borderWidth: '1px', fontSize: '14px', fontWeight: 'bold'}} class='whiteButton' href = {`/findplans/${state}/${county}/special-needs-plan`}>Special Needs Plan (SNP)</a>
                        </div>
                    </div>
                    : carrier === undefined ?
                        county === 'all-counties' ? 
                            <div style={{marginLeft: "5%"}}>
                                <div style={{fontSize: "3em", fontFamily: "DM Serif Display", marginBottom: '20px', width: '60%'}}>{(typeName + "s " + typeAbbrev).trim()} in {stateName}</div>
                                <div className="bodyParagraph" style={{marginBottom: '20px'}}>
                                    Please choose a carrier below.
                                </div>
                                <div>
                                    <h3 style={{marginBottom: '20px', fontSize: '2em'}}><b>Choose a carrier to view {(typeName + "s " + typeAbbrev).trim()}</b></h3>
                                    <Table striped bordered hover style={{width:"50%", marginBottom:"30px"}}>
                                    <tbody>
                                    {
                                        status === 'success' ?
                                        carriersInState.map((c, index) => {
                                            if (c === undefined) return null; else
                                            return (
                                                <><tr><td><a href = {`/findplans/${state}/${county}/${type}/${c.replaceAll(' ', '-').toLowerCase()}`} key={index}>{c}</a></td></tr></>
                                            )
                                        })
                                        : "Loading carriers..."
                                    }
                                    </tbody>
                                    </Table>
                                </div>
                            </div>
                        :
                            <>
                                <Route path={`${props.match.path}/:state?/:county?/medicare-advantage`}        render={() => (<CountyStateMAPage controller={props.controller}
                                                                                                                        ratingTable={ratingTable()}
                                                                                                                        planYear={'2022'}
                                                                                                                        countyName={countyName}
                                                                                                                        stateName={stateName}
                                                                                                                        highestEnrolledPlan={highestEnrolledPlan.name}
                                                                                                                        highestEnrolledPlanValue={((parseFloat(highestEnrolledPlan.enrollment) / totalEnrollment())*100).toFixed(2) + "%"}
                                                                                                                        highestEnrolledPlanURL={highestEnrolledPlan.carrier ? highestEnrolledPlan.carrier.web_addr : ""}
                                                                                                                        highestRatedPlan={highestRatedPlan.name}
                                                                                                                        highestRatedPlanValue={highestRatedPlan.star_rating + " stars"}
                                                                                                                        highestRatedPlanURL={highestRatedPlan.carrier ? highestRatedPlan.carrier.web_addr : ""}
                                                                                                                        lowestMoopPlan={lowestMoopPlan.name}
                                                                                                                        lowestMoopPlanValue={lowestMoopPlan.moop}
                                                                                                                        lowestMoopPlanURL={lowestMoopPlan.carrier ? lowestMoopPlan.carrier.web_addr : ""}
                                                                                                                        dateUpdated={new Date('January 20, 2022 06:00:00').toLocaleDateString() + " - " + timeSince(new Date('January 20, 2022 06:00:00'))}
                                                                                                        />)}/>
                                <Route path={`${props.match.path}/:state?/:county?/prescription-drug-plan`}       render={() => (<CountyStatePDPPage controller={props.controller}
                                                                                                                        ratingTable={ratingTable()}
                                                                                                                        planYear={'2022'}
                                                                                                                        countyName={countyName}
                                                                                                                        stateName={stateName}
                                                                                                                        dateUpdated={new Date('January 20, 2022 06:00:00').toLocaleDateString() + " - " + timeSince(new Date('January 20, 2022 06:00:00'))}
                                                                                                        />)}/>
                                <Route path={`${props.match.path}/:state?/:county?/medicare-supplement`}        render={() => (<CountyStateSuppPage controller={props.controller}
                                                                                                                        ratingTableG={ratingTable("G")}
                                                                                                                        ratingTableN={ratingTable("N")}
                                                                                                                        ratingTableHDG={ratingTable("HDG")}
                                                                                                                        planYear={'2022'}
                                                                                                                        countyName={countyName}
                                                                                                                        stateName={stateName}
                                                                                                                        dateUpdated={new Date('January 20, 2022 06:00:00').toLocaleDateString() + " - " + timeSince(new Date('January 20, 2022 06:00:00'))}
                                                                                                        />)}/>
                                <Route path={`${props.match.path}/:state?/:county?/special-needs-plan`}       render={() => (<CountyStateSNPPage controller={props.controller}
                                                                                                                        ratingTable={ratingTable()}
                                                                                                                        planYear={'2022'}
                                                                                                                        countyName={countyName}
                                                                                                                        stateName={stateName}
                                                                                                                        dateUpdated={new Date('January 20, 2022 06:00:00').toLocaleDateString() + " - " + timeSince(new Date('January 20, 2022 06:00:00'))}
                                                                                                        />)}/>
                            </>
                    :
                    <div style={{marginLeft: "5%"}}>
                        {
                            county === 'all-counties' ?
                                <>
                                    <div style={{fontSize: "3em", fontFamily: "DM Serif Display", marginBottom: '20px', width: '60%'}}>{carrierName} {(typeName + "s " + typeAbbrev).trim()} in {stateName}</div>
                                    <div className="bodyParagraph" style={{marginBottom: '20px'}}>
                                        {carrierName} provides {(typeName + "s " + typeAbbrev).trim()} in {stateName}. Compare plans and 
                                        find the right one for you in the table below.
                                    </div>
                                    <div className="bodyParagraph" style={{marginBottom: '20px'}}>
                                        {ratingTable("", carrierName)}
                                    </div>
                                </>
                            :
                                <>
                                    <div style={{fontSize: "3em", fontFamily: "DM Serif Display", marginBottom: '20px', width: '60%'}}>{carrierName} {(typeName + "s " + typeAbbrev).trim()} in {countyName}, {stateName}</div>
                                    <div className="bodyParagraph" style={{marginBottom: '20px'}}>
                                        {carrierName} provides {(typeName + "s " + typeAbbrev).trim()} in {stateName}. Compare plans and 
                                        find the right one for you in the table below.
                                    </div>
                                    <div className="bodyParagraph" style={{marginBottom: '20px'}}>
                                        {ratingTable("", carrierName)}
                                    </div>
                                </>
                        }
                        
                    </div>
                    }
                </div>
                <div className = "footer" style={{backgroundColor: "#FFF7EB"}}>
                    <div className = "footer-content" style={{marginLeft:"20%", marginTop: "50px", paddingTop: "30px", width: "60%"}}>
                        <img src='https://planprovide-resources.s3.us-west-1.amazonaws.com/medicare-bits-logo.png' height='35px' style={{}}></img><br/>
                        
                        <div className="horCont" style={{fontFamily: 'DM Serif Display', justifyContent: "flex-start"}}>
                            <div style={{marginRight: "20px"}}>About us</div>
                            <div style={{marginRight: "20px"}}>Terms of Use</div>
                            <div style={{marginRight: "20px"}}>Privacy Policy</div>
                            <div style={{marginRight: "20px"}}>Disclaimers</div>
                        </div>

                        <div className="footerDetails" style={{paddingBottom: "50px", fontSize: "12px"}}>
                            MedicareBits.com is not affiliated with or endorsed by any government website entity or publication.

                            The Medicare Advantage and Medicare Part D prescription drug plan data on our site comes directly from Medicare and is subject to change.
                            Medicare has neither reviewed nor endorsed the information on our site.

                            We provide our site for educational purposes and strive to present unbiased and accurate information. However, MedicareBits is not intended as 
                            a substitute for your lawyer, doctor, healthcare provider, financial advisor, or pharmacist. For more information on your Medicare coverage, 
                            please be sure to seek legal, medical, pharmaceutical, or financial advice from a licensed professional or telephone Medicare at 1-800-633-4227.

                            We are an independent education, research, and technology company. We are not affiliated with any Medicare plan, plan carrier, healthcare provider, 
                            or insurance company. We are not compensated for Medicare plan enrollments.

                            Limitations, copayments, and restrictions may apply.
                            We make every effort to show all available Medicare Part D or Medicare Advantage plans in your service area. However, since our data is provided 
                            by Medicare, it is possible that this may not be a complete listing of plans available in your service area. For a complete listing please contact 
                            1-800-MEDICARE (TTY users should call 1-877-486-2048), 24 hours a day/7 days a week or consult www.medicare.gov.
                        </div>
                    </div>
                </div>
            </div>
            <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/9260589.js"></script>
        </>
    );
}



export default withRouter(FindPlans);
