import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { ClientContext } from '../components/PCAContext';
import { BrokerContext } from '../components/BrokerContext';

import mixpanel from 'mixpanel-browser';

import Sidebar           from '../components/Sidebar';
import Headbar           from '../components/Headbar';
import SetEnvironment    from '../components/SetEnvironment';

import './pages.css';
import './ClientFormPage.css';

import ClientDetailsForm from '../components/ClientDetailsForm';
import InfiniteQueryList from '../components/InfiniteQueryList';

// Fetch all applications by broker id
const fetchClientApplications = async (key, broker_id, client_id, page_num=1) => {
  if (client_id != -1) {
    let json = JSON.stringify({"broker_id": broker_id, "client_id": client_id, "page_num": page_num});

    const res = await fetch('/api/fetch_paginated_client_applications', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: json
    });
    return res.json();
  } else {
    return [];
  }
}

function ClientDetails(props) {
  let history = useHistory();

  const [client, setClient] = useContext(ClientContext);
  const [broker, setBroker] = useContext(BrokerContext);

  let { id } = useParams();

  let bannerSuccessMessage = "Client Updated!";
  let bannerFailureMessage = "An unknown error occurred."

  const handleSubmit = (json) => {
    console.log("submit")
    return new Promise((resolve, reject) => {
      // Uses POST method to send json object of data to Flask
      fetch('/api/update_client' , {
        method: 'POST',
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: json,
      }).then(res => {
        // Checks that external services return ok, throws 400-500 errors automatically made by the system
        if (res.ok) {
          resolve("success");
          return res.json();
        } else {
          if (res.status === 400) {
            resolve("failure");
            return res.json();
          }
        }
      }
      ).then(data => {
        // Checks for errors thrown by internal services, sends to error page
        if (data["status"] === "error") {
          let errorMessage = data["errorMessage"];
          history.push(`/error/${errorMessage}`);
          reject("Internal error.");
        }
      });
    })
  }

  useEffect(() => {
     if (history) {
       return history.listen((location) => {
          props.controller.abort();
          console.log(`You changed the page to: ${location.pathname}`);
       });
     }
  },[history]);

  return (
    <div className = "background">
        <SetEnvironment></SetEnvironment>
        <Sidebar current={props.sidebarLocation || "clients"} controller={props.controller}></Sidebar>
        <div className = "middle container">
            <Headbar title = "Clients"></Headbar>
            <ClientDetailsForm
              client_id={id}
              title={client.first_name + " " + client.last_name}
              subTitle="Update client information and view application history below."
              pageTitle="Clients"
              buttonLabel="Update"
              handleSubmit={handleSubmit}
              mixpanelEvent="Update client profile"
              bannerSuccessMessage={bannerSuccessMessage}
              bannerFailureMessage={bannerFailureMessage}
              canEditEmail={true}
            />

            <InfiniteQueryList
                itemName="application"
                title="Application History"
                queryName="clientApplications"
                queryArgs={[broker.id, client.id]}
                queryFunc={fetchClientApplications}
                location="client details page"
                mixpanelTrack="New application started"
                mixpanelTimeEvent="Client form submitted"
                newItemDestination="/applications/pick-client/"
                bannerMessage="Application(s) successfully deleted!"
                isApplicationHistory={true}
                style={{width: "90%"}}
            ></InfiniteQueryList>
        </div>
    </div>
  );
}

export default withRouter(ClientDetails);
