import React, { useContext, useState, useEffect } from 'react';
import { withRouter, useHistory, Link, useParams } from 'react-router-dom';
import { useQuery, useMutation, queryCache } from 'react-query';

import { BrokerContext } from '../../../components/BrokerContext';
import { PlanContext, ClientContext, ApplicationContext } from '../../../components/PCAContext';
import LoadingOverlay from '../../../components/LoadingOverlay';
import AncillaryPlanBox from '../../../components/AncillaryPlanBox';

import * as ApplicationServices from '../../../domain/application';
import * as ApplicationEnvelopeServices from '../../../domain/applicationEnvelope';

import ApplicationEnvelope from '../../../data/applicationEnvelope';
import Application from '../../../data/application';
import Broker from '../../../data/broker';
import Client from '../../../data/client';

import './ReadySubmit.css';

import Banner from '../../../components/Banner';

import mixpanel from 'mixpanel-browser';
import { ENVContext } from '../../../components/ENVContext';

function ReadyToSubmit(props) {
    let history = useHistory();

    const [ENV, setENV] = useContext(ENVContext);
    const [broker, setBroker] = useContext(BrokerContext);

    const [plan, setPlan] = useContext(PlanContext);
    const [client, setClient] = useContext(ClientContext);
    const [application, setApplication] = useContext(ApplicationContext);

    const [submitMethods, setSubmitMethods] = useState([]);
    const [showBanner, setShowBanner] = useState(false);
    const [submissionLoading, setSubmissionLoading] = useState({});
    const [refreshPoll, setRefreshPoll] = useState(0);
    const [faxValues, setFaxValues] = useState({});
    const [tabIsFocused, setTabIsFocused] = useState(true);

    const [sendEmailMutate] = useMutation(async (data) => {
      if (multiplePlansStatus !== 'success') {
          return;
      }
      console.log('sendEmailMutate');

      const formData = new FormData(data.formData);

      var object = {};
      formData.forEach((value, key) => {object[key] = value});

      let app = new Application(object);
      app.submission_email = object.secure_email;
      app.submission_email_other = object.additional_email;
      app.update(data.app);

      ApplicationServices.submit(data.submitMethod, application, app, broker, client).then(res => {
        setApplication({ ...application, ...res });
        setShowBanner(true);
      });

      mixpanel.init(ENV.MIXPANEL_TOKEN);
      mixpanel.track(app.type + " Application submitted", { application_id: application.id});
    });

    const { data: multiplePlansQuery, status: multiplePlansStatus } = useQuery(
      ['multiplePlans', application.id], 
      async (key, id) => {
        return ApplicationEnvelopeServices.get(id, props.controller.controller.signal);
      }
    );

    const sendEmail = async (data) => {
      try {
        await sendEmailMutate(data);
      }
      catch {
        console.log("ERROR!");
      }
      setSubmissionLoading({...submissionLoading, [data.index]: true});
    }

    function onFocus() {
      setTabIsFocused(true);
    }
    function onBlur() {
      setTabIsFocused(false);
    }

    function advanceToNext() {
      console.log("NEXT")
      console.log(application.id);
      ApplicationEnvelopeServices.update({
        id: application.id,
        status: 5
      }).then(res => {
        history.push(`/applications/submitted/${application.id}`);
      });

      
    }

    useEffect(() => {
      window.addEventListener('focus', onFocus);
      window.addEventListener('blur', onBlur);
      // Specify how to clean up after this effect:
      return () => {
        window.removeEventListener('focus', onFocus);
        window.removeEventListener('blur', onBlur);
      };
    }, []);

    useEffect(() => {
      if (multiplePlansStatus === 'success' && multiplePlansQuery !== null && multiplePlansQuery !== undefined && Array.isArray(multiplePlansQuery.applications)) {
        let allAppsSubmitted = true;
        for (let p of multiplePlansQuery.applications) {
          if (p.status !== 5) {
            allAppsSubmitted = false;
            break;
          }
        }
        if (allAppsSubmitted) {
          console.log("ALL APPS SUBMITTED!");
          advanceToNext();
        } 
      }
    }, [multiplePlansQuery]);

    useEffect(() => {
      console.log(submitMethods);
    }, [submitMethods])

    useEffect(() => {
        console.log("poll");

        setTimeout(() => {
            setRefreshPoll(refreshPoll+1);
        }, 1000);

        if (tabIsFocused) {
          console.log("db check");
          checkSubmissionStatus();
        }
    }, [application, refreshPoll]);

    function checkSubmissionStatus() {
      try {
        queryCache.refetchQueries(['multiplePlans', application.id], {
          exact: false,
          stale: true,
          throwOnError: true,
          active: true,
        }).then(()=>{ console.log("queries refetched"); });
      } catch (error) {
        console.log("ERROR! failed to refresh items")
        console.log(error)
      }
    }

    function updateSubmitMethod(index, value) {
      let s = [...submitMethods];
      s[index] = value;

      setSubmitMethods(s)
    }

    function removeGMT(s) {
      return s.replace("GMT", "");
    }

    return(
      <>
        {
          (multiplePlansStatus === "loading") ?
            <LoadingOverlay/> : <></>
        }
        <div className = "mainTitle">
            {
                showBanner ? (<Banner message ="Email sent!"></Banner>)
                : (<div className = "bannerEmpty"></div>)
            }
            <div className = "titleInfo" style={{left:"5%"}}>
                <h1 className = "titleName">Application Ready to Submit</h1>
                <p className = "titleDetails">Your application has been signed. Choose how you would like to submit the application.</p>
            </div>
        </div>
        <div className = "mainArea">
          <div className = "vertCont appInfo" style={{left:"5%", width:"80%"}}>
            <div className = "horCont appName">
              <div className = "vertCont">
                  <label>Name</label>
                  <Link to = {'/clientdetails/'+client.id} className = "linkStyle">{`${client.first_name} ${client.last_name}`}</Link>
                  <br/>
                  <label>Plans</label>
              </div>
            </div>
            {
            multiplePlansStatus === 'success' && multiplePlansQuery !== null && multiplePlansQuery !== undefined && Array.isArray(multiplePlansQuery.applications) ?
            multiplePlansQuery.applications.sort((a, b) => parseFloat(a.premium) < parseFloat(b.premium) ? 1 : -1)
            .map((option, index) => (
               option.type ?
               <form name = {`form_${option.type}`}
                     id={`form_${option.type}`}
                     onSubmit = {(e) => {e.preventDefault(); sendEmail({formData: e.target, index: index, app: option, submitMethod: submitMethods[index]}); }}
                     style={{height: "300px", marginBottom: "130px"}}>
                   <div style = {{display: "flex", marginLeft: "-10%", width: "110%", paddingTop: "20px", paddingLeft: "80px"}}
                        className = "vertCont submitInfo formArea">
                       <div className = "horCont">
                           <div style = {{left: "0%"}} className = "vertCont">
                               <div className = "vertCont">
                                   {option.type === "SOA" ? <></> :
                                     <div style = {{left: "0px", marginLeft: "0px", width: "90%"}} className = "horCont boxLabels">
                                         <div style = {{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                           <label style = {{width: "35%", marginLeft:"60px"}}>Carrier</label>
                                           <label style = {{width: "10%"}}>Plan</label>
                                           <label style = {{width: "18%"}}>Type</label>
                                           <label style = {{width: "27%"}}>Monthly Premium</label>
                                           <label style = {{width: "0%"}}></label>
                                         </div>
                                     </div>
                                   }
                                   <AncillaryPlanBox
                                      type={option.type === '1' ? "Medicare Supplement" : option.type === 'dental' ? "Dental" : option.type === 'vision' ? "Vision" : option.type}
                                      companyName = {option.carrier_name}
                                      planName = {option.name}
                                      value = {option.id}
                                      notes = {option.notes}
                                      premium = {option.premium ? option.premium : "Not available"}
                                      style = {{marginLeft: "0%", width:"90%", flex: "1", border: "solid 1px #BDBDBD", boxShadow: "none"}}
                                      carrierWidth = "35%"
                                      planWidth = "10%"
                                      typeWidth = "18%"
                                      premiumWidth = "7%"
                                      buttonWidth = "20%"
                                      hideButton
                                      hideDetailsButton
                                   ></AncillaryPlanBox>
                               </div>
                           </div>
                       </div>
                       <div className = "horCont">
                           <div className = "vertCont">
                               <label for = "submit_to">Submit to</label>
                               <select name = "submit_to"
                                       onChange = {(evt) => {
                                         if (evt.target.value == "carrier") {
                                           setFaxValues({...faxValues, [index]: option.carrier_fax});
                                         }
                                         else {
                                           setFaxValues({...faxValues, [index]: ""});
                                         }
                                       }}
                                       required>
                                   <option value = "carrier">Carrier</option>
                                   <option value = "FMO">FMO</option>
                               </select>
                           </div>
                           <div className = "vertCont">
                               <label for = "submit_method">Submission Method</label>
                               <select name = "submit_method" onChange={(evt) => updateSubmitMethod(index, evt.target.value)} required>
                                   <option value = "email">Secure Email</option>
                                   <option value = "fax">Fax</option>
                               </select>
                           </div>
                       </div>
                       {submitMethods[index] == 'fax' ?
                         <div className = "horCont">
                             <div className="half">
                               <div className = "vertCont">
                                   <label for = "fax_number">Fax Number</label>
                                   <input type = "text"
                                          name = {`fax_number_${option.type}`}
                                          defaultValue = {option.carrier_fax}
                                          value = {faxValues[index]}
                                          onChange = {(evt) => {setFaxValues({...faxValues, [index]: evt.target.value});}}
                                          required
                                   ></input>
                               </div>
                             </div>
                         </div>
                         :
                         <div className = "horCont">
                             <div className = "vertCont">
                                 <label for = "secure_email">Email to submit to</label>
                                 <input type = "email" name = {`secure_email`} placeholder = "example@example.com" required></input>
                             </div>
                             <div className = "vertCont">
                                 <label for = "additional_email">Additional email (optional)</label>
                                 <input type = "email" name = {`additional_email`} placeholder = "example@example.com"></input>
                             </div>
                         </div>
                       }
                       <div className="vertCont" style={{width: "550px"}}>
                          <div className = "horCont"
                               style={{marginTop: "20px"}}>
                             <input type="submit"
                                    className = {
                                      option.status === 5 || submissionLoading[index] === true ? "disabledButtonStyle" :
                                      "buttonStyle"}
                                    name={`submit_button_${option.type}`} id={`submit_button_${option.type}`}
                                    value="Submit"
                                    style={{width:"150px"}}>
                             </input>
                             <input type="button" className = "secondaryButtonStyle"
                                    value="Download Application"
                                    name={`download_button_${index}`} id={`download_button_${index}`}
                                    style = {{width: "220px", marginLeft: "30px"}}
                                    onClick = {() => {window.open(option.download_link)}}>
                             </input>
                             {option.type === "SOA" ?
                               <div style={{marginLeft: "40px", width:"160px", marginBottom: "12px"}}>
                                 <Link onClick={() => {advanceToNext()}}>Skip submission</Link>
                               </div> : <></>
                             }
                          </div>
                          <div style={{fontSize: "10px",
                                       color: "gray",
                                       width: "600px",
                                       marginTop: "10px",
                                       marginBottom: "10px",}}
                                name={`submission_text_${option.type}`} id={`submission_text_${option.type}`}>
                              {option.status === 5 ?
                                  "Emailed on " + removeGMT(option.date_submitted) + " to " + option.submit_email + "." : <></>
                              }
                          </div>
                       </div>
                   </div>
               </form> : <></>
            )) : <></>
            }
          </div>
        </div>
      </>
    );
}

export default ReadyToSubmit;
