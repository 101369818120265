import React, { useState, createContext, useContext } from 'react';
import { ENVProvider, ENVContext } from './ENVContext';
import { ReactQueryDevtools } from 'react-query-devtools'

export const NavigationContext = createContext();

export const NavigationProvider = props => {
    const [ENV, setENV] = useContext(ENVContext);

    return(
      <>
        <NavigationContext.Provider>
            {props.children}
        </NavigationContext.Provider>
        {/*ENV.environment === "dev" ? <ReactQueryDevtools initialIsOpen /> : <></> */}
      </>
    );
}
