import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory, Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import { BrokerContext } from '../../../components/BrokerContext';
import { ApplicationContext } from '../../../components/PCAContext';

import '../../pages.css';
import '../../Dashboard.css';

import AgencyNavigation from '../../../components/AgencyNavigation';
import DashBoxContainer from '../../../components/DashBoxContainer';
import DashBoxBrokerContainer from '../../../components/DashBoxBrokerContainer';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Pagination from '../../../components/Pagination';

import { compareDate } from '../../../utils.js';

import TooltipPlayer from 'react-tooltip-player';

require('dotenv').config()

const fetchApplications = async (key, broker_id) => {
    if (broker_id && broker_id != -1) {
        let json = JSON.stringify({"broker_id": broker_id, "page_num": 1});

        const res = await fetch('/api/get_paginated_applications_by_owner_broker_id', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: json
        });
        let x = res.json();
        console.log(x);
        return x;
    } else {
        return [];
    }
}

const fetchBrokers = async (key, agency_id) => {
    if (agency_id && agency_id != -1) {
        let json = JSON.stringify({"agency_id": agency_id});

        const res = await fetch('/api/get_brokers_by_agency_id', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: json
        });
        return res.json()
    } else {
        return [];
    }
}

function filterLast30(app) {
    let dateA = new Date(app.auto_create_date);
    let dateB = new Date();
    let time_diff = dateB.getTime() - dateA.getTime();
    let days_diff = time_diff / (1000 * 3600 * 24)
    return days_diff < 30;
}

function sortByMostApps(a, b) {
    if (a.applications.length < b.applications.length) {
        return 1;
    } else {
        return -1;
    }
}

function AgencyDashboard() {
    const [broker, setBroker] = useContext(BrokerContext);
    const [application, setApplication] = useContext(ApplicationContext);

    let { data: appsData = [], status: appsStatus } = useQuery(['dashAgencyApps', broker.id], fetchApplications);
    let { data: brokersData = [], status: brokersStatus } = useQuery(['dashBrokers', broker.agency_id], fetchBrokers);

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(Math.floor((window.innerHeight + 100) / 150));
    let history = useHistory();

    let indexOfLastPost = currentPage * postsPerPage;
    let indexOfFirstPost = indexOfLastPost - postsPerPage;
    let currentPosts = [];
    let recentlyCompletedApps = [];

    useEffect(() => {
      setApplication({
          id: -1,
          status: -1,
          url: '',
          download: '',
          csv: '',
          envelope_id: '',
          access_code: '',
          effective_date: '',
      });
    }, [])

    // if (brokersStatus == 'success' && Array.isArray(brokersData)) {
    //     brokersData;
    //     currentPosts = top_brokers.slice(indexOfFirstPost, indexOfLastPost);
    // }
    if (appsStatus == 'success' && Array.isArray(appsData.items)) {
        console.log('appsdata');
        console.log(appsData.items);
        recentlyCompletedApps = appsData.items.filter((app) => {
            return app.status == 5;
        });
        recentlyCompletedApps.sort(compareDate);
        recentlyCompletedApps.reverse();
        recentlyCompletedApps = recentlyCompletedApps.slice(0, 10);
    }

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const pageDown = () => setCurrentPage(currentPage-1);

    const pageUp = () => setCurrentPage(currentPage+1);

    return(
        <>
            <AgencyNavigation current="dashboard"/>
            <div style = {{height: "90vh", marginBottom: "0px"}} className = "mainArea">
            <div className = "dashArea" style={{}}>
                <div className = "appArea">
                    <div style = {{height: "10%"}}>
                        {
                            brokersStatus === 'loading' && (<p>Brokers loading...</p>)
                        }
                        {
                            brokersStatus === 'success' &&(<h3 className = "incompleteApps">Top brokers by submitted applications ({brokersData ? brokersData.length : 0})</h3>)
                        }
                    </div>
                    <div style = {{height: "85%"}}>
                        <DashBoxBrokerContainer brokers = {brokersStatus == 'success' && Array.isArray(brokersData) ? brokersData.sort(sortByMostApps).sort(compareDate) : null} status = {brokersStatus} size = "big"/>
                    </div>
                </div>
                <div className = "graphArea">
                    <div id = "dashGraph1">
                        <div style = {{marginTop: "10px", marginLeft: "10px", height: "10%"}}>
                            <h3>Applications submitted in past 30 days</h3>
                        </div>
                        <div style = {{height: "90%", display: "flex", alignSelf: appsStatus==="success" ? "center" : undefined}}>
                            {appsStatus === "success" ?
                              <h1 style = {{fontSize: "6em", alignSelf: "center"}}>
                                {recentlyCompletedApps.filter(filterLast30).length}
                              </h1>
                              : <div style={{marginTop:"34px", marginLeft: "10px"}}>Loading...</div>
                            }
                        </div>
                    </div>
                    <div style = {{marginTop: "10px"}} id = "dashGraph2">
                       <div style = {{marginTop: "10px", marginLeft: "10px"}}>
                            <h3>Recently submitted applications</h3>
                            <div>
                                {appsStatus === "success" ?
                                  <DashBoxContainer apps = {recentlyCompletedApps} status = {appsStatus} size = "small"></DashBoxContainer>
                                  : "Loading..."
                                }
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    );
}

export default withRouter(AgencyDashboard);
