import React from 'react';
import { usePaginatedQuery } from 'react-query';

import './Pagination.css';

const Pagination = ( {currentPage, postsPerPage, totalPosts, paginate, pageDown, pageUp} ) => {
    const pageNumbers = [];
    for(let i = 1; i<= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className = "horCont pageBox">
            {
                currentPage != pageNumbers[0]
                ? <div className = "arrowBox" onClick = {pageDown}><a>&#x2190;</a></div>
                : <div style = {{width: "40px"}}></div>
            }
            {pageNumbers.map((number, index) => (
                (pageNumbers.length < 10) || (number === currentPage) || (number > currentPage - 5 && number < currentPage + 5) || (number === pageNumbers.length) || (number === 1) ?
                  <div key = {index} className = "numberBox" onClick = {() => paginate(number)}
                       style={number === currentPage ? {background: "rgba(64, 104, 163, 0.5", marginLeft: "10px", marginRight: "10px"} : {marginLeft: "10px", marginRight: "10px"}}>
                      <a>{number}</a>
                  </div>
                : (pageNumbers.length > 10) && ((number === currentPage - 5) || (number === currentPage + 5)) ? "..." : <></>
            ))}
            {
                currentPage != pageNumbers[pageNumbers.length - 1]
                ? <div className = "arrowBox" onClick = {pageUp}><a>&#x2192;</a></div>
                : <div style = {{width: "40px"}}></div>
            }
        </nav>
    )

}

export default Pagination;
