import React from 'react';
import './Checkbox.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';

function CheckBox(props) {

    const handleChange = (evt) => {
      if (props.onChange) {
        props.onChange(!props.checked);
      }

      if (props.formikHandleChange) {
        props.formikHandleChange(evt);
      }
    }

    return(
        <div className = "checkDiv">
            <Field type="checkbox"
                   name = {props.name}
                   onChange={props.formikHandleChange ? handleChange : undefined}
                   defaultChecked = {props.defaultChecked || ""}
                   style = {{marginTop: "4px"}}
                   checked={props.checked || undefined}></Field>
            <div className = "checkLabelDiv">
                <label htmlFor={props.name}>{props.labelInfo}</label>
            </div>
        </div>
    );
}

export default CheckBox;
