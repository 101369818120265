export default class Agency {
    constructor(data) {
        this.update(data);
    }
    update(data) {
        this.id = data.id || this.id;
        this.name = data.name || this.name;
        this.npn = data.npn || this.npn;
        this.email = data.email || this.email;
        this.phone = data.phone || this.phone;

        this.db_brokers = data.db_brokers || this.db_brokers;

        this.auto_create_date = data.auto_create_date || this.auto_create_date;
        this.auto_last_updated = data.auto_last_updated || this.auto_last_updated;
    }
}